import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Currency, CurrencyAmount, TokenAmount, JSBI } from '@wowswap-io/wowswap-sdk'
import { getTradeTokenComparator } from '../../components/SearchModal/sorting'
import { MIN_AMOUNT_FOR_SWAP, ROUTER_ADDRESS } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { TradeToken } from '../../hooks/Tokens.types'
import { useAllValueTokens } from '../../hooks/tokens/tokenLists'
// import { TokenType, useAllTradeTokens, useAllTradeTokensWithBalances, useTokenHandlers } from '../../hooks/Tokens'
import {
  useAllShortingTokensWithBalances,
  useAllTradeTokensWithBalances,
  useStakeTokensWithBalances,
  useTokenHandlers
} from '../../hooks/tokens/Tokens'
import { usePathTradeExactIn } from '../../hooks/Trades'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useCalculateBalance } from '../../hooks/useCalculateBalance'
import { useCalculateOpenPosition } from '../../hooks/useCalculateOpenPosition'
import { calcHIR } from '../../utils/calculations'
import * as math from '../../utils/math'
import { bn } from '../../utils/math'
import { AppDispatch, AppState } from '../index'
import { tryParseAmount } from '../swap/hooks'

import { MIN_WOW_BALANCE_X4, MIN_WOW_BALANCE_X5 } from '../financial/actions'

import {
  Field,
  resetSwapState,
  selectCurrency,
  setClosePositionInfo,
  setIsShortTrade,
  setLeverage,
  setMaxPositionAmount,
  setOpenPositionInfo,
  switchCurrencies,
  typeInput
} from './actions'
import { TradeDerivedInfo } from './types'

const EMPTY_OPEN_POSITION_AMOUNT_TYPED = ''

export function useTradeActionHandlers(): {
  onCurrencySelection: (field: Field, currency?: TradeToken) => void
  onSwitchTokens: () => void
  onUserInput: (typedValue: string) => void
  onChangeLeverage: (leverage: number) => void
  onMaxPosition: (isMaxPosition: boolean) => void
  onShortTradeModeChange: (isShortTrade: boolean) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onCurrencySelection = useCallback(
    (field: Field, currency?: TradeToken) => {
      dispatch(
        selectCurrency({
          field,
          currency: currency?.address || ''
        })
      )
    },
    [dispatch]
  )

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies())
  }, [dispatch])

  const onUserInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ typedValue }))
    },
    [dispatch]
  )

  const onChangeLeverage = useCallback(
    (leverage: number) => {
      dispatch(setLeverage({ leverage }))
    },
    [dispatch]
  )

  const onMaxPosition = useCallback(
    (isMaxPosition: boolean) => {
      dispatch(setMaxPositionAmount({ isMaxPosition }))
    },
    [dispatch]
  )

  const onShortTradeModeChange = useCallback(
    (isShortTrade: boolean) => {
      dispatch(resetSwapState())
      dispatch(setIsShortTrade({ isShortTrade }))
    },
    [dispatch]
  )

  return {
    onUserInput,
    onMaxPosition,
    onSwitchTokens,
    onChangeLeverage,
    onCurrencySelection,
    onShortTradeModeChange
  }
}

export function useTradeClosePositionHandler() {
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (payload: { amountOut: string; debtPayable: string; protocolFee: string; profit: string }) => {
      dispatch(setClosePositionInfo(payload))
    },
    [dispatch]
  )
}

export function useTradeOpenPositionHandler() {
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (payload: { borrowAmount: string; borrowRate: number; liquidationCost: number; maxBorrow: string }) => {
      dispatch(setOpenPositionInfo(payload))
    },
    [dispatch]
  )
}

export function useTradeState(): AppState['trade'] {
  return useSelector<AppState, AppState['trade']>(state => state.trade)
}

export function useDerivedTradeInfo(): TradeDerivedInfo {
  const state = useTradeState()
  const allValuableTokens = useAllValueTokens()

  const { onCurrencySelection } = useTradeActionHandlers()
  const { toRealToken, toRealCurrency } = useTokenHandlers()

  const allTokensWithBalances = useAllTradeTokensWithBalances()
  const allShortTokensWithBalances = useAllShortingTokensWithBalances()

  const stakeTokens = useStakeTokensWithBalances()
  const xWOW = useMemo(() => stakeTokens.find(token => token.stakableInfo?.isBase), [stakeTokens])
  const stakePossibleTokens = useMemo(() => stakeTokens.filter(token => token !== xWOW), [stakeTokens, xWOW])
  const lpToken = useMemo(() => stakePossibleTokens.find(token => token.stakableInfo?.pairName), [stakePossibleTokens])
  const WOW = useMemo(() => stakePossibleTokens.find(token => token !== lpToken), [stakePossibleTokens, lpToken])

  const stakeState = useSelector<AppState, AppState['financial']>(state => state.financial)
  const minWowForX4 = stakeState[MIN_WOW_BALANCE_X4].current
  const minWowForX5 = stakeState[MIN_WOW_BALANCE_X5].current

  const WOWxWOW = useMemo(() => {
    return WOW?.balance && xWOW?.balance ? new TokenAmount(WOW, JSBI.add(WOW.balance.raw, xWOW.balance.raw)) : null
  }, [WOW, xWOW])

  const maxLeverage = useMemo(() => {
    if (WOWxWOW && typeof minWowForX4 === 'number' && typeof minWowForX5 === 'number') {
      const WOWsum = parseFloat(WOWxWOW.toExact())

      const isX5 = WOWsum > minWowForX5
      if (isX5)
        return {
          level: 5,
          needToX5: 0,
          needToX4: 0
        }
      const isX4 = WOWsum > minWowForX4
      if (isX4)
        return {
          level: 4,
          needToX5: (minWowForX5 - WOWsum).toFixed(2),
          needToX4: 0
        }
      return {
        level: 3,
        needToX5: (minWowForX5 - WOWsum).toFixed(2),
        needToX4: (minWowForX4 - WOWsum).toFixed(2)
      }
    }

    return {
      level: 3,
      needToX5: 0,
      needToX4: 0
    }
  }, [WOWxWOW, minWowForX4, minWowForX5])

  const tokens = state.isShortTrade ? allShortTokensWithBalances : allTokensWithBalances

  const inputToken = tokens[state.INPUT]
  const outputToken = tokens[state.OUTPUT]

  const isOpenPosition = useMemo(() => {
    if (inputToken) {
      return 'isLendable' in inputToken.info
    }
    return true
  }, [inputToken])

  const isOpenNative = useMemo(
    () => inputToken && isOpenPosition && 'isNative' in inputToken.info && inputToken.info.isNative,
    [inputToken, isOpenPosition]
  )

  const inputAmount = math
    .bn(state.typedValue || 0)
    .mul(math.bn(isOpenPosition ? state.leverage : 1))
    .toDecimals(isOpenNative || !inputToken ? 18 : inputToken.decimals)

  const typedAmount = tryParseAmount(state.typedValue, inputToken)

  const parsedAmount = useMemo(() => {
    if (inputAmount.isNaN()) {
      return undefined
    }

    const inputAmountStr = inputAmount.str()

    return isOpenNative
      ? CurrencyAmount.ether(inputAmountStr)
      : inputToken
      ? new TokenAmount(inputToken, inputAmountStr)
      : undefined
  }, [isOpenNative, inputAmount, inputToken])

  const tradeAmount = useMemo(() => {
    if (!parsedAmount || !inputToken) return undefined

    if (inputToken.lendableInfo?.isNative) {
      return CurrencyAmount.ether(parsedAmount.raw)
    }

    if (inputToken.proxyInfo || (inputToken.shortingInfo && parsedAmount)) {
      const realToken = toRealToken(inputToken)
      return realToken ? new TokenAmount(realToken, parsedAmount.raw) : undefined
    }

    return parsedAmount
  }, [parsedAmount, inputToken, toRealToken])

  const proxyAddress = isOpenPosition ? outputToken?.proxybleAddress : inputToken?.proxybleAddress

  const definedPath = useMemo(() => {
    const proxy = proxyAddress ? allValuableTokens[proxyAddress] : undefined
    const path = [inputToken, proxy, outputToken]
    return path.map(toRealCurrency).filter((token): token is Currency => !!token)
  }, [proxyAddress, inputToken, outputToken, toRealCurrency, allValuableTokens])

  const trade = usePathTradeExactIn(tradeAmount, definedPath) || undefined

  const possibleInputs = useMemo(() => {
    const list = Object.values(tokens)

    list.sort(getTradeTokenComparator())
    return list
  }, [tokens])

  const possibleOutputs = useMemo(() => {
    const list = Object.values(tokens).filter(token => {
      if (inputToken) {
        if (isOpenPosition) {
          if (state.isShortTrade) {
            return token.shortingInfo?.stableAddress === inputToken.address
          } else {
            return token.proxyInfo?.lendableAddress === inputToken.address
          }
        } else {
          if (state.isShortTrade) {
            return inputToken.shortingInfo?.stableAddress === token.address
          } else {
            return inputToken.proxyInfo?.lendableAddress === token.address
          }
        }
      }
      return true
    })
    list.sort(getTradeTokenComparator())
    return list
  }, [tokens, inputToken, isOpenPosition, state.isShortTrade])

  useEffect(() => {
    const isPossible = Boolean(possibleOutputs.find(token => token?.address === outputToken?.address))
    if (outputToken && !isPossible) {
      onCurrencySelection(Field.OUTPUT, undefined)
    }

    if (possibleOutputs.length === 1 && possibleOutputs[0]?.address !== outputToken?.address) {
      onCurrencySelection(Field.OUTPUT, possibleOutputs[0])
    }
  }, [outputToken, possibleOutputs, onCurrencySelection])

  const inputError = useMemo(() => {
    let inputError: string | undefined
    if (!inputToken || !outputToken) {
      inputError = inputError ?? 'Select a token'
    }

    if (!tradeAmount || !state.typedValue) {
      inputError = inputError ?? 'Enter an amount'
    }

    if (
      !state.isMaxPosition &&
      inputToken &&
      inputToken.balance &&
      typedAmount &&
      typedAmount.greaterThan(inputToken.balance)
    ) {
      inputError = inputError ?? 'Insufficient Balance'
    }

    return inputError
  }, [tradeAmount, typedAmount, inputToken, outputToken, state.isMaxPosition, state.typedValue])

  // preload data from contract

  const inputTokenBalance = inputToken?.balance

  const openPositionAmountTyped = useMemo(() => {
    const amountTyped = state.isMaxPosition ? inputTokenBalance : typedAmount

    return amountTyped ? amountTyped.raw.toString() : EMPTY_OPEN_POSITION_AMOUNT_TYPED
  }, [state.isMaxPosition, inputTokenBalance, typedAmount])

  const { callback: debtCallback } = useCalculateBalance({
    isOpenPosition,
    shorting: state.isShortTrade,
    amount: openPositionAmountTyped,
    inputAddress: toRealToken(inputToken)?.address,
    outputAddress: toRealToken(outputToken)?.address,
    proxyAddress: inputToken?.proxybleAddress
  })
  useEffect(() => {
    if (!debtCallback) {
      return
    }

    debtCallback()
  }, [debtCallback])

  const { callback: openPositionCallback } = useCalculateOpenPosition({
    isOpenPosition,
    shorting: state.isShortTrade,
    leverageFactor: state.leverage,
    amount: openPositionAmountTyped,
    inputAddress: toRealToken(inputToken)?.address,
    outputAddress: toRealToken(outputToken)?.address,
    proxyAddress: outputToken?.proxybleAddress
  })
  useEffect(() => {
    if (!openPositionCallback) {
      return
    }

    openPositionCallback()
  }, [openPositionCallback])

  // calculations

  const HIR = useMemo(() => (state.borrowRate ? calcHIR(state.borrowRate, 60 * 60) : undefined), [state.borrowRate])

  // presets
  const amount = useMemo(() => {
    const amount = outputToken ? new TokenAmount(outputToken, state.amountOut || '0') : undefined
    return amount
  }, [state.amountOut, outputToken])

  return {
    maxLeverage,
    inputToken,
    outputToken,
    possibleInputs,
    possibleOutputs,
    isOpenPosition,
    isOpenNative,
    inputError,
    position: {
      trade,
      leverage: state.leverage,
      amount,
      typedAmount,
      debtPayable: outputToken ? new TokenAmount(outputToken, state.debtPayable) : undefined,
      profit: outputToken && state.profit ? new TokenAmount(outputToken, state.profit) : undefined,
      protocolFee: outputToken ? new TokenAmount(outputToken, state.protocolFee) : undefined,
      borrowAmount: inputToken ? new TokenAmount(inputToken, state.borrowAmount) : undefined,
      borrowMax: inputToken ? new TokenAmount(inputToken, state.maxBorrow) : undefined,
      HIR
    }
  }
}

export function useApproval() {
  const { chainId } = useActiveWeb3React()

  // const { typedValue } = useTradeState()
  const { position, inputToken, inputError, isOpenPosition } = useDerivedTradeInfo()

  // check whether the user has approved the router on the input token

  const amount = useMemo(() => {
    if (!position.typedAmount) return undefined
    if (!inputToken) return undefined

    if (inputToken.lendableInfo?.isNative) {
      return CurrencyAmount.ether(position.typedAmount.raw)
    }

    return position.typedAmount
  }, [position.typedAmount, inputToken])

  const [approval, approveCallback] = useApproveCallback(amount, ROUTER_ADDRESS[chainId!])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const showApproveFlow =
    isOpenPosition &&
    !inputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED))

  return {
    approval,
    approvalSubmitted,
    approveCallback,
    setApprovalSubmitted,
    showApproveFlow
  }
}

export function useIsMoreThenMaximumAmount() {
  const { position, isOpenPosition, outputToken } = useDerivedTradeInfo()
  return useMemo(() => {
    if (isOpenPosition && position.leverage === 1) return undefined
    if (!outputToken) return undefined
    const borrowAmount = position.borrowAmount?.toExact() || 0
    const borrowMax = position.borrowMax?.toExact() || 0
    return isOpenPosition
      ? position.borrowMax?.toExact() === '0' || bn(borrowAmount).isGreaterThan(borrowMax)
      : undefined
  }, [isOpenPosition, position.borrowAmount, position.borrowMax, outputToken, position.leverage])
}

export function useMinAmountInForTrade() {
  const { typedValue, leverage } = useTradeState()
  const { isOpenPosition, inputToken } = useDerivedTradeInfo()
  const tradeState = useTradeState()

  return useMemo(() => {
    if (tradeState.isShortTrade) {
      return isOpenPosition && leverage >= 0.1 ? getMinimumAmount(inputToken, typedValue) : undefined
    } else {
      return isOpenPosition && leverage > 1 ? getMinimumAmount(inputToken, typedValue) : undefined
    }
  }, [isOpenPosition, inputToken, typedValue, leverage, tradeState.isShortTrade])
}

// private

function getMinimumAmount(token: TradeToken | undefined, typedValue: string): number | undefined {
  let minAmount
  if (token && token.lendableInfo?.isLendable && Number(typedValue) < MIN_AMOUNT_FOR_SWAP[token.symbol!]) {
    minAmount = MIN_AMOUNT_FOR_SWAP[token.symbol!]
  }

  return minAmount
}
