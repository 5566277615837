import React from 'react'
import styled from 'styled-components'

import VotesList from '../VotesList'
import SliderCustom from '../SliderCustom'

const CurrentResultsStyle = styled.div``
const CurrentResultsTitle = styled.div`
  margin-bottom: 20px;

  font-family: 'Retro';
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.text1};
`
const CurrentResultsSliders = styled.div`
  margin-bottom: 20px;
`
const CurrentResultsSliderStyle = styled.div`
  margin-bottom: 10px;
`
const CurrentResultsSliderTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 17px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		margin-bottom: 14px;
	`};
`
const CurrentResultsSliderTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text1};

  ${({ theme }) => theme.mediaWidth.upToLarge`
		font-size: 18px;
		line-height: 18px;
	`};
`
const CurrentResultsSliderValue = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text1};
`

const CurrentResultsSubtitle = styled.div`
  margin-bottom: 10px;

  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.text1};
`
interface CurrentResultsProps {
  title: string
}

export function CurrentResults({ title }: CurrentResultsProps) {
  return (
    <CurrentResultsStyle>
      <CurrentResultsTitle>{title}</CurrentResultsTitle>

      <CurrentResultsSliders>
        <CurrentResultsSlider title="For 25,101 xWOW" value="25%" />

        <CurrentResultsSlider title="Against 125,101 xWOW" value="75%" />
      </CurrentResultsSliders>

      <CurrentResultsSubtitle>Votes (24)</CurrentResultsSubtitle>

      <VotesList />
    </CurrentResultsStyle>
  )
}

interface CurrentResultsSliderProps {
  title: string
  value: string
}

function CurrentResultsSlider({ title, value }: CurrentResultsSliderProps) {
  return (
    <CurrentResultsSliderStyle>
      <CurrentResultsSliderTop>
        <CurrentResultsSliderTitle>{title}</CurrentResultsSliderTitle>

        <CurrentResultsSliderValue>{value}</CurrentResultsSliderValue>
      </CurrentResultsSliderTop>

      <SliderCustom from={0} to={100} max={100} value={10} onChange={() => {}} />
    </CurrentResultsSliderStyle>
  )
}
