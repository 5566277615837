import React from 'react'
import styled from 'styled-components'
import { ReactComponent as IconArrowsRight } from '../../assets/svg/icon--9-arrows-right.svg'

const TabsWrapper = styled.div`
  position: relative;

  max-width: 894px;
  margin: 0 auto;
  margin-top: 28px;
  margin-bottom: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
		margin: 0 12px;
		margin-top: 28px;
    margin-bottom: 15px;
		justify-content: flex-start;
	`};
`
const TabsBody = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	&::before {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 10

		display: none;
		width: 90px;
		height: 100%;
		
		content: '';
		background: linear-gradient(268.74deg, #F4BB30 1.19%, #F5BF31 32.29%, rgba(246, 196, 51, 0) 99.07%);

		${({ theme }) => theme.mediaWidth.upToSmall`
			display: block;
		`};
	}	
`
const TabsItem = styled.div`
  margin: 0 15px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		margin: 0 10px;
	`};
`
const TabsLink = styled.a<{ active?: 'active' }>`
  font-family: 'Retro';
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;

  transition: all 0.4s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.text2};
  }
  ${({ active }) => {
    if (active === 'active') {
      return `
				color: #E85A44;
			`
    } else {
      return `
				color: #000000;
			`
    }
  }}

  ${({ theme }) => theme.mediaWidth.upToSmall`
		font-size: 14px;
		line-height: 19px;
	`};
`
const TabsBtn = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 15;

  display: none;

  border: none;
  background: none;
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		display: flex;
	`};
`
export default function Tabs() {
  return (
    <TabsWrapper>
      <TabsBody>
        <TabsItem>
          <TabsLink href="#" active="active">
            All
          </TabsLink>
        </TabsItem>

        <TabsItem>
          <TabsLink href="#">Core</TabsLink>
        </TabsItem>

        <TabsItem>
          <TabsLink href="#">Listings</TabsLink>
        </TabsItem>

        <TabsItem>
          <TabsLink href="#">Donations</TabsLink>
        </TabsItem>

        <TabsItem>
          <TabsLink href="#">Active</TabsLink>
        </TabsItem>

        <TabsItem>
          <TabsLink href="#">Closed</TabsLink>
        </TabsItem>
      </TabsBody>

      <TabsBtn>
        <IconArrowsRight />
      </TabsBtn>
    </TabsWrapper>
  )
}
