import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { convertHexToRGBA } from 'utils/converters'

export const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0px 15px 3px;
  border-radius: 10px;
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  color: ${({ theme }) => theme.colors.text1};
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover:not(.active-nav-link) {
    background: ${({ theme }) => convertHexToRGBA(theme.colors.text2, 0.3)};
    transition: background-color 0.2s;
  }

  &.active-nav-link {
    color: ${({ theme }) => theme.colors.text2};
    cursor: default;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    &:hover {
      background: transparent;
    }
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
    padding: 0px 10px;
  `};

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      color: ${theme.colors.text4};
    `}
`

export const ExpandableLink = styled(Link)`
  svg {
    margin: 3px -5px 0px 5px;
  }
`

ExpandableLink.defaultProps = {
  to: '#'
}

export const SubMenuLink = styled(Link)`
  padding: 0px 15px;
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  border-radius: 0px;
  font-size: 16px;

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      font-size: 14px;
    `}
`

export const SubMenu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 100%;
  height: 0;
  width: 100%;
  border-radius: 0px 0px 10px 10px;
  background: white;
  overflow: hidden;
  transition: 0.2s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      height: auto;
      transition: background-color 0.2s;
    `}

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      background: black;
    `}
`

export const Item = styled.div<{ withSubMenuOpen?: boolean }>`
  position: relative;

  ${({ theme, withSubMenuOpen }) =>
    withSubMenuOpen &&
    css`
      border-radius: 10px 10px 0px 0px;

      ${ExpandableLink} {
        background: ${({ theme }) => convertHexToRGBA(theme.colors.text2, 0.3)};
        border-radius: 10px 10px 0px 0px;
        box-shadow: 0px 5px 24px -5px rgb(0 0 0 / 32%);
      }

      ${SubMenu} {
        box-shadow: 0px 10px 24px -5px rgb(0 0 0 / 32%);
      }

      ${theme.mode === 'dark' &&
        css`
          ${ExpandableLink} {
            box-shadow: 0px 0px 100px rgb(243 186 47 / 50%);
          }

          ${SubMenu} {
            box-shadow: 0px 0px 100px rgb(243 186 47 / 50%);
          }
        `}
    `}
`

export const DesktopNav = styled.nav`
  display: flex;
  margin-left: 20px;
  margin-right: 10px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    display: none;
  `};
`

export const MobileNav = styled.nav<{ position: 'top' | 'sidebar' }>`
  display: none;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    display: flex;
  `};

  ${({ position }) =>
    position === 'top' &&
    css`
      margin-left: 20px;
      margin-right: 10px;
    `};

  ${({ position }) =>
    position === 'sidebar' &&
    css`
      flex-direction: column;

      ${Link} {
        height: 40px;
      }
    `};
`
