import React from 'react'
import styled from 'styled-components'
import { ButtonConfirmed, ButtonPrimary } from '../components/Button'
import { ContentWrapper } from '../components/ContentWrapper'
import { FeeDistributionTable } from '../components/economicModel/FeeDistributionTable'
import FeeModule from '../components/economicModel/FeeModule'
import { ParamValue } from '../components/financailModel/ParamValue'
import {
  GridSidebarWraper,
  GridSidebar,
  SidebarLeft,
  GridSidebarBody,
  GridSidebarTitle,
  GridSidebarBlockTitle,
  GridSidebarBack,
  GridSidebarAction,
  GridSidebarHeader
} from '../components/GridSidebar'
import Loader from '../components/Loader'
import MenuSidebar from '../components/MenuSidebar'
import ProgressSteps from '../components/ProgressSteps'
import { AutoRow, RowBetween } from '../components/Row'
import { ApprovalState } from '../hooks/useApproveCallback'
import { TREASURE_FACTOR, Param, SingleParamType, TRADER_PROFIT_FEE, FeeParams } from '../state/financial/actions'
import {
  changeStateEqual,
  useFinancialModelInfo,
  useFinancialActionHandlers,
  useInitParams,
  useFinancialApprove,
  useVoteForParams,
  VoteStatus,
  useVoteForFees,
  fixErrorOnFee,
  calcNewValue
} from '../state/financial/hooks'
import { useActiveWeb3React } from '../hooks'
import { ChainId } from '@wowswap-io/wowswap-sdk'

const GovernanceModules = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -7px;
  margin-right: -7px;
  margin-bottom: 20px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
		flex-direction: column;
	`};
`
const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
`

export function GovernanceEconomicModel() {
  useInitParams()

  return <GovernanceEconomicModelComponent />
}

function GovernanceEconomicModelComponent() {
  const { chainId } = useActiveWeb3React()

  const { params, state, hasUpdate, hasFeeUpdate, updates, xWOW } = useFinancialModelInfo()
  const { onChangeSingleParam } = useFinancialActionHandlers()
  const {
    approval,
    approvalSubmitted,
    approveCallback,
    showApproveFlow,
    setApprovalSubmitted,
    tokenAmount
  } = useFinancialApprove()

  const handleChangeFeeParam = (field: Param, value: number) => {
    const userValues = Object.entries(params).reduce((acc, [key, item]) => {
      acc[key as Param] = item.userValue
      return acc
    }, {} as FeeParams)

    const newState = changeStateEqual(userValues, field, value)
    const stateWithoutError = fixErrorOnFee(newState)

    Object.entries(stateWithoutError).forEach(([key, val]) => {
      onChangeSingleParam(key as SingleParamType, {
        userValue: val
      })
    })

    const newValues = Object.entries(stateWithoutError).reduce((acc, [key, val]) => {
      acc[key as Param] = calcNewValue({
        amount: state[key as Param].amount,
        value: state[key as Param].current,
        balance: xWOW.balance?.raw.toString(),
        userValue: val,
        votedValue: state[key as Param].votedValue,
        voted: state[key as Param].voted
      })
      return acc
    }, {} as FeeParams)

    Object.entries(newValues).forEach(([key, val]) => {
      onChangeSingleParam(key as SingleParamType, {
        newValue: val
      })
    })
  }

  const handleChangeSingleParam = (field: SingleParamType, value: number) => {
    const newValue = calcNewValue({
      amount: state[field].amount,
      value: state[field].current,
      balance: xWOW.balance?.raw.toString(),
      userValue: value,
      votedValue: state[field].votedValue,
      voted: state[field].voted
    })
    onChangeSingleParam(field, {
      ...state[field],
      userValue: value,
      newValue
    })
  }

  const { status: statusParams, callback: callbackParams } = useVoteForParams()
  const { status: statusFees, callback: callbackFees } = useVoteForFees()

  const handleVote = () => {
    if (hasFeeUpdate && statusFees === VoteStatus.VALID && callbackFees) {
      const userValues = Object.entries(params).reduce((acc, [key, item]) => {
        acc[key as Param] = item.userValue
        return acc
      }, {} as FeeParams)

      callbackFees(userValues).then(() => {
        setApprovalSubmitted(false)
      })
    } else if (hasUpdate && statusParams === VoteStatus.VALID && callbackParams) {
      callbackParams(updates).then(() => {
        setApprovalSubmitted(false)
      })
    }
  }

  const isPageTitleWithBackground = chainId === ChainId.ETHEREUM || chainId === ChainId.IOTEX

  if (!state.loaded) {
    return (
      <GridSidebarWraper>
        <GridSidebar>
          <SidebarLeft>
            <MenuSidebar />
          </SidebarLeft>

          <GridSidebarBody>
            <GridSidebarHeader withBackground={isPageTitleWithBackground}>
              <GridSidebarBack to={'/governance-menu'} />

              <GridSidebarTitle>Economic model</GridSidebarTitle>
            </GridSidebarHeader>

            <ContentWrapper NoNegativeMargin maxWidth="894px" padding="26px 36px 36px 36px">
              <LoaderWrapper>
                <Loader size={'80px'} />
              </LoaderWrapper>
            </ContentWrapper>
          </GridSidebarBody>
        </GridSidebar>
      </GridSidebarWraper>
    )
  }

  return (
    <GridSidebarWraper>
      <GridSidebar>
        <SidebarLeft>
          <MenuSidebar />
        </SidebarLeft>

        <GridSidebarBody>
          <GridSidebarHeader withBackground={isPageTitleWithBackground}>
            <GridSidebarBack to={'/governance-menu'} />

            <GridSidebarTitle>Economic model</GridSidebarTitle>
          </GridSidebarHeader>

          <ContentWrapper NoNegativeMargin maxWidth="894px" padding="26px 36px 36px 36px">
            <GridSidebarBlockTitle style={{ textAlign: 'center' }}>Fee collection</GridSidebarBlockTitle>

            <GovernanceModules>
              <FeeModule
                title="Trader Profit Fee"
                field={TRADER_PROFIT_FEE}
                from={state[TRADER_PROFIT_FEE].min}
                to={state[TRADER_PROFIT_FEE].max}
                marks={[state[TRADER_PROFIT_FEE].min, state[TRADER_PROFIT_FEE].max]}
                current={state[TRADER_PROFIT_FEE].current}
                userValue={state[TRADER_PROFIT_FEE].userValue}
                newValue={state[TRADER_PROFIT_FEE].newValue}
                onChange={handleChangeSingleParam}
                onRenderMark={(mark: number, possibleNull?: boolean) => (
                  <ParamValue precision={2} percentage possibleNull={possibleNull}>
                    {mark}
                  </ParamValue>
                )}
                feeInfoLow={'Lower trader fees increase trader’s profit but decreases revenue'}
                feeInfoHign={'Higher trader fees decrease trader’s profit but increase revenue'}
              />

              <FeeModule
                title="Liquidity Provider Profit Fee"
                field={TREASURE_FACTOR}
                from={state[TREASURE_FACTOR].min}
                to={state[TREASURE_FACTOR].max}
                marks={[state[TREASURE_FACTOR].min, state[TREASURE_FACTOR].max]}
                current={state[TREASURE_FACTOR].current}
                userValue={state[TREASURE_FACTOR].userValue}
                newValue={state[TREASURE_FACTOR].newValue}
                onChange={handleChangeSingleParam}
                onRenderMark={(mark: number, possibleNull?: boolean) => (
                  <ParamValue precision={2} percentage possibleNull={possibleNull}>
                    {mark}
                  </ParamValue>
                )}
                feeInfoLow={'Lower trader fees increase liquidity provider’s profit but decreases revenue'}
                feeInfoHign={'Higher trader fees decrease liquidity provider’s profit but increase revenue'}
              />
            </GovernanceModules>

            <GridSidebarAction maxWidth="340px">
              {showApproveFlow && hasUpdate ? (
                <RowBetween>
                  <ButtonConfirmed
                    onClick={approveCallback}
                    disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted || !hasUpdate}
                    width="47%"
                    altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                    confirmed={approval === ApprovalState.APPROVED}
                    pending={approval === ApprovalState.PENDING}
                    style={{ padding: '0' }}
                  >
                    {approval === ApprovalState.PENDING ? (
                      <AutoRow gap="6px" justify="center">
                        Approving <Loader margin="0 -8px 0 0" />
                      </AutoRow>
                    ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                      'Approved'
                    ) : (
                      'Approve ' + tokenAmount.token.symbol
                    )}
                  </ButtonConfirmed>

                  <ButtonPrimary
                    width="47%"
                    disabled={statusFees !== VoteStatus.VALID || approval !== ApprovalState.APPROVED || !hasUpdate}
                    onClick={handleVote}
                  >
                    Vote
                  </ButtonPrimary>
                </RowBetween>
              ) : (
                <ButtonPrimary disabled={statusFees !== VoteStatus.VALID || !hasUpdate} onClick={handleVote}>
                  Vote
                </ButtonPrimary>
              )}

              {showApproveFlow && hasUpdate && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />}
            </GridSidebarAction>

            <GridSidebarBlockTitle style={{ textAlign: 'center', marginTop: '5rem' }}>
              Fee distribution
            </GridSidebarBlockTitle>

            <FeeDistributionTable onParamChange={handleChangeFeeParam} />

            <GridSidebarAction maxWidth="340px">
              {showApproveFlow && hasFeeUpdate ? (
                <RowBetween>
                  <ButtonConfirmed
                    onClick={approveCallback}
                    disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted || !hasFeeUpdate}
                    width="47%"
                    altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                    confirmed={approval === ApprovalState.APPROVED}
                    pending={approval === ApprovalState.PENDING}
                    style={{ padding: '0' }}
                  >
                    {approval === ApprovalState.PENDING ? (
                      <AutoRow gap="6px" justify="center">
                        Approving <Loader margin="0 -8px 0 0" />
                      </AutoRow>
                    ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                      'Approved'
                    ) : (
                      'Approve ' + tokenAmount.token.symbol
                    )}
                  </ButtonConfirmed>

                  <ButtonPrimary
                    width="47%"
                    disabled={statusFees !== VoteStatus.VALID || approval !== ApprovalState.APPROVED || !hasFeeUpdate}
                    onClick={handleVote}
                  >
                    Vote
                  </ButtonPrimary>
                </RowBetween>
              ) : (
                <ButtonPrimary disabled={statusFees !== VoteStatus.VALID || !hasFeeUpdate} onClick={handleVote}>
                  Vote
                </ButtonPrimary>
              )}

              {showApproveFlow && hasFeeUpdate && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />}
            </GridSidebarAction>
          </ContentWrapper>
        </GridSidebarBody>
      </GridSidebar>
    </GridSidebarWraper>
  )
}
