import React from 'react'
import styled from 'styled-components'
import { ExternalLink } from '../Shared'
import { TextBaseXl } from '../Text'

const InfoCard = styled.button<{ active?: boolean; color?: string }>`
  background-color: ${({ theme, color }) => (color ? color : theme.colors.primary1)};
  padding: 3px 5px 3px 20px;
  outline: none;
  border: 2px solid transparent;
  border-radius: 10px;
  width: 100% !important;  

  &:focus {
    // box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.primary1};
  }
  // border-color: ${({ theme, active }) => (active ? 'transparent' : theme.colors.bg3)};
`

const OptionCard = styled(InfoCard as any)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // padding: 1rem;

  transition: all 0.4s ease;

  &:hover {
    text-decoration: none;
  }
`

const OptionCardLeft = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  justify-content: center;
  height: 100%;
`

const OptionExternalLink = styled(ExternalLink)`
  text-decoration: none;

  &:focus {
    text-decoration: none;
  }
`

const OptionCardClickable = styled(OptionCard as any)<{ clickable?: boolean }>`
  &:hover {
    cursor: ${({ clickable }) => (clickable ? 'pointer' : '')};
    border: ${({ clickable, theme }) => (clickable ? `2px solid ${theme.colors.primary1}` : ``)};
  }
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`

const GreenCircle = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  justify-content: center;
  align-items: center;

  display: none;

  &:first-child {
    height: 8px;
    width: 8px;
    margin-right: 8px;
    background-color: ${({ theme }) => theme.colors.primary5};
    border-radius: 50%;
  }
`

const CircleWrapper = styled.div`
  color: ${({ theme }) => theme.colors.primary5};
  display: flex;
  justify-content: center;
  align-items: center;
`

// #JUNK const HeaderText = styled.div`
//   ${({ theme }) => theme.flexRowNoWrap};
//   color: ${props =>
//     props.color === 'blue' ? ({ theme }) => theme.colors.primary1 : ({ theme }) => theme.colors.text1};
//   font-size: 1rem;
//   font-weight: 700;
// `
//  const HeaderText = styled.div`
//   display: flex;

//   font-family: Abadi MT Std, sans-serif;
//   font-weight: normal;
//   font-size: 20px;
//   line-height: 100%;
// `

const SubHeader = styled.div`
  color: ${({ theme }) => theme.colors.text1};
  margin-top: 10px;
  font-size: 12px;
`

const IconWrapper = styled.div<{ size?: number | null }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  background: ${({ theme }) => theme.colors.bg0};
  border-radius: 5px;

  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '40px')};
    width: ${({ size }) => (size ? size + 'px' : '40px')};
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    align-items: flex-end;

		& > img,
		span {
			height: 30px;
			width: 30px;
		}
  `};
`

export default function Option({
  link = null,
  clickable = true,
  size,
  onClick = null,
  color,
  header,
  subheader = null,
  icon,
  active = false,
  id
}: {
  link?: string | null
  clickable?: boolean
  size?: number | null
  onClick?: null | (() => void)
  color: string
  header: React.ReactNode
  subheader: React.ReactNode | null
  icon: string
  active?: boolean
  id: string
}) {
  const content = (
    <OptionCardClickable id={id} onClick={onClick} clickable={clickable && !active} active={active} color={color}>
      <OptionCardLeft>
        <TextBaseXl>
          {active ? (
            <CircleWrapper>
              <GreenCircle>
                <div />
              </GreenCircle>
            </CircleWrapper>
          ) : (
            ''
          )}
          {header}
        </TextBaseXl>
        {subheader && <SubHeader>{subheader}</SubHeader>}
      </OptionCardLeft>
      <IconWrapper size={size}>
        <img src={icon} alt={'Icon'} />
      </IconWrapper>
    </OptionCardClickable>
  )
  if (link) {
    return <OptionExternalLink href={link}>{content}</OptionExternalLink>
  }

  return content
}
