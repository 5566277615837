import React from 'react'
import Pikachu from '../../assets/images/pikachu.gif'
import { PortfolioEmptyWrapper, PortfolioEmptyImg, PortfolioBoldText, PortfolioSmallText } from './styleds'
import { ButtonError } from '../../components/Button'
import { useHistory } from 'react-router-dom'

interface IPortfolioEmptyView {
  title: string
  subtitle: string
}

export const PortfolioEmpty: React.FC<IPortfolioEmptyView> = props => {
  const history = useHistory()

  function redirectToSwapPage() {
    history.push({ pathname: '/swap' })
  }

  return (
    <PortfolioEmptyWrapper>
      <div>
        <PortfolioEmptyWrapper>
          <PortfolioEmptyImg src={Pikachu} alt="Pikachu image" />
        </PortfolioEmptyWrapper>
        <PortfolioBoldText>{props.title}</PortfolioBoldText>
        <PortfolioSmallText>{props.subtitle}</PortfolioSmallText>
        <ButtonError onClick={redirectToSwapPage}>Switch to trade</ButtonError>
      </div>
    </PortfolioEmptyWrapper>
  )
}
