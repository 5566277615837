import WebFont from 'webfontloader'
export { default as lightTheme } from './light'
export { default as darkTheme } from './dark'
export { default as maticTheme } from './matic'
export { default as hecoTheme } from './heco'
export { default as ethereumTheme } from './ethereum'
export { default as avalancheTheme } from './avalanche'
export { default as iotexTheme } from './iotex'

WebFont.load({
  google: {
    families: ['Noto+Sans:400,500,600,700,800,900', 'sans-serif']
  }
})
