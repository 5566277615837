import { getAddress } from 'ethers/lib/utils'
import { ChainId, Token, TokenAmount } from '@wowswap-io/wowswap-sdk'

export type LendableTradeTokenInfo = {
  isLendable: true
  isNative: boolean
  isBase: boolean
  reserveAddress?: string
  proxyAddresses?: string[]
}

export type ReserveTradeTokenInfo = {
  isReserve: true
  lendableAddress: string
}

export type ProxyTradeTokenInfo = {
  isProxy: true
  pairName: string
  lendableAddress: string
  tradableAddress: string
  proxyAddress?: string
}

export type BaseTokenTradeInfo = {
  isBase: true
  proxyAddresses: string[]
}

export interface StakeBalance {
  amount: TokenAmount
  minted: TokenAmount
  timeout: string
}

export type StakeTokenTradeInfo = {
  isStaking: true
  isBase?: true
  pairName?: string
  staked?: StakeBalance
}

export type ShortableTokenInfo = {
  shortable?: boolean
  reserveAddress?: string
}

export type ShortProxyTokenInfo = {
  isShort: true
  pairName: string
  stableAddress: string
  shortAddress: string
  proxyAddress?: string
}

export type TradeTokenInfo = {
  id: string
  chainId: ChainId
  address: string
  decimals: number
  symbol: string
  name: string
  shortable?: boolean
} & (
  | LendableTradeTokenInfo
  | ProxyTradeTokenInfo
  | ReserveTradeTokenInfo
  | BaseTokenTradeInfo
  | StakeTokenTradeInfo
  | ShortableTokenInfo
  | ShortProxyTokenInfo
)

export class TradeToken extends Token {
  constructor(public info: TradeTokenInfo, public balance?: TokenAmount) {
    super(info.chainId, getAddress(info.address), info.decimals, info.symbol, info.name)
  }

  get proxyInfo() {
    return 'isProxy' in this.info ? (this.info as ProxyTradeTokenInfo) : undefined
  }
  get lendableInfo() {
    return 'isLendable' in this.info ? (this.info as LendableTradeTokenInfo) : undefined
  }
  get reserveInfo() {
    return 'isReserve' in this.info ? (this.info as ReserveTradeTokenInfo) : undefined
  }
  get baseInfo() {
    return 'isBase' in this.info ? (this.info as BaseTokenTradeInfo) : undefined
  }
  get shortableInfo() {
    // can be moved at reserve
    return 'shortable' in this.info ? (this.info as ShortableTokenInfo) : undefined
  }
  get shortingInfo() {
    // can be shorted
    return 'isShort' in this.info ? (this.info as ShortProxyTokenInfo) : undefined
  }

  get stakableInfo() {
    return 'isStaking' in this.info ? (this.info as StakeTokenTradeInfo) : undefined
  }
  set staked(params: StakeBalance) {
    ;(this.info as StakeTokenTradeInfo).staked = params
  }

  get uniqSymbol() {
    return this?.proxyInfo?.pairName || this.stakableInfo?.pairName || this.info.symbol
  }

  get tradableAddress() {
    return this.proxyInfo?.tradableAddress || this.shortingInfo?.shortAddress
  }

  get proxybleAddress() {
    return this.proxyInfo?.proxyAddress || this.shortingInfo?.proxyAddress
  }

  get lendableAddress() {
    if (this.shortingInfo) {
      return this.shortingInfo.stableAddress
    }

    if (this.proxyInfo) {
      return this.proxyInfo.lendableAddress
    }

    if (this.lendableInfo) {
      return this.address
    }

    if (this.shortableInfo) {
      return this.address
    }

    return undefined
  }

  get pairAddress() {
    if (this.proxyInfo) {
      return this.address
    }

    if (this.shortingInfo) {
      return this.address
    }

    return undefined
  }
}
