import { TokenAmount } from '@wowswap-io/wowswap-sdk'
import React, { useCallback } from 'react'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent
} from '../TransactionConfirmationModal'
import SwapModalHeader from './SwapModalHeader'
import SwapModalFooter from './SwapModalFooter'

import { TradeToken } from '../../hooks/Tokens.types'

export default function ConfirmConvertModal({
  isOpen,
  typedValue,
  inputToken,
  outputToken,
  amountOut,
  onConfirm,
  onDismiss,
  attemptingTxn,
  swapErrorMessage,
  txHash
}: {
  isOpen: boolean
  typedValue: string
  inputToken: TradeToken | undefined
  outputToken: TradeToken | undefined
  amountOut: TokenAmount | undefined
  onConfirm: () => void
  onDismiss: () => void
  attemptingTxn: boolean
  swapErrorMessage: string | undefined
  txHash: string | undefined
}) {
  const modalHeader = useCallback(() => {
    return (
      <SwapModalHeader
        typedValue={typedValue}
        inputToken={inputToken}
        outputToken={outputToken}
        amountOut={amountOut}
      />
    )
  }, [typedValue, inputToken, outputToken, amountOut])

  const modalBottom = useCallback(() => {
    return <SwapModalFooter onConfirm={onConfirm} disabledConfirm={false} />
  }, [onConfirm])

  // text to show while loading
  const pendingText = `Convert ${typedValue} ${inputToken?.info.symbol} for ${amountOut?.toSignificant(6)} ${
    outputToken?.info.symbol
  }`

  const confirmationContent = useCallback(
    () =>
      swapErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={swapErrorMessage} />
      ) : (
        <ConfirmationModalContent
          title="Confirm Swap"
          onDismiss={onDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [onDismiss, modalBottom, modalHeader, swapErrorMessage]
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
    />
  )
}
