import React from 'react'
import styled from 'styled-components'
import { useDerivedStakeInfo, useRefenceApy } from '../../state/stake/hooks'
import { bn } from '../../utils/math'
import { SortTableText } from '../SortTable'
import StakeItem from './StakeItem'

const TableStyle = styled.div`
  padding: 20px 20px 10px;
  margin-bottom: 32px;

  border-radius: 10px;
  background: #fdf4e1;
  border: 4px solid #000000;

  ${({ theme }) => theme.mediaWidth.upToMedium`
		padding: 0;
		
		font-size: 18px;
		line-height: 23px;

		border-radius: 10px;
		background: #fdf4e1;
	`};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0;
		
		font-size: 18px;
		line-height: 23px;

		border-radius: 10px;
		background: #fdf4e1;
	`};
`

const TableHeader = styled.div`
  display: flex;
  padding: 0 10px;
  margin-bottom: 11px;

  & > div:nth-child(1) {
    flex-grow: 2;
  }
  & > div:nth-child(n + 2) {
    margin-right: 10px;
    flex: 0 1 15%;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 10px;
    margin-top: 10px;
    align-items: center;

    & > div {
      width: 15%;
      flex: 0 0 15%;
      margin-right: 2%;
    }
	`};

  ${({ theme }) => theme.mediaWidth.upToSmall` 
    display: none;
  `};
`

const TableList = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
		& > div {
			margin-bottom: 10px;

			:last-child {
				margin-bottom: 0;
			}
		}
	`};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    & > div {
      margin-bottom: 20px;

      :last-child {
        margin-bottom: 20px;
      }
    }
  `};
`

const TableHeaderCell = styled(SortTableText)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:not(:first-of-type) {
    text-align: right;
  }
`

function DesktopTableHeader() {
  return (
    <TableHeader>
      <TableHeaderCell>Token</TableHeaderCell>
      <TableHeaderCell>14 Days Lock APY</TableHeaderCell>
      <TableHeaderCell>183 Days Lock APY</TableHeaderCell>
      <TableHeaderCell>365 Days Lock APY</TableHeaderCell>
      <TableHeaderCell>458 Days Lock APY</TableHeaderCell>
      <TableHeaderCell>730 Days Lock APY</TableHeaderCell>
    </TableHeader>
  )
}

export function StakeTable() {
  const { possibleInputs, amountXwowTotalSupply, effectiveWOWFarmingSpeed } = useDerivedStakeInfo()
  const totalXWOW = bn(amountXwowTotalSupply?.raw.toString() || '0')

  const refs = useRefenceApy()

  return (
    <TableStyle>
      <DesktopTableHeader />

      <TableList>
        {possibleInputs &&
          possibleInputs.map((el, index) => (
            <StakeItem
              key={el.address}
              bgColor={index % 2 ? undefined : 'white'}
              token={el}
              effectiveWOWFarmingSpeed={effectiveWOWFarmingSpeed}
              totalXWOW={totalXWOW}
              apyRef={refs[el.address]}
            />
          ))}
      </TableList>
    </TableStyle>
  )
}
