import React, { useCallback } from 'react'
import { Currency, TokenAmount } from '@wowswap-io/wowswap-sdk'
import { ArrowDown } from 'react-feather'
import { format, differenceInCalendarDays } from 'date-fns'
import { TransactionResponse } from '@ethersproject/providers'

import { TranslateString } from '../../utils/translateTextHelpers'
import { DepositStatus, StakeDirection } from '../../state/stake/actions'
import {
  useStakeActionHandlers,
  useDerivedStakeInfo,
  useStake,
  useUnstake,
  useStakeApprove
} from '../../state/stake/hooks'
import { TradeToken } from '../../hooks/Tokens.types'
import { ApprovalState } from '../../hooks/useApproveCallback'

import Loader from '../../components/Loader'
import { ButtonConfirmed, ButtonGreen, ButtonPrimary } from '../Button'
import { AutoColumn } from '../Column'
import EarnCurrencyInputPanel from '../../components/earn/EarnCurrencyInputPanel'
import { AutoRow, RowBetween } from '../Row'
import { StakeDuration } from './StakeDuration'
import { ArrowWrapper, BottomGrouping } from '../swap/styleds'
import {
  ApyLabel,
  ApyValue,
  ApyWrapper,
  YourWalletUnstakeItemStyle,
  YourWalletUnstakeItemLeft,
  YourWalletUnstakeItemRight,
  YourWalletUnstakeItemIcons,
  YourWalletUnstakeItemValue,
  YourWalletUnstakeItemDate,
  NotAvailable,
  YourWalletStakeBody,
  YourWalletStakeStyle,
  YourWalletStakeTabs,
  YourWalletStakeTabsLink,
  YourWalletStakeItemTab,
  YourWalletStakeList
} from './styleds'
import CurrencyLogo from '../CurrencyLogo'
import ProgressSteps from '../ProgressSteps'
import { useBlockTimestamp } from '../../state/application/hooks'

function getDateFromTimestamp(timestamp?: number): Date | undefined {
  return timestamp && timestamp > 0 ? new Date(timestamp * 1000) : undefined
}

function getDiffDaysFromToken(token?: TradeToken, compareTimestamp?: number) {
  const lockTime = getDateFromTimestamp(Number(token?.stakableInfo?.staked?.timeout))
  const compareDate = compareTimestamp ? getDateFromTimestamp(compareTimestamp)! : new Date()

  if (lockTime) {
    return Math.max(14, differenceInCalendarDays(lockTime, compareDate))
  }
  return 14
}

export function YourWalletStake() {
  const blockTimestamp = useBlockTimestamp()
  const data = useDerivedStakeInfo()
  const {
    onValueInput,
    onTokenChange,
    onUnstakeTokenChange,
    onChangeLocktime,
    onChangeDirection
  } = useStakeActionHandlers()

  const handleInputValue = useCallback(
    (value: string) => {
      onValueInput(value)
    },
    [onValueInput]
  )
  const handleMaxInput = useCallback(() => {
    if (data.maxSpend) {
      onValueInput(data.maxSpend.toExact())
    }
  }, [data.maxSpend, onValueInput])

  const handleTokenChangeInput = useCallback(
    (token: TradeToken | Currency) => {
      onTokenChange((token as TradeToken).address)
      onChangeLocktime(getDiffDaysFromToken(token as TradeToken, blockTimestamp))
    },
    [onTokenChange, blockTimestamp, onChangeLocktime]
  )
  const handleTokenChangeOutput = useCallback(
    (token: TradeToken | Currency) => {
      onTokenChange((token as TradeToken).address!)
    },
    [onTokenChange]
  )

  const { callback: onDeposit, status: deposit } = useStake({
    token: data.output!,
    lpToken: data.input!,
    amount: data.amount?.raw.toString()!,
    period: data.period
  })

  const { callback: onUnstake, status: unstakeStatus } = useUnstake()

  const convert = () => {
    if (onDeposit && deposit === DepositStatus.VALID) {
      onDeposit()
        .then(() => {
          setApprovalSubmitted(false)
        })
        .catch(console.error)
      onValueInput('')
    }
  }

  const handleLockTimeChange = (period: number) => {
    onChangeLocktime(period)
  }

  const handleCheck = (token: TradeToken) => {
    onUnstakeTokenChange(token.address)
  }

  const handleUnstake = (token: TradeToken) => {
    if (onUnstake && unstakeStatus === DepositStatus.VALID) {
      onUnstake({ lpToken: token! }).catch(console.error)
    }
  }

  const {
    amountToApprove,
    approval,
    approvalSubmitted,
    approveCallback,
    setApprovalSubmitted,
    showApproveFlow
  } = useStakeApprove()

  return (
    <YourWalletStakeStyle>
      <YourWalletStakeTabs>
        <YourWalletStakeTabsLink
          active={data.direction === StakeDirection.STAKE}
          onClick={() => onChangeDirection(StakeDirection.STAKE)}
        >
          Stake
        </YourWalletStakeTabsLink>

        <YourWalletStakeTabsLink
          active={data.direction === StakeDirection.UNSTAKE}
          onClick={() => onChangeDirection(StakeDirection.UNSTAKE)}
        >
          Unstake
        </YourWalletStakeTabsLink>
      </YourWalletStakeTabs>

      <YourWalletStakeBody>
        <YourWalletStakeItemTab active={data.direction === StakeDirection.STAKE}>
          <AutoColumn gap={'2px'}>
            <EarnCurrencyInputPanel
              id="stake-currency-input"
              label={TranslateString(76, 'From')}
              value={data?.typedValue}
              showMaxButton={true}
              selected={data?.input}
              other={data?.output}
              variants={data?.possibleInputs}
              onUserInput={handleInputValue}
              onMax={handleMaxInput}
              onCurrencySelect={handleTokenChangeInput}
            />

            <AutoColumn justify="space-between">
              <AutoRow justify={'center'} style={{ padding: '0 1rem' }}>
                <ArrowWrapper clickable>
                  <ArrowDown size="20" onClick={() => {}} />
                </ArrowWrapper>
              </AutoRow>
            </AutoColumn>

            <StakeDuration onChange={handleLockTimeChange} minDate={getDiffDaysFromToken(data.input, blockTimestamp)} />

            <AutoColumn justify="space-between">
              <AutoRow justify={'center'} style={{ padding: '0 1rem' }}>
                <ArrowWrapper clickable>
                  <ArrowDown size="20" onClick={() => {}} />
                </ArrowWrapper>
              </AutoRow>
            </AutoColumn>
          </AutoColumn>

          <EarnCurrencyInputPanel
            id="stake-currency-output"
            label={TranslateString(76, 'To')}
            value={data?.amountOut?.toExact() || ''}
            showMaxButton={false}
            selected={data?.output}
            other={data?.input}
            variants={data?.possibleOutputs}
            disabled={true}
            disableCurrencySelect={true}
            onUserInput={handleInputValue}
            onMax={() => {}}
            onCurrencySelect={handleTokenChangeOutput}
          />

          <ApyWrapper>
            <ApyLabel>Your staking APY:</ApyLabel>
            <ApyValue>{data.APY}%</ApyValue>
          </ApyWrapper>

          <BottomGrouping style={{ marginTop: '20px' }}>
            {showApproveFlow ? (
              <RowBetween>
                <ButtonConfirmed
                  onClick={approveCallback}
                  disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                  width="47%"
                  altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                  confirmed={approval === ApprovalState.APPROVED}
                  pending={approval === ApprovalState.PENDING}
                  style={{ padding: '0' }}
                >
                  {approval === ApprovalState.PENDING ? (
                    <AutoRow gap="6px" justify="center">
                      Approving <Loader margin="0 -8px 0 0" />
                    </AutoRow>
                  ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                    'Approved'
                  ) : (
                    'Approve ' + (amountToApprove as TokenAmount)?.token.symbol
                  )}
                </ButtonConfirmed>

                <ButtonPrimary
                  width="47%"
                  disabled={deposit !== DepositStatus.VALID || approval !== ApprovalState.APPROVED}
                  onClick={convert}
                >
                  Stake
                </ButtonPrimary>
              </RowBetween>
            ) : (
              <ButtonPrimary disabled={deposit !== DepositStatus.VALID} onClick={convert}>
                Stake
              </ButtonPrimary>
            )}

            {showApproveFlow && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />}
          </BottomGrouping>
        </YourWalletStakeItemTab>

        <YourWalletStakeItemTab active={data.direction === StakeDirection.UNSTAKE}>
          <YourWalletStakeList>
            {data.possibleInputs?.map(token => (
              <YourWalletUnstakeItem
                token={token}
                onCheck={handleCheck}
                key={token.address}
                isChecking={token.address === data.unstakeToken?.address}
                onUnstake={handleUnstake}
                approval={approval}
                approvalSubmitted={approvalSubmitted}
                approveCallback={approveCallback}
                showApproveFlow={showApproveFlow}
              />
            ))}
          </YourWalletStakeList>
        </YourWalletStakeItemTab>
      </YourWalletStakeBody>
    </YourWalletStakeStyle>
  )
}

const styleSmallBtn = {
  height: '37px',
  padding: '0 11px',
  lineHeight: '13px',
  fontSize: '13px',
  borderRadius: '5px'
}

function YourWalletUnstakeItem({
  approval,
  approvalSubmitted,
  approveCallback,
  showApproveFlow,
  isChecking,
  token,
  onCheck,
  onUnstake
}: {
  approval?: ApprovalState
  approvalSubmitted?: boolean
  approveCallback?: () => Promise<TransactionResponse | undefined>
  showApproveFlow?: boolean
  isChecking: boolean
  token: TradeToken
  onCheck: (token: TradeToken) => void
  onUnstake: (token: TradeToken) => void
}) {
  const timeout = token.stakableInfo?.staked ? token.stakableInfo?.staked.timeout : undefined
  const isPossibleUnstake = timeout
    ? token.stakableInfo?.staked?.amount.greaterThan('0') && Number(timeout) * 1000 < Date.now()
    : false
  const formatedDate = timeout && timeout !== '0' ? format(new Date(Number(timeout) * 1000), 'dd MMMM yyyy') : ''

  const handleClickCheck = useCallback(() => {
    onCheck(token)
  }, [token, onCheck])

  const handleClickUnstake = useCallback(() => {
    onUnstake(token)
  }, [token, onUnstake])

  const btn = useCallback(() => {
    if (!isChecking) {
      return (
        <ButtonGreen style={styleSmallBtn} onClick={handleClickCheck}>
          check
        </ButtonGreen>
      )
    }

    if (showApproveFlow) {
      if (approval === ApprovalState.APPROVED) {
        return (
          <ButtonPrimary onClick={handleClickUnstake} style={styleSmallBtn}>
            Unstake
          </ButtonPrimary>
        )
      }

      return (
        <ButtonConfirmed
          onClick={approveCallback}
          disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
          altDisabledStyle={approval === ApprovalState.PENDING}
          pending={approval === ApprovalState.PENDING}
          style={styleSmallBtn}
        >
          {approval === ApprovalState.PENDING ? (
            <AutoRow gap="6px" justify="center">
              Approving
            </AutoRow>
          ) : (
            'Approve xWOW'
          )}
        </ButtonConfirmed>
      )
    }

    return (
      <ButtonPrimary onClick={handleClickUnstake} style={styleSmallBtn}>
        Unstake
      </ButtonPrimary>
    )
  }, [isChecking, showApproveFlow, approval, approvalSubmitted, approveCallback, handleClickCheck, handleClickUnstake])

  return (
    <YourWalletUnstakeItemStyle>
      <YourWalletUnstakeItemLeft>
        <YourWalletUnstakeItemIcons>
          <CurrencyLogo currency={token} />
          <YourWalletUnstakeItemValue>
            {token.stakableInfo?.staked?.amount.toSignificant(6)} {token.symbol}
          </YourWalletUnstakeItemValue>
        </YourWalletUnstakeItemIcons>

        <YourWalletUnstakeItemDate>{formatedDate ? `Staked until ${formatedDate}` : ''}</YourWalletUnstakeItemDate>
      </YourWalletUnstakeItemLeft>

      <YourWalletUnstakeItemRight>
        {isPossibleUnstake ? btn() : <NotAvailable>Not available</NotAvailable>}
      </YourWalletUnstakeItemRight>
    </YourWalletUnstakeItemStyle>
  )
}
