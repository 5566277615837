import React from 'react'
import { Trade } from '@wowswap-io/wowswap-sdk'
import { useActiveWeb3React } from '../../../hooks'
import { useLastTruthy } from '../../../hooks/useLast'
import { useTradeState } from '../../../state/trade/hooks'
import { Position } from '../../../state/trade/types'
import { useUserSlippageTolerance } from '../../../state/user/hooks'
import { AutoColumn } from '../../Column'
import { AdvancedDetailsFooter } from '../styleds'
import { LongTradeDetails } from './LongTradeDetails'
import { ShortTradeDetails } from './ShortTradeDetails'

export interface TradeDetails {
  trade?: Trade
  position?: Position
  isOpenPosition: boolean
}

export const TradeDetails: React.FC<TradeDetails> = ({ trade, position, isOpenPosition }) => {
  const { chainId } = useActiveWeb3React()
  const state = useTradeState()
  const lastTrade = useLastTruthy(trade, state.isShortTrade || chainId)
  const [allowedSlippage] = useUserSlippageTolerance()
  const tradeInfo = trade ?? lastTrade ?? undefined

  return (
    <AdvancedDetailsFooter show={Boolean(tradeInfo)}>
      <AutoColumn gap="md">
        {tradeInfo && (
          <AutoColumn style={{ padding: '0 20px' }} gap="8px">
            {state.isShortTrade ? (
              <ShortTradeDetails
                position={position}
                trade={tradeInfo}
                allowedSlippage={allowedSlippage}
                isOpenPosition={isOpenPosition}
              />
            ) : (
              <LongTradeDetails
                position={position}
                trade={tradeInfo}
                allowedSlippage={allowedSlippage}
                isOpenPosition={isOpenPosition}
              />
            )}
          </AutoColumn>
        )}
      </AutoColumn>
    </AdvancedDetailsFooter>
  )
}
