import React from 'react'
import styled from 'styled-components'
import { ListStat } from '../ListStat'
import { ModuleWrapper } from '../ModuleWrapper'

const LiquidationStatisticsStyle = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
		
	`};
`

const LiquidationStatisticsTitle = styled.div`
  margin-bottom: 20px;

  font-family: Retro;
  font-size: 20px;
  line-height: 28px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		margin-bottom: 22px;
	`};
`

const LiquidationStatisticsBody = styled.div`
  & > div {
    margin-bottom: 20px;

    :last-child {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		& > div {
			margin-bottom: 22px;

			:last-child {
				margin-bottom: 0;
			}
		}
	`};
`

export function LiquidationStatistics() {
  return (
    <ModuleWrapper>
      <LiquidationStatisticsStyle>
        <LiquidationStatisticsTitle>Liquidation statistics:</LiquidationStatisticsTitle>

        <LiquidationStatisticsBody>
          <ListStat
            title="Swaps liquidated:"
            items={[
              {
                itemName: 'Last 24 hours',
                value: '132'
              },
              {
                itemName: 'Last month',
                value: '311'
              },
              {
                itemName: 'Since launch',
                value: '422'
              }
            ]}
          />

          <ListStat
            title="Paid to liquidators:"
            items={[
              {
                itemName: 'Last 24 hours',
                value: '$13.222'
              },
              {
                itemName: 'Last month',
                value: '$133.222'
              },
              {
                itemName: 'Since launch',
                value: '$922.222'
              }
            ]}
          />

          <ListStat
            title="Top daily liquidators:"
            items={[
              {
                itemName: 'xd8m...6dx35',
                value: '$922.222'
              },
              {
                itemName: 'xd8m...6dx35',
                value: '$922.222'
              },
              {
                itemName: 'xd8m...6dx35',
                value: '$922.222'
              },
              {
                itemName: 'xd8m...6dx35',
                value: '$922.222'
              },
              {
                itemName: 'xd8m...6dx35',
                value: '$922.222'
              },
              {
                itemName: 'xd8m...6dx35',
                value: '$922.222'
              },
              {
                itemName: 'xd8m...6dx35',
                value: '$922.222'
              },
              {
                itemName: 'xd8m...6dx35',
                value: '$922.222'
              },
              {
                itemName: 'xd8m...6dx35',
                value: '$922.222'
              },
              {
                itemName: 'xd8m...6dx35',
                value: '$922.222'
              },
              {
                itemName: 'xd8m...6dx35',
                value: '$922.222'
              },
              {
                itemName: 'xd8m...6dx35',
                value: '$922.222'
              },
              {
                itemName: 'xd8m...6dx35',
                value: '$922.222'
              },
              {
                itemName: 'xd8m...6dx35',
                value: '$922.222'
              },
              {
                itemName: 'xd8m...6dx35',
                value: '$922.222'
              },
              {
                itemName: 'xd8m...6dx35',
                value: '$922.222'
              }
            ]}
          />
        </LiquidationStatisticsBody>
      </LiquidationStatisticsStyle>
    </ModuleWrapper>
  )
}
