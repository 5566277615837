export interface UserNotification {
  pin: string
  telegramChats: string[]
  pushTokens: string[]
  emailAddresses: Record<string, boolean>
  telegramEnabled: boolean
  pushEnabled: boolean
  emailEnabled: boolean
  liquidationEnabled: boolean
  profitValues: string[]
  positionHealthValues: string[]
  apyValues: string[]
  chainIds: string[]
}

const convertToString = (arr: number[] = []) => {
  return arr.map(item => item.toString())
}

export const buildUser = (rawUser: any): UserNotification => {
  const user: UserNotification = { ...rawUser }
  user.profitValues = convertToString(rawUser.profitValues)
  user.positionHealthValues = convertToString(rawUser.positionHealthValues)
  user.apyValues = convertToString(rawUser.apyValues)

  return user
}
