import { useCallback, useEffect, useState } from 'react'
import { useActiveWeb3React } from '../../hooks'
import useDebounce from '../../hooks/useDebounce'
import useIsWindowVisible from '../../hooks/useIsWindowVisible'
import { updateBlockNumber } from './actions'
import { useDispatch } from 'react-redux'

export default function Updater(): null {
  const { library, chainId } = useActiveWeb3React()
  const dispatch = useDispatch()

  const windowVisible = useIsWindowVisible()

  const [state, setState] = useState<{
    chainId: number | undefined
    blockNumber: number | null
    timestamp: number | null
  }>({
    chainId,
    blockNumber: null,
    timestamp: null
  })

  const blockNumberCallback = useCallback(
    (blockNumber: number) => {
      library?.getBlock(blockNumber).then(block => {
        setState(state => {
          if (chainId === state.chainId) {
            if (typeof state.blockNumber !== 'number') return { chainId, blockNumber, timestamp: state.timestamp }
            return { chainId, blockNumber: Math.max(blockNumber, state.blockNumber), timestamp: block.timestamp }
          }
          return state
        })
      })
    },
    [chainId, setState, library]
  )

  // attach/detach listeners
  useEffect(() => {
    if (!library || !chainId || !windowVisible) return undefined

    setState({ chainId, blockNumber: null, timestamp: null })

    library
      .getBlockNumber()
      .then(blockNumberCallback)
      .catch(error => console.error(`Failed to get block number for chainId: ${chainId}`, error))

    library.on('block', blockNumberCallback)
    return () => {
      library.removeListener('block', blockNumberCallback)
    }
  }, [dispatch, chainId, library, blockNumberCallback, windowVisible])

  const debouncedState = useDebounce(state, 100)

  useEffect(() => {
    if (!debouncedState.chainId || !debouncedState.blockNumber || !debouncedState.timestamp || !windowVisible) return
    dispatch(
      updateBlockNumber({
        chainId: debouncedState.chainId,
        blockNumber: debouncedState.blockNumber,
        timestamp: debouncedState.timestamp
      })
    )
  }, [windowVisible, dispatch, debouncedState.blockNumber, debouncedState.chainId, debouncedState.timestamp])

  return null
}
