import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { ReactComponent as IconArrow } from '../../assets/images/icon--arrows.svg'

export const GridSidebarWraper = styled.div`
  display: flex;
  align-items: stretch;
  min-height: calc(100vh - 114px);

  overflow-x: hidden;

  ${({ theme }) => theme.mediaWidth.upToMedium`
		min-height: calc(100vh - 90px);
	`};
`

export const GridSidebar = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;
`

const GridSidebarBackStyle = styled(({ desktopShow, ...rest }) => <NavLink {...rest} />)`
  display: none;

  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #000000;

  cursor: pointer;

  transition: all 0.4s ease;

  & > svg {
    display: block;
    width: 22px;
    height: 20px;
    margin-right: 10px;

    transform: rotate(180deg);

    fill: ${({ theme }) => theme.colors.black};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.text2};
    & > svg {
      fill: ${({ theme }) => theme.colors.primary1};
    }
  }

  ${({ desktopShow }) => {
    if (desktopShow) {
      return `
				display: inline-flex;
				align-items: center;
				margin-left: 12px;
				margin-top: 20px;
				margin-bottom: 10px;
			`
    } else {
      return `display: none;`
    }
  }}

  ${({ theme }) => theme.mediaWidth.upToMedium`
		display: inline-flex;
		align-items: center;
		margin-left: 12px;
		margin-bottom: 10px;
		margin-top: 12px;
	`};
`

export const SidebarLeft = styled.div`
  width: 368px;
  flex: 0 0 368px;
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;

  background-color: ${({ theme }) => theme.colors.bg0};
  border-right: 4px solid ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.mediaWidth.upToLarge`
		width: 248px;
		flex: 0 0 248px;
	`};

  ${({ theme }) => theme.mediaWidth.upToMedium`
		display: none;
	`};
`

export const GridSidebarBody = styled.div`
  flex-grow: 2;
  padding: 0 54px;
  padding-bottom: 40px;
  width: 100%;

  & h1 {
    margin: 0;
    margin-top: 30px;
    margin-bottom: 20px;

    text-align: center;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
		padding: 0 30px;
		padding-bottom: 40px;
	`};

  ${({ theme }) => theme.mediaWidth.upToMedium`
		padding: 0;
		padding-top: 50px;
		padding-bottom: 100px;

		& h1 {
			margin-top: 0;
			margin-bottom: 10px;
		}
	`};
`

const GridSidebarTitleStyle = styled.h1`
  font-family: Retro;
  font-weight: normal;
  font-size: 30px;
  line-height: 42px;

  & > div {
    position: relative;
    top: -3px;

    display: inline-block;
    padding: 0 12px;
    margin-left: 22px;

    font-size: 20px;
    line-height: 28px;
    color: #f3ba2f;

    background-color: #fdf4e1;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
		font-size: 20px;
		line-height: 22px;
	`};
`

export const GridSidebarBlockTitle = styled.div`
  margin-bottom: 10px;

  font-family: Retro;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
`

const GridSidebarActionStyle = styled.div<{ maxWidth?: string }>`
  width: ${({ maxWidth }) => maxWidth || '100%'};
  margin: 0 auto;
  margin-top: 20px;
`

export const GridSidebarHeader = styled.div<{ withBackground?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ withBackground, theme }) => {
    if (withBackground) {
      return `
        background: ${theme.colors.pageTitelBG};
        margin-bottom: 20px;
        border-radius: 0 0 20px 20px;
      `
    }

    return ``
  }};
`

interface GridSidebarBackProps {
  desktopShow?: boolean
  to: string
}

export function GridSidebarBack({ desktopShow, to }: GridSidebarBackProps) {
  return (
    <GridSidebarBackStyle desktopShow={desktopShow} to={to}>
      <IconArrow />
      Back
    </GridSidebarBackStyle>
  )
}

export function GridSidebarTitle(props: { children: React.ReactNode; count?: string }) {
  const { children, count, ...rest } = props

  return (
    <GridSidebarTitleStyle {...rest}>
      {children}

      {count && <div>{count}</div>}
    </GridSidebarTitleStyle>
  )
}

export function GridSidebarAction(props: { children: React.ReactNode; maxWidth?: string }) {
  const { children, ...rest } = props

  return <GridSidebarActionStyle {...rest}>{children}</GridSidebarActionStyle>
}
