import styled from 'styled-components'
import { darken } from 'polished'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import Column from '../Column'
import { SearchInput } from '../SearchModal/styleds'

export const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.25rem 0.25rem 0.25rem 0.5rem')};
`

export const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  height: 2.2rem;
  font-size: 18px;
  font-weight: normal;
  background-color: ${({ selected, theme }) => (selected ? theme.colors.white : theme.colors.white)};
  color: ${({ selected, theme }) => (selected ? theme.colors.text1 : theme.colors.text1)};
  border-radius: 8px;
  // #JUNK box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
  box-shadow: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0 0.5rem;
  margin-top: 4px;

  :focus,
  :hover {
    background-color: ${({ selected, theme }) => (selected ? theme.colors.white : theme.colors.white)};
  }
`

export const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.colors.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.5rem 1rem 0 1rem;
  margin-bottom: 7px;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.colors.text2)};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		margin-bottom: 3px;
	`};
`

export const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > img {
    width: 24px;
    height: 24px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
			width: 20px;
			height: 20px;
		`};
  }
`

export const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.colors.text1 : theme.colors.text1)};
    stroke-width: 2px;
  }
`

export const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  background-color: ${({ theme }) => theme.colors.bg0};
  z-index: 1;
`

export const Container = styled.div<{ hideInput: boolean }>`
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '10px')};
  // border: 2px solid ${({ theme }) => theme.colors.bg1};
  background-color: ${({ theme }) => theme.colors.bg1};
`

export const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0.5rem 0 0.5rem;' : '  margin: 0 0.5rem 0 0.5rem;')}
  font-size:  ${({ active }) => (active ? '18px' : '16px')};

	${({ theme }) => theme.mediaWidth.upToSmall`
		margin: 0 0.3rem 0 0.3rem;

		font-size: 16px;
		line-height: 16px;
	`};
`

export const StyledBalanceMax = styled.button`
  height: 27px;
  background-color: ${({ theme }) => theme.colors.primary1};
  border: 2px solid ${({ theme }) => theme.colors.primary1};
  border-color: ${({ theme }) => theme.colors.primary1};
  border-radius: 0.5rem;
  font-size: 16px;

  font-weight: normal;
  cursor: pointer;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.colors.buttonText};
  :hover {
    border: 2px solid ${({ theme }) => theme.colors.primary1};
  }
  :focus {
    border: 2px solid ${({ theme }) => theme.colors.primary1};
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		height: 20px;

		font-size: 14px;
  `};
`

export const StyledBalanceMaxDivider = styled.div`
  width: 1px;
  height: 24px;
  margin-left: 9px;
  margin-right: 12px;

  background-color: ${({ theme }) => theme.colors.text4};

  ${({ theme }) => theme.mediaWidth.upToSmall`
		height: 20px;
		margin-left: 2px;
		margin-right: 4px; 
	`};
`

export const DisabledLabel = styled.div<{ empty?: boolean; placeholder?: string }>`
  color: #000000;
  width: 0;
  position: relative;
  font-weight: bold;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: transparent;
  font-family: 'Abadi MT Std', sans-serif;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 14px 8px;
  border-radius: 6px;
  margin-right: 6px;

  ${({ empty, placeholder }) =>
    empty &&
    `
    &:before {
    opacity: 0.5;
    content: "${placeholder}";
  }`}
`

export const EarnTableSearchWrapper = styled(Column)`
  width: 344px;
  padding-bottom: 22px;
`

export const EarnTableSearchInput = styled(SearchInput)`
  line-height: 20px;
  font-size: 20px;
  background-color: #fdf4e1;
`

export const EmptySearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0;
  background-color: #fdf4e1;
  border-radius: 10px;
`
