import { Fraction } from '@wowswap-io/wowswap-sdk'
import BigNumber from 'bignumber.js'
import './math'
import { bn, fraction, toBN, oneRay, RAY, wad, ray } from './math'

export function calcHIR(rate: BigNumber.Value, currentTimestamp: BigNumber.Value) {
  return new Fraction(
    calcCompoundedInterest(bn(rate), bn(currentTimestamp), bn(0))
      .sub(RAY)
      .rayMul(oneRay.mul(100))
      .str(),
    RAY
  ).toSignificant(4)
}

export function calcHealth(cost: BigNumber.Value, liquidationCost: BigNumber.Value): BigNumber {
  const bnCost = bn(cost)
  const bnLiquidationCost = bn(liquidationCost)

  return bnCost.gt(bnLiquidationCost)
    ? bn(cost)
        .sub(liquidationCost)
        .div(cost)
        .mul(100)
    : bn(0)
}

export function calcExpectedDebt(debt: BigNumber, rate: BigNumber, lastUpdate: BigNumber, currentTimestamp: BigNumber) {
  const cumulatedInterest = calcCompoundedInterest(rate, currentTimestamp, lastUpdate)

  return debt.rayMul(cumulatedInterest)
}

export function calcExpectedIncome(
  deposit: BigNumber,
  rate: BigNumber,
  liquidityIndex: BigNumber,
  currentTimestamp: BigNumber,
  lastUpdateTimestamp: BigNumber
) {
  const cumulatedInterest = calcLinearInterest(rate, currentTimestamp, lastUpdateTimestamp)

  return deposit.rayMul(cumulatedInterest.rayMul(liquidityIndex))
}

export function calcAPY(liquidityRate: string) {
  return liquidityRate
    ? fraction(
        calcCompoundedInterest(toBN(liquidityRate), bn(31536000), bn(0))
          .sub(RAY)
          .rayMul(oneRay.mul(100))
          .str()
      ).toSignificant(4)
    : 0
}

export function calcUtilization(utilizationRate: string) {
  return fraction(utilizationRate)
    .multiply('100')
    .toSignificant(2)
}

const BlockPerYear = 10512000

export function calcStakingAPY({
  period,
  effectiveWOWFarmingSpeed,
  totalWowValue,
  yourXWOW,
  totalXWOW
}: {
  period?: number
  effectiveWOWFarmingSpeed?: string
  totalWowValue: BigNumber
  yourXWOW: BigNumber
  totalXWOW: BigNumber
}) {
  if (!period || !effectiveWOWFarmingSpeed || !totalWowValue.toNumber() || !totalXWOW.toNumber()) {
    return bn(0)
  }

  return bn(yourXWOW)
    .mul(100)
    .mul(effectiveWOWFarmingSpeed)
    .div(totalXWOW)
    .div(wad(1))
    .mul(BlockPerYear)
    .div(totalWowValue)
    .mul(wad(1))
}

export function calcReferenceAPY({
  period,
  effectiveWOWFarmingSpeed,
  yourXWOW,
  yourWOW,
  totalXWOW
}: {
  period?: number
  effectiveWOWFarmingSpeed?: string
  yourXWOW?: string
  yourWOW?: string
  totalXWOW: BigNumber
}) {
  if (!period || !effectiveWOWFarmingSpeed || !totalXWOW.toNumber()) {
    return bn(0)
  }

  const wowFarmingSpeed = bn(effectiveWOWFarmingSpeed).div(wad(1))
  const amountAllXwow = bn(totalXWOW)
    .plus(yourXWOW || '0')
    .div(wad(1))

  return bn(yourXWOW || '0')
    .mul(100)
    .mul(getApyMultiplier(period))
    .mul(wowFarmingSpeed)
    .mul(BlockPerYear)
    .div(amountAllXwow)
    .div(yourWOW || '1')
}

export const binomCompound = (rate: BigNumber, periods: BigNumber.Value, binoms = 5) => {
  const exp = bn(periods)
  if (exp.eq(0)) return ray(0)
  let el = rate.mul(exp)
  let result = ray(1).add(el)
  for (let i = 1; i < binoms; i++) {
    if (exp.lte(i)) break
    el = el
      .mul(exp.sub(i))
      .rayMul(rate)
      .div(i + 1)
    result = result.add(el)
  }

  return result
}

function calcCompoundedInterest(rate: BigNumber, currentTimestamp: BigNumber, lastUpdateTimestamp: BigNumber) {
  const timeDifference = currentTimestamp.minus(lastUpdateTimestamp)
  return binomCompound(rate, timeDifference)
}

function calcLinearInterest(rate: BigNumber, currentTimestamp: BigNumber, lastUpdateTimestamp: BigNumber) {
  const timeDifference = currentTimestamp.minus(lastUpdateTimestamp)

  const cumulatedInterest = rate.multipliedBy(timeDifference).plus(RAY)

  return cumulatedInterest
}

function getApyMultiplier(period: number) {
  if (period >= 14 && period <= 60) {
    return 1 + ((period - 14) * (3 - 1)) / (60 - 14)
  }
  if (period > 60 && period <= 180) {
    return 3 + ((period - 60) * (6 - 3)) / (180 - 60)
  }
  if (period > 180 && period <= 365) {
    return 6 + ((period - 180) * (8 - 6)) / (365 - 180)
  }
  if (period > 365 && period <= 730) {
    return 8 + ((period - 365) * (10 - 8)) / (730 - 365)
  }

  return 1
}
