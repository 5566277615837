import styled, { css } from 'styled-components'

import Logo from 'components/Logo'

export const MainLogo = styled(Logo)`
  display: block;
  width: 100%;
  border-radius: 5px;
  ${props =>
    props.theme.mode === 'dark' &&
    css`
      /* INFO: для черных иконок без background-а */
      background: radial-gradient(circle at center, #bbb 0% 68%, transparent 68% 100%);
    `}
`

export const SubLogo = styled(Logo)`
  display: block;
  position: absolute;
  right: -2px;
  bottom: -2px;
  width: 60%;
  border: 2px solid white;
  border-radius: 50%;
  ${props =>
    props.theme.mode === 'dark' &&
    css`
      border-color: black;
    `}
`

export const Root = styled.div<{ isSingle: boolean; size: string }>`
  position: relative;
  width: ${props => props.size};
  height: ${props => props.size};

  ${props => css`
    ${MainLogo} {
      width: ${props.isSingle ? '100%' : '90%'};
    }
  `}
`
