import React from 'react'
import { Text } from 'rebass'
import { ButtonError } from '../Button'
import { AutoRow } from '../Row'

export default function SwapModalFooter({
  onConfirm,
  disabledConfirm
}: {
  onConfirm: () => void
  disabledConfirm: boolean
}) {
  return (
    <>
      <AutoRow marginTop={'20px'}>
        <ButtonError onClick={onConfirm} disabled={disabledConfirm} id="confirm-sonvert">
          <Text fontSize={16} fontWeight={'normal'}>
            Convert
          </Text>
        </ButtonError>
      </AutoRow>
    </>
  )
}
