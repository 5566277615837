import styled from 'styled-components'

export const PortfolioItemStyle = styled.div<{ bgColor?: string }>`
  background: ${({ bgColor }) => bgColor || 'none'};
  border-radius: 10px;

  & > div:nth-child(2) {
    ${({ bgColor }) => {
      if (bgColor) {
        return `
          background: #FDF4E1;
        `
      } else {
        return `
        background: #ffffff;
      `
      }
    }}
  }
`

export const SquareFake = styled.div`
  width: 20px;
  height: 20px;
  background: #f2f2f2;
  border-radius: 3px;
`

export const SquareWrapper = styled.div`
  padding-bottom: 10px;
`

export const PortfolioTableHeaderFake = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  & > div:nth-child(1) {
    flex-grow: 2;
    margin-right: 15px;
    padding-left: 10px;
  }

  & > div:nth-child(2) {
    width: 10%;
    flex: 0 0 10%;
    margin-right: 15px;
  }

  & > div:nth-child(3) {
    width: 10%;
    flex: 0 0 10%;
    margin-right: 15px;
  }
  & > div:nth-child(4) {
    width: 10%;
    flex: 0 0 10%;
    margin-right: 15px;
  }
  & > div:nth-child(5) {
    width: 10%;
    flex: 0 0 10%;
    margin-right: 15px;
  }
  & > div:nth-child(6) {
    width: 16%;
    flex: 0 0 16%;
    margin-right: 15px;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
  margin-bottom: 8px;
  
  font-size: 18px;
  line-height: 23px;

  & > div:nth-child(2),
  & > div:nth-child(3),
  & > div:nth-child(5),
  & > div:nth-child(6) {
    display: none;
  }

  & > div:nth-child(2) {
    width: 57%;
    flex: 0 0 57%;
  }

  & > div:nth-child(4) {
    width: 22.5%;
    flex: 0 0 22.5%;
    margin-right: 55px;
  }
`};
`

export const PortfolioFakeItemLogo = styled.div`
  background: #f2f2f2;
  width: 28px;
  height: 28px;
  border-radius: 50%;
`

export const OpenIconFake = styled.div`
  width: 18px;
  height: 18px;

  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
  position: absolute;
  top: 12px;
  right: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
`};
`

export const PortfolioItemWraper = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 6px 4px 10px;
  min-height: 60px;

  border-radius: 10px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  min-height: 45px;
  padding: 8px 13px;
  flex-wrap: wrap;
  justify-content: flex-start;
  
`};

  ${({ theme }) => theme.mediaWidth.upToMedium`
  border-radius: 0;
`};
`

export const TokenBodyStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 2;
  margin-right: 25px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  width: 58%;
  flex: 0 0 57%;
`};
`
export const TokenBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  margin-right: 16px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  margin-right: 13px;
`};
`

export const TokenTexts = styled.div`
  margin-right: 15px;
  flex: 0 0 10%;
  width: 10%;
`

export const TokenTextsBig = styled.div`
  margin-right: 15px;
  flex: 0 0 16%;
  width: 16%;
`

export const CurrencyOneFake = styled.div`
  background: #f2f2f2;
  border-radius: 5px;
  width: 100%
  height: 18px;
  margin-bottom: 5px;
`

export const OneItemFake = styled.div`
  background: #f2f2f2;
  border-radius: 5px;
  width: 100%
  height: 24px;
  margin-bottom: 5px;
`

export const PositionsFake = styled.div`
  background: #f2f2f2;
  border-radius: 5px;
  margin-bottom: 5px;
  height: 13px;
`

export const HealthFake = styled(CurrencyOneFake)`
  width: 10%;
  flex: 0 0 10%;
  margin-right: 15px;
  border-radius: 5px;
  background: #f2f2f2;
  height: 22px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: auto;
    flex: auto;
    flex-grow: 2;
    margin-right: 41px;
  `};
`

export const CurrencyTwoFake = styled.div`
  background: #f2f2f2;
  border-radius: 5px;
  width: 100%
  height: 18px;
`

export const Debt = styled.div`
  flex-grow: 2;
  width: 80%;
  flex: 0 0 80%;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  width: 100%;
  flex: 0 0  100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;

  // background-color: ${({ theme }) => theme.colors.white};
`};
`

export const PositionsFakeWrapper = styled.div`
  flex-grow: 2;
  width: 80%;
  flex: 0 0 80%;
`

export const NetValue = styled.div`
  width: 16%;
  flex: 0 0 16%;
  margin-right: 25px;

  text-align: right;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  width: 100%;
  flex: 0 0  100%;
  display: flex;
  justify-content: space-between;

  // background-color: ${({ theme }) => theme.colors.white};
`};
`
