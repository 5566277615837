import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'

import ROUTER_ABI from '../constants/abis/router.json'
import RESERVE_ABI from '../constants/abis/reserve.json'
import RESERVE_FACTORY_ABI from '../constants/abis/reserveFactory.json'
import { ROUTER_ADDRESS } from '../constants'
import { ChainId, JSBI, Percent, Token, CurrencyAmount, Currency } from '@wowswap-io/wowswap-sdk'
import { TokenAddressMap } from '../state/lists/hooks'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  [ChainId.ETHEREUM]: 'https://etherscan.com',
  [ChainId.ROPSTEN]: 'https://ropsten.etherscan.com',
  [ChainId.MAINNET]: 'https://bscscan.com',
  [ChainId.LOCALNET]: '',
  [ChainId.HECO]: 'https://hecoinfo.com',
  [ChainId.MATIC]: 'https://explorer-mainnet.maticvigil.com',
  [ChainId.BSCTESTNET]: 'https://testnet.bscscan.com',
  [ChainId.AVALANCE]: 'https://snowtrace.io',
  [ChainId.FUJI]: '',
  [ChainId.IOTEX]: 'https://iotexscan.io',
  [ChainId.ANDROMEDA]: '',
  [ChainId.STARDUST]: 'https://stardust-explorer.metis.io'
}

export const dateToUnixtime = (date: Date) => Math.floor(date.valueOf() / 1000)

export function getEtherscanLink(chainId: ChainId, data: string, type: 'transaction' | 'token' | 'address'): string {
  const prefix = ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[56]

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000))
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

// account is optional
export function getRouterContract(chainId: ChainId, library: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS[chainId], ROUTER_ABI, library, account)
}

export function getReserveContract(address: string, library: Web3Provider, account?: string): Contract {
  return getContract(address, RESERVE_ABI, library, account)
}

export function getReserveFactoryContract(address: string, library: Web3Provider, account?: string): Contract {
  return getContract(address, RESERVE_FACTORY_ABI, library, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === Currency.getBaseCurrency()) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}

export const filterDefined = <T>(collection: Array<T | undefined>) =>
  collection.filter((some): some is T => typeof some !== 'undefined')

export const defined = <T>(something: T | undefined): something is T => typeof something !== 'undefined'

export const logError = (msg: string) => <TError>(e: TError) => {
  console.error(msg, '\n', e)
  throw e
}

export const doWith = <TIn, TOut = any>(
  collection: TIn[],
  func: (tasks: Array<Promise<TOut>>, el: TIn, index: number, collection: TIn[]) => void
) =>
  Promise.all(
    collection.reduce((tasks, el, index, all) => {
      func(tasks, el, index, all)
      return tasks
    }, [] as Array<Promise<TOut>>)
  )

export const toDictionaryValue = <TKey extends string | number | symbol, TElement, TValue>(
  collection: TElement[],
  getKey: (el: TElement) => TKey,
  getValue: (el: TElement) => TValue
): Record<TKey, TValue> =>
  collection.reduce((dict, el) => {
    dict[getKey(el)] = getValue(el)
    return dict
  }, {} as Record<TKey, TValue>)

export const toDictionary = <TKey extends string | number | symbol, TValue>(
  collection: TValue[],
  getKey: (el: TValue) => TKey
): Record<TKey, TValue> =>
  collection.reduce((dict, el) => {
    dict[getKey(el)] = el
    return dict
  }, {} as Record<TKey, TValue>)

export const flat = <T>(accumulator: T[], value: T[]) => accumulator.concat(value)

export const flatten = <TElement>(collection: Array<Array<TElement>>) => collection.reduce(flat, [])

export const filterAll = <TIn>(func: (el: TIn) => boolean) => (collection: TIn[]) => collection.filter(func)

export const mapAll = <TIn, TOut>(func: (el: TIn) => TOut) => (collection: TIn[]) => collection.map(func)

export const sleep = (n: number) => new Promise(resolve => setTimeout(resolve, n))
