import React from 'react'
import ReactSlider from 'react-slider'
import useDebouncedChangeHandler from '../../utils/useDebouncedChangeHandler'

import {
  SliderCustomBtn,
  SliderGovValue,
  SliderGovValueItem,
  SliderCustomWraper,
  StyledReactSliderGov
} from './styleds'

interface SliderCustomProps {
  value: number
  from: number
  to: number
  currentWidth?: number
  step: number
  marks?: number[]
  onChange: (value: number) => void
  onRenderMark?: (mark: number) => any
}

export function SliderGov({ onChange, value, from, to, marks, currentWidth, step, onRenderMark }: SliderCustomProps) {
  const [val, setValue] = useDebouncedChangeHandler<number>(
    value,
    v => {
      onChange!(v)
    },
    100
  )

  return (
    <SliderCustomWraper>
      <StyledReactSliderGov value={currentWidth || 0}>
        <ReactSlider
          className="slider"
          min={from}
          step={step}
          max={to}
          thumbClassName="thumb"
          trackClassName="track"
          onChange={(v: number | number[] | undefined | null) => setValue(v as number)}
          value={val}
          renderThumb={props => (
            <div {...props}>
              <SliderCustomBtn />
            </div>
          )}
        />
        <div className={'disabled-track'} />
      </StyledReactSliderGov>

      {marks && (
        <SliderGovValue>
          {marks.map((mark, idx) => {
            const strMark = onRenderMark ? onRenderMark(mark) : mark
            return <SliderGovValueItem key={strMark + idx}>{strMark}</SliderGovValueItem>
          })}
        </SliderGovValue>
      )}
    </SliderCustomWraper>
  )
}
