import React, { useState, useLayoutEffect } from 'react'

import CrossLoader from '../../assets/svg/cross-loader.svg'

import {
  CurrencyOneFake,
  CurrencyTwoFake,
  Debt,
  PortfolioItemStyle,
  PortfolioItemWraper,
  PortfolioFakeItemLogo,
  TokenBodyStyled,
  TokenTexts,
  HealthFake,
  OpenIconFake,
  PositionsFakeWrapper,
  SquareFake,
  SquareWrapper,
  OneItemFake,
  PositionsFake,
  TokenTextsBig
} from './styleds'

interface PortfolioItemProps {
  isOdd: boolean
}

function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export default function PortfolioLoaderItem({ isOdd }: PortfolioItemProps) {
  const [width] = useWindowSize()

  return (
    <PortfolioItemStyle bgColor={isOdd ? '#ffffff' : ''}>
      <PortfolioItemWraper>
        <TokenBodyStyled>
          <PortfolioFakeItemLogo />

          {width > 1280 && (
            <>
              <Debt>
                <CurrencyOneFake />
                <CurrencyTwoFake />
              </Debt>
              <SquareWrapper>
                <SquareFake />
              </SquareWrapper>
            </>
          )}
          {width <= 1280 && (
            <PositionsFakeWrapper>
              <PositionsFake />
              <PositionsFake />
            </PositionsFakeWrapper>
          )}
        </TokenBodyStyled>
        {width > 1280 && (
          <TokenTexts>
            <CurrencyOneFake />
            <CurrencyTwoFake />
          </TokenTexts>
        )}
        {width > 1280 && (
          <TokenTexts>
            <CurrencyOneFake />
            <CurrencyTwoFake />
          </TokenTexts>
        )}
        {width > 1280 && (
          <TokenTexts>
            <OneItemFake />
          </TokenTexts>
        )}
        {width > 1280 && (
          <TokenTexts>
            <OneItemFake />
          </TokenTexts>
        )}
        {width > 1280 && (
          <TokenTextsBig>
            <OneItemFake />
          </TokenTextsBig>
        )}
        {width <= 1280 && <HealthFake />}
        {width <= 1280 && (
          <OpenIconFake>
            <img alt="Cross Loader" src={CrossLoader} />
          </OpenIconFake>
        )}
      </PortfolioItemWraper>
    </PortfolioItemStyle>
  )
}
