import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  height: 300px;
  margin-bottom: 20px;
  background: #fdf4e1;
  border: 1px solid #f3ba2f;
  box-sizing: border-box;
  border-radius: 5px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

const Content = styled.div`
  position: relative;
  min-width: 300px;
  min-height: 300px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    transform: scale(0.7);
    left: -10%;
  `}
`

const ArrowUp = styled.div`
  position: absolute;
  border: 1px solid #000000;
  box-sizing: border-box;

  width: 1px;
  height: 220px;
  bottom: 60px;
  left: 40px;
`

const ArrowRight = styled.hr`
  position: absolute;
  border: 1px solid #000000;
  box-sizing: border-box;

  width: 310px;
  height: 0px;
  bottom: 60px;
  left: 40px;
`

const Risk = styled.div<{ left: number }>`
  position: absolute;
  border: 1px solid #000000;
  box-sizing: border-box;

  width: 1px;
  height: 9px;
  bottom: 60px;
  left: ${({ left }) => left}px;
`

const Level = styled.div<{ bottom: number }>`
  position: absolute;
  border: 1px solid #d1d1cf;
  box-sizing: border-box;

  width: 308px;
  height: 0px;
  bottom: ${({ bottom }) => bottom}px;
  left: 42px;
`

const RiskLabel = styled.div<{ left: number }>`
  position: absolute;
  font-size: 10px;

  bottom: 46px;
  left: ${({ left }) => left}px;
`

const Label = styled.div<{ left: number; bottom: number }>`
  position: absolute;
  font-size: 19px;
  color: black;

  bottom: ${({ bottom }) => bottom}px;
  left: ${({ left }) => left}px;
`

const LabelWithSub = styled.div<{ left: number; bottom: number }>`
  position: absolute;
  font-size: 19px;
  color: black;
  display: inline-flex;
  border-top: 1px solid black;

  bottom: ${({ bottom }) => bottom}px;
  left: ${({ left }) => left}px;
`

const Sub = styled.div`
  position: absolute;
  font-size: 13px;
  color: black;

  margin-top: 8px;
  margin-left: 12px;
`

const Pointer = styled.div<{ left: number; bottom: number }>`
  position: absolute;
  bottom: ${({ bottom }) => bottom}px;
  left: ${({ left }) => left}px;

  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #f3ba2f;
  border: 2px solid #000000;
`

const CANVAS_WIDTH = 300
const CANVAS_HEIGHT = 154

const SvgCanvas = styled.svg`
  position: absolute;
  bottom: 68px;
  left: 40px;

  height: ${CANVAS_HEIGHT}px;
  width: ${CANVAS_WIDTH}px;

  path {
    fill: none;
    stroke: #f3ba2f;
    stroke-width: 2px;
  }
`

export function Graph({ r0, r80, r100, optimal }: { r0: number; r80: number; r100: number; optimal: number }) {
  const R0 = r0
  const R80 = r0 + r80
  const R100 = r0 + r80 + r100

  const mulX = CANVAS_WIDTH / 100
  const mulY = CANVAS_HEIGHT / R100

  return (
    <Wrapper>
      <Content>
        <ArrowUp />
        <ArrowRight />
        <Risk left={70} />
        <Risk left={100} />
        <Risk left={130} />
        <Risk left={160} />
        <Risk left={190} />
        <Risk left={220} />
        <Risk left={250} />
        <Risk left={280} />
        <Risk left={310} />
        <Risk left={340} />
        <RiskLabel left={35}>0%</RiskLabel>
        <RiskLabel left={60}>10%</RiskLabel>
        <RiskLabel left={90}>20%</RiskLabel>
        <RiskLabel left={120}>30%</RiskLabel>
        <RiskLabel left={150}>40%</RiskLabel>
        <RiskLabel left={180}>50%</RiskLabel>
        <RiskLabel left={210}>60%</RiskLabel>
        <RiskLabel left={240}>70%</RiskLabel>
        <RiskLabel left={270}>80%</RiskLabel>
        <RiskLabel left={300}>90%</RiskLabel>
        <RiskLabel left={330}>100%</RiskLabel>
        <Level bottom={100} />
        <Level bottom={140} />
        <Level bottom={180} />
        <Level bottom={220} />
        <Level bottom={260} />

        <Label bottom={10} left={120}>
          Pool Utilization Rate
        </Label>
        <LabelWithSub bottom={70} left={13}>
          R <Sub>0</Sub>
        </LabelWithSub>
        <LabelWithSub bottom={220} left={5}>
          R <Sub>100</Sub>
        </LabelWithSub>
        <SvgCanvas>
          <path d={`M0 ${(R100 - R0) * mulY} L${optimal * mulX} ${(R100 - R80) * mulY} L${CANVAS_WIDTH} 0`} />
        </SvgCanvas>
        <Pointer left={34} bottom={61 + R0 * mulY} />
        <Pointer left={34 + optimal * mulX} bottom={61 + R80 * mulY} />
        <Pointer left={333} bottom={61 + R100 * mulY} />
      </Content>
    </Wrapper>
  )
}
