import base from './base'

export default {
  ...base,
  colors: {
    ...base.colors,
    shadow1: '#2F80ED',

    white: base.colors.white,
    black: base.colors.black,

    text1: base.colors.black,
    text2: base.colors.orange,
    text3: base.colors.red,
    text4: base.colors.grey2,

    // backgrounds / greys
    bg0: base.colors.light,
    fg0: base.colors.black,
    bg1: base.colors.white,
    fg1: base.colors.black,
    bg2: '#fffdfe',
    fg2: base.colors.black,
    bg3: '#ffecf9',
    fg3: base.colors.black,
    bg4: '#E6AE25',
    fg4: base.colors.white,
    bg5: '#FCEEBF',
    fg5: base.colors.black,
    bg6: 'linear-gradient(193.68deg,#2a1926 0.68%,#1f121c 100.48%)',
    fg6: base.colors.white,
    modalBG: 'linear-gradient(90deg, rgba(255, 247, 60, 0.4) 0%, rgba(243, 186, 47, 0.4) 100%)',
    advancedBG: 'rgba(255,223,248,0.6)',
    pageTitelBG: 'transparent',

    //primary colors
    primary1: base.colors.orange,
    primary4: base.colors.red,
    primary5: base.colors.green,
    primary6: '#ffdff5',

    // color text
    primaryText1: '#FFFFFF',
    buttonText: base.colors.light,

    // secondary colors
    secondary1: base.colors.grey1,

    // gradient
    gradient: `linear-gradient(90deg, #A3EEFF 0%, #51A8E9 100%)`,

    red: '#E85A44'
  }
}
