import React, { useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { Param } from '../../state/financial/actions'
import { ParamValue } from '../financailModel/ParamValue'
import { SliderGov } from '../SliderCustom/SliderGov'

const FeeDistributionItemStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  padding-left: 16px;
  padding-right: 20px;

  font-size: 16px;
  line-height: 16px;

  &:nth-child(even) {
    background: ${({ theme }) => theme.colors.bg0};
  }
  &:nth-child(odd) {
    background: ${({ theme }) => theme.colors.bg1};
  }

  border-radius: 10px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		flex-direction: column;
		align-items: flex-start;
		margin: 0 15px;
		padding: 10px 0;

		background: none;
		border-bottom: 2px solid #FDF4E1;
		border-radius: 0;

		& > div {
			width: 100%;
			flex: 0 0 100%;
		}
	`};
`
const Name = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;

  font-weight: bold;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		margin-bottom: 10px;
	`};
`

const ItemIcon = styled.img`
  display: block;
  width: 26px;
  height: 18px;
  margin-right: 8px;
`

const CurrentValue = styled.div`
  width: 15%;
  flex: 0 0 15%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		display: flex;
		align-items: center;	
		justify-content: space-between;
		margin-bottom: 8px;
	`};
`

const NewValue = styled.div`
  width: 15%;
  flex: 0 0 15%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		display: flex;
		align-items: center;	
		justify-content: space-between;
		margin-bottom: 8px;
	`};
`

const YourValue = styled.div`
  width: 36%;
  flex: 0 0 36%;
  display: flex;
  align-items: center;

  overflow: hidden;

  & > div:first-child {
    width: 85px;
    flex: 0 0 85px;

    font-weight: bold;
  }
  & > div:last-child {
    flex-grow: 2;
    margin-top: 15px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		flex-direction: column;
		align-items: stretch;		

		& > div:first-child {
			width: 100%;
			flex: 0 0 100%;
			display: flex;
			align-items: center;	
			justify-content: space-between;
			margin-bottom: 3px;
		}
	`};
`

const TitleBlock = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: #999999;
  font-weight: normal;
`

function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

interface FeeDistributionItemProps {
  field: Param
  icon: string
  title: string
  currentValue: number
  newValue: number
  yourValue: number
  onChange: (field: Param, value: number) => void
}

export function FeeDistributionItem({
  field,
  icon,
  title,
  currentValue,
  newValue,
  yourValue,
  onChange
}: FeeDistributionItemProps) {
  const [width] = useWindowSize()

  const onChangeValue = (value: number) => {
    onChange(field, value)
  }

  const currentWidth = 100 - currentValue

  return (
    <FeeDistributionItemStyle>
      <Name>
        <ItemIcon src={icon} />
        {title}
      </Name>

      <CurrentValue>
        {width <= 600 && <TitleBlock>Current Value</TitleBlock>}
        <ParamValue precision={2} percentage>
          {currentValue}
        </ParamValue>
      </CurrentValue>

      <NewValue>
        {width <= 600 && <TitleBlock>New Value (applied in 24h)</TitleBlock>}
        <ParamValue precision={2} percentage possibleNull>
          {newValue}
        </ParamValue>
      </NewValue>

      <YourValue>
        <div>
          {width <= 600 && <TitleBlock>Your Value</TitleBlock>}
          <ParamValue precision={2} percentage>
            {yourValue}
          </ParamValue>
        </div>

        <SliderGov
          from={0}
          to={100}
          value={yourValue}
          currentWidth={currentWidth}
          marks={[0, 100]}
          step={0.1}
          onChange={onChangeValue}
          onRenderMark={(mark: number) => `${mark}%`}
        />
      </YourValue>
    </FeeDistributionItemStyle>
  )
}
