import { ethers } from 'ethers'
import { useEffect, useState } from 'react'
import { useActiveWeb3React } from '.'
import { SIGNER_API_URLS } from '../constants'

export enum Status {
  Unavailable,
  Loading,
  Error,
  Success
}

export const useGuardedPriceSignature = (pair?: string) => {
  const { chainId } = useActiveWeb3React()
  const [result, setResult] = useState<{ status: Status; error?: Error; data?: unknown }>({
    status: Status.Unavailable
  })
  useEffect(() => {
    async function fetchSignature() {
      if (!chainId) {
        setResult({ status: Status.Unavailable })
        return
      }

      try {
        const response = await fetch(SIGNER_API_URLS[chainId], {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({ pair })
        })
        const data = await response.json()

        setResult({ status: Status.Success, data })
      } catch (error) {
        setResult({ status: Status.Error, error })
      }
    }

    if (pair && pair !== ethers.constants.AddressZero) fetchSignature()
  }, [pair, chainId])

  return result
}
