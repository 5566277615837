import React from 'react'
import styled from 'styled-components'

const VotesListBody = styled.div`
  color: ${({ theme }) => theme.colors.primary5};

  ${({ theme }) => theme.mediaWidth.upToMedium`
		
	`};
`
const VotesListItems = styled.div`
  padding: 9px 10px;

  background-color: ${({ theme }) => theme.colors.bg1};
  border-radius: 10px;

  &:nth-child(2n) {
    background-color: transparent;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
		padding: 10px 15px;

		border-radius: 5px;
	`};
`
const VotesListBottom = styled.div`
  padding-top: 20px;

  text-align: center;
`
const VotesListLink = styled.a`
  font-family: 'Retro';
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.text1};
  text-decoration: none;

  transition: all 0.4s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.bg5};
  }
`
const VotesListItemBody = styled.div`
  display: flex;
  align-items: center;
`
const VotesListItemCode = styled.div`
  width: 105px;
  padding-right: 5px;

  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text1};

  ${({ theme }) => theme.mediaWidth.upToMedium`
		width: 107px;

		font-size: 14px;
		line-height: 14px;
	`};
`
const VotesListItemCheck = styled.div<{ action?: 'red' }>`
  width: 70px;
  padding-right: 3px;

  font-weight: 500;
  font-size: 16px;
  line-height: 16px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
		font-size: 14px;
		line-height: 14px;
	`};

  ${({ action }) => {
    if (action === 'red') {
      return `
				color: #E85A44;
			`
    } else {
      return `
				color: #42CE61;
			`
    }
  }}
`
const VotesListItemTotal = styled.div`
  flex-grow: 2;
  padding-left: 3px;

  text-align: right;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  white-space: nowrap;
  word-break: keep-all;
  color: ${({ theme }) => theme.colors.text1};

  ${({ theme }) => theme.mediaWidth.upToMedium`
		font-size: 14px;
		line-height: 14px;
	`};
`
export default function VotesList() {
  return (
    <VotesListBody>
      <VotesListItems>
        <VotesListItem code="0x21f...211" check="For" total="214 xWOW" />
      </VotesListItems>

      <VotesListItems>
        <VotesListItem code="0x21f...211" check="For" total="111 xWOW" />
      </VotesListItems>

      <VotesListItems>
        <VotesListItem code="0x21f...211" check="For" total="5 xWOW" />
      </VotesListItems>

      <VotesListItems>
        <VotesListItem code="0x21f...211" check="Against" total="14 xWOW" action="red" />
      </VotesListItems>

      <VotesListItems>
        <VotesListItem code="0x21f...211" check="For" total="5 xWOW" />
      </VotesListItems>

      <VotesListItems>
        <VotesListItem code="0x21f...211" check="For" total="111 xWOW" />
      </VotesListItems>

      <VotesListItems>
        <VotesListItem code="0x21f...211" check="For" total="5 xWOW" />
      </VotesListItems>

      <VotesListItems>
        <VotesListItem code="0x21f...211" check="Against" total="14 xWOW" action="red" />
      </VotesListItems>

      <VotesListItems>
        <VotesListItem code="0x21f...211" check="For" total="5 xWOW" />
      </VotesListItems>

      <VotesListBottom>
        <VotesListLink href="#">+more</VotesListLink>
      </VotesListBottom>
    </VotesListBody>
  )
}

interface VotesListItemProps {
  code: string
  check: string
  total: string
  action?: 'red'
}

function VotesListItem({ code, check, total, action }: VotesListItemProps) {
  return (
    <VotesListItemBody>
      <VotesListItemCode>{code}</VotesListItemCode>

      <VotesListItemCheck action={action}>{check}</VotesListItemCheck>

      <VotesListItemTotal>{total}</VotesListItemTotal>
    </VotesListItemBody>
  )
}
