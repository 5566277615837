import React from 'react'
import styled from 'styled-components'
import { LiquidationItem } from '../LiquidationItem'
import SortTable from '../SortTable'

const LiquidationTableStyle = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
		
	`};
`

const LiquidationTableHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;

  & > div:nth-child(1) {
    width: 186px;
    flex: 0 0 186px;
    margin-right: 25px;
    padding-left: 20px;
  }
  & > div:nth-child(3) {
    flex-grow: 2;
    margin-right: 218px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
		& > div:nth-child(1) {
			width: 1250px;
			flex: 0 0 125px;
			margin-right: 15px;
			padding-left: 12px;
		}
		& > div:nth-child(3) {
			margin-right: 196px;
		}
	`};

  ${({ theme }) => theme.mediaWidth.upToSmall`
		& > div:nth-child(1) {
			width: 102px;
			flex: 0 0 102px;
			margin-right: 0;
			padding-left: 12px;
		}
		& > div:nth-child(3) {
			margin-right: 60px;
		}
	`};
`

const LiquidationTableSortWraper = styled.div<{ flexRight?: boolean }>`
  ${({ flexRight }) => {
    if (flexRight) {
      return `
				display: flex;
				justify-content: flex-end;
			`
    } else {
      return ``
    }
  }}
`

const LiquidationTableList = styled.div``

export function LiquidationTable() {
  return (
    <LiquidationTableStyle>
      <LiquidationTableHeader>
        <LiquidationTableSortWraper>
          <SortTable title="Owner" field="Owner" />
        </LiquidationTableSortWraper>

        <LiquidationTableSortWraper>
          <SortTable
            title="Health"
            field="Health"
            sort
            help="Your transaction will revert if the price changes unfavorably by more than this percentage."
          />
        </LiquidationTableSortWraper>

        <LiquidationTableSortWraper flexRight>
          <SortTable
            title="Max reward"
            field="Max reward"
            sort
            help="Your transaction will revert if the price changes unfavorably by more than this percentage."
          />
        </LiquidationTableSortWraper>
      </LiquidationTableHeader>

      <LiquidationTableList>
        <LiquidationItem
          bgColor="#ffffff"
          owner="xd8m...6dx35"
          health={{
            action: 'down',
            value: '8.5%'
          }}
          reward="12.22 BNB"
          disabled
        />

        <LiquidationItem
          owner="xd8m...6dx35"
          health={{
            action: 'down',
            value: '0.0%'
          }}
          reward="0.0353 BUSD"
        />

        <LiquidationItem
          bgColor="#ffffff"
          owner="xd8m...6dx35"
          health={{
            action: 'middle',
            value: '25.0%'
          }}
          reward="12.22 BNB"
          disabled
        />

        <LiquidationItem
          owner="xd8m...6dx35"
          health={{
            action: 'up',
            value: '51.0%'
          }}
          reward="12.22 BNB"
          disabled
        />

        <LiquidationItem
          bgColor="#ffffff"
          owner="xd8m...6dx35"
          health={{
            action: 'middle',
            value: '25.0%'
          }}
          reward="124.2552 BNB"
          disabled
        />

        <LiquidationItem
          owner="xd8m...6dx35"
          health={{
            action: 'down',
            value: '4.0%'
          }}
          reward="12.22 BNB"
          disabled
        />

        <LiquidationItem
          bgColor="#ffffff"
          owner="xd8m...6dx35"
          health={{
            action: 'up',
            value: '51.0%'
          }}
          reward="1422.22 BUSD"
          disabled
        />

        <LiquidationItem
          owner="xd8m...6dx35"
          health={{
            action: 'middle',
            value: '26.0%'
          }}
          reward="12.22 BNB"
        />

        <LiquidationItem
          bgColor="#ffffff"
          owner="xd8m...6dx35"
          health={{
            action: 'down',
            value: '6.0%'
          }}
          reward="0.0353 BUSD"
          disabled
        />
      </LiquidationTableList>
    </LiquidationTableStyle>
  )
}
