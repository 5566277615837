import React from 'react'
import styled from 'styled-components'

const StatsTotalStyle = styled.div`
  padding: 22px 19px 20px 20px;

  background: ${({ theme }) => theme.colors.bg0};
  border-radius: 10px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		overflow: auto;
		padding: 0;

		background: none;	
		border-radius: 0;
	`};
`
const StatsTotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
const StatsTotalItem = styled.div`
  text-align: center;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		flex: 0 0 auto;
		margin-right: 8px;
		padding: 15px 15px 15px 15px;

		background: ${({ theme }) => theme.colors.bg0};	
		border-radius: 10px;
	`};

  &:last-child {
    ${({ theme }) => theme.mediaWidth.upToLarge`
			margin-right: 0;
		`};
  }
`
const StatsTotalTitle = styled.div`
  margin-bottom: 10px;

  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text1};
`
const StatsTotalNumber = styled.div`
  font-family: 'Retro';
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text3};
`

export function StatsTotal() {
  return (
    <StatsTotalStyle>
      <StatsTotalWrapper>
        <StatsTotalItem>
          <StatsTotalTitle>Total Value Locked</StatsTotalTitle>
          <StatsTotalNumber>$321,303</StatsTotalNumber>
        </StatsTotalItem>

        <StatsTotalItem>
          <StatsTotalTitle>Total Volume</StatsTotalTitle>
          <StatsTotalNumber>$120,012,012</StatsTotalNumber>
        </StatsTotalItem>

        <StatsTotalItem>
          <StatsTotalTitle>Total Fees Collected</StatsTotalTitle>
          <StatsTotalNumber>$12,000,021</StatsTotalNumber>
        </StatsTotalItem>

        <StatsTotalItem>
          <StatsTotalTitle>Total Liquidations</StatsTotalTitle>
          <StatsTotalNumber>$10,125,555</StatsTotalNumber>
        </StatsTotalItem>

        <StatsTotalItem>
          <StatsTotalTitle>Donated in Total</StatsTotalTitle>
          <StatsTotalNumber>$123,107</StatsTotalNumber>
        </StatsTotalItem>
      </StatsTotalWrapper>
    </StatsTotalStyle>
  )
}
