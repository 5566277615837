import { useMemo } from 'react'
import { useTradeClosePositionHandler } from '../state/trade/hooks'
import { getRouterContract } from '../utils'
import { useActiveWeb3React } from './index'

export enum CalculateBalanceStatus {
  INVALID,
  VALID
}

interface ICalculateBalance {
  isOpenPosition: boolean
  shorting: boolean
  amount?: string
  inputAddress?: string
  outputAddress?: string
  proxyAddress?: string
}

interface ICalculateBalanceReturnType {
  status: CalculateBalanceStatus
  callback: null | (() => Promise<any>)
}

export function useCalculateBalance(args: ICalculateBalance): ICalculateBalanceReturnType {
  const { isOpenPosition, shorting, amount, inputAddress, outputAddress, proxyAddress } = args
  const { account, contract, chainId } = useContract()
  const onChangeDebtInfo = useTradeClosePositionHandler()

  return useMemo(() => {
    if (!account || !chainId || !amount || !inputAddress || !outputAddress || !contract || isOpenPosition) {
      return { callback: null, status: CalculateBalanceStatus.INVALID }
    }

    const inputs = [outputAddress]
    if (proxyAddress) {
      inputs.push(proxyAddress)
    }
    inputs.push(inputAddress, account, amount)

    return {
      status: CalculateBalanceStatus.VALID,
      callback: async function onCalculateBalance() {
        const methodParts: string[] = []
        methodParts.push('calculate')
        if (proxyAddress) methodParts.push('Proxy')
        if (shorting) methodParts.push('Short')
        methodParts.push('Balance')
        const method = methodParts.join('')

        return contract.callStatic[method](...inputs)
          .then((response: any) => {
            const { amountOut, debtPayable, protocolFee, profit } = response

            onChangeDebtInfo({
              amountOut: amountOut.toString(),
              debtPayable: debtPayable.toString(),
              protocolFee: protocolFee.toString(),
              profit: profit.toString()
            })

            return profit.toString()
          })
          .catch(e => {
            console.error('Failed calculate close position', e)
          })
      }
    }
  }, [
    inputAddress,
    outputAddress,
    proxyAddress,
    amount,
    account,
    chainId,
    contract,
    isOpenPosition,
    shorting,
    onChangeDebtInfo
  ])
}

function useContract() {
  const { account, chainId, library } = useActiveWeb3React()

  return useMemo(() => {
    if (!account || !chainId || !library) {
      return {}
    }

    return {
      account,
      chainId,
      contract: getRouterContract(chainId, library, account)
    }
  }, [account, chainId, library])
}
