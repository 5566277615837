import { createReducer } from '@reduxjs/toolkit'
import {
  Field,
  replaceTradeState,
  selectCurrency,
  switchCurrencies,
  typeInput,
  setLeverage,
  setLeverageAllow,
  setClosePositionInfo,
  setOpenPositionInfo,
  setIsShortTrade,
  resetSwapState,
  setMaxPositionAmount
} from './actions'

export interface TradeState {
  readonly typedValue: string
  readonly [Field.INPUT]: string
  readonly [Field.OUTPUT]: string

  readonly leverage: number
  readonly leverageAllow: number
  readonly showError: boolean

  readonly amountOut: string
  readonly debtPayable: string
  readonly protocolFee: string
  readonly profit: string
  readonly borrowAmount: string
  readonly borrowRate: number
  readonly liquidationCost: number
  readonly maxBorrow: string
  readonly isShortTrade: boolean
  readonly isMaxPosition: boolean
}

const initialState: TradeState = {
  typedValue: '',
  [Field.INPUT]: '',
  [Field.OUTPUT]: '',
  leverage: 1,
  leverageAllow: 3,
  showError: false,
  amountOut: '',
  debtPayable: '',
  protocolFee: '',
  profit: '',
  borrowAmount: '',
  borrowRate: 0,
  liquidationCost: 0,
  maxBorrow: '',
  isShortTrade: false,
  isMaxPosition: false
}

export default createReducer<TradeState>(initialState, builder =>
  builder
    .addCase(
      replaceTradeState,
      (state, { payload: { typedValue, recipient, field, inputCurrencyId, outputCurrencyId } }) => {
        return {
          ...initialState
        }
      }
    )
    .addCase(selectCurrency, (state, { payload: { field, currency } }) => {
      return {
        ...state,
        [field]: currency
      }
    })
    .addCase(switchCurrencies, state => {
      return {
        ...state,
        typedValue: '',
        [Field.INPUT]: state[Field.OUTPUT],
        [Field.OUTPUT]: state[Field.INPUT],
        debtPayable: '',
        protocolFee: '',
        profit: ''
      }
    })
    .addCase(typeInput, (state, { payload: { typedValue } }) => {
      return {
        ...state,
        typedValue,
        debtPayable: '',
        protocolFee: '',
        profit: ''
      }
    })
    .addCase(setLeverage, (state, { payload: { leverage } }) => {
      const showError = state.leverageAllow <= leverage
      return {
        ...state,
        leverage,
        showError
      }
    })
    .addCase(setLeverageAllow, (state, { payload: { leverageAllow } }) => {
      return {
        ...state,
        leverageAllow
      }
    })
    .addCase(setClosePositionInfo, (state, { payload }) => {
      return {
        ...state,
        ...payload
      }
    })
    .addCase(setIsShortTrade, (state, { payload: { isShortTrade } }) => {
      return {
        ...state,
        isShortTrade
      }
    })
    .addCase(setOpenPositionInfo, (state, { payload }) => {
      return {
        ...state,
        ...payload
      }
    })
    .addCase(resetSwapState, () => {
      return { ...initialState }
    })
    .addCase(setMaxPositionAmount, (state, { payload: { isMaxPosition } }) => {
      return {
        ...state,
        isMaxPosition
      }
    })
)
