import React from 'react'
import styled from 'styled-components'

const Wrap = styled.div<{ disabled: boolean }>`
  display: flex;
  ${({ disabled }) =>
    disabled &&
    `
    color: rgb(209, 209, 207);
  `}
`

const Styled = styled.div`
  max-width: 65px;
  word-break: keep-all;
  overflow: hidden;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    max-width: none;
	`};
`

const toString = (n: number, precision: number) => Math.floor(Number(n.toFixed(precision)) * 10 ** 3) / 10 ** 3

export function ParamValue({
  precision,
  percentage,
  possibleNull,
  children
}: {
  precision: number
  percentage?: boolean
  possibleNull?: boolean
  children: number
}) {
  const isNull = children === 0
  const disabled = Boolean(isNull && possibleNull)
  return (
    <Wrap disabled={disabled}>
      <Styled>{disabled ? '-' : toString(children, precision)}</Styled>
      {!disabled && percentage && '%'}
    </Wrap>
  )
}
