import React from 'react'
import styled from 'styled-components'
import { SingleParamType } from '../../state/financial/actions'
import { SliderGov } from '../SliderCustom/SliderGov'

const FeeModuleStyle = styled.div`
  flex-grow: 2;
  margin: 0 7px;
  padding: 20px 20px 24px 20px;

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
		margin-bottom: 10px;
		padding: 15px;

		:last-child {
			margin-bottom: 0;
		}
	`};
`

const FeeModuleTitle = styled.div`
  margin-bottom: 10px;

  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
`

const FeeModuleBody = styled.div`
  display: flex;
  justify-content: space-between;

  & > div:first-child {
    width: 42%;
    flex: 0 0 42%;
  }
  & > div:last-child {
    width: 58%;
    flex: 0 0 58%;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
		flex-direction: column;

		& > div:first-child,
		& > div:last-child {
			width: 100%;
			flex: 0 0 100%;
		}

		& > div:first-child {
			margin-bottom: 7px;
		}
	`};
`

const CurrentValue = styled.div`
  margin-bottom: 15px;

  ${({ theme }) => theme.mediaWidth.upToMedium`	
		margin-bottom: 10px;
	`};
`

const Value = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  white-space: nowrap;

  font-size: 14px;
  line-height: 14px;
  color: #999999;

  & > div {
    display: flex;
    margin-top: 5px;

    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    color: #000000;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`	
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		& > div {
			margin-top: 0;			
		}
	`};
`

const NewValue = styled.div`
  max-width: 100px;

  ${({ theme }) => theme.mediaWidth.upToMedium`	
		max-width: none;
	`};
`

const YourValue = styled.div``

const YourValueTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;

  font-size: 14px;
  line-height: 14px;
  color: #999999;

  & > div {
    display: flex;
    margin-left: 6px;

    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`	
		margin-bottom: 17px;
	`};
`

const YourValueInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 12px;

  font-size: 12px;
  line-height: 12px;
  color: #999999;

  & > div:first-child {
    margin-right: 10px;
  }
  & > div:last-child {
    text-align: right;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`	
		padding-top: 15px;
	`};
`

interface FeeModuleProps {
  title: string
  field: SingleParamType
  current: number
  userValue: number
  newValue: number
  from: number
  to: number
  marks: number[]
  feeInfoLow: string
  feeInfoHign: string
  onChange: (field: SingleParamType, value: number) => void
  onRenderMark: (mark: number, possibleNull?: boolean) => string | React.ReactNode
}

export default function FeeModule({
  title,
  field,
  current,
  userValue,
  newValue,
  from,
  to,
  marks,
  feeInfoLow,
  feeInfoHign,
  onChange,
  onRenderMark
}: FeeModuleProps) {
  const handleChange = (value: number) => {
    onChange(field, value)
  }

  const currentWidth = ((to - from - (current - from)) / (to - from)) * 100
  const step = Math.abs((to - from) / 1000)

  return (
    <FeeModuleStyle>
      <FeeModuleTitle>{title}</FeeModuleTitle>

      <FeeModuleBody>
        <div>
          <CurrentValue>
            <Value>
              Current Value
              <div>{onRenderMark(current)}</div>
            </Value>
          </CurrentValue>

          <NewValue>
            <Value>
              New Value
              <div>{onRenderMark(newValue, true)}</div>
            </Value>
          </NewValue>
        </div>

        <div>
          <YourValue>
            <YourValueTitle>
              Your Value
              <div>{onRenderMark(userValue)}</div>
            </YourValueTitle>

            <SliderGov
              from={from}
              to={to}
              value={userValue}
              currentWidth={currentWidth}
              marks={marks}
              step={step}
              onChange={handleChange}
              onRenderMark={onRenderMark}
            />

            <YourValueInfo>
              <div>{feeInfoLow}</div>

              <div>{feeInfoHign}</div>
            </YourValueInfo>
          </YourValue>
        </div>
      </FeeModuleBody>
    </FeeModuleStyle>
  )
}
