import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { escapeRegExp } from '../../utils'
import useDebouncedChangeHandler from '../../utils/useDebouncedChangeHandler'

const StyledInput = styled.input<{
  error?: boolean
  fontSize?: string
  align?: string
  inputStyle?: 'border' | 'border--lg'
}>`
  color: ${({ error, theme }) => (error ? theme.colors.primary1 : theme.colors.text1)};
  width: 0;
  position: relative;
  font-weight: bold;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: transparent;
  font-family: 'Abadi MT Std', sans-serif;
  font-size: ${({ fontSize }) => fontSize ?? '20px'};
  text-align: ${({ align }) => align && align};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 14px 8px;
  border-radius: 6px;
  margin-right: 6px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.text4};
  }

  ${({ inputStyle }) => {
    if (inputStyle === 'border') {
      return `
          padding: 5px;
          width: 60px;
          flex: 0 0 60px;

          font-family: 'Abadi MT Std', sans-serif;
          font-weight: normal;
          font-size: 20px;
          line-height: 20px;        
          text-align: center;
          color: #000000;

          background: #FFFFFF;
          border: 2px solid #000000;
          border-radius: 5px;

					&:hover,
					&:active,
					&:focus {
						border: 2px solid #F3BA2F;
					}
        `
    } else {
      return ``
    }
  }}

  ${({ theme }) => theme.mediaWidth.upToSmall`
		font-size: 18px;
		line-height: 18px;   
	`};

  ${({ theme, inputStyle }) => theme.mediaWidth.upToLarge`
    ${inputStyle === 'border' &&
      `
      // border-color: red
    `}
  `}
`

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

export const Input = React.memo(function InnerInput({
  value,
  onUserInput,
  placeholder,
  ...rest
}: {
  value: string | number
  onUserInput: (input: string) => void
  error?: boolean
  fontSize?: string
  align?: 'right' | 'left'
  inputStyle?: 'border' | 'border--lg'
} & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>) {
  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  const [, setNextValue] = useDebouncedChangeHandler<string | number>(
    value,
    v => {
      onUserInput(v as string)
    },
    300
  )

  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
      setLocalValue(nextUserInput)
      setNextValue(nextUserInput)
    }
  }

  return (
    <StyledInput
      {...rest}
      value={localValue}
      onChange={event => {
        // replace commas with periods, because uniswap exclusively uses period as the decimal separator
        enforcer(event.target.value.replace(/,/g, '.'))
      }}
      // universal input options
      inputMode="decimal"
      title="Token Amount"
      autoComplete="off"
      autoCorrect="off"
      // text-specific options
      type="text"
      pattern="^[0-9]*[.,]?[0-9]*$"
      placeholder={placeholder || '0.0'}
      minLength={1}
      maxLength={79}
      spellCheck="false"
    />
  )
})

export default Input

// const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group
