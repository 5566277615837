import React from 'react'
import styled from 'styled-components'
import { ContentWrapper, ContentWrapperMobileFullWidth } from '../components/ContentWrapper'
import { BlockTitleLg } from '../components/BlockTitle'
import { LiquidationTable } from '../components/LiquidationTable'
import { LiquidationStatistics } from '../components/LiquidationStatistics'
import { Checkbox } from '../components/Checkbox/Checkbox'
import { Input as LiquidationInput } from '../components/NumericalInput'

const LiquidationRow = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		display: block;
	`};
`

const LiquidationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 55px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
		flex-direction: column;
		align-items: start;
		margin-bottom: 27px;
	`};
`

const LiquidationFilter = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		margin-top: 6px;
		width: 100%;	
	`};
`

const LiquidationFilterOwner = styled.div`
  margin-right: 17px;
`

const LiquidationFilterSearchWraper = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
		width: 100%;	
	`};
`

const LiquidationFilterSearch = styled(LiquidationInput)`
  height: 48px;
  width: 252px;
  flex: 0 0 auto;
  margin: 0;
  padding-left: 13px;
  padding-right: 13px;

  font-weight: 250;
  font-size: 16px;
  line-height: 16px;
  text-align: left;

  border: 3px solid ${({ theme }) => theme.colors.black};
  border-radius: 10px;

  :hover,
  :focus {
    border-width: 3px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		width: 100%;	
		height: 46px;
		max-width: 258px;
		padding-left: 15px;
		padding-right: 15px;
	`};
`

const LiquidationBody = styled.div`
  flex-grow: 2;
`

const LiquidationSidebar = styled.div`
  padding: 25px 0 40px 24px;
  width: 408px;
  flex: 0 0 408px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		width: 100%;
		flex: 0 0 100%;
		max-width: 976px;
		margin: 0 auto;
		padding: 20px 0;
	`};

  ${({ theme }) => theme.mediaWidth.upToMedium`
		padding: 20px 12px;		
	`};
`

export default function Liquidation() {
  return (
    <LiquidationRow>
      <LiquidationBody>
        <ContentWrapper>
          <LiquidationHeader>
            <BlockTitleLg>Liquidation</BlockTitleLg>

            <LiquidationFilter>
              <LiquidationFilterOwner>
                <Checkbox text="Owner" />
              </LiquidationFilterOwner>

              <LiquidationFilterSearchWraper>
                <LiquidationFilterSearch
                  placeholder="Filter by Wallet"
                  value={''}
                  onUserInput={() => {}}
                  inputStyle="border"
                />
              </LiquidationFilterSearchWraper>
            </LiquidationFilter>
          </LiquidationHeader>

          <ContentWrapperMobileFullWidth>
            <LiquidationTable />
          </ContentWrapperMobileFullWidth>
        </ContentWrapper>
      </LiquidationBody>

      <LiquidationSidebar>
        <LiquidationStatistics />
      </LiquidationSidebar>
    </LiquidationRow>
  )
}
