import React, { useRef, useState } from 'react'
// #JUNK import { Settings, X } from 'react-feather'
import styled from 'styled-components'
import {
  useUserSlippageTolerance,
  useExpertModeManager
  // #JUNK useAudioModeManager
} from '../../state/user/hooks'
import TransactionSettings from '../TransactionSettings'
import { RowBetween } from '../Row'
// #JUNK import { TYPE } from '../Shared'
// import Toggle from '../Toggle'
// import { ThemeContext } from 'styled-components'
import { AutoColumn } from '../Column'
import { ButtonError } from '../Button'
import { useSettingsMenuOpen, useToggleSettingsMenu } from '../../state/application/hooks'
import { Text } from 'rebass'
import Modal from '../Modal'
import TranslatedText from '../TranslatedText'
import { ReactComponent as SettingsIcon } from '../../assets/images/settings.svg'
// #JUNK import { ReactComponent as CloseIcon } from '../../assets/images/close.svg'
import { BlockTitle } from '../BlockTitle'
import { BlockHeader } from '../BlockHeader'
import { IconClose } from '../Icons'

const StyledMenuIcon = styled(SettingsIcon)`
  height: 26px;
  width: 26px;

	fill: #999999;

	transition: all 0.4s ease;

  > * {
    // stroke: ${({ theme }) => theme.colors.black};
  }
	&:hover {
		fill: ${({ theme }) => theme.colors.primary1};
	}
`

const StyledCloseIcon = styled.div`
  position: absolute;
  top: -14px;
  right: -12px;

  height: 24px;
  width: 24px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
		top: -3px;
		right: -2px;
	`};
`

const StyledMenuButton = styled.button`
  position: relative;
  width: 26px;
  height: 100%;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
	height: 26px;
  // background-color: ${({ theme }) => theme.colors.bg3};

  padding: 0;
  // border-radius: 0.5rem;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
    // background-color: ${({ theme }) => theme.colors.bg4};
  }

  svg {
    // margin-top: 3px;
  }
`
const EmojiWrapper = styled.div`
  position: absolute;
  bottom: -6px;
  right: 0px;
  font-size: 16px;
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

// #JUNK
// const MenuFlyout = styled.span`
//   min-width: 20.125rem;
//   background-color: ${({ theme }) => theme.colors.bg1};
//   box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
//     0px 24px 32px rgba(0, 0, 0, 0.01);

//   border: 2px solid ${({ theme }) => theme.colors.bg3};

//   border-radius: 0.5rem;
//   display: flex;
//   flex-direction: column;
//   font-size: 1rem;
//   position: absolute;
//   top: 3rem;
//   right: 0rem;
//   z-index: 100;

//   ${({ theme }) => theme.mediaWidth.upToExtraSmall`
//     min-width: 18.125rem;
//     right: -46px;
//   `};
// `

const Break = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.bg3};
`

const ModalContentWrapper = styled.div`
	position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
	width: 100%;
  // background-color: ${({ theme }) => theme.colors.bg2};
  // border-radius: 20px;
`

export default function SettingsTab() {
  const node = useRef<HTMLDivElement>()

  // #JUNK const theme = useContext(ThemeContext)
  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()
  const [expertMode, toggleExpertMode] = useExpertModeManager()

  // #JUNK const [audioMode, toggleSetAudioMode] = useAudioModeManager()

  // show confirmation view before turning on
  const [showConfirmation, setShowConfirmation] = useState(false)

  const showSettings = useSettingsMenuOpen()
  const toggleSettings = useToggleSettingsMenu()

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu>
      <Modal isOpen={showConfirmation} onDismiss={() => setShowConfirmation(false)} maxHeight={100}>
        <ModalContentWrapper>
          <AutoColumn gap="lg">
            <RowBetween style={{ padding: '0 2rem' }}>
              <div />
              <Text fontWeight={700} fontSize={18}>
                Are you sure?
              </Text>
              <StyledCloseIcon onClick={() => setShowConfirmation(false)} />
            </RowBetween>
            <Break />
            <AutoColumn gap="lg" style={{ padding: '0 2rem' }}>
              <Text fontWeight={700} fontSize={18}>
                Expert mode turns off the confirm transaction prompt and allows high slippage trades that often result
                in bad rates and lost funds.
              </Text>
              <Text fontWeight={600} fontSize={18}>
                ONLY USE THIS MODE IF YOU KNOW WHAT YOU ARE DOING.
              </Text>
              <ButtonError
                error={true}
                padding={'12px'}
                onClick={() => {
                  if (window.prompt(`Please type the word "confirm" to enable expert mode.`) === 'confirm') {
                    toggleExpertMode()
                    setShowConfirmation(false)
                  }
                }}
              >
                <Text fontSize={18} fontWeight={700} id="confirm-expert-mode">
                  Turn On Expert Mode
                </Text>
              </ButtonError>
            </AutoColumn>
          </AutoColumn>
        </ModalContentWrapper>
      </Modal>
      <StyledMenuButton onClick={toggleSettings} id="open-settings-dialog-button">
        <StyledMenuIcon />
        {expertMode && (
          <EmojiWrapper>
            <span role="img" aria-label="wizard-icon">
              🧙
            </span>
          </EmojiWrapper>
        )}
      </StyledMenuButton>
      <Modal isOpen={showSettings} onDismiss={toggleSettings} maxHeight={100} maxWidth="496px">
        <ModalContentWrapper ref={node as any}>
          <AutoColumn gap="10px" style={{ width: '100%' }}>
            <StyledCloseIcon onClick={toggleSettings}>
              <IconClose />
            </StyledCloseIcon>

            <BlockHeader marginBottom="5px">
              <BlockTitle padding="0 50px 0 0">
                <TranslatedText translationId={86}>Transaction Settings</TranslatedText>
              </BlockTitle>
            </BlockHeader>

            <TransactionSettings rawSlippage={userSlippageTolerance} setRawSlippage={setUserslippageTolerance} />
            {/* <Text fontWeight={600} fontSize={16}>
							<TranslatedText translationId={94}>Interface Settings</TranslatedText>
						</Text>

						<RowBetween>
							<RowFixed>
								<TYPE.black fontWeight={600} fontSize={14} color={theme.colors.text2}>
									<TranslatedText translationId={96}>Toggle Audio Mode</TranslatedText>
								</TYPE.black>
							</RowFixed>
							<Toggle isActive={audioMode} toggle={toggleSetAudioMode} />
						</RowBetween> */}
          </AutoColumn>
        </ModalContentWrapper>
      </Modal>
    </StyledMenu>
  )
}
