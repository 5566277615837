import React, { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'
import { AppDispatch } from '../../state'
import { clearAllTransactions } from '../../state/transactions/actions'
import { shortenAddress } from '../../utils'

import { useActiveWeb3React } from '../../hooks'
import { useGetWalletName } from '../../hooks/useWalletDetector'

import { AutoRow } from '../Row'
import Copy from '../Copy/Copy'
import Transaction from './Transaction'
//  #JUNK import { ReactComponent as Close } from '../../assets/images/x.svg'
import { getEtherscanLink } from '../../utils'
import { injected, walletconnect, walletlink, fortmatic, portis, bsc } from '../../connectors'
import CoinbaseWalletIcon from '../../assets/images/coinbaseWalletIcon.svg'
import WalletConnectIcon from '../../assets/images/walletConnectIcon.svg'
import FortmaticIcon from '../../assets/images/fortmaticIcon.png'
import PortisIcon from '../../assets/images/portisIcon.png'
import Identicon from '../Identicon'
import { ButtonSecondary } from '../Button'
import { ExternalLink as LinkIcon } from 'react-feather'
import { ExternalLink, /* #JUNK LinkStyledButton,*/ TYPE } from '../Shared'
import { IconClose } from '../Icons'
import { TextBase, TextBaseXl } from '../Text'

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  margin-bottom: 10px;

  font-family: Retro;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  color: ${props => (props.color === 'blue' ? ({ theme }) => theme.colors.primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
		margin-bottom: 10px;
  `};
`

const CloseIcon = styled.div`
  position: absolute;
  top: -26px;
  right: -22px;

  display: flex;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		top: -4px;
		right: -4px;
  `};
`
const UpperSection = styled.div`
  position: relative;
  margin-bottom: 10px;

  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
  }

  h5:last-child {
    margin-bottom: 0px;
  }

  h4 {
    margin-top: 0;
    font-weight: 700;
  }
`

const InfoCard = styled.div`
  position: relative;
`

const AccountGroupingRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  margin-bottom: 13px;
  justify-content: space-between;
  align-items: center;

  font-weight: 600;
  color: ${({ theme }) => theme.colors.text1};

  div {
    ${({ theme }) => theme.flexRowNoWrap}
    align-items: center;
  }
  :nth-child(2) {
    margin-bottom: 18px;
  }
  :last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		margin-bottom: 17px;
	`};
`

const AccountSection = styled.div`
  padding: 9px 9px 14px 15px;

  background-color: ${({ theme }) => theme.colors.bg1};
  border-radius: 10px;
`

const YourAccount = styled.div`
  h5 {
    margin: 0 0 1rem 0;
    font-weight: 600;
  }

  h4 {
    margin: 0;
    font-weight: 700;
  }
`

const LowerSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  flex-grow: 1;

  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;

  h5 {
    margin: 0;

    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: ${({ theme }) => theme.colors.text3};
  }
`

const AccountControl = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 0;
  width: 100%;

  font-weight: bold;
  font-size: 22px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.text1};

  a:hover {
    text-decoration: underline;
  }

  p {
    min-width: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		font-size: 20px;
		line-height: 100%;
	`};
`

const AddressLink = styled(ExternalLink)<{ hasENS: boolean; isENS: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 22px;

  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.text2};

  transition: all 0.4s ease;
  :hover {
    color: ${({ theme }) => theme.colors.text3};
    text-decoration: none !important;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		font-size: 14px;
		line-height: 100%;
	`};
`
// const WalletName = styled.div`
//   width: initial;
// 	font-family: 'Abadi MT Std';
// 	font-style: normal;
// 	font-weight: 500;
// 	font-size: 16px;
// 	line-height: 100%;
//   color: ${({ theme }) => theme.colors.text3};
// `

const WalletAction = styled(ButtonSecondary)`
  width: fit-content;
  margin-left: 8px;
  padding: 5px 15px;
  height: 26px;

  font-family: 'Abadi MT Std';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.white};

  border: none;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.primary1};
  transition: all 0.4s ease;

  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.primary4};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		height: 23px;
		padding: 5px 12px;

		font-size: 14px;
		line-height: 100%;
	`};
`

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  flex: 0 0 28px;
  width: 28px;
  height: 28px;

  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
  & > div {
    width: 28px;
    height: 28px;
    justify-content: center;
  }
`

const TransactionListWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  padding-right: 15px;
  max-height: 400px;
  overflow: auto;
`

const MainWalletAction = styled(WalletAction)`
  color: ${({ theme }) => theme.colors.primary1};
`

const LinkIconWraper = styled.div`
  & > svg {
    width: 23px;
    height: 23px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		& > svg {
			width: 16px;
			height: 16px;
		}
	`};
`

function renderTransactions(transactions: string[]) {
  return (
    <TransactionListWrapper>
      {transactions.map((hash, i) => {
        return <Transaction key={i} hash={hash} />
      })}
    </TransactionListWrapper>
  )
}

interface AccountDetailsProps {
  toggleWalletModal: () => void
  pendingTransactions: string[]
  confirmedTransactions: string[]
  ENSName?: string
  openOptions: () => void
}

export default function AccountDetails({
  toggleWalletModal,
  pendingTransactions,
  confirmedTransactions,
  ENSName,
  openOptions
}: AccountDetailsProps) {
  const { chainId, account, connector } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch<AppDispatch>()
  const walletName = useGetWalletName()

  function formatConnectorName() {
    return <TextBase color={theme.colors.text3}>Connected with {walletName}</TextBase>
  }

  function getStatusIcon() {
    if (connector === injected) {
      return (
        <IconWrapper size={28}>
          <Identicon />
        </IconWrapper>
      )
    } else if (connector === walletconnect) {
      return (
        <IconWrapper size={28}>
          <img src={WalletConnectIcon} alt={'wallet connect logo'} />
        </IconWrapper>
      )
    } else if (connector === walletlink) {
      return (
        <IconWrapper size={28}>
          <img src={CoinbaseWalletIcon} alt={'coinbase wallet logo'} />
        </IconWrapper>
      )
    } else if (connector === fortmatic) {
      return (
        <IconWrapper size={28}>
          <img src={FortmaticIcon} alt={'fortmatic logo'} />
        </IconWrapper>
      )
    } else if (connector === portis) {
      return (
        <>
          <IconWrapper size={28}>
            <img src={PortisIcon} alt={'portis logo'} />
            <MainWalletAction
              onClick={() => {
                portis.portis.showPortis()
              }}
            >
              Show Portis
            </MainWalletAction>
          </IconWrapper>
        </>
      )
    }
    return null
  }

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }))
  }, [dispatch, chainId])

  return (
    <>
      <UpperSection>
        <CloseIcon onClick={toggleWalletModal}>
          <IconClose />
        </CloseIcon>
        <HeaderRow>Account</HeaderRow>
        <AccountSection>
          <YourAccount>
            <InfoCard>
              <AccountGroupingRow>
                {formatConnectorName()}
                <div>
                  {connector !== injected && connector !== walletlink && connector !== bsc && (
                    <WalletAction
                      onClick={() => {
                        ;(connector as any).close()
                      }}
                    >
                      Disconnect
                    </WalletAction>
                  )}
                  <WalletAction
                    onClick={() => {
                      openOptions()
                    }}
                  >
                    Change
                  </WalletAction>
                </div>
              </AccountGroupingRow>
              <AccountGroupingRow id="web3-account-identifier-row">
                <AccountControl>
                  {ENSName ? (
                    <>
                      <div>
                        {getStatusIcon()}
                        <p> {ENSName}</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        {getStatusIcon()}
                        <p> {account && shortenAddress(account)}</p>
                      </div>
                    </>
                  )}
                </AccountControl>
              </AccountGroupingRow>
              <AccountGroupingRow>
                {ENSName ? (
                  <>
                    <AccountControl>
                      <div>
                        {account && (
                          <Copy toCopy={account}>
                            <span style={{ marginLeft: '10px' }}>Copy Address</span>
                          </Copy>
                        )}
                        {chainId && account && (
                          <AddressLink
                            hasENS={!!ENSName}
                            isENS={true}
                            href={chainId && getEtherscanLink(chainId, ENSName, 'address')}
                          >
                            <LinkIcon size={19} />
                            <span style={{ marginLeft: '11px' }}>View on explorer</span>
                          </AddressLink>
                        )}
                      </div>
                    </AccountControl>
                  </>
                ) : (
                  <>
                    <AccountControl>
                      <div>
                        {account && (
                          <Copy toCopy={account}>
                            <span style={{ marginLeft: '10px' }}>Copy Address</span>
                          </Copy>
                        )}
                        {chainId && account && (
                          <AddressLink
                            hasENS={!!ENSName}
                            isENS={false}
                            href={getEtherscanLink(chainId, account, 'address')}
                          >
                            <LinkIconWraper>
                              <LinkIcon size={19} />
                            </LinkIconWraper>
                            <span style={{ marginLeft: '11px' }}>View on explorer</span>
                          </AddressLink>
                        )}
                      </div>
                    </AccountControl>
                  </>
                )}
              </AccountGroupingRow>
            </InfoCard>
          </YourAccount>
        </AccountSection>
      </UpperSection>

      <AccountSection>
        {!!pendingTransactions.length || !!confirmedTransactions.length ? (
          <LowerSection>
            <AutoRow mb={'20px'} style={{ justifyContent: 'space-between' }}>
              <TYPE.body
                color={theme.colors.text1}
                fontWeight={'bold'}
                fontSize={20}
                style={{
                  lineHeight: '26px'
                }}
              >
                Recent Transactions
              </TYPE.body>
              <WalletAction onClick={clearAllTransactionsCallback}>Clear all</WalletAction>
            </AutoRow>
            {renderTransactions(pendingTransactions)}
            {renderTransactions(confirmedTransactions)}
          </LowerSection>
        ) : (
          <LowerSection>
            <TextBaseXl fontWeight="bold">Your transactions will appear here...</TextBaseXl>
          </LowerSection>
        )}
      </AccountSection>
    </>
  )
}
