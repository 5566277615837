import { useCallback } from 'react'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { UnsupportedChainIdError, useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { ChainId } from '@wowswap-io/wowswap-sdk'
import useSwitchChainOnMetamask from './useChangeChain'

const useAuth = () => {
  const { activate } = useWeb3ReactCore()
  const { changeNetwork } = useSwitchChainOnMetamask()

  const login = useCallback(
    async (connector: AbstractConnector, neededChainId: ChainId): Promise<void> => {
      if (connector) {
        return activate(connector, undefined, true).catch(error => {
          if (error instanceof UnsupportedChainIdError) {
            changeNetwork(neededChainId)
          }
        })
      } else {
        console.error('[LOGIN] Can not activate chain, because not connector.')
      }
    },
    [activate, changeNetwork]
  )

  return { login }
}

export default useAuth
