import React from 'react'
import styled from 'styled-components'

import { ListStat } from '../ListStat'

const InformationStyle = styled.div``
const InformationTitle = styled.div`
  margin-bottom: 20px;

  font-family: 'Retro';
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.text1};
`

interface InformationProps {
  title?: string
}

export function Information({ title }: InformationProps) {
  return (
    <InformationStyle>
      <InformationTitle>{title}</InformationTitle>
      <ListStat
        items={[
          {
            itemName: 'Author',
            value: '0x51…135 (Core)'
          },
          {
            itemName: 'Start date',
            value: 'Mar 22, 2021, 4:00 PM'
          },
          {
            itemName: 'End Date',
            value: 'Mar 29, 2021, 4:00 PM'
          },
          {
            itemName: 'Snapshot',
            value: '1,122,201'
          }
        ]}
      />
    </InformationStyle>
  )
}
