import { createAction } from '@reduxjs/toolkit'

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT'
}

export const selectCurrency = createAction<{ field: Field; currencyId: string; currencyName: string }>(
  'swap/selectCurrency'
)
export const switchCurrencies = createAction<void>('swap/switchCurrencies')
export const typeInput = createAction<{ field: Field; typedValue: string }>('swap/typeInput')
export const replaceSwapState = createAction<{
  field: Field
  typedValue: string
  inputCurrencyId?: string
  outputCurrencyId?: string
  recipient: string | null
}>('swap/replaceSwapState')
export const setRecipient = createAction<{ recipient: string | null }>('swap/setRecipient')

export const setDebtInfo = createAction<{ debtPayable: number; protocolFee: number; profit: number }>(
  'swap/setDebtInfo'
)

export const setOpenPositionInfo = createAction<{
  borrowAmount: number
  borrowRate: number
  liquidationCost: number
  maxBorrow: number
}>('swap/setOpenPositionInfo')
export const setLeverage = createAction<{ leverage: number }>('swap/setLeverage')
export const setLeverageAllow = createAction<{ leverageAllow: number }>('swap/setLeverageAllow')
export const setShowError = createAction<{ showError: boolean }>('swap/setShowError')
