import React from 'react'
import styled from 'styled-components'
import Modal from '../Modal'
import { CloseIcon, Spinner } from '../Shared'
import { Text } from 'rebass'
import { RowBetween } from '../Row'
import { AutoColumn, ColumnCenter } from '../Column'
import Circle from '../../assets/images/blue-loader.svg'

const Wrapper = styled.div`
  width: 100%;
  // overflow-y: auto;
`
const Section = styled(AutoColumn)`
  // padding: 24px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 40px 0;
`

const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

const WraperClose = styled.div`
  position: absolute;
  top: 23px;
  right: 23px;
`

interface ApprovalPendingModalProps {
  isOpen: boolean
  onDismiss: () => void
  pendingText: string
}

export default function ApprovalPendingModal({ isOpen, onDismiss, pendingText }: ApprovalPendingModalProps) {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      <Wrapper>
        <Section>
          <RowBetween>
            <div />
            <WraperClose>
              <CloseIcon onClick={onDismiss} />
            </WraperClose>
          </RowBetween>
          <ConfirmedIcon>
            <CustomLightSpinner src={Circle} alt="loader" size={'90px'} />
          </ConfirmedIcon>
          <AutoColumn gap="12px" justify={'center'}>
            <Text fontWeight={700} fontSize={18}>
              Waiting For Approval
            </Text>
            <AutoColumn gap="12px" justify={'center'}>
              <Text fontWeight={600} fontSize={14} color="" textAlign="center">
                {pendingText}
              </Text>
            </AutoColumn>
            <Text fontSize={12} color="#565A69" textAlign="center">
              Approve this transaction in your wallet
            </Text>
          </AutoColumn>
        </Section>
      </Wrapper>
    </Modal>
  )
}
