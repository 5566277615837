import React, { useContext, useMemo } from 'react'
import { Fraction, JSBI, TokenAmount, Trade } from '@wowswap-io/wowswap-sdk'
import { ThemeContext } from 'styled-components'
import { Field } from '../../../state/swap/actions'
import { Position } from '../../../state/trade/types'

import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../../utils/prices'

import { RowBetween, RowFixed } from '../../Row'
import { TextBase } from '../../Text'
import { basisPointsToPercent } from '../../../utils'
import FormattedPriceImpact from '../FormattedPriceImpact'

interface IShortTradeDetails {
  trade: Trade
  allowedSlippage: number
  isOpenPosition: boolean
  position?: Position
}

export const ShortTradeDetails: React.FC<IShortTradeDetails> = props => {
  const { trade, allowedSlippage, position, isOpenPosition } = props

  const theme = useContext(ThemeContext)
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)
  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const detailsData = useMemo(() => {
    if (!position) {
      return []
    }

    if (isOpenPosition) {
      return [
        {
          title: 'Minimum shorted amount',
          value: `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'} ${
            trade.outputAmount.currency.symbol
          }`
        },
        {
          title: 'Price impact',
          value: <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        },
        {
          title: 'Expected shorted amount',
          value: `${position.trade?.outputAmount.toSignificant(6) ?? '-'} ${trade.outputAmount.currency.symbol ?? '-'}`
        },
        {
          title: 'HIR (Hourly Interest Rate)',
          value: `${position.HIR} %`
        }
      ]
    } else if (position.profit) {
      const slippageTolerance = basisPointsToPercent(allowedSlippage)

      const slippageAdjustedAmountOut = new Fraction(JSBI.BigInt(1))
        .add(slippageTolerance)
        .invert()
        .multiply(position.profit.raw).quotient

      const minimumOutput = new TokenAmount(position.profit.token, slippageAdjustedAmountOut)

      return [
        {
          title: 'Expected output',
          value: `${position.profit?.toSignificant(6) ?? '-'} ${trade.outputAmount.currency.symbol}`
        },
        {
          title: 'Price impact',
          value: <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        },
        {
          title: 'Minimum output',
          value: `${minimumOutput.toSignificant(6)} ${trade.outputAmount.currency.symbol}`
        }
      ]
    } else {
      return []
    }
  }, [position, allowedSlippage, slippageAdjustedAmounts, trade, isOpenPosition, priceImpactWithoutFee])

  return (
    <>
      {detailsData.map((detailsItem, key) => {
        return (
          <RowBetween key={key}>
            <RowFixed>
              <TextBase color={theme.colors.text4}>{detailsItem.title}</TextBase>
            </RowFixed>
            <RowFixed>
              <TextBase>{detailsItem.value}</TextBase>
            </RowFixed>
          </RowBetween>
        )
      })}
    </>
  )
}
