import { ChainId } from '@wowswap-io/wowswap-sdk'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useTradeState } from '../../state/trade/hooks'
import Modal from '../Modal'
import { ExternalLink, CloseIcon, Spinner } from '../Shared'
import { Text } from 'rebass'
import { RowBetween, RowFixed } from '../Row'
import { AlertTriangle, /* #JUNK ArrowUpCircle,*/ CheckCircle } from 'react-feather'
import { ButtonPrimary, ButtonLight } from '../Button'
import { AutoColumn, ColumnCenter } from '../Column'
import Circle from '../../assets/images/blue-loader.svg'
import MetaMaskLogo from '../../assets/images/metamask.png'
import { getEtherscanLink } from '../../utils'
import { useActiveWeb3React } from '../../hooks'
import useAddTokenToMetamask from '../../hooks/useAddTokenToMetamask'
import { BlockHeader } from '../BlockHeader'
import { BlockTitle } from '../BlockTitle'
import { IconApprovingArrow } from '../Icons'

import { TradeToken } from '../../hooks/Tokens.types'

const Wrapper = styled.div`
  width: 100%;
  // overflow-y: auto;
`
const Section = styled(AutoColumn)`
  // padding: 24px;
`

const BottomSection = styled(Section)`
  background-color: ${({ theme }) => theme.colors.bg2};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`
const StyledLogo = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 6px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 40px 0;
`

const CustomLightSpinner = styled(Spinner)<{ size: string }>`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`

const ConfirmationModalCloseWraper = styled.div`
  position: absolute;
  top: 22px;
  right: 22px;
`

const WraperClose = styled.div`
  position: absolute;
  top: 23px;
  right: 23px;
`

function ConfirmationPendingContent({ onDismiss, pendingText }: { onDismiss: () => void; pendingText: string }) {
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          <WraperClose>
            <CloseIcon onClick={onDismiss} />
          </WraperClose>
        </RowBetween>
        <ConfirmedIcon>
          <CustomLightSpinner src={Circle} alt="loader" size={'90px'} />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={'center'}>
          <Text fontWeight={700} fontSize={18}>
            Waiting For Confirmation
          </Text>
          <AutoColumn gap="12px" justify={'center'}>
            <Text fontWeight={600} fontSize={14} color="" textAlign="center">
              {pendingText}
            </Text>
          </AutoColumn>
          <Text fontSize={12} color="#565A69" textAlign="center">
            Confirm this transaction in your wallet
          </Text>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: TradeToken | undefined
}) {
  const theme = useContext(ThemeContext)
  const { library } = useActiveWeb3React()
  const tradeState = useTradeState()
  const { addToken, success } = useAddTokenToMetamask(currencyToAdd, tradeState.isShortTrade ? 'short' : 'long')
  const state = useTradeState()

  return (
    <Wrapper>
      <WraperClose>
        <CloseIcon onClick={onDismiss} />
      </WraperClose>

      <Section>
        <BlockHeader marginBottom="0">
          <BlockTitle>
            Transaction <br />
            Submitted
          </BlockTitle>
        </BlockHeader>

        <ConfirmedIcon>
          {/* #JUNK <ArrowUpCircle strokeWidth={0.5} size={90} color={theme.colors.primary1} /> */}
          <IconApprovingArrow />
        </ConfirmedIcon>

        <AutoColumn justify={'center'}>
          {chainId && hash && (
            <ExternalLink href={getEtherscanLink(chainId, hash, 'transaction')}>
              <Text
                fontWeight={500}
                fontSize={20}
                color={theme.colors.primary1}
                style={{
                  textDecoration: 'underline',
                  lineHeight: '20px',
                  marginBottom: '20px'
                }}
              >
                View on explorer
              </Text>
            </ExternalLink>
          )}
          {currencyToAdd && library?.provider?.isMetaMask && (
            <ButtonLight onClick={addToken}>
              {!success ? (
                <RowFixed>
                  Add {state.isShortTrade ? '-' : '*'}
                  {currencyToAdd.symbol} to Metamask <StyledLogo src={MetaMaskLogo} />
                </RowFixed>
              ) : (
                <RowFixed>
                  Added {state.isShortTrade ? '-' : '*'}
                  {currencyToAdd.symbol}{' '}
                  <CheckCircle size={'16px'} stroke={theme.colors.primary1} style={{ marginLeft: '6px' }} />
                </RowFixed>
              )}
            </ButtonLight>
          )}
          <ButtonPrimary onClick={onDismiss} style={{ margin: '7px 0 0 0' }}>
            <Text fontWeight={'normal'} fontSize={16}>
              Close
            </Text>
          </ButtonPrimary>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent
}: {
  title: string
  onDismiss: () => void
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper>
      <div>
        <ConfirmationModalCloseWraper>
          <CloseIcon onClick={onDismiss} />
        </ConfirmationModalCloseWraper>

        <BlockHeader marginBottom="10px">
          <BlockTitle>{title}</BlockTitle>
        </BlockHeader>

        {topContent()}
      </div>

      <div>{bottomContent()}</div>
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  const theme = useContext(ThemeContext)
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <WraperClose>
            <CloseIcon onClick={onDismiss} />
          </WraperClose>
          <Text fontWeight={700} fontSize={18}>
            Error
          </Text>
        </RowBetween>
        <AutoColumn style={{ marginTop: 20, padding: '2rem 0' }} gap="24px" justify="center">
          <AlertTriangle color={theme.colors.primary4} style={{ strokeWidth: 1.5 }} size={64} />
          <Text
            fontWeight={700}
            fontSize={14}
            color={theme.colors.primary4}
            style={{ textAlign: 'center', width: '85%' }}
          >
            {message}
          </Text>
        </AutoColumn>
      </Section>
      <BottomSection gap="12px">
        <ButtonPrimary onClick={onDismiss}>Dismiss</ButtonPrimary>
      </BottomSection>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  onDismiss: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  currencyToAdd?: TradeToken | undefined
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  currencyToAdd
}: ConfirmationModalProps) {
  const { chainId } = useActiveWeb3React()

  if (!chainId) return null

  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      {attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
        content()
      )}
    </Modal>
  )
}
