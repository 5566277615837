import SentryMonitoringService from 'services/monitoring/sentry/sentry.monitoring.service'

const AppMonitoringService = {
  init: () => {
    SentryMonitoringService.init()
  },

  setTag: (name: string, value: string) => {
    SentryMonitoringService.setTag(name, value)
  }
}

export default AppMonitoringService
