import { BigNumber } from '@ethersproject/bignumber'
import React from 'react'
import { TokenAmount } from '@wowswap-io/wowswap-sdk'
import { useAllRewardTokens } from '../../hooks/tokens/tokenLists'

import { TradeToken } from '../../hooks/Tokens.types'
import { DepositStatus } from '../../state/stake/actions'
import { useCalcClaim, useClaimAll } from '../../state/stake/hooks'
import { ButtonGreen } from '../Button'
import CurrencyLogo from '../CurrencyLogo'
import {
  YourWalletGovernanceItemIcons,
  YourWalletGovernanceItemLeft,
  YourWalletGovernanceItemStyle,
  YourWalletGovernanceItemValue,
  YourWalletGovernanceStyle,
  YourWalletGovernanceTitle
} from './styleds'

interface YourWalletGovernanceItemProps {
  token: TradeToken
  amount: BigNumber
}

function YourWalletGovernanceItem({ token, amount }: YourWalletGovernanceItemProps) {
  const tokenAmount = new TokenAmount(token, amount.toString())
  return (
    <YourWalletGovernanceItemStyle>
      <YourWalletGovernanceItemLeft>
        <YourWalletGovernanceItemIcons>
          <CurrencyLogo currency={token} />
          <YourWalletGovernanceItemValue>
            {tokenAmount.toSignificant(6)} {token.symbol}
          </YourWalletGovernanceItemValue>
        </YourWalletGovernanceItemIcons>
      </YourWalletGovernanceItemLeft>
    </YourWalletGovernanceItemStyle>
  )
}

export function YourWalletGovernance() {
  const mapTradeTokens = useAllRewardTokens()
  const listTradeTokens = Object.values(mapTradeTokens)

  const claimInfo = useCalcClaim()
  const { status, callback } = useClaimAll()

  const hasToClaim = claimInfo && Object.entries(claimInfo).some(([token, amount]) => amount.gt('0'))
  const handleClaim = () => {
    if (callback && status === DepositStatus.VALID) {
      callback()
    }
  }

  return (
    <YourWalletGovernanceStyle>
      <YourWalletGovernanceTitle>Governance rewards</YourWalletGovernanceTitle>

      {listTradeTokens.map(token => {
        if (!claimInfo || !claimInfo[token.address]) {
          return null
        }

        return <YourWalletGovernanceItem key={token.address} token={token} amount={claimInfo[token.address]} />
      })}

      <ButtonGreen disabled={!hasToClaim} onClick={handleClaim}>
        Claim all
      </ButtonGreen>
    </YourWalletGovernanceStyle>
  )
}
