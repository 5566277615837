import { parseUnits } from '@ethersproject/units'
import { BigNumber } from '@ethersproject/bignumber'
import { ParsedQs } from 'qs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChainId, Currency, CurrencyAmount, JSBI, Token, TokenAmount, Trade } from '@wowswap-io/wowswap-sdk'
import { BASE_TOKEN_SYMBOL, MIN_AMOUNT_FOR_SWAP } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { useCurrency } from '../../hooks/tokens/Tokens'
import { useTradeExactIn } from '../../hooks/Trades'
import useENS from '../../hooks/useENS'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import useToggledVersion, { Version } from '../../hooks/useToggledVersion'
import { isAddress } from '../../utils'
import { computeSlippageAdjustedAmounts } from '../../utils/prices'
import tokenJson from '../../wowswap.json'
import { AppDispatch, AppState } from '../index'
import { WrappedTokenInfo } from '../lists/hooks'
import { useUserSlippageTolerance } from '../user/hooks'
import { getDefaultToken, useCurrencyBalances } from '../wallet/hooks'
import { useCalculateBalance } from '../../hooks/useCalculateBalance'
import { useCalculateOpenPosition } from '../../hooks/useCalculateOpenPosition'
import {
  Field,
  replaceSwapState,
  selectCurrency,
  setRecipient,
  switchCurrencies,
  typeInput,
  setLeverage,
  setDebtInfo,
  setOpenPositionInfo
} from './actions'
import { SwapState } from './reducer'
import { useReserveContractForToken } from '../../hooks/useContract'
import { Contract } from '@ethersproject/contracts'
import { useSingleCallResult } from '../multicall/hooks'
import { bn } from '../../utils/math'
import { calcHIR } from '../../utils/calculations'
import useDebounce from '../../hooks/useDebounce'

export interface LeverageTrade {
  isOpenPosition: boolean
  tradeble?: string
  lendable?: string
  shortable?: string

  leverageFactor: number
  typedAmount?: CurrencyAmount
  typedValue: string
  amountOut: string

  borrow?: string
  availableLiquidity?: string
  HIR?: string

  debtPayable: number
  profit: number
  protocolFee: number
  borrowAmount: number
  borrowRate: number
  liquidationCost: number
  maxBorrow: number
}

export function useSwapState(): AppState['swap'] {
  return useSelector<AppState, AppState['swap']>(state => state.swap)
}

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency?: WrappedTokenInfo) => void
  onSwitchTokens: () => void
  onUserInput: (field: Field, typedValue: string) => void
  onChangeRecipient: (recipient: string | null) => void
  onChangeLeverage: (leverage: number) => void
} {
  const dispatch = useDispatch<AppDispatch>()
  const onCurrencySelection = useCallback(
    (field: Field, currency?: WrappedTokenInfo) => {
      dispatch(
        selectCurrency({
          field,
          currencyId:
            currency instanceof Token
              ? currency.address
              : currency === Currency.getBaseCurrency()
              ? Currency.getBaseCurrency().symbol!
              : '',
          currencyName: currency?.tokenInfo?.proxyName || ''
        })
      )
    },
    [dispatch]
  )

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies())
  }, [dispatch])

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }))
    },
    [dispatch]
  )

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }))
    },
    [dispatch]
  )

  const onChangeLeverage = useCallback(
    (leverage: number) => {
      dispatch(setLeverage({ leverage }))
    },
    [dispatch]
  )

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
    onChangeLeverage
  }
}

export function useSwapDebtHandlers() {
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (payload: { debtPayable: number; protocolFee: number; profit: number }) => {
      dispatch(setDebtInfo(payload))
    },
    [dispatch]
  )
}

export function useSwapOpenPositionHandler() {
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (payload: { borrowAmount: number; borrowRate: number; liquidationCost: number; maxBorrow: number }) => {
      dispatch(setOpenPositionInfo(payload))
    },
    [dispatch]
  )
}

// try to parse a user entered amount for a given token
export function tryParseAmount(value?: string, currency?: Currency): CurrencyAmount | undefined {
  if (!value || !currency) {
    return undefined
  }
  try {
    const parts = value.split('.')
    value = [parts[0], (parts[1] || '0').substr(0, currency.decimals)].join('.')
    const typedValueParsed = parseUnits(value, currency.decimals).toString()
    if (typedValueParsed !== '0') {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(JSBI.BigInt(typedValueParsed))
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

const BAD_RECIPIENT_ADDRESSES: string[] = [
  '0xBCfCcbde45cE874adCB698cC183deBcF17952812', // v2 factory
  '0xf164fC0Ec4E93095b804a4795bBe1e041497b92a', // v2 router 01
  '0x05fF2B0DB69458A0750badebc4f9e13aDd608C7F' // v2 router 02
]

/**
 * Returns true if any of the pairs or tokens in a trade have the given checksummed address
 * @param trade to check for the given address
 * @param checksummedAddress address to check in the pairs and tokens
 */
function involvesAddress(trade: Trade, checksummedAddress: string): boolean {
  return (
    trade.route.path.some(token => token.address === checksummedAddress) ||
    trade.route.pairs.some(pair => pair.liquidityToken.address === checksummedAddress)
  )
}

export type TokenTrade = 'tradable' | 'lendable' | undefined
// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo(): {
  currencies: { [field in Field]?: Currency }
  currencyBalances: { [field in Field]?: CurrencyAmount }
  parsedAmount: CurrencyAmount | undefined
  v2Trade: Trade | undefined
  v1Trade: Trade | undefined
  inputError?: string
  lendableToken: Currency | undefined
  minAmountInForTrade?: number
  leverageTrade: LeverageTrade
} {
  const { account, chainId } = useActiveWeb3React()

  const toggledVersion = useToggledVersion()

  const {
    independentField,
    leverage,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId, currencyName: inputCurrencyName },
    [Field.OUTPUT]: { currencyId: outputCurrencyId, currencyName: outputCurrencyName },
    recipient,
    debtPayable,
    profit,
    protocolFee,
    borrowAmount,
    borrowRate,
    liquidationCost,
    maxBorrow
  } = useSwapState()

  const inputCurrency = useCurrency(inputCurrencyId, inputCurrencyName)
  const outputCurrency = useCurrency(outputCurrencyId, outputCurrencyName)

  const lendableToken = (isLendaleToken(inputCurrency) ? inputCurrency : outputCurrency) || Currency.getBaseCurrency()

  const recipientLookup = useENS(recipient ?? undefined)
  const to: string | null = (recipient === null ? account : recipientLookup.address) ?? null

  const relevantTokenBalances = useCurrencyBalances(
    account ?? undefined,
    [inputCurrency ?? undefined, outputCurrency ?? undefined],
    lendableToken
  )
  const currencyBalances = {
    [Field.INPUT]: relevantTokenBalances[0],
    [Field.OUTPUT]: relevantTokenBalances[1]
  }

  const currencies: { [field in Field]?: Currency } = {
    [Field.INPUT]: inputCurrency ?? undefined,
    [Field.OUTPUT]: outputCurrency ?? undefined
  }

  const isOpenPosition =
    isLendaleToken(currencies[Field.INPUT]) === undefined ? true : isLendaleToken(currencies[Field.INPUT])!
  const tradeble = ((isOpenPosition ? currencies[Field.OUTPUT] : currencies[Field.INPUT]) as WrappedTokenInfo)?.address
  const lendable = ((isOpenPosition ? currencies[Field.INPUT] : currencies[Field.OUTPUT]) as WrappedTokenInfo)?.address

  const leverageFactor = fromLeverage(leverage)
  const targetValue = isOpenPosition ? (Number(typedValue) * leverageFactor).toString() : typedValue
  const isExactIn: boolean = independentField === Field.INPUT

  const typedAmount = tryParseAmount(typedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined)
  const parsedAmount = tryParseAmount(targetValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined)

  const typedTrade = useTradeExactIn(isExactIn ? typedAmount : undefined, outputCurrency ?? undefined)
  const bestTradeExactIn = useTradeExactIn(isExactIn ? parsedAmount : undefined, outputCurrency ?? undefined)
  // const bestTradeExactOut = useTradeExactOut(inputCurrency ?? undefined, !isExactIn ? parsedAmount : undefined)

  const v2Trade = bestTradeExactIn
  // const v2Trade = isExactIn ? bestTradeExactIn : bestTradeExactOut

  // get link to trade on v1, if a better rate exists
  const v1Trade = undefined //useV1Trade(isExactIn, currencies[Field.INPUT], currencies[Field.OUTPUT], parsedAmount)

  let inputError: string | undefined
  if (!account) {
    inputError = 'Connect Wallet'
  }

  if (!parsedAmount) {
    inputError = inputError ?? 'Enter an amount'
  }

  if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
    inputError = inputError ?? 'Select a token'
  }

  const formattedTo = isAddress(to)
  if (!to || !formattedTo) {
    inputError = inputError ?? 'Enter a recipient'
  } else {
    if (
      BAD_RECIPIENT_ADDRESSES.indexOf(formattedTo) !== -1 ||
      (bestTradeExactIn && involvesAddress(bestTradeExactIn, formattedTo))
      // (bestTradeExactOut && involvesAddress(bestTradeExactOut, formattedTo))
    ) {
      inputError = inputError ?? 'Invalid recipient'
    }
  }

  const [allowedSlippage] = useUserSlippageTolerance()

  const slippageAdjustedAmounts =
    typedTrade && allowedSlippage && computeSlippageAdjustedAmounts(typedTrade, allowedSlippage)

  const slippageAdjustedAmountsV1 =
    v1Trade && allowedSlippage && computeSlippageAdjustedAmounts(v1Trade, allowedSlippage)

  // compare input balance to max input based on version
  const [balanceIn, amountIn] = [
    currencyBalances[Field.INPUT],
    toggledVersion === Version.v1
      ? slippageAdjustedAmountsV1
        ? slippageAdjustedAmountsV1[Field.INPUT]
        : null
      : slippageAdjustedAmounts
      ? slippageAdjustedAmounts[Field.INPUT]
      : null
  ]

  if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
    inputError = 'Insufficient ' + amountIn.currency.symbol + ' balance'
  }

  const minAmountInForTrade =
    isOpenPosition && getMinimumAmount(currencies[Field.INPUT], typedValue)
      ? getMinimumAmount(currencies[Field.INPUT], typedValue)
      : undefined

  const inputAddress = getCurrencyAddress(currencies[Field.INPUT], chainId)
  const outputAddress = getCurrencyAddress(currencies[Field.OUTPUT], chainId)
  const debtAmount = parsedAmount ? parsedAmount.raw.toString() : ''
  const openPositionAmount = typedAmount ? typedAmount.raw.toString() : ''

  const { callback: debtCallback } = useCalculateBalance({
    isOpenPosition,
    shorting: false,
    amount: debtAmount,
    inputAddress,
    outputAddress
  })
  useEffect(() => {
    if (!debtCallback) {
      return
    }

    debtCallback()
  }, [debtCallback, inputAddress, outputAddress, isOpenPosition, debtAmount])

  const debouncedLeverageFactor = useDebounce(leverageFactor, 300)
  const { callback: openPositionCallback } = useCalculateOpenPosition({
    isOpenPosition,
    shorting: false,
    leverageFactor: debouncedLeverageFactor,
    amount: openPositionAmount,
    inputAddress,
    outputAddress
  })
  useEffect(() => {
    if (!openPositionCallback) {
      return
    }

    openPositionCallback()
  }, [openPositionCallback, inputAddress, outputAddress, isOpenPosition, openPositionAmount, debouncedLeverageFactor])

  const reserveContract = useReserveContractForToken(lendable || getDefaultToken().address)
  const { availableLiquidity } = useReserveData(reserveContract)

  const HIR = borrowRate ? calcHIR(borrowRate, bn(60 * 60)) : undefined
  const amountOut: string = !isOpenPosition ? profit.toPrecision(6) : v2Trade?.outputAmount.toSignificant(6)!

  const borrow = bn(borrowAmount).toPrecision(6)

  return {
    currencies,
    currencyBalances,
    parsedAmount,
    v2Trade: v2Trade ?? undefined,
    inputError,
    v1Trade,
    lendableToken,
    minAmountInForTrade,
    leverageTrade: {
      leverageFactor,
      isOpenPosition,
      tradeble,
      lendable,
      shortable: lendable,
      typedValue,
      typedAmount,
      amountOut,
      availableLiquidity,
      HIR,
      borrow,
      debtPayable,
      profit,
      protocolFee,
      borrowAmount,
      borrowRate,
      liquidationCost,
      maxBorrow
    }
  }
}

function isLendaleToken(token: Currency | null | undefined): boolean | undefined {
  if (token) {
    const foundToken = tokenJson.tokens.find(tk => tk.symbol === token.symbol)

    if (token.symbol === BASE_TOKEN_SYMBOL) {
      return true
    }
    if (foundToken) {
      return foundToken.lendable
    }
  }

  return undefined
}

function parseCurrencyFromURLParameter(urlParam: any): string {
  if (typeof urlParam === 'string') {
    const valid = isAddress(urlParam)
    if (valid) return valid
    if (urlParam.toUpperCase() === 'ETH') return 'ETH'
    if (valid === false) return 'ETH'
  }
  return 'ETH' ?? ''
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : ''
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output' ? Field.OUTPUT : Field.INPUT
}

function getMinimumAmount(currency: Currency | undefined, typedValue: string): number | undefined {
  let minAmount
  if (currency && isLendaleToken(currency) && Number(typedValue) < MIN_AMOUNT_FOR_SWAP[currency.symbol!]) {
    minAmount = MIN_AMOUNT_FOR_SWAP[currency.symbol!]
  }

  return minAmount
}

function getCurrencyAddress(currency: Currency | null | undefined, chainId?: ChainId) {
  return currency instanceof Token ? currency.address : getDefaultToken(chainId).address
}

const ENS_NAME_REGEX = /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/
function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== 'string') return null
  const address = isAddress(recipient)
  if (address) return address
  if (ENS_NAME_REGEX.test(recipient)) return recipient
  if (ADDRESS_REGEX.test(recipient)) return recipient
  return null
}

export function useLeverage(): { leverage: number; leverageAllow: number; showError: boolean } {
  const { leverage, leverageAllow, showError } = useSwapState()

  return {
    leverage,
    leverageAllow,
    showError
  }
}

export function useDebtInfo(): { debtPayable: number; profit: number; protocolFee: number } {
  const { debtPayable, profit, protocolFee } = useSwapState()

  return {
    debtPayable,
    profit,
    protocolFee
  }
}

function useReserveData(contract: Contract | null): { availableLiquidity?: string; utilizationRate?: string } {
  const availableLiquidityResponse = useSingleCallResult(contract, 'getAvailableLiquidity', undefined)
  const utilizationRateResponse = useSingleCallResult(contract, 'getBorrowRate', undefined)

  return useMemo(() => {
    if (!availableLiquidityResponse.result) return {}
    if (!utilizationRateResponse.result) return {}

    return {
      availableLiquidity: (availableLiquidityResponse.result?.[0] as BigNumber).toString(),
      utilizationRate: (utilizationRateResponse.result?.[0] as BigNumber).toString()
    }
  }, [availableLiquidityResponse, utilizationRateResponse])
}

export function queryParametersToSwapState(parsedQs: ParsedQs): SwapState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency)
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency)
  if (inputCurrency === outputCurrency) {
    if (typeof parsedQs.outputCurrency === 'string') {
      inputCurrency = ''
    } else {
      outputCurrency = ''
    }
  }

  const recipient = validatedRecipient(parsedQs.recipient)

  return {
    [Field.INPUT]: {
      currencyId: inputCurrency,
      currencyName: '' // TODO: fix currencyName
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency,
      currencyName: '' // TODO: fix currencyName
    },
    typedValue: parseTokenAmountURLParameter(parsedQs.exactAmount),
    independentField: parseIndependentFieldURLParameter(parsedQs.exactField),
    recipient,
    leverage: 1,
    leverageAllow: 3,
    showError: false,

    debtPayable: 0,
    protocolFee: 0,
    profit: 0,
    borrowAmount: 0,
    borrowRate: 0,
    liquidationCost: 0,
    maxBorrow: 0
  }
}

// updates the swap state to use the defaults for a given network
export function useDefaultsFromURLSearch():
  | { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined }
  | undefined {
  const { chainId } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const parsedQs = useParsedQueryString()
  const [result, setResult] = useState<
    { inputCurrencyId: string | undefined; outputCurrencyId: string | undefined } | undefined
  >()

  useEffect(() => {
    if (!chainId) return
    const parsed = queryParametersToSwapState(parsedQs)

    dispatch(
      replaceSwapState({
        typedValue: parsed.typedValue,
        field: parsed.independentField,
        inputCurrencyId: parsed[Field.INPUT].currencyId,
        outputCurrencyId: parsed[Field.OUTPUT].currencyId,
        recipient: parsed.recipient
      })
    )

    setResult({ inputCurrencyId: parsed[Field.INPUT].currencyId, outputCurrencyId: parsed[Field.OUTPUT].currencyId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, chainId])

  return result
}

const LEVERAGE_COEF = 1

export function toLeverage(leverageFactor: number) {
  return leverageFactor * LEVERAGE_COEF
}

export function fromLeverage(leverage: number) {
  return leverage / LEVERAGE_COEF
}
