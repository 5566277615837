import React from 'react'
import styled from 'styled-components'
import useDebouncedChangeHandler from '../../utils/useDebouncedChangeHandler'
import { Input as SelectLeverageInput } from '../NumericalInput'
import SliderCustom from '../SliderCustom'

const SelectLeverageBody = styled.div``
const SelectLeverageWraper = styled.div`
  padding: 10px 15px;
  margin-bottom: 5px;

  background: #ffffff;
  border-radius: 10px;
`
const SelectLeverageTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
const SelectLeverageTitle = styled.h2`
  margin: 0;
  margin-right: 12px;
  padding-bottom: 2px;

  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #000000;

  ${({ theme }) => theme.mediaWidth.upToMedium`
		font-size: 16px;
		line-height: 16px;
	`};
`
const SelectLeverageBottom = styled.div``
// const SelectLeverageError = styled.div`
//   font-weight: normal;
//   font-size: 16px;
//   line-height: 110%;
//   color: #42ce61;
// `

interface ISelectLeverageProps {
  value?: string
  from?: number
  to?: number
  max?: number
  min?: number
  marks?: Array<number>
  onChange?: (v: string) => void
  onMax?: (exceedingValue: number) => void
  disabledTrackWidth?: string
}

const SelectLeverage: React.FC<ISelectLeverageProps> = ({
  to,
  max,
  from,
  value,
  onChange,
  onMax,
  marks,
  disabledTrackWidth
}) => {
  const [nextLeverage, setNextLeverage] = useDebouncedChangeHandler(
    value!,
    v => {
      if (value && max && +v > max) {
        onChange!(max.toString())
        if (onMax) onMax(+v)
      } else {
        onChange!(v)
      }
    },
    500
  )

  if (!marks) {
    return null
  }

  return (
    <SelectLeverageBody>
      <SelectLeverageWraper>
        <SelectLeverageTop>
          <SelectLeverageTitle>Select leverage:</SelectLeverageTitle>

          <SelectLeverageInput
            className="select-leverage__input"
            value={nextLeverage}
            onUserInput={setNextLeverage}
            inputStyle="border"
          />
        </SelectLeverageTop>

        <SelectLeverageBottom>
          <SliderCustom
            value={parseFloat(nextLeverage)}
            max={max!}
            to={to || marks[marks.length - 1]}
            from={from || marks[0]}
            marks={marks}
            disabledTrackWidth={disabledTrackWidth}
            onChange={n => setNextLeverage(n.toFixed(2))}
            onMax={onMax}
          />
        </SelectLeverageBottom>
      </SelectLeverageWraper>
    </SelectLeverageBody>
  )
}

export default SelectLeverage
