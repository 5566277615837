import React, { ChangeEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TokenAmount } from '@wowswap-io/wowswap-sdk'
import { Text } from 'rebass'
import styled from 'styled-components'

import Modal from '../Modal'
import Column from '../Column'
import { RowBetween } from '../Row'
import { SearchInput, FadedSpan, MenuItem, PaddedColumn } from '../SearchModal/styleds'
import { CloseIcon } from '../Shared'
import TranslatedText from '../TranslatedText'
import Loader from '../Loader'
import CurrencyLogo from '../CurrencyLogo'
import { RowFixed } from '../Row'

import { useActiveWeb3React } from '../../hooks'
import { TradeToken } from '../../hooks/Tokens.types'

import { MenuItemWraper, StyledBalanceText, TokensWrapper } from '../trade/styleds'
interface EarnCurrencySearchModalProps {
  isOpen: boolean
  selected?: TradeToken
  other?: TradeToken
  variants?: TradeToken[]
  onDismiss: () => void
  onSelect: (token: TradeToken) => void
}

export default function EarnCurrencySearchModal({
  isOpen,
  selected,
  other,
  variants,
  onDismiss,
  onSelect
}: EarnCurrencySearchModalProps) {
  const handleCurrencySelect = useCallback(
    (token: TradeToken) => {
      onSelect(token)
      onDismiss()
    },
    [onDismiss, onSelect]
  )

  return (
    <Modal height="660px" isOpen={isOpen} onDismiss={onDismiss} maxHeight={90} minHeight={40} paddingBottom="0">
      <EarnTokenSearch
        onDismiss={onDismiss}
        onSelect={handleCurrencySelect}
        selected={selected}
        other={other}
        variants={variants}
      />
    </Modal>
  )
}
interface EarnTokenSearchProps {
  selected?: TradeToken
  other?: TradeToken
  variants?: TradeToken[]

  onDismiss: () => void
  onSelect: (token: TradeToken) => void
}

const CurrencySearchTitle = styled(Text)`
  font-family: 'Retro';
  color: ${({ theme }) => theme.colors.text1};
`
const CurrencySearchRow = styled(RowBetween)`
  position: relative;
`
export function EarnTokenSearch({ selected, other, variants, onSelect, onDismiss }: EarnTokenSearchProps) {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState('')
  const searchRegExp = !searchValue ? null : new RegExp(searchValue, 'gi')
  const variantsFiltered =
    !searchValue || !searchRegExp
      ? variants
      : variants?.filter(
          token =>
            token?.symbol?.match(searchRegExp) ||
            token?.name?.match(searchRegExp) ||
            token.address.match(searchRegExp) ||
            token?.tradableAddress?.match(searchRegExp)
        )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value)
    },
    [setSearchValue]
  )

  return (
    <Column style={{ width: '100%', flex: '1 1', height: '100%' }}>
      <PaddedColumn gap="18px">
        <CurrencySearchRow>
          <CurrencySearchTitle fontWeight={400} fontSize={20}>
            <TranslatedText translationId={82}>Select a token</TranslatedText>
          </CurrencySearchTitle>
          <CloseIcon onClick={onDismiss} />
        </CurrencySearchRow>
      </PaddedColumn>

      {variants && variants?.length > 10 && (
        <SearchInput
          type="text"
          id="token-search-input"
          placeholder={t('Search name')}
          value={searchValue}
          onChange={handleChange}
          style={{ marginBottom: '10px' }}
        />
      )}

      <TokensWrapper>
        {variantsFiltered?.map(token => (
          <CurrencyRow
            key={token.uniqSymbol}
            token={token}
            onSelect={() => onSelect(token)}
            other={other}
            selected={selected}
          />
        ))}
      </TokensWrapper>
    </Column>
  )
}

function CurrencyRow({
  token,
  selected,
  other,
  onSelect
}: {
  token: TradeToken
  selected?: TradeToken
  other?: TradeToken
  onSelect: () => void
}) {
  const { account } = useActiveWeb3React()
  const key = token?.address
  const isSelected = selected && selected.uniqSymbol === token.uniqSymbol
  const isOtherSelected = other && other.uniqSymbol === token.uniqSymbol

  return (
    <MenuItem
      className={`token-item-${key}`}
      onClick={() => (isSelected ? null : onSelect())}
      disabled={isSelected}
      selected={isOtherSelected}
    >
      <MenuItemWraper>
        <CurrencyLogo
          currency={token}
          size={'25px'}
          style={{
            display: 'block',
            marginTop: '5px',
            marginLeft: '6px',
            marginRight: '10px'
          }}
        />
        <Column style={{ flexGrow: 2, marginTop: '2px' }}>
          <Text
            title={token.info.name}
            fontWeight={700}
            style={{
              paddingTop: '0',
              color: '#000000',
              fontFamily: '"Abadi MT Std", sans-serif',
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '100%'
            }}
          >
            {token.info.id}
          </Text>
          <FadedSpan
            style={{
              color: '#999999',
              fontFamily: '"Abadi MT Std", sans-serif',
              fontWeight: 200,
              fontSize: '16px',
              lineHeight: '100%'
            }}
          >
            {token.info.name}
          </FadedSpan>
        </Column>
        <RowFixed
          style={{
            justifySelf: 'flex-end',
            minWidth: '65px'
          }}
        >
          {token.balance ? <Balance balance={token.balance} /> : account ? <Loader /> : null}
        </RowFixed>
      </MenuItemWraper>
    </MenuItem>
  )
}

function Balance({ balance }: { balance: TokenAmount }) {
  return <StyledBalanceText title={balance.toExact()}>{balance.toSignificant(4)}</StyledBalanceText>
}
