import React, { useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { TokenAmount } from '@wowswap-io/wowswap-sdk'
import { useAllTradeTokens } from '../../hooks/tokens/tokenLists'
import PortfolioLoaderItem from '../PortfolioLoaderItem'
import SortTable from '../SortTable'
import { PortfolioTableHeader, PortfolioTableList, PortfolioTableStyle } from './styleds'

import { PortfolioPosition } from '../../state/portfolio/hooks'
import { amount, fromDecimals } from '../../utils/math'

import { calcHealth } from '../../utils/calculations'
import { TradeToken } from '../../hooks/Tokens.types'

enum Order {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type OrderBy = {
  field: string
  order: Order
}

interface IRenderRowProps {
  position: PortfolioPosition
  tradeble: TradeToken
  lendable: TradeToken
  isOdd: boolean
}

interface IPortfolioTableProps {
  positions: PortfolioPosition[] | null
  cols: Array<{ field: string; title: React.ReactElement | string; subtitle?: string }>
  renderRow: (position: IRenderRowProps) => React.ReactElement
  renderEmpty: () => React.ReactElement
}

/* type SearchValueGetter = (earn: any) => string | number
 *
 * const sortValueGetters: {[key:string]: SearchValueGetter} = {
 *   "Token": (position) => earn.token.title,
 *   "APY": (position) => percentStringToNumber(earn.apy),
 *   "Deposited": (position) => earn.deposited.split(' ')[0],
 *   "Borrowed": (position) => earn.borrowed.split(' ')[0],
 *   "Utilization": (position) => percentStringToNumber(earn.utilization),
 *   "Balance": (position) => earn.balance.split(' ')[0],
 *   "Total Value": (position) => earn.totalValue.split(' ')[0],
 * } */

const NUM_DIGITS = 4

export const PortfolioTable: React.FC<IPortfolioTableProps> = ({ positions, renderRow, renderEmpty, cols }) => {
  const allTradeTokens = Object.values(useAllTradeTokens())
  const [orderBy, setOrderBy] = useState<OrderBy>({ order: Order.ASC, field: 'Debt' })

  const positionComponents = useMemo(() => {
    if (!positions) {
      return Array.from({ length: 5 }).map((_, idx) => {
        const isOdd = idx % 2 === 0
        return <PortfolioLoaderItem key={idx} isOdd={isOdd} />
      })
    }

    const result = positions
      .filter(position => position.amount && !position.amount?.isZero())
      .sort((a: any, b: any) => {
        let lendableA
        let lendableB

        let valueA: number
        let valueB: number

        if (orderBy.field === 'Profit') {
          valueA =
            a.currentCost && b.currentDebt && b.selfValue
              ? +new BigNumber(a.currentCost.toString())
                  .minus(a.currentDebt.toString())
                  .minus(a.selfValue.toString())
                  .toFixed(8)
              : 0

          valueB =
            b.currentCost && b.currentDebt && b.selfValue
              ? +new BigNumber(b.currentCost.toString())
                  .minus(b.currentDebt.toString())
                  .minus(b.selfValue.toString())
                  .toFixed(8)
              : 0
        } else if (orderBy.field === 'Liquidation Price') {
          lendableA = allTradeTokens.find((t: TradeToken) => t.address === a.lendable)!
          lendableB = allTradeTokens.find((t: TradeToken) => t.address === b.lendable)!

          const tradebleA = allTradeTokens.find((t: TradeToken) => t.address === a.pair)! as TradeToken
          const lpA = fromDecimals(a.liquidationCost.toString(), lendableA.decimals).div(
            fromDecimals(a.amount.toString(), tradebleA.decimals)
          )
          valueA = +new TokenAmount(lendableA, amount(lpA, lendableA.decimals).str()).toSignificant(NUM_DIGITS)

          const tradebleB = allTradeTokens.find((t: TradeToken) => t.address === b.pair)! as TradeToken
          const lpB = fromDecimals(b.liquidationCost.toString(), lendableB.decimals).div(
            fromDecimals(b.amount.toString(), tradebleB.decimals)
          )
          valueB = +new TokenAmount(lendableB, amount(lpB, lendableB.decimals).str()).toSignificant(NUM_DIGITS)
        } else if (orderBy.field === 'Health') {
          valueA = +calcHealth(a.currentCost.toString(), a.liquidationCost.toString()).toFixed(2)
          valueB = +calcHealth(b.currentCost.toString(), b.liquidationCost.toString()).toFixed(2)
        } else {
          lendableA = allTradeTokens.find((t: TradeToken) => t.address === a.lendable)!
          lendableB = allTradeTokens.find((t: TradeToken) => t.address === b.lendable)!
          valueA = +new TokenAmount(
            lendableA,
            (orderBy.field === 'Debt'
              ? a.currentDebt?.toString()
              : a.currentCost.sub(a.currentDebt).toString()
            )?.toString() || '0'
          ).toSignificant(4)
          valueB = +new TokenAmount(
            lendableB,
            (orderBy.field === 'Debt'
              ? b.currentDebt?.toString()
              : b.currentCost.sub(b.currentDebt).toString()
            )?.toString() || '0'
          ).toSignificant(4)
        }

        return (
          (valueB !== valueA
            ? valueB - valueA
            : lendableA && lendableB
            ? lendableB.uniqSymbol! > lendableA.uniqSymbol!
              ? 1
              : lendableB.uniqSymbol! < lendableA.uniqSymbol!
              ? -1
              : 0
            : 0) * (orderBy.order === Order.DESC ? 1 : -1)
        )
      })
      .map((position, idx) => {
        // TODO: Do not use ! to convert undefined to string!
        const lendable = allTradeTokens.find((t: TradeToken) => t.address === position.lendable)! as TradeToken
        const tradeble = allTradeTokens.find((t: TradeToken) => t.address === position.pair)! as TradeToken
        const isOdd = idx % 2 === 0

        return renderRow({ position, lendable, tradeble, isOdd })
      })

    if (result.length === 0) return renderEmpty()

    return result
  }, [positions, allTradeTokens, orderBy, renderRow, renderEmpty])

  return (
    <PortfolioTableStyle>
      {Array.isArray(positionComponents) && (
        <PortfolioTableHeader>
          {cols.map(({ field, title, subtitle }, index: number) => {
            return (
              <SortTable
                title={title}
                field={field}
                subtitle={subtitle}
                sort
                key={index}
                order={{
                  orderBy: orderBy,
                  method: () =>
                    setOrderBy({
                      order: orderBy.order === Order.ASC ? Order.DESC : Order.ASC,
                      field: field
                    })
                }}
              />
            )
          })}
        </PortfolioTableHeader>
      )}

      <PortfolioTableList>{positionComponents}</PortfolioTableList>
    </PortfolioTableStyle>
  )
}
