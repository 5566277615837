import React from 'react'
import styled from 'styled-components'

import ProposalsItems from '../ProposalsItems'

const ProposalsListBody = styled.div``
const ProposalsListItem = styled.div`
  margin-bottom: 5px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
		margin-bottom: 10px;
	`};
  &:last-child {
    margin-bottom: 0;
  }
`
const ProposalsListBottom = styled.div`
  margin-top: 20px;

  text-align: center;
`
const ProposalsListLink = styled.a`
  font-family: 'Retro';
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.text1};
  text-decoration: none;

  transition: all 0.4s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.bg5};
  }
`

export default function ProposalsList() {
  return (
    <ProposalsListBody>
      <ProposalsListItem>
        <ProposalsItems label="Active" title="Increase PICKLE Collateral Factor to 45%" />
      </ProposalsListItem>

      <ProposalsListItem>
        <ProposalsItems label="Closed" labelAction="closed" title="List RUNE as Collateral Asset" />
      </ProposalsListItem>

      <ProposalsListItem>
        <ProposalsItems label="Closed" labelAction="closed" title="List RUNE as Collateral Asset" />
      </ProposalsListItem>

      <ProposalsListItem>
        <ProposalsItems label="Closed" labelAction="closed" title="List RUNE as Collateral Asset" />
      </ProposalsListItem>

      <ProposalsListItem>
        <ProposalsItems label="Closed" labelAction="closed" title="List RUNE as Collateral Asset" />
      </ProposalsListItem>

      <ProposalsListItem>
        <ProposalsItems label="Closed" labelAction="closed" title="List RUNE as Collateral Asset" />
      </ProposalsListItem>

      <ProposalsListItem>
        <ProposalsItems label="Closed" labelAction="closed" title="List RUNE as Collateral Asset" />
      </ProposalsListItem>

      <ProposalsListItem>
        <ProposalsItems label="Closed" labelAction="closed" title="List RUNE as Collateral Asset" />
      </ProposalsListItem>

      <ProposalsListBottom>
        <ProposalsListLink href="#">+more</ProposalsListLink>
      </ProposalsListBottom>
    </ProposalsListBody>
  )
}
