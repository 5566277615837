import { Currency, ChainId, JSBI, Percent, Token, WETH } from '@wowswap-io/wowswap-sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { injected, walletconnect } from '../connectors'
import jsonConfig from '../wowswap.json'

// TODO: ChainID
export const ROUTER_ADDRESS: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: '0x0D4898d1581Da1e8833c011533C76F95705bea6f',
  [ChainId.ROPSTEN]: '0x38D0295ffD1d1c6c9FCD8D4f7E9BBE8DAD0b85c1',
  [ChainId.MAINNET]: '0x7516c1e72F6B5De886987AedAA7C44e60e11637b',
  [ChainId.BSCTESTNET]: '0x6138ecBdE74494e576a674A0a1CCf97346D21fe3',
  [ChainId.LOCALNET]: '0x202bd10569D5DAb8Bd8044dC2FEa6A6b494cf2b7',
  [ChainId.MATIC]: '0x322f0aD39B23e6607E461399D12153d432e712d6',
  [ChainId.HECO]: '0x49A9Bc850b1f31ffC63d36C5f9Cd85fA55C3D685',
  [ChainId.AVALANCE]: '0xc46f72901D45E5Dc4902ECc75BEb22a1537515d5',
  [ChainId.FUJI]: '',
  [ChainId.IOTEX]: '0x078Ba87e5c8d11330F28E2997956Fad2B4C6820b',
  [ChainId.ANDROMEDA]: '0xF9F619e6a2075b2A30EAFee49E1d039d23Fdc028',
  [ChainId.STARDUST]: '0x3F0C1455d1682f9437B8BAC64cb8935A100c1da9'
}

const PRODUCTION = process.env.NODE_ENV === 'production'
const url = (path: string) => (PRODUCTION ? path : 'http://localhost:8000/sign')

export const SIGNER_API_URLS: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: url('https://ethereum-signer.wowswap.io/sign'),
  [ChainId.MAINNET]: url('https://bsc-signer.wowswap.io/sign'),
  [ChainId.HECO]: url('https://heco-signer.wowswap.io/sign'),
  [ChainId.MATIC]: url('https://matic-signer.wowswap.io/sign'),
  [ChainId.ROPSTEN]: url('https://ropsten-signer.wowswap.io/sign'),
  [ChainId.BSCTESTNET]: url('http://localhost:8000/sign'),
  [ChainId.LOCALNET]: url('http://localhost:8000/sign'),
  [ChainId.AVALANCE]: url('https://avalance-signer.wowswap.io/sign'),
  [ChainId.FUJI]: url('http://localhost:8000/sign'),
  [ChainId.IOTEX]: url('https://iotex-signer.wowswap.io/sign'),
  [ChainId.ANDROMEDA]: url('https://metis-signer.wowswap.io/sign'),
  [ChainId.STARDUST]: url('https://metis-testnet-signer.wowswap.io/sign')
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const NATIVE_TOKEN_WRAPPED: Record<ChainId, string> = Object.entries(WETH).reduce((dict, [chainId, token]) => {
  dict[token.chainId] = token.address
  return dict
}, {} as Record<ChainId, string>)

export const TREASURER_ADDRESS: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: '0xfB7d27eB3F0665F5704192A31722c33615bda0E5',
  [ChainId.ROPSTEN]: '0xfB7d27eB3F0665F5704192A31722c33615bda0E5',
  [ChainId.MAINNET]: '0x8ee5849366735a3f89C3B86270F9073CC942ffB7',
  [ChainId.BSCTESTNET]: '', // TODO: update this
  [ChainId.LOCALNET]: '0x1D3347370E2a7Bb60af83c15697043f4FE90E4fd',
  [ChainId.HECO]: '0xF1eCFaA88A837a8D4e74DaDB405EE37e8110DDd1',
  [ChainId.MATIC]: '0xc7ae2cC4039E18F9F59D76Fe9D5fB75c1BbfB903',
  [ChainId.AVALANCE]: '0x2105BbF40aa3685ca77AF8A8C88339da46fbD6Cc',
  [ChainId.FUJI]: '',
  [ChainId.IOTEX]: '0x01B5B462B55a65101d7C7637b056d2DAe16E5f9c',
  [ChainId.ANDROMEDA]: '0x0D5d5DF8b6414CEDeC83Ad7e50d18f096f435Ea8',
  [ChainId.STARDUST]: '0x5BbC54931E7d2C62Fe0d600c740B7f82CC88E2F7'
}
export const GOVERNANCE_ADDRESS: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: '0x2105BbF40aa3685ca77AF8A8C88339da46fbD6Cc',
  [ChainId.ROPSTEN]: '0x8Bf4445616794D0dc8006DC752e48390f7090316',
  [ChainId.MAINNET]: '0xB82b8321453c2957C593FA6AA7BC9f8bC43d18CC',
  [ChainId.BSCTESTNET]: '', // TODO: update this
  [ChainId.LOCALNET]: '0x508D97012a04dD2bDa057B6824539345561aa6F3',
  [ChainId.HECO]: '', // temporary disabled 0x360bBe4DE153c9D553E34B655b90EE4f1c2DCb16',
  [ChainId.MATIC]: '0x6167EBFba9A16C5D6B50522FF32fd9A453cF64ED',
  [ChainId.AVALANCE]: '0xEb3e85cf56b982f0fBDd1cc8feB0929be767b2FF',
  [ChainId.FUJI]: '',
  [ChainId.IOTEX]: '0xd9DdCD76CD95e78B405d32ec5b63cbd5A15F43f3',
  [ChainId.ANDROMEDA]: '0x01B5B462B55a65101d7C7637b056d2DAe16E5f9c',
  [ChainId.STARDUST]: '0xec5945CE27b08330c70a5f14e414D0632C0Cd003'
}

export const BASE_TOKEN_SYMBOL_MAP: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: 'ETH',
  [ChainId.ROPSTEN]: 'ETH',
  [ChainId.MAINNET]: 'BNB', // WBNB
  [ChainId.BSCTESTNET]: 'BNB',
  [ChainId.LOCALNET]: 'BNB',
  [ChainId.MATIC]: 'MATIC',
  [ChainId.HECO]: 'HT',
  [ChainId.AVALANCE]: 'AVAX',
  [ChainId.FUJI]: 'AVAX',
  [ChainId.IOTEX]: 'IOTX',
  [ChainId.ANDROMEDA]: 'METIS',
  [ChainId.STARDUST]: 'METIS'
}

export const BASE_TOKEN_SYMBOL: string = Currency.getBaseCurrency().symbol!

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

const findConfig = (symbol: string, chain: ChainId) =>
  configToToken(jsonConfig.tokens.find(t => t.symbol === symbol && t.chainId === chain))

export const findReserve = (lendable: string, chain: ChainId): string | undefined =>
  (jsonConfig as any).reserves[chain.toString()]?.[lendable]

const configToToken = (cfg?: Pick<Token, 'address' | 'name' | 'symbol' | 'decimals' | 'chainId'>) =>
  cfg ? new Token(cfg.chainId, cfg.address, cfg.decimals, cfg.symbol, cfg.name) : undefined

export const DAI = new Token(ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18, 'DAI', 'Dai Stablecoin')
export const USDC = new Token(ChainId.MAINNET, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD//C')
export const USDT = new Token(ChainId.MAINNET, '0xdAC17F958D2ee523a2206206994597C13D831ec7', 6, 'USDT', 'Tether USD')
export const COMP = new Token(ChainId.MAINNET, '0xc00e94Cb662C3520282E6f5717214004A7f26888', 18, 'COMP', 'Compound')
export const MKR = new Token(ChainId.MAINNET, '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2', 18, 'MKR', 'Maker')
export const AMPL = new Token(ChainId.MAINNET, '0xD46bA6D942050d489DBd938a2C909A5d5039A161', 9, 'AMPL', 'Ampleforth')
export const BUSD = findConfig('BUSD', ChainId.MAINNET)!

const WETH_ONLY: ChainTokenList = {
  [ChainId.ETHEREUM]: [WETH[ChainId.ETHEREUM]],
  [ChainId.ROPSTEN]: [WETH[ChainId.ROPSTEN]],
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.BSCTESTNET]: [WETH[ChainId.BSCTESTNET]],
  [ChainId.LOCALNET]: [WETH[ChainId.LOCALNET]],
  [ChainId.MATIC]: [WETH[ChainId.MATIC]],
  [ChainId.HECO]: [WETH[ChainId.HECO]],
  [ChainId.AVALANCE]: [WETH[ChainId.AVALANCE]],
  [ChainId.FUJI]: [WETH[ChainId.FUJI]],
  [ChainId.IOTEX]: [WETH[ChainId.IOTEX]],
  [ChainId.ANDROMEDA]: [WETH[ChainId.ANDROMEDA]],
  [ChainId.STARDUST]: [WETH[ChainId.STARDUST]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.BSCTESTNET]: [...WETH_ONLY[ChainId.BSCTESTNET], findConfig('BUSD', ChainId.BSCTESTNET)!],
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], BUSD],
  [ChainId.LOCALNET]: [...WETH_ONLY[ChainId.LOCALNET], findConfig('BUSD', ChainId.LOCALNET)!],
  [ChainId.HECO]: [...WETH_ONLY[ChainId.HECO], findConfig('USDT', ChainId.HECO)!]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [USDC, USDT],
    [DAI, USDT]
  ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export enum SUPPORTED_WALLET_NAMES {
  INJECTED = 'INJECTED',
  METAMASK = 'METAMASK',
  METAMASK_MOBILE_DEEP_LINK = 'METAMASK_MOBILE_DEEP_LINK',
  COIN98 = 'COIN98',
  MATHWALLET = 'MATHWALLET',
  TRUSTWALLET = 'TRUSTWALLET',
  WALLET_CONNECT = 'WALLET_CONNECT'
}

export const SUPPORTED_WALLETS: { [key in SUPPORTED_WALLET_NAMES]: WalletInfo } = {
  // BSC: {
  //   connector: bsc,
  //   name: 'Binance Chain Wallet',
  //   iconName: 'binance.svg',
  //   description: 'Easy-to-use browser extension.',
  //   href: null,
  //   color: '#E8831D'
  // },
  [SUPPORTED_WALLET_NAMES.INJECTED]: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  [SUPPORTED_WALLET_NAMES.METAMASK]: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#F3BA2F'
  },
  [SUPPORTED_WALLET_NAMES.METAMASK_MOBILE_DEEP_LINK]: {
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'MetaMask deep link.',
    href: null,
    color: '#F3BA2F',
    mobile: true,
    mobileOnly: true
  },
  [SUPPORTED_WALLET_NAMES.COIN98]: {
    connector: injected,
    name: 'Coin98',
    iconName: 'coin98.svg',
    description: 'Connect to Coin98.',
    href: null,
    color: '#F3BA2F'
  },
  [SUPPORTED_WALLET_NAMES.MATHWALLET]: {
    connector: injected,
    name: 'MathWallet',
    iconName: 'mathwallet.svg',
    description: 'Connect to Math Wallet.',
    href: null,
    color: '#F3BA2F'
  },
  [SUPPORTED_WALLET_NAMES.TRUSTWALLET]: {
    connector: injected,
    name: 'TrustWallet',
    iconName: 'trustwallet.svg',
    description: 'Connect to Trust Wallet.',
    href: null,
    color: '#F3BA2F',
    mobile: true
  },
  [SUPPORTED_WALLET_NAMES.WALLET_CONNECT]: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#F3BA2F',
    mobile: true
  }
  // WALLET_LINK: {
  //   connector: walletlink,
  //   name: 'Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Use Coinbase Wallet app on mobile device',
  //   href: null,
  //   color: '#315CF5'
  // },
  // COINBASE_LINK: {
  //   name: 'Open in Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Open in Coinbase Wallet app.',
  //   href: 'https://go.cb-w.com/mtUDhEZPy1',
  //   color: '#315CF5',
  //   mobile: true,
  //   mobileOnly: true
  // },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconName: 'fortmaticIcon.png',
  //   description: 'Login using Fortmatic hosted wallet',
  //   href: null,
  //   color: '#6748FF',
  //   mobile: true
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true
  // }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))

export const MIN_AMOUNT_FOR_SWAP: any = {
  BNB: 0.25,
  MATIC: 120,
  BUSD: 100,
  USDC: 100,
  WETH: 0.0005,
  ETH: 0.0005,
  HT: 8,
  USDT: 100
}
