import React from 'react'
import styled from 'styled-components'

import Label from '../Label'

const ProposalsItemsBody = styled.div`
  padding: 15px 15px;

  background-color: ${({ theme }) => theme.colors.bg1};
  border-radius: 10px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		padding: 10px 15px 15px 15px;

	`};
`
const ProposalsItemsTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3px;

  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary5};

  ${({ theme }) => theme.mediaWidth.upToSmall`
		align-items: flex-start;
		flex-direction: column;
	`};
`
const ProposalsItemsTopLabel = styled.div`
  margin-right: 15px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		margin-bottom: 8px;
	`};
`
const ProposalsItemsTitle = styled.h3`
  display: flex;
  align-items: center;
  margin: 0;

  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.text1};
`
const ProposalsItemsBottom = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text4};

  & > div {
    margin-left: 10px;
    margin-right: 8px;
  }
`

interface ProposalsItemsProps {
  label: string
  title: string
  labelAction?: 'closed'
}

export default function ProposalsItems({ label, title, labelAction }: ProposalsItemsProps) {
  return (
    <ProposalsItemsBody>
      <ProposalsItemsTop>
        <ProposalsItemsTopLabel>
          <Label text={label} action={labelAction} />
        </ProposalsItemsTopLabel>

        <ProposalsItemsTitle>{title}</ProposalsItemsTitle>
      </ProposalsItemsTop>

      <ProposalsItemsBottom>
        #QmUqNed By 0xb6e3...79dB
        <Label text="Core" size="sm" type="outline" />
        0.1 CREAM start 3/22/2021 end 3/25/2021
      </ProposalsItemsBottom>
    </ProposalsItemsBody>
  )
}
