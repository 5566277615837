import React from 'react'
import styled from 'styled-components'

const BlockTitleStyle = styled.div<{ padding?: string }>`
  padding: ${({ padding }) => padding || ''};

  font-family: 'Retro', sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
`

const BlockTitleLgStyle = styled.div<{ padding?: string }>`
  padding: ${({ padding }) => padding || ''};

  font-family: 'Retro', sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		font-size: 20px;
		line-height: 28px;
	`};
`

export const PageTitleStyles = styled.div<{ withBackground?: boolean }>`
  font-family: 'Retro', sans-serif;
  font-weight: normal;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  margin: 40px auto 30px;

  ${({ withBackground, theme }) => {
    if (withBackground) {
      return `
        background: ${theme.colors.pageTitelBG};
        border-radius: 10px;
      `
    }

    return ``
  }};
`

export function BlockTitle(props: { children: React.ReactNode; padding?: string }) {
  const { children, ...rest } = props
  return <BlockTitleStyle {...rest}>{children}</BlockTitleStyle>
}

export function BlockTitleLg(props: { children: React.ReactNode; padding?: string }) {
  const { children, ...rest } = props
  return <BlockTitleLgStyle {...rest}>{children}</BlockTitleLgStyle>
}
