import React from 'react'
import styled from 'styled-components'

const LabelBody = styled.div<{ size?: 'sm'; action?: 'closed'; type?: 'outline' }>`
	display: inline-block;

	font-style: normal;
	font-weight: 500;
	color: ${({ theme }) => theme.colors.white};

	${({ size }) => {
    if (size === 'sm') {
      return `
					padding: 2px 8px;

					font-size: 14px;
					line-height: 14px;

					border-radius: 3px;
				`
    } else {
      return `
				padding: 5px 15px;

				font-size: 16px;
				line-height: 16px;

				border-radius: 5px;
			`
    }
  }}

	${({ action }) => {
    if (action === 'closed') {
      return `
				background-color: #E85A44;
				`
    } else {
      return `
				background-color: #42CE61;
			`
    }
  }}

	${({ type }) => {
    if (type === 'outline') {
      return `
				color: #999999;

				background-color: transparent;
				border: 1px solid #D1D1CF;
				`
    } else {
      return ``
    }
  }}

`

interface LabelProps {
  text: string
  size?: 'sm'
  action?: 'closed'
  type?: 'outline'
}

export default function Label({ text, size, action, type }: LabelProps) {
  return (
    <LabelBody size={size} action={action} type={type}>
      {text}
    </LabelBody>
  )
}
