import React from 'react'
import { ContentWrapperInBlock } from '../components/ContentWrapper'
import {
  GridSidebarWraper,
  GridSidebar,
  SidebarLeft,
  GridSidebarBody,
  GridSidebarTitle,
  GridSidebarBack,
  GridSidebarHeader
} from '../components/GridSidebar'
import MenuSidebar from '../components/MenuSidebar'
import ProposalsList from '../components/ProposalsList'
import Tabs from '../components/Tabs'
import { useActiveWeb3React } from '../hooks'
import { ChainId } from '@wowswap-io/wowswap-sdk'

export default function GovernanceProposals() {
  const { chainId } = useActiveWeb3React()

  const isPageTitleWithBackground = chainId === ChainId.ETHEREUM || chainId === ChainId.IOTEX

  return (
    <GridSidebarWraper>
      <GridSidebar>
        <SidebarLeft>
          <MenuSidebar />
        </SidebarLeft>

        <GridSidebarBody>
          <GridSidebarHeader withBackground={isPageTitleWithBackground}>
            <GridSidebarBack to={'/governance-menu'} />
            <GridSidebarTitle count="64">Proposals</GridSidebarTitle>
          </GridSidebarHeader>

          <Tabs />

          <ContentWrapperInBlock NoNegativeMargin maxWidth="894px" padding="26px 36px 36px 36px">
            <ProposalsList />
          </ContentWrapperInBlock>
        </GridSidebarBody>
      </GridSidebar>
    </GridSidebarWraper>
  )
}
