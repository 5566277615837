import React from 'react'
import styled from 'styled-components'
import { ContentWrapper } from '../components/ContentWrapper'
import DonationModule from '../components/DonationModule'
import { ButtonGreen } from '../components/Button/'
import {
  GridSidebarWraper,
  GridSidebar,
  SidebarLeft,
  GridSidebarBody,
  GridSidebarTitle,
  GridSidebarBack,
  GridSidebarHeader
} from '../components/GridSidebar'
import MenuSidebar from '../components/MenuSidebar'
import { TextBaseH3, TextBaseWithoutChanges } from '../components/Text'
import LogoStc from '../assets/images/logo-stc@2x-01.png'
import LogoTf from '../assets/images/logo-tf@2x-01.png'
import { useActiveWeb3React } from '../hooks'
import { ChainId } from '@wowswap-io/wowswap-sdk'

const DonationsRow = styled.div`
  display: flex;
  margin: 0 -7px;
  flex-wrap: wrap;
`
const DonationsCol = styled.div`
  display: flex;
  width: 50%;
  flex: 0 0 50%;
  padding: 0 7px;
  margin-bottom: 20px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		width: 100%;
		flex: 0 0 100%;
`};
`
const DonationsTextBlock = styled.div`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`
const DonationsFooter = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  button {
    max-width: 340px;
  }
`

export default function GovernanceDonations() {
  const { chainId } = useActiveWeb3React()

  const isPageTitleWithBackground = chainId === ChainId.ETHEREUM || chainId === ChainId.IOTEX

  return (
    <GridSidebarWraper>
      <GridSidebar>
        <SidebarLeft>
          <MenuSidebar />
        </SidebarLeft>

        <GridSidebarBody>
          <GridSidebarHeader withBackground={isPageTitleWithBackground}>
            <GridSidebarBack to={'/governance-menu'} />
            <GridSidebarTitle>Donations</GridSidebarTitle>
          </GridSidebarHeader>

          <ContentWrapper NoNegativeMargin maxWidth="894px" padding="36px 36px 36px 36px">
            <DonationsRow>
              <DonationsCol>
                <DonationModule logo={LogoStc} current="45" currentNew="21" currentSlider="15">
                  <DonationsTextBlock>
                    <TextBaseH3>In total 10,254 USD donated</TextBaseH3>

                    <TextBaseWithoutChanges>
                      <span role="img" aria-label="aria-labelledby">
                        🍜{' '}
                      </span>{' '}
                      3,523 children are saved from hunger per month.
                    </TextBaseWithoutChanges>

                    <TextBaseWithoutChanges>
                      <span role="img" aria-label="aria-labelledby">
                        🙌
                      </span>{' '}
                      300 children get warm blankets.
                    </TextBaseWithoutChanges>

                    <TextBaseWithoutChanges>
                      <span role="img" aria-label="aria-labelledby">
                        🎭
                      </span>{' '}
                      712,123 face masks donated to refugee workers.
                    </TextBaseWithoutChanges>
                  </DonationsTextBlock>

                  <DonationsTextBlock>
                    <TextBaseH3>Save the children</TextBaseH3>

                    <TextBaseWithoutChanges>
                      Around the world, too many children still start life at a disadvantage simply because of who they
                      are and where they are from.
                    </TextBaseWithoutChanges>

                    <TextBaseWithoutChanges>
                      Save the Children has a bold ambition: we believe in a world in which all children survive, have
                      the chance to learn, and are protected from abuse, neglect and exploitation.
                    </TextBaseWithoutChanges>
                  </DonationsTextBlock>
                </DonationModule>
              </DonationsCol>

              <DonationsCol>
                <DonationModule logo={LogoTf} current="45" currentNew="21" currentSlider="15">
                  <DonationsTextBlock>
                    <TextBaseH3>In total 1,254 USD donated</TextBaseH3>

                    <TextBaseWithoutChanges>
                      <span role="img" aria-label="aria-labelledby">
                        🏝{' '}
                      </span>{' '}
                      24,511 trees planted
                    </TextBaseWithoutChanges>
                  </DonationsTextBlock>

                  <DonationsTextBlock>
                    <TextBaseH3>Trees for the future</TextBaseH3>

                    <TextBaseWithoutChanges>
                      A Maryland-based nonprofit organization founded in 1989 that helps communities around the world
                      plant trees.
                    </TextBaseWithoutChanges>
                  </DonationsTextBlock>
                </DonationModule>
              </DonationsCol>
            </DonationsRow>

            <DonationsFooter>
              <ButtonGreen>vote</ButtonGreen>
            </DonationsFooter>
          </ContentWrapper>
        </GridSidebarBody>
      </GridSidebar>
    </GridSidebarWraper>
  )
}
