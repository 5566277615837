import React from 'react'
import { Price } from '@wowswap-io/wowswap-sdk'
// #JUNK import { useContext } from 'react'
// #JUNK import { Repeat } from 'react-feather'
// #JUNK import { Text } from 'rebass'
// #JUNK import { ThemeContext } from 'styled-components'
import { StyledBalanceMaxMini } from './styleds'
import { IconExchange } from '../Icons'
import { TextBase } from '../Text'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  // #JUNK const theme = useContext(ThemeContext)

  const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `${price?.quoteCurrency?.symbol} per ${price?.baseCurrency?.symbol}`
    : `${price?.baseCurrency?.symbol} per ${price?.quoteCurrency?.symbol}`

  return (
    <TextBase style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
      {show ? (
        <>
          {formattedPrice ?? '-'} {label}
          <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            {/* #JUNK <Repeat size={9} /> */}
            <IconExchange />
          </StyledBalanceMaxMini>
        </>
      ) : (
        '-'
      )}
    </TextBase>
  )
}
