import React from 'react'
import styled from 'styled-components'
import { SliderGov } from '../SliderCustom/SliderGov'
import { SingleParamType } from '../../state/financial/actions'

const ValuesStyle = styled.div<{ wrap?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  min-width: 264px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
			flex-direction: column;
		}
	`};

  ${({ wrap }) => {
    if (wrap === 'wrap') {
      return `
					flex-wrap: wrap;
				`
    } else {
      return ``
    }
  }}
`
const ValuesTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		flex-direction: column;
		margin-bottom: 0;
	}
`};
`
const ValuesCol1 = styled.div<{ wrap?: string }>`
  width: 100%;
  max-width: 49px;
  margin-right: 20px;

  ${({ wrap }) => {
    if (wrap === 'wrap') {
      return `
				max-width: 100px;
				margin-right: 0;
			`
    } else {
      return ``
    }
  }}

  ${({ theme }) => theme.mediaWidth.upToLarge`
		max-width: 100%;
		margin-bottom: 7px;
		margin-right: 0;
		}
	`};
`
const ValuesCol2 = styled.div<{ wrap?: string }>`
  margin-right: 20px;

  ${({ wrap }) => {
    if (wrap === 'wrap') {
      return `
				margin-right: 0;
			`
    } else {
      return ``
    }
  }}
  ${({ theme }) => theme.mediaWidth.upToLarge`
			margin-bottom: 7px;
			margin-right: 0;
		}
	`};
`
const ValuesCol3 = styled.div<{ wrap?: string }>`
  width: 100%;
  max-width: 175px;

  ${({ wrap }) => {
    if (wrap === 'wrap') {
      return `
				max-width: 100%;
			`
    } else {
      return ``
    }
  }}

  ${({ theme }) => theme.mediaWidth.upToLarge`
		max-width: 100%;
	`};
`
const ValuesBody = styled.div<{ direction?: 'row' }>`
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.text4};

  ${({ theme }) => theme.mediaWidth.upToLarge`
		flex-direction: row;
		justify-content: space-between;
	`};

  ${({ direction }) => {
    if (direction === 'row') {
      return `
				flex-direction: row;
				align-items: center;
			`
    } else {
      return ``
    }
  }}
`
const ValuesCurent = styled.div<{ padding?: 'null'; paddingLeft?: 'left' }>`
  display: flex;
  padding-top: 5px;

  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text1};

  ${({ theme }) => theme.mediaWidth.upToLarge`
		padding-top: 0;
	`};

  ${({ padding }) => {
    if (padding === 'null') {
      return `
				padding: 0;
			`
    } else {
      return ``
    }
  }}
`
const ValuesSlider = styled.div`
  padding-top: 13px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		padding-top: 19px;
	`};
`
interface ValuesProps {
  field: SingleParamType
  current: number
  userValue: number
  newValue: number
  from: number
  to: number
  marks: number[]
  onChange: (field: SingleParamType, value: number) => void
  onRenderMark: (mark: number, possibleNull?: boolean) => string | React.ReactNode
}

export function Values({ field, current, userValue, newValue, from, to, marks, onChange, onRenderMark }: ValuesProps) {
  const handleChange = (value: number) => {
    onChange(field, value)
  }

  const currentWidth = ((to - from - (current - from)) / (to - from)) * 100
  const step = Math.abs((to - from) / 1000)

  return (
    <ValuesStyle>
      <ValuesCol1>
        <ValuesBody>
          Current Value
          <ValuesCurent>{onRenderMark(current)}</ValuesCurent>
        </ValuesBody>
      </ValuesCol1>

      <ValuesCol2>
        <ValuesBody>
          New Value
          <ValuesCurent>{onRenderMark(newValue, true)}</ValuesCurent>
        </ValuesBody>
      </ValuesCol2>

      <ValuesCol3>
        <ValuesBody direction="row">
          Your Value&nbsp;
          <ValuesCurent padding="null" paddingLeft="left">
            {onRenderMark(userValue)}
          </ValuesCurent>
        </ValuesBody>

        <ValuesSlider>
          <SliderGov
            from={from}
            to={to}
            value={userValue}
            currentWidth={currentWidth}
            marks={marks}
            step={step}
            onChange={handleChange}
            onRenderMark={onRenderMark}
          />
        </ValuesSlider>
      </ValuesCol3>
    </ValuesStyle>
  )
}

export function ValuesColumn({
  field,
  current,
  userValue,
  newValue,
  from,
  to,
  marks,
  onChange,
  onRenderMark
}: ValuesProps) {
  const handleChange = (value: number) => {
    onChange(field, value)
  }

  const currentWidth = ((to - from - (current - from)) / (to - from)) * 100
  const step = Math.abs((to - from) / 1000)

  return (
    <ValuesStyle wrap="wrap">
      <ValuesTop>
        <ValuesCol1 wrap="wrap">
          <ValuesBody>
            Current Value
            <ValuesCurent>{onRenderMark(current)}</ValuesCurent>
          </ValuesBody>
        </ValuesCol1>

        <ValuesCol2 wrap="wrap">
          <ValuesBody>
            New Value
            <ValuesCurent>{onRenderMark(newValue, true)}</ValuesCurent>
          </ValuesBody>
        </ValuesCol2>
      </ValuesTop>

      <ValuesCol3 wrap="wrap">
        <ValuesBody direction="row">
          Your Value&nbsp;
          <ValuesCurent padding="null" paddingLeft="left">
            {onRenderMark(userValue)}
          </ValuesCurent>
        </ValuesBody>

        <ValuesSlider>
          <SliderGov
            from={from}
            to={to}
            value={userValue}
            currentWidth={currentWidth}
            marks={marks}
            step={step}
            onChange={handleChange}
            onRenderMark={onRenderMark}
          />
        </ValuesSlider>
      </ValuesCol3>
    </ValuesStyle>
  )
}
