import styled from 'styled-components'

import { convertHexToRGBA } from 'utils/converters'

export const Button = styled.a`
  height: 48px;
  padding: 0px 15px 0px 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  color: ${({ theme }) => theme.colors.text1};
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background: ${({ theme }) => convertHexToRGBA(theme.colors.text2, 0.3)};
    transition: 0.2s;
  }

  svg {
    width: 28px;
    margin-right: 10px;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    margin-bottom: 20px;
    padding: 0px;

    &:hover {
      background: transparent;
    }

    svg {
      width: 40px;
    }
  `};
`
