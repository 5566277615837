import { ChainId } from '@wowswap-io/wowswap-sdk'
import { useCallback, useState } from 'react'

const NetworkParams: any = {
  [ChainId.MAINNET]: {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18
    },
    rpcUrls: ['https://bsc-dataseed.binance.org'],
    blockExplorerUrls: ['https://bscscan.com']
  },
  [ChainId.MATIC]: {
    chainId: '0x89',
    chainName: 'Matic Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrls: ['https://rpc-mainnet.matic.network'],
    blockExplorerUrls: ['https://polygonscan.com/']
  },
  [ChainId.HECO]: {
    chainId: '0x80',
    chainName: 'Heco Mainnet',
    nativeCurrency: {
      name: 'HT',
      symbol: 'HT',
      decimals: 18
    },
    rpcUrls: ['https://http-mainnet-node.huobichain.com'],
    blockExplorerUrls: ['https://hecoinfo.com/']
  },
  [ChainId.ETHEREUM]: {
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://etherscan.io']
  },
  [ChainId.AVALANCE]: {
    chainId: '0xA86A',
    chainName: 'Avalanche Network',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io']
  },
  [ChainId.IOTEX]: {
    chainId: '0x1251',
    chainName: 'IoTex Mainnet',
    nativeCurrency: {
      name: 'IOTX',
      symbol: 'IOTX',
      decimals: 18
    },
    rpcUrls: ['https://babel-api.mainnet.iotex.io'],
    blockExplorerUrls: ['https://iotexscan.io/']
  }
}

export default function useSwitchChainOnMetamask(): {
  changeNetwork: (chainId: ChainId) => void
  success: boolean | undefined
} {
  const { ethereum } = window

  const [success, setSuccess] = useState<boolean | undefined>()

  const addEthereumChain = useCallback(
    (networkParams: any) => {
      if (ethereum && ethereum.isMetaMask && ethereum.request && networkParams) {
        ethereum
          .request({
            method: 'wallet_addEthereumChain',
            params: [networkParams]
          })
          .then((success: any) => {
            setSuccess(success)
          })
          .catch(() => setSuccess(false))
      } else {
        setSuccess(false)
      }
    },
    [ethereum]
  )

  const changeNetwork = useCallback(
    (chainId: ChainId) => {
      if (ethereum && ethereum.isMetaMask && ethereum.request && NetworkParams[chainId]) {
        ethereum
          .request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: NetworkParams[chainId].chainId }]
          })
          .then((success: any) => {
            setSuccess(success)
          })
          .catch(switchError => {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
              addEthereumChain(NetworkParams[chainId])
            } else {
              setSuccess(false)
            }
          })
      } else {
        setSuccess(false)
      }
    },
    [ethereum, addEthereumChain]
  )

  return { changeNetwork, success }
}
