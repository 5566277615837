import { BigNumber } from '@ethersproject/bignumber'
import { useMemo } from 'react'
import { useActiveWeb3React } from '../../hooks'
import { useAllTradeTokens } from '../../hooks/tokens/tokenLists'
import { useRouterContract } from '../../hooks/useContract'
import { useSingleContractMultipleData } from '../multicall/hooks'

const defined = <T>(something: T | undefined): something is T => typeof something !== 'undefined'

export interface PortfolioPosition {
  pair: string
  lendable: string
  tradeble: string
  amount: BigNumber
  currentCost: BigNumber
  currentDebt: BigNumber
  liquidationCost: BigNumber
  principalDebt: BigNumber
  rate: BigNumber
  selfValue: BigNumber
  value: BigNumber
  shorting: boolean
}

export function usePositions(shorting: boolean): PortfolioPosition[] | null {
  const { account } = useActiveWeb3React()
  const contract = useRouterContract()

  const allTokens = useAllTradeTokens()
  const allTradable = useMemo(
    () => Object.values(allTokens).filter(t => (shorting && t.shortingInfo) || (!shorting && t.proxyInfo)),
    [allTokens, shorting]
  )

  const inputs = useMemo(() => {
    if (!account) {
      return []
    }

    return allTradable.map(token => {
      return {
        pair: token.address,
        lendable: token.lendableAddress,
        proxy: token.proxybleAddress,
        tradable: token.tradableAddress,
        inputs: [account, token.lendableAddress, token.proxybleAddress, token.tradableAddress].filter(defined)
      }
    })
  }, [account, allTradable])

  const strictInputs = inputs.filter(i => !i.proxy)
  const proxyInputs = inputs.filter(i => i.proxy)

  const positionRequests = useSingleContractMultipleData(
    contract,
    shorting ? 'getShortPosition' : 'getPosition',
    strictInputs.map(i => i.inputs)
  )
  const positionWithProxiesRequests = useSingleContractMultipleData(
    contract,
    shorting ? 'getProxyShortPosition' : 'getProxyPosition',
    proxyInputs.map(i => i.inputs)
  )

  return useMemo(() => {
    if (positionRequests.some(req => req.loading)) return null
    if (positionWithProxiesRequests.some(req => req.loading)) return null

    const results: PortfolioPosition[] = []

    const insertResult = (input: typeof inputs[number], request: any) => {
      results.push({
        pair: input.pair,
        lendable: input.lendable,
        tradeble: input.tradable,
        ...request.result.position,
        shorting: false
      })
    }

    positionRequests.forEach((request, idx) => !request.error && insertResult(strictInputs[idx], request))
    positionWithProxiesRequests.forEach((request, idx) => !request.error && insertResult(proxyInputs[idx], request))

    return results
  }, [strictInputs, proxyInputs, positionRequests, positionWithProxiesRequests, inputs])
}
