import { MaxUint256 } from '@ethersproject/constants'
import { TransactionResponse } from '@ethersproject/providers'
import { Trade, TokenAmount, CurrencyAmount, Currency, Token } from '@wowswap-io/wowswap-sdk'
import { useCallback, useMemo } from 'react'
import { ROUTER_ADDRESS } from '../constants'
import { useTokenAllowance } from '../data/Allowances'
import { getTradeVersion, useV1TradeExchangeAddress } from '../data/V1'
import { Field } from '../state/swap/actions'
import { useTransactionAdder, useHasApproval, useHasPendingApproval } from '../state/transactions/hooks'
import { computeSlippageAdjustedAmounts } from '../utils/prices'
import { calculateGasMargin } from '../utils'
import { useTokenContract } from './useContract'
import { useActiveWeb3React } from './index'
import { Version } from './useToggledVersion'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { WrappedTokenInfo } from '../state/lists/hooks'
import { TradeToken } from '../hooks/Tokens.types'

export enum ApprovalState {
  UNKNOWN = 'UNKNOWN',
  NOT_APPROVED = 'NOT_APPROVED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED'
}

export function useApproveProxyCallback(amountToApprove?: CurrencyAmount, spender?: string) {
  return useApproveCallback(amountToApprove, spender)
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  amountToApprove?: CurrencyAmount,
  spender?: string,
  strictAmount = false
): [ApprovalState, () => Promise<void>] {
  const { account } = useActiveWeb3React()
  const token = amountToApprove instanceof TokenAmount ? (amountToApprove.token as TradeToken) : undefined

  const currentAllowance = useTokenAllowance(token, account ?? undefined, spender)
  const hasApproval = useHasApproval(token?.address, spender)
  const pendingApproval = useHasPendingApproval(token?.address, spender)

  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    if (amountToApprove.currency === Currency.getBaseCurrency()) return ApprovalState.APPROVED
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN

    const pendingApprovalState = pendingApproval ? ApprovalState.PENDING : ApprovalState.APPROVED
    const approvalState = hasApproval ? pendingApprovalState : ApprovalState.NOT_APPROVED

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lessThan(amountToApprove) ? approvalState : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, hasApproval, pendingApproval, spender])

  const tokenContract = useTokenContract(token?.address)
  const addTransaction = useTransactionAdder()

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily')
      return
    }
    if (!token) {
      console.error('no token')
      return
    }

    if (!tokenContract) {
      console.error('tokenContract is null')
      return
    }

    if (!amountToApprove) {
      console.error('missing amount to approve')
      return
    }

    if (!spender) {
      console.error('no spender')
      return
    }

    const estimatedGas = await tokenContract.estimateGas.approve(
      spender,
      strictAmount ? amountToApprove.raw.toString() : MaxUint256
    )

    return tokenContract
      .approve(spender, strictAmount ? amountToApprove.raw.toString() : MaxUint256, {
        gasLimit: calculateGasMargin(estimatedGas)
      })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: 'Approved ' + amountToApprove.currency.symbol,
          approval: { tokenAddress: token.address, spender: spender }
        })
      })
      .catch((error: Error) => {
        console.debug('Failed to approve token', error)
        throw error
      })
  }, [approvalState, token, tokenContract, amountToApprove, spender, strictAmount, addTransaction])

  return [approvalState, approve]
}

// wraps useApproveCallback in the context of a swap
export function useApproveCallbackFromTrade(trade?: Trade, allowedSlippage = 0) {
  const { chainId } = useActiveWeb3React()

  const isWrapped = (currency?: Currency): currency is WrappedTokenInfo => !!currency && 'tokenInfo' in currency

  const amountToApprove = useMemo(() => {
    const currencyIn = wrappedCurrency(trade?.inputAmount.currency, trade?.route.chainId)
    const currencyOut = wrappedCurrency(trade?.outputAmount.currency, trade?.route.chainId)

    if (trade && isWrapped(currencyIn) && isWrapped(currencyOut)) {
      if (currencyIn.tokenInfo.lendable) {
        return computeSlippageAdjustedAmounts(trade, allowedSlippage)[Field.INPUT]
      } else {
        const slippage = computeSlippageAdjustedAmounts(trade, allowedSlippage)[Field.INPUT]!
        const proxyAddress = currencyIn.getTokenAddress
        return new TokenAmount(
          new Token(currencyIn.chainId, proxyAddress, currencyIn.decimals, currencyIn.symbol, currencyIn.name),
          slippage.raw
        )
      }
    } else {
      return trade ? computeSlippageAdjustedAmounts(trade, allowedSlippage)[Field.INPUT] : undefined
    }
  }, [trade, allowedSlippage])
  const tradeIsV1 = getTradeVersion(trade) === Version.v1
  const v1ExchangeAddress = useV1TradeExchangeAddress(trade)
  return useApproveCallback(amountToApprove, tradeIsV1 ? v1ExchangeAddress : ROUTER_ADDRESS[chainId!])
}
