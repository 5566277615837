import { createReducer } from '@reduxjs/toolkit'
import {
  StakeState,
  setLockTime,
  setToken,
  setUnstakeToken,
  setTypeInput,
  setDirection,
  resetStakeState,
  StakeDirection
} from './actions'

const initialState: StakeState = {
  tokenAddress: '',
  unstakeTokenAddress: '',
  typedValue: '',
  period: 14,
  direction: StakeDirection.STAKE
}

export default createReducer<StakeState>(initialState, builder =>
  builder
    .addCase(setLockTime, (state, { payload }) => {
      return {
        ...state,
        period: payload.locktime
      }
    })
    .addCase(setToken, (state, { payload }) => {
      return {
        ...state,
        tokenAddress: payload.tokenAddress,
        typedValue: ''
      }
    })
    .addCase(setUnstakeToken, (state, { payload }) => {
      return {
        ...state,
        unstakeTokenAddress: payload.tokenAddress,
        typedValue: ''
      }
    })
    .addCase(setTypeInput, (state, { payload }) => {
      return {
        ...state,
        typedValue: payload.amount
      }
    })
    .addCase(setDirection, (state, { payload }) => {
      return {
        ...state,
        direction: payload.direction
      }
    })
    .addCase(resetStakeState, () => {
      return {
        ...initialState
      }
    })
)
