import { Credentials, StringTranslations } from '@crowdin/crowdin-api-client'

import React, { lazy, Suspense, useEffect, useState } from 'react'
import { isDesktop } from 'react-device-detect'
import TagManager from 'react-gtm-module'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import { WOWSWAP_ROUTE } from '../constants/routes/routes'

import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
import Header from '../components/Header'
import Popups from '../components/Popups'
import { TitleBar } from '../components/TitleBar'
import Web3ReactManager from '../components/Web3ReactManager'
import { allLanguages, EN } from '../constants/localisation/languageCodes'
import { useActiveWeb3React } from '../hooks'
import { LanguageContext } from '../hooks/LanguageContext'
import { TranslationsContext } from '../hooks/TranslationsContext'
import { useRouterGuards } from '../hooks/useRouterGuards'
import { useListenerFirebasePushNotifications } from '../hooks/usePushNotifications'

import Earn from './Earn/Earn'
import GovernanceMenu from './Governance/GovernanceMunu'
import GovernanceDonations from './GovernanceDonations'
import { GovernanceEconomicModel } from './GovernanceEconomicModel'
import { GovernanceFinancialModel } from './GovernanceFinancialModel'
import GovernanceProposals from './GovernanceProposals'
import GovernanceProposalsItem from './GovernanceProposalsItem'
import GovernanceStats from './GovernanceStats'
import { GovernanceYourWallet } from './GovernanceYourWallet/GovernanceYourWallet'
import Liquidation from './Liquidation'
import Portfolio from './Portfolio'
import { TradePage } from './Trade'
const Landing = lazy(() => import('./Landing'))
const Alerts = lazy(() => import('./Alerts'))
const XDegen = lazy(() => import('./XDegen'))

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  min-height: 100vh;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    overflow-x: hidden;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
`
const BodyWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  position: relative;
`
TagManager.initialize({
  gtmId: 'GTM-52WTN2S'
})

export default function App() {
  useListenerFirebasePushNotifications()

  const { chainId, account } = useActiveWeb3React()

  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])
  const apiKey = `${process.env.REACT_APP_CROWDIN_APIKEY}`
  const projectId = parseInt(`${process.env.REACT_APP_CROWDIN_PROJECTID}`)
  const fileId = 6

  const credentials: Credentials = {
    token: apiKey
  }

  const stringTranslationsApi = new StringTranslations(credentials)

  const getStoredLang = (storedLangCode: string) => {
    return allLanguages.filter(language => {
      return language.code === storedLangCode
    })[0]
  }

  useEffect(() => {
    const storedLangCode = localStorage.getItem('pancakeSwapLanguage')
    if (storedLangCode) {
      const storedLang = getStoredLang(storedLangCode)
      setSelectedLanguage(storedLang)
    } else {
      setSelectedLanguage(EN)
    }
  }, [])

  const fetchTranslationsForSelectedLanguage = async () => {
    stringTranslationsApi
      .listLanguageTranslations(projectId, selectedLanguage.code, undefined, fileId, 200)
      .then(translationApiResponse => {
        if (translationApiResponse.data.length < 1) {
          setTranslations(['error'])
        } else {
          setTranslations(translationApiResponse.data)
        }
      })
      .then(() => setTranslatedLanguage(selectedLanguage))
      .catch(error => console.error(error))
  }

  useEffect(() => {
    if (selectedLanguage) {
      fetchTranslationsForSelectedLanguage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage])

  const { isCanOpenGov, isCanOpenPortfolio } = useRouterGuards(chainId, account)

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <Route component={GoogleAnalyticsReporter} />
        <AppWrapper>
          <LanguageContext.Provider
            value={{ selectedLanguage, setSelectedLanguage, translatedLanguage, setTranslatedLanguage }}
          >
            <TranslationsContext.Provider value={{ translations, setTranslations }}>
              {isDesktop && <TitleBar />}
              <HeaderWrapper>
                <Header />
              </HeaderWrapper>
              <BodyWrapper>
                <Popups />
                <Web3ReactManager>
                  <Switch>
                    <Route exact strict key="landing" path={WOWSWAP_ROUTE.LANDING} component={Landing} />
                    <Route exact strict key="xdegen" path={WOWSWAP_ROUTE.XDEGEN} component={XDegen} />
                    <Route exact strict key="swap" path={WOWSWAP_ROUTE.TRADE} component={TradePage} />
                    {account && <Route exact strict key="alerts" path={WOWSWAP_ROUTE.ALERTS} component={Alerts} />}
                    <Route exact strict key="earn" path={WOWSWAP_ROUTE.EARN} component={Earn} />
                    {isCanOpenPortfolio && (
                      <Route exact strict key="portfolio" path={WOWSWAP_ROUTE.PORTFOLIO} component={Portfolio} />
                    )}
                    <Route exact strict key="liquidation" path={WOWSWAP_ROUTE.LIQUIDATION} component={Liquidation} />
                    <Route
                      exact
                      strict
                      key="governance-menu"
                      path={WOWSWAP_ROUTE.GOVERNANCE_MENU}
                      component={GovernanceMenu}
                    />
                    {isCanOpenGov && (
                      <Route
                        exact
                        strict
                        key="governance-your-wallet"
                        path={WOWSWAP_ROUTE.GOVERNANCE_YOUR_WALLET}
                        component={GovernanceYourWallet}
                      />
                    )}
                    {isCanOpenGov && (
                      <Route
                        exact
                        strict
                        key="governance-proposals"
                        path={WOWSWAP_ROUTE.GOVERNANCE_PROPOSALS}
                        component={GovernanceProposals}
                      />
                    )}
                    {isCanOpenGov && (
                      <Route
                        exact
                        strict
                        key="governance-proposals-item"
                        path={WOWSWAP_ROUTE.GOVERNANCE_PROPOSALS_ITEM}
                        component={GovernanceProposalsItem}
                      />
                    )}
                    {isCanOpenGov && (
                      <Route
                        exact
                        strict
                        key="governance-financial-model"
                        path={WOWSWAP_ROUTE.GOVERNANCE_FINANCIAL_MODEL}
                        component={GovernanceFinancialModel}
                      />
                    )}
                    {isCanOpenGov && (
                      <Route
                        exact
                        strict
                        key="governance-economic-model"
                        path={WOWSWAP_ROUTE.GOVERNANCE_ECONOMIC_MODEL}
                        component={GovernanceEconomicModel}
                      />
                    )}
                    {isCanOpenGov && (
                      <Route
                        exact
                        strict
                        key="governance-donations"
                        path={WOWSWAP_ROUTE.GOVERNANCE_DONATIONS}
                        component={GovernanceDonations}
                      />
                    )}
                    {isCanOpenGov && (
                      <Route
                        exact
                        strict
                        key="governance-stats"
                        path={WOWSWAP_ROUTE.GOVERNANCE_STATS}
                        component={GovernanceStats}
                      />
                    )}
                    <Redirect to="/" />
                  </Switch>
                </Web3ReactManager>
              </BodyWrapper>
            </TranslationsContext.Provider>
          </LanguageContext.Provider>
        </AppWrapper>
      </BrowserRouter>
    </Suspense>
  )
}
