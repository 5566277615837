import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { darken } from 'polished'
import React, { useMemo } from 'react'
import { Activity } from 'react-feather'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { NetworkContextName } from '../../constants'
import useENSName from '../../hooks/useENSName'
import { useWalletModalToggle } from '../../state/application/hooks'
import { isTransactionRecent, useAllTransactions } from '../../state/transactions/hooks'
import { TransactionDetails } from '../../state/transactions/reducer'
import { shortenAddress } from '../../utils'
import { ButtonSecondary } from '../Button'
import Loader from '../Loader'
import { RowBetween } from '../Row'
import WalletModal from '../WalletModal'

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 12px 28px;
  height: 48px;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  :focus {
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
padding-left: 25px;
padding-right: 25px;
`};

  ${({ theme }) => theme.mediaWidth.upToSmall`
height: 38px;
padding: 0 15px;
font-size: 13px;
`};
`
const Web3StatusError = styled(Web3StatusGeneric)`
  padding: 12px 20px;

  background-color: #f33e21;
  border: 3px solid ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  :hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.primary4};
  }

  & > svg {
    width: 24px;
    height: 24px;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
padding: 0 16px;

		                  & > svg {
			                  width: 18px;
			                  height: 18px;
		                  }
`};
`

const Web3StatusConnect = styled(Web3StatusGeneric)<{ faded?: boolean }>`
  background-color: ${({ theme }) => theme.colors.primary4};
  border: none;
  color: ${({ theme }) => theme.colors.primaryText1};
  font-weight: normal;
  font-family: 'Retro', sans-serif;
  font-size: 13px;
  line-height: 140%;

  :hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.primary1};
    border: 2px solid ${({ theme }) => darken(0.05, theme.colors.primary4)};
    color: ${({ theme }) => theme.colors.primaryText1};
  }
  :focus {
    background-color: ${({ theme }) => theme.colors.primary4};
    outline: none;
    box-shadow: none;
  }

  ${({ faded }) =>
    faded &&
    css`
      background-color: ${({ theme }) => theme.colors.primary4};
      border: 3px solid ${({ theme }) => theme.colors.black};
      color: ${({ theme }) => theme.colors.primaryText1};

      :hover,
      :focus {
        border: 3px solid ${({ theme }) => darken(0.05, theme.colors.black)};
        color: ${({ theme }) => darken(0.05, theme.colors.primaryText1)};
      }
    `}
`

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  background-color: ${({ theme }) => theme.colors.primary5};
  border: 3px solid ${({ theme }) => theme.colors.black};
  color: ${({ pending, theme }) => (pending ? theme.colors.black : theme.colors.white)};
  font-weight: normal;
  border-radius: 10px;
  height: 48px;

  :hover,
  :focus {
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.primary5};
    border: 3px solid ${({ theme }) => theme.colors.black};
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
border: 2px solid ${({ theme }) => theme.colors.black};
                                                                           :hover,
                                                                           :focus {
                                                                             border: 2px solid ${({ theme }) =>
                                                                               theme.colors.black};
                                                                           }

`};
`

const Text = styled.p`
  font-family: 'Retro', sans-serif;
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // margin: 0 0.5rem 0 0.25rem;
  margin: 0;
  font-size: 13px;
  width: fit-content;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.buttonText};
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function Web3StatusInner() {
  const { t } = useTranslation()
  const { account, error } = useWeb3React()

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)

  const hasPendingTransactions = !!pending.length
  const toggleWalletModal = useWalletModalToggle()

  const handleConnectWallet = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'ClickConnect'
      }
    })
    toggleWalletModal()
  }

  if (account) {
    const address = shortenAddress(account)
    const isDesktop = window.innerWidth > 1080
    return (
      <Web3StatusConnected id="web3-status-connected" onClick={toggleWalletModal} pending={hasPendingTransactions}>
        {hasPendingTransactions ? (
          <RowBetween>
            <Text>{pending?.length} Pending</Text> <Loader />
          </RowBetween>
        ) : (
          <>{ENSName || isDesktop ? address : `...${address.split('...')[1]}`}</>
        )}
      </Web3StatusConnected>
    )
  } else if (error) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>{error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error'}</Text>
      </Web3StatusError>
    )
  } else {
    return (
      <Web3StatusConnect id="connect-wallet" onClick={handleConnectWallet} faded={!account}>
        <Text>{t('Connect wallet')}</Text>
      </Web3StatusConnect>
    )
  }
}

export default function Web3Status() {
  const { active, account } = useWeb3React()
  const contextNetwork = useWeb3React(NetworkContextName)

  const { ENSName } = useENSName(account ?? undefined)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter(tx => !tx.receipt).map(tx => tx.hash)
  const confirmed = sortedRecentTransactions.filter(tx => tx.receipt).map(tx => tx.hash)

  if (!contextNetwork.active && !active) {
    return null
  }

  return (
    <>
      <Web3StatusInner />
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}
