import light from './light'

export default {
  ...light,
  colors: {
    ...light.colors,

    bg2: '#E84142',

    // gradient
    gradient: `linear-gradient(90deg, #FF6262 0%, #E84141 100%)`
  }
}
