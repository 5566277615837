import React, { useContext } from 'react'
import { Trade, TradeType } from '@wowswap-io/wowswap-sdk'
import { ThemeContext } from 'styled-components'
import { Field } from '../../../state/swap/actions'
import { Position } from '../../../state/trade/types'

import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../../utils/prices'

import { AutoColumn } from '../../Column'
import QuestionHelper from '../../QuestionHelper'
import { RowBetween, RowFixed } from '../../Row'
import { TextBase } from '../../Text'
import FormattedPriceImpact from '../FormattedPriceImpact'

import { useChainLabel } from '../../../hooks'

interface ILongTradeDetails {
  trade: Trade
  allowedSlippage: number
  isOpenPosition: boolean
  position?: Position
}

export const LongTradeDetails: React.FC<ILongTradeDetails> = props => {
  const { trade, allowedSlippage, isOpenPosition, position } = props

  const theme = useContext(ThemeContext)
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)

  const chainLabel = useChainLabel()
  const label =
    chainLabel === 'QuickSwap'
      ? `A portion of each trade (0.30%) goes to QuickSwap liquidity providers as a protocol incentive.`
      : `For each trade a 0.2% fee is paid. 0.17% goes to liquidity providers to PancakeSwap treasury.`

  return (
    <>
      <AutoColumn style={{ padding: '0 20px' }} gap="8px">
        <RowBetween>
          <RowFixed>
            <TextBase color={theme.colors.text4}>{isExactIn ? 'Minimum received' : 'Maximum sold'}</TextBase>
            {/* #JUNK <QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." /> */}
          </RowFixed>
          <RowFixed>
            <TextBase>
              {isExactIn
                ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${trade.outputAmount.currency.symbol}` ??
                  '-'
                : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${trade.inputAmount.currency.symbol}` ??
                  '-'}
            </TextBase>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TextBase color={theme.colors.text4}>Price Impact</TextBase>
            {/* #JUNK <QuestionHelper text="The difference between the market price and estimated price due to trade size." /> */}
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <TextBase color={theme.colors.text4}>Liquidity Provider Fee</TextBase>
            <QuestionHelper text={label} />
          </RowFixed>
          <TextBase>
            {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${trade.inputAmount.currency.symbol}` : '-'}
          </TextBase>
        </RowBetween>

        {isOpenPosition && (
          <RowBetween>
            <RowFixed>
              <TextBase color={theme.colors.text4}>You borrow for this swap</TextBase>
            </RowFixed>
            <TextBase>
              {position?.borrowAmount?.toSignificant(6)} {trade.inputAmount.currency.symbol}
            </TextBase>
          </RowBetween>
        )}

        {isOpenPosition && (
          <RowBetween>
            <RowFixed>
              <TextBase color={theme.colors.text4}>HIR (Hourly Interest Rate)</TextBase>
            </RowFixed>
            <TextBase>{position?.HIR} %</TextBase>
          </RowBetween>
        )}
      </AutoColumn>
    </>
  )
}
