import React, { useCallback, useState } from 'react'

import Tooltip from '../Tooltip'

import { QuestionBaseIcon, QuestionWrapper } from './styleds'

export default function QuestionHelper({ text, backgroundColor }: { text: string; backgroundColor?: string }) {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  const QuestionIcon = QuestionBaseIcon

  return (
    <span style={{ marginLeft: 7 }}>
      <Tooltip text={text} show={show}>
        <QuestionWrapper onClick={open} onMouseEnter={open} onMouseLeave={close} backgroundColor={backgroundColor}>
          <QuestionIcon />
        </QuestionWrapper>
      </Tooltip>
    </span>
  )
}
