const BASE_NOTIFICATION_URL = process.env.REACT_APP_NOTIFICATION_URL ?? ''

const NOTIFICATION_URLS = {
  USER: 'user',
  EMAIL_SUBSCRIBE: 'user/subscribe/email',
  PUSH_SUBSCRIBE: 'user/subscribe/push',
  UNSUBSCRIBE: 'user/unsubscribe',
  TEST: '/notifications/test'
}

const buildUrl = (path = '', params: any): URL => {
  const url = new URL(path, BASE_NOTIFICATION_URL)
  url.search = new URLSearchParams(params).toString()

  return url
}

const HTTP = {
  get: (url: string): Promise<Response> => {
    return fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      mode: 'cors'
    })
  },

  post: (url: string, data: any): Promise<Response> => {
    return fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify(data)
    })
  }
}

type UpdateUserNotifications =
  | { chainIds: string[] }
  | { profitValues: string[]; chainIds: string[] }
  | { positionHealthValues: string[]; chainIds: string[] }
  | { liquidationEnabled: boolean; chainIds: string[] }
  | { apyValues: string[]; chainIds: string[] }
  | { telegramEnabled: boolean; chainIds: string[] }
  | { pushEnabled: boolean; chainIds: string[] }
  | { emailEnabled: boolean; chainIds: string[] }

class NotificationsHttpService {
  fetchUserNotifications(wallet: string): Promise<Response> {
    const url = buildUrl(NOTIFICATION_URLS.USER, { address: wallet }).toString()

    return HTTP.get(url)
  }

  updateUserNotifications(wallet: string, data: UpdateUserNotifications): Promise<Response> {
    const url = buildUrl(NOTIFICATION_URLS.USER, { address: wallet }).toString()

    return HTTP.post(url, data)
  }

  subscribeEmailNotifications(wallet: string, data: { email: string; redirectUrl: string }): Promise<Response> {
    const url = buildUrl(NOTIFICATION_URLS.EMAIL_SUBSCRIBE, { address: wallet }).toString()

    return HTTP.post(url, data)
  }

  subscribePushNotifications(wallet: string, data: { name: string; token: string }): Promise<Response> {
    const url = buildUrl(NOTIFICATION_URLS.PUSH_SUBSCRIBE, { address: wallet }).toString()

    return HTTP.post(url, data)
  }

  unsubscribeNotifications(wallet: string, data: { name: string; type: string }): Promise<Response> {
    const url = buildUrl(NOTIFICATION_URLS.UNSUBSCRIBE, { address: wallet }).toString()

    return HTTP.post(url, data)
  }
}

export default new NotificationsHttpService()
