import { BigNumber } from '@ethersproject/bignumber'
import * as bignumber from 'bignumber.js'
import { TransactionResponse } from '@ethersproject/providers'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CurrencyAmount, TokenAmount } from '@wowswap-io/wowswap-sdk'
import { ROUTER_ADDRESS, TREASURER_ADDRESS } from '../../constants'
import { useActiveWeb3React } from '../../hooks'
import { TradeToken } from '../../hooks/Tokens.types'
import { useStakeTokensWithBalances } from '../../hooks/tokens/Tokens'
import { ApprovalState } from '../../hooks/useApproveCallback'
import { useSingleApproveCallback } from '../../hooks/useApproveSingleCallback'
import { useRouterContract, useStakeTokenContract, useTreasurerContract } from '../../hooks/useContract'
import useDebounce from '../../hooks/useDebounce'
import { calculateGasMargin } from '../../utils'
import { calcStakingAPY, calcReferenceAPY } from '../../utils/calculations'
import { amount as bnAmount, bn } from '../../utils/math'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { AppDispatch, AppState } from '../index'
import { useSingleCallResult } from '../multicall/hooks'
import { useTransactionAdder } from '../transactions/hooks'
import {
  DepositStatus,
  setDirection,
  setLockTime,
  setToken,
  setTypeInput,
  setUnstakeToken,
  StakeDirection
} from './actions'

function useStakeState(): AppState['stake'] {
  return useSelector<AppState, AppState['stake']>(state => state.stake)
}
export interface StakeBalance {
  token: TradeToken
  amount: TokenAmount
  minted: string
  timeout: string
}

export function useDerivedStakeInfo(): {
  xWOW?: TradeToken
  lpToken?: TradeToken
  WOW?: TradeToken
  input?: TradeToken
  output?: TradeToken
  unstakeToken?: TradeToken
  maxSpend?: CurrencyAmount
  typedValue: string
  amount?: CurrencyAmount
  amountOut?: TokenAmount
  amountUnstake?: TokenAmount
  direction: StakeDirection
  possibleInputs: TradeToken[]
  possibleOutputs: TradeToken[]
  period: number
  amountXwowTotalSupply?: TokenAmount
  effectiveWOWFarmingSpeed?: string
  APY: string
  stableAPY: string
} {
  const { typedValue, period, tokenAddress, unstakeTokenAddress, direction } = useStakeState()
  const tokens = useStakeTokensWithBalances()
  const effectiveWOWFarmingSpeed = useEffectiveWowSpeed()

  const xWOW = useMemo(() => tokens.find(token => token.stakableInfo?.isBase), [tokens])
  const possibleTokens = useMemo(() => tokens.filter(token => token !== xWOW), [tokens, xWOW])
  const lpToken = useMemo(() => possibleTokens.find(token => token.stakableInfo?.pairName), [possibleTokens])
  const WOW = useMemo(() => possibleTokens.find(token => token !== lpToken), [possibleTokens, lpToken])

  const input = tokenAddress ? tokens.find(token => token.address === tokenAddress) : undefined
  const output = xWOW
  const possibleInputs: TradeToken[] = possibleTokens
  const possibleOutputs: TradeToken[] = []
  const unstakeToken = unstakeTokenAddress ? tokens.find(token => token.address === unstakeTokenAddress) : undefined
  const maxSpend = maxAmountSpend(input?.balance)

  const debouncedTypedValue = useDebounce(typedValue, 300)
  const parsedAmount = debouncedTypedValue && input && bnAmount(debouncedTypedValue, input.decimals).str()
  const amount = parsedAmount && input ? new TokenAmount(input!, parsedAmount) : undefined

  const isAlreadySteaked = input?.stakableInfo?.staked?.amount.greaterThan('0')
  const amountWithStakedBn = bn(amount?.raw.toString() || 0).plus(
    input?.stakableInfo?.staked?.amount.raw.toString() || 0
  )
  const amountWithStaked = parsedAmount && input ? new TokenAmount(input!, amountWithStakedBn.toString(10)) : undefined

  const amountDeposit = useCalcAmount({ token: xWOW!, lpToken: input!, amount, period })
  const amountOutWithoutStaked = output && amountDeposit ? new TokenAmount(output, amountDeposit) : undefined
  const amountUnstake =
    xWOW && unstakeToken && direction === StakeDirection.UNSTAKE
      ? unstakeToken?.stakableInfo?.staked?.minted
      : undefined

  const amountDepositWithStaked = useCalcAmount({ token: xWOW!, lpToken: input!, amount: amountWithStaked, period })
  const amountOutTotalBn =
    isAlreadySteaked && amountDepositWithStaked
      ? bn(amountDepositWithStaked).minus(input?.stakableInfo?.staked?.minted.raw.toString() || 0)
      : bn(0)
  const amountOutTotal =
    output && amountOutTotalBn && amountOutTotalBn.gt(0)
      ? new TokenAmount(output, amountOutTotalBn.toString(10))
      : undefined

  const amountOut = isAlreadySteaked ? amountOutTotal : amountOutWithoutStaked

  const totalSupplyRaw = useTotalSupply(xWOW)
  const amountXwowTotalSupply = useMemo(
    () => (xWOW && totalSupplyRaw ? new TokenAmount(xWOW, totalSupplyRaw) : undefined),
    [xWOW, totalSupplyRaw]
  )

  const valueWOW = WOW?.stakableInfo?.staked?.amount
  const valueLP = useConvertToWow(xWOW, lpToken?.stakableInfo?.staked?.amount)

  const inWOW = bn(amount?.raw.toString() || '0')
  const inLP = bn(useConvertToWow(xWOW, amount) || '0')

  const valueCurrent = amount && input && WOW ? (WOW.equals(input) ? bn(inWOW) : bn(inLP)) : bn(0)
  const totalWowValue = bn(valueWOW?.raw.toString() || '0')
    .plus(valueLP || '0')
    .plus(valueCurrent || '0')

  const yourXWOW = amountOut ? bn(xWOW?.balance?.raw.toString() || 0).plus(amountOut?.raw.toString() || 0) : bn(0)
  const totalXWOW = bn(totalSupplyRaw || 0).plus(amountOut?.raw.toString() || 0)

  const stableTotalWowValue = bn(valueWOW?.raw.toString() || 0).plus(valueLP || 0)
  const stableYourXWOW = bn(xWOW?.balance?.raw.toString() || 0)
  const stableTotalXWOW = bn(totalSupplyRaw || 0)

  const APY = amount ? calcStakingAPY({ period, effectiveWOWFarmingSpeed, totalWowValue, yourXWOW, totalXWOW }) : 0
  const stableAPY = calcStakingAPY({
    period,
    effectiveWOWFarmingSpeed,
    totalWowValue: stableTotalWowValue,
    yourXWOW: stableYourXWOW,
    totalXWOW: stableTotalXWOW
  })

  return {
    xWOW,
    lpToken,
    WOW,
    input,
    output,
    unstakeToken,
    maxSpend,
    typedValue,
    amount,
    amountOut,
    amountUnstake,
    possibleInputs,
    possibleOutputs,
    direction,
    period,
    amountXwowTotalSupply,
    effectiveWOWFarmingSpeed,
    APY: APY.toFixed(0),
    stableAPY: stableAPY.toFixed(0)
  }
}

export function useStakeActionHandlers(): {
  onChangeLocktime: (locktime: number) => void
  onChangeDirection: (direction: StakeDirection) => void
  onTokenChange: (token: string) => void
  onUnstakeTokenChange: (token: string) => void
  onValueInput: (amount: string) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onTokenChange = useCallback(
    (tokenAddress: string) => {
      dispatch(setToken({ tokenAddress }))
    },
    [dispatch]
  )

  const onUnstakeTokenChange = useCallback(
    (tokenAddress: string) => {
      dispatch(setUnstakeToken({ tokenAddress }))
    },
    [dispatch]
  )

  const onValueInput = useCallback(
    (amount: string) => {
      dispatch(setTypeInput({ amount }))
    },
    [dispatch]
  )

  const onChangeLocktime = useCallback(
    (locktime: number) => {
      dispatch(setLockTime({ locktime }))
    },
    [dispatch]
  )

  const onChangeDirection = useCallback(
    (direction: StakeDirection) => {
      dispatch(setDirection({ direction }))
    },
    [dispatch]
  )

  return {
    onChangeLocktime,
    onChangeDirection,
    onTokenChange,
    onUnstakeTokenChange,
    onValueInput
  }
}

export function useRefenceApy(): { [address: string]: { amountXwow?: string; value?: string } } {
  const { xWOW, lpToken, WOW } = useDerivedStakeInfo()

  const wowAmount = new TokenAmount(WOW!, '1')
  const amountXWowFromWow = useCalcAmount({
    token: xWOW!,
    lpToken: WOW,
    amount: wowAmount,
    period: 14
  })
  const valueWOW = useConvertToWow(xWOW, wowAmount)

  const lpAmount = new TokenAmount(lpToken!, '1')
  const amountXWowFromLP = useCalcAmount({
    token: xWOW!,
    lpToken: lpToken,
    amount: lpAmount,
    period: 14
  })
  const valueLP = useConvertToWow(xWOW, lpAmount)

  return {
    [WOW?.address!]: {
      amountXwow: amountXWowFromWow,
      value: valueWOW
    },
    [lpToken?.address!]: {
      amountXwow: amountXWowFromLP,
      value: valueLP
    }
  }
}

export enum ReferenceAPYType {
  apy14 = 'apy14',
  apy183 = 'apy183',
  apy365 = 'apy365',
  apy458 = 'apy458',
  apy730 = 'apy730'
}

interface ReferenceAPY {
  period: number
  label: string
  value: bignumber.BigNumber
}

export function useCalcReferenceAPY({
  apyRef,
  effectiveWOWFarmingSpeed,
  totalXWOW
}: {
  apyRef?: { amountXwow?: string; value?: string }
  effectiveWOWFarmingSpeed?: string
  totalXWOW: bignumber.BigNumber
}): {
  [key in ReferenceAPYType]: ReferenceAPY
} {
  return useMemo(() => {
    const apy14 = calcReferenceAPY({
      period: 14,
      effectiveWOWFarmingSpeed,
      yourXWOW: apyRef?.amountXwow,
      totalXWOW,
      yourWOW: apyRef?.value
    })
    const apy183 = calcReferenceAPY({
      period: 183,
      effectiveWOWFarmingSpeed,
      yourXWOW: apyRef?.amountXwow,
      totalXWOW,
      yourWOW: apyRef?.value
    })
    const apy365 = calcReferenceAPY({
      period: 365,
      effectiveWOWFarmingSpeed,
      yourXWOW: apyRef?.amountXwow,
      totalXWOW,
      yourWOW: apyRef?.value
    })
    const apy458 = calcReferenceAPY({
      period: 458,
      effectiveWOWFarmingSpeed,
      yourXWOW: apyRef?.amountXwow,
      totalXWOW,
      yourWOW: apyRef?.value
    })
    const apy730 = calcReferenceAPY({
      period: 730,
      effectiveWOWFarmingSpeed,
      yourXWOW: apyRef?.amountXwow,
      totalXWOW,
      yourWOW: apyRef?.value
    })

    return {
      apy14: {
        period: 14,
        label: 'Days Lock APY',
        value: apy14
      },
      apy183: {
        period: 182,
        label: 'Days Lock APY',
        value: apy183
      },
      apy365: {
        period: 365,
        label: 'Days Lock APY',
        value: apy365
      },
      apy458: {
        period: 458,
        label: 'Days Lock APY',
        value: apy458
      },
      apy730: {
        period: 730,
        label: 'Days Lock APY',
        value: apy730
      }
    }
  }, [totalXWOW, effectiveWOWFarmingSpeed, apyRef])
}

export function useCalcClaim(): undefined | Record<string, BigNumber> {
  const { account, chainId } = useActiveWeb3React()
  const contract = useTreasurerContract(TREASURER_ADDRESS[chainId!])

  const response = useSingleCallResult(contract, 'calcClaimAll', [account!])

  return useMemo(() => {
    if (response.loading || !response.result) return undefined
    return response.result['amounts']
      .map((amount: BigNumber, idx: number) => {
        return {
          amount,
          token: response.result!['tokens'][idx]
        }
      })
      .reduce((acc: any, item: { amount: BigNumber; token: string }) => {
        acc[item.token] = item.amount
        return acc
      }, {})
  }, [response])
}

function useEffectiveWowSpeed() {
  const { chainId } = useActiveWeb3React()
  const contract = useTreasurerContract(TREASURER_ADDRESS[chainId!])

  const response = useSingleCallResult(contract, 'effectiveWOWFarmingSpeed', [])

  return useMemo(() => {
    if (response.loading) return undefined
    return (response.result?.[0] as BigNumber)?.toString()
  }, [response])
}

export function useConvertToWow(xWOW?: TradeToken, amount?: TokenAmount) {
  const contract = useStakeTokenContract(xWOW?.address)

  const inputs = [amount?.token.address, amount?.raw.toString()]

  const response = useSingleCallResult(contract, 'convertToWOW', inputs)

  return useMemo(() => {
    if (response.loading) return undefined
    return (response.result?.[0] as BigNumber)?.toString()
  }, [response])
}

export function useCalcAmount({
  token,
  lpToken,
  amount,
  period
}: {
  token: TradeToken
  lpToken?: TradeToken
  amount?: TokenAmount
  period: number
}) {
  const contract = useStakeTokenContract(token?.address)
  const inputs = [lpToken?.address, amount?.raw.toString(), period]
  const response = useSingleCallResult(contract, 'calcAmountOut', inputs)

  return useMemo(() => {
    if (response.loading || !response.result) return undefined
    return (response.result?.[0] as BigNumber)?.toString()
  }, [response])
}

function useTotalSupply(token?: TradeToken) {
  const contract = useStakeTokenContract(token?.address)

  const response = useSingleCallResult(contract, 'totalSupply', [])

  return useMemo(() => {
    if (response.loading || !response.result) return null
    return (response.result?.[0] as BigNumber)?.toString()
  }, [response])
}

export function useStake({
  token,
  lpToken,
  amount,
  period
}: {
  token: TradeToken
  lpToken: TradeToken
  amount: string
  period: number
}): { status: DepositStatus; callback: null | (() => Promise<any>) } {
  const data = useDerivedStakeInfo()
  const { account } = useActiveWeb3React()
  const contract = useRouterContract()
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!account || !contract || !token || !lpToken || !amount || !period) {
      return { callback: null, status: DepositStatus.INVALID }
    }

    const inputs: any[] = [lpToken?.address, amount, period, account]

    const methodName = 'stake'
    const options: any = {
      from: account
    }

    return {
      status: DepositStatus.VALID,
      callback: async () => {
        const gasEstimate = await contract.estimateGas[methodName](...inputs, options)

        return contract[methodName](...inputs, {
          ...options,
          gasLimit: calculateGasMargin(gasEstimate)
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Staked ${data.amount!.toSignificant(4)} ${data.amount!.currency.symbol}`
          })
        })
      }
    }
  }, [data, account, contract, addTransaction, token, amount, lpToken, period])
}

export function useUnstake(): {
  status: DepositStatus
  callback: null | (({ lpToken }: { lpToken: TradeToken }) => Promise<any>)
} {
  const data = useDerivedStakeInfo()
  const { account } = useActiveWeb3React()
  const contract = useRouterContract()
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!account || !contract) {
      return { callback: null, status: DepositStatus.INVALID }
    }

    return {
      status: DepositStatus.VALID,
      callback: async ({ lpToken }: { lpToken: TradeToken }) => {
        const inputs: any[] = [lpToken?.address, account]

        const methodName = 'unstake'
        const options: any = {
          from: account
        }
        const gasEstimate = await contract.estimateGas[methodName](...inputs, options)

        return contract[methodName](...inputs, {
          ...options,
          gasLimit: calculateGasMargin(gasEstimate)
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Untaked ${data.amountUnstake?.toSignificant(4)} ${data.amountUnstake?.currency.symbol}`
          })
        })
      }
    }
  }, [data, account, contract, addTransaction])
}

export function useClaimAll(): { status: DepositStatus; callback: null | (() => Promise<any>) } {
  const { account, chainId } = useActiveWeb3React()
  const contract = useTreasurerContract(TREASURER_ADDRESS[chainId!])
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!account || !contract) {
      return { callback: null, status: DepositStatus.INVALID }
    }

    const inputs: any[] = [account]

    const methodName = 'claimAll'
    const options: any = {
      from: account
    }

    return {
      status: DepositStatus.VALID,
      callback: async () => {
        const gasEstimate = await contract.estimateGas[methodName](...inputs, options)

        return contract[methodName](...inputs, {
          ...options,
          gasLimit: calculateGasMargin(gasEstimate)
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Claimed`
          })
        })
      }
    }
  }, [addTransaction, account, contract])
}

export function useStakeApprove() {
  const { chainId } = useActiveWeb3React()
  const { amount, amountUnstake, direction, xWOW, possibleInputs } = useDerivedStakeInfo()

  const totalMinted = possibleInputs?.reduce(
    (acc, token) => acc.plus(token.stakableInfo?.staked?.minted.raw.toString() || 0),
    bn(0)
  )
  const hasXWOW = totalMinted?.gt(0) || false

  const amountToApproveToken = direction === StakeDirection.STAKE ? amount : undefined
  const amountToApproveXwow =
    xWOW && direction === StakeDirection.STAKE
      ? new TokenAmount(xWOW!, totalMinted?.toString(10) || '0')
      : amountUnstake

  // check whether the user has approved the router on the input token
  const [approvalToken, approveCallbackToken] = useSingleApproveCallback(
    amountToApproveToken,
    chainId && ROUTER_ADDRESS[chainId]
  )

  const [approvalXwow, approveCallbackXwow] = useSingleApproveCallback(amountToApproveXwow, xWOW?.address, true)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  const isTokenToApprove =
    direction === StakeDirection.STAKE ? (approvalToken === ApprovalState.APPROVED && hasXWOW ? false : true) : false

  const approval = isTokenToApprove ? approvalToken : approvalXwow
  const approveCallback = isTokenToApprove ? approveCallbackToken : approveCallbackXwow
  const amountToApprove = isTokenToApprove ? amountToApproveToken : amountToApproveXwow

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const showApproveFlow =
    approval === ApprovalState.NOT_APPROVED ||
    approval === ApprovalState.PENDING ||
    (approvalSubmitted && approval === ApprovalState.APPROVED)

  return {
    approval,
    approveCallback,
    amountToApprove,
    approvalSubmitted,
    setApprovalSubmitted,
    showApproveFlow
  }
}
