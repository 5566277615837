import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { Text } from 'rebass'
import styled from 'styled-components'
import { OrderBy } from '../PortfolioTable'
import SortTable, { Order } from '../SortTable'
import EarnItem from './EarnItem'
import EarnItemLoader from './EarnItemLoader'
import { EarnTableSearchInput, EarnTableSearchWrapper, EmptySearchWrapper } from './styleds'

const EarnTableStyle = styled.div`
  padding: 20px;
  margin-bottom: 32px;

  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mediaWidth.upToMedium`
padding: 0;

font-size: 18px;
line-height: 23px;

border-radius: 0;
background: none;
`};
`

const EarnTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 14px;
  margin-bottom: 11px;

  & > div:nth-child(1) {
    flex-grow: 2;
  }
  & > div:nth-child(2) {
    width: 8.5%;
    flex: 0 0 8.5%;
  }
  & > div:nth-child(3) {
    width: 18.5%;
    flex: 0 0 18.5%;
  }
  & > div:nth-child(4) {
    width: 18.5%;
    flex: 0 0 18.5%;
  }
  & > div:nth-child(5) {
    width: 13.8%;
    flex: 0 0 13.8%;
  }
  & > div:nth-child(6) {
    width: 14.8%;
    flex: 0 0 14.8%;
  }
  & > div:nth-child(7) {
    width: 14.5%;
    flex: 0 0 14.5%;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
display: none;
`};
`

const EarnTableSortWraper = styled.div<{ flexRight?: boolean }>`
  ${({ flexRight }) => {
    if (flexRight) {
      return `
display: flex;
justify-content: flex-end;
`
    } else {
      return ``
    }
  }}
`

const EarnTableList = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
                    & > div {
                      margin-bottom: 10px;

                      :last-child {
                        margin-bottom: 0;
                      }
                    }
`};
`

type Earn = {
  key: string
  token: {
    icon: string
    title: string
    name: string
    address: string
    tradableAddress?: string
  }
  apy: string
  deposited: string
  borrowed: string
  utilization: string
  balance: string
  totalValue: string
}

type SearchValueGetter = (earn: Earn) => string | number

const percentStringToNumber = (value: string) => {
  return Number(value.replace(/^(-)|[^0-9.,]+/g, '$1')) // 0.25% -> 0.25
}

const sortValueGetters: { [key: string]: SearchValueGetter } = {
  Token: earn => earn.token.title,
  APY: earn => percentStringToNumber(earn.apy),
  Deposited: earn => earn.deposited.split(' ')[0],
  Borrowed: earn => earn.borrowed.split(' ')[0],
  Utilization: earn => percentStringToNumber(earn.utilization),
  Balance: earn => earn.balance.split(' ')[0],
  'Total Value': earn => earn.totalValue.split(' ')[0]
}

export function EarnTable({ data }: { data: Array<Earn> | null }) {
  const [searchValue, setSearchValue] = useState('')
  const [orderBy, setOrderBy] = useState<OrderBy>({ order: Order.DESC, field: 'APY' })

  const earnsSorted = useMemo(() => {
    const sortedEarns = !data
      ? null
      : data?.sort((prevEarn, nextEarn) => {
          const valueGetter = sortValueGetters[orderBy.field]
          if (!valueGetter) return 0

          const prevEarnValue = valueGetter(prevEarn)
          const nextEarnValue = valueGetter(nextEarn)

          if (prevEarnValue === nextEarnValue) {
            return 0
          } else {
            if (orderBy.order === Order.ASC) {
              return prevEarnValue > nextEarnValue ? 1 : -1
            } else {
              return prevEarnValue < nextEarnValue ? 1 : -1
            }
          }
        })

    return !searchValue.length || !sortedEarns
      ? sortedEarns
      : sortedEarns.filter(earn => {
          const searchValueLowerCased = searchValue.toLowerCase()
          return (
            earn.token.title.toLowerCase().includes(searchValueLowerCased) ||
            earn.token.name.toLowerCase().includes(searchValueLowerCased) ||
            earn.token.address.toLowerCase().includes(searchValueLowerCased) ||
            earn?.token?.tradableAddress?.toLowerCase().includes(searchValueLowerCased)
          )
        })
  }, [data, searchValue, orderBy.field, orderBy.order])

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }, [])

  return (
    <EarnTableStyle>
      <EarnTableSearchWrapper style={{ width: '334px' }}>
        <EarnTableSearchInput
          type="text"
          placeholder="Search name or paste address"
          value={searchValue}
          onChange={handleChange}
        />
      </EarnTableSearchWrapper>
      <EarnTableHeader>
        {['Token', 'APY', 'Deposited', 'Borrowed', 'Utilization', 'Balance', 'Total Value'].map(title => (
          <EarnTableSortWraper flexRight key={title}>
            <SortTable
              sort
              title={title}
              field={title}
              order={{
                orderBy: orderBy,
                method: () =>
                  setOrderBy({
                    order: title === orderBy.field ? (orderBy.order === Order.ASC ? Order.DESC : Order.ASC) : Order.ASC,
                    field: title
                  })
              }}
            />
          </EarnTableSortWraper>
        ))}
      </EarnTableHeader>

      <EarnTableList>
        {!earnsSorted ? (
          Array.from({ length: 2 }).map((el, idx) => (
            <EarnItemLoader bgColor={idx % 2 ? undefined : '#FDF4E1'} key={`EARN_STUB_${idx}`} />
          ))
        ) : earnsSorted.length === 0 ? (
          <EmptySearchWrapper>
            <Text fontSize={16} color="#E85A44">
              No results found.
            </Text>
          </EmptySearchWrapper>
        ) : (
          earnsSorted.map((earn, idx) => (
            <EarnItem bgColor={idx % 2 ? undefined : '#FDF4E1'} {...earn} key={`EARN_${earn.key}_${idx}`} />
          ))
        )}
      </EarnTableList>
    </EarnTableStyle>
  )
}
