import React from 'react'
import styled from 'styled-components'

const ListStatItemStyle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  :last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		
	`};
`

const ItemStatTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		font-size: 14px;
		line-height: 14px;
	`};
`

const ItemStatValue = styled(ItemStatTitle)`
  font-weight: 700;

  a {
    color: ${({ theme }) => theme.colors.text2};

    transition: all 0.4s ease;

    &:hover {
      color: ${({ theme }) => theme.colors.text3};
    }
    &:focus {
      color: ${({ theme }) => theme.colors.text3};
    }
    &:active {
      color: ${({ theme }) => theme.colors.text3};
    }
  }
`

interface ItemStatProps {
  title: string
  value: React.ReactNode
}

export function ItemStat({ title, value }: ItemStatProps) {
  return (
    <ListStatItemStyle>
      <ItemStatTitle>{title}</ItemStatTitle>

      <ItemStatValue>{value}</ItemStatValue>
    </ListStatItemStyle>
  )
}
