import React from 'react'
import styled from 'styled-components'
import { ContentWrapper } from '../components/ContentWrapper'
import { ModuleWrapperWhite } from '../components/ModuleWrapper'
import {
  GridSidebarWraper,
  GridSidebar,
  SidebarLeft,
  GridSidebarBody,
  GridSidebarTitle,
  GridSidebarBack,
  GridSidebarHeader
} from '../components/GridSidebar'
import MenuSidebar from '../components/MenuSidebar'
import { Values } from '../components/financailModel/Values'
import { ButtonConfirmed, ButtonPrimary } from '../components/Button'
import { FinMultiplicator } from '../components/financailModel/FinMultiplicator'
import {
  useFinancialModelInfo,
  useFinancialActionHandlers,
  useInitParams,
  useFinancialApprove,
  useVoteForParams,
  VoteStatus,
  calcNewValue,
  calcRparam,
  memoizer
} from '../state/financial/hooks'
import {
  SingleParamType,
  BASE_BORROW_RATE,
  OPTIMAL_SLOPE,
  EXCESS_SLOPE,
  MAX_RATE_MULTIPLIER,
  LIQUIDATION_MARGIN,
  LIQUIDATION_REWARD,
  MAX_PRICE_THRESHOLD,
  MIN_WOW_BALANCE_X4,
  MIN_WOW_BALANCE_X5,
  MAX_LIQUIDATION_REWARD,
  OPTIMAL_UTILIZATION,
  POOL_UTILIZATION_ALLOWANCE
} from '../state/financial/actions'
import { Graph } from '../components/financailModel/Graph'
import { AutoRow, RowBetween } from '../components/Row'
import { ApprovalState } from '../hooks/useApproveCallback'
import Loader from '../components/Loader'
import ProgressSteps from '../components/ProgressSteps'
import { ParamValue } from '../components/financailModel/ParamValue'
import { useActiveWeb3React } from '../hooks'
import { ChainId } from '@wowswap-io/wowswap-sdk'

const memCalcRparam = memoizer(calcRparam)

const FinancialModelRow = styled.div`
  display: flex;
  margin: 0 -7px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		flex-direction: column;
	`};
`
const FinancialModelCol = styled.div`
  width: 50%;
  flex: 0 0 50%;
  padding: 0 7px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		width: 100%;
		flex: 0 0 100%;
		margin-bottom: 10px;
	`};
`
const FinancialModelItem = styled.div`
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
		margin-bottom: 10px;
	`};
`
const FinancialModelBtn = styled.div`
  padding-top: 6px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		padding-top: 10px;
	`};
`
const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
`

export function GovernanceFinancialModel() {
  useInitParams()

  return <GovernanceFinancialModelComponent />
}

function GovernanceFinancialModelComponent() {
  const { chainId } = useActiveWeb3React()

  const { state, hasUpdate, updates, xWOW } = useFinancialModelInfo()
  const { onChangeSingleParam } = useFinancialActionHandlers()
  const {
    approval,
    approvalSubmitted,
    approveCallback,
    showApproveFlow,
    tokenAmount,
    setApprovalSubmitted
  } = useFinancialApprove()

  const handleChangeSingleParam = (field: SingleParamType, value: number) => {
    const newValue = calcNewValue({
      amount: state[field].amount,
      value: state[field].current,
      balance: xWOW.balance?.raw.toString(),
      userValue: value,
      votedValue: state[field].votedValue,
      voted: state[field].voted
    })

    onChangeSingleParam(field, {
      ...state[field],
      userValue: value,
      newValue
    })
  }

  const { status, callback } = useVoteForParams()

  const handleVote = () => {
    if (status === VoteStatus.VALID && callback) {
      callback(updates).then(() => {
        setApprovalSubmitted(false)
      })
    }
  }

  const isPageTitleWithBackground = chainId === ChainId.ETHEREUM || chainId === ChainId.IOTEX

  if (!state.loaded) {
    return (
      <GridSidebarWraper>
        <GridSidebar>
          <SidebarLeft>
            <MenuSidebar />
          </SidebarLeft>

          <GridSidebarBody>
            <GridSidebarHeader withBackground={isPageTitleWithBackground}>
              <GridSidebarBack to={'/governance-menu'} />
              <GridSidebarTitle>Financial model</GridSidebarTitle>
            </GridSidebarHeader>

            <ContentWrapper NoNegativeMargin maxWidth="894px" padding="26px 36px 36px 36px">
              <LoaderWrapper>
                <Loader size={'80px'} />
              </LoaderWrapper>
            </ContentWrapper>
          </GridSidebarBody>
        </GridSidebar>
      </GridSidebarWraper>
    )
  }

  return (
    <GridSidebarWraper>
      <GridSidebar>
        <SidebarLeft>
          <MenuSidebar />
        </SidebarLeft>

        <GridSidebarBody>
          <GridSidebarHeader withBackground={isPageTitleWithBackground}>
            <GridSidebarBack to={'/governance-menu'} />

            <GridSidebarTitle>Financial model</GridSidebarTitle>
          </GridSidebarHeader>

          <ContentWrapper NoNegativeMargin maxWidth="894px" padding="36px 36px 36px 36px">
            <FinancialModelRow>
              <FinancialModelCol>
                <FinancialModelItem>
                  <ModuleWrapperWhite title="Base Hourly Interest Rate Curve" marginBottom="8px">
                    <Graph
                      r0={state[BASE_BORROW_RATE].userValue}
                      r80={state[OPTIMAL_SLOPE].userValue}
                      r100={state[EXCESS_SLOPE].userValue}
                      optimal={state[OPTIMAL_UTILIZATION].userValue}
                    />
                    <FinMultiplicator
                      top={true}
                      rating="0"
                      field={BASE_BORROW_RATE}
                      from={state[BASE_BORROW_RATE].min}
                      to={state[BASE_BORROW_RATE].max}
                      marks={[state[BASE_BORROW_RATE].min, state[BASE_BORROW_RATE].max]}
                      current={state[BASE_BORROW_RATE].current}
                      userValue={state[BASE_BORROW_RATE].userValue}
                      newValue={state[BASE_BORROW_RATE].newValue}
                      onChange={handleChangeSingleParam}
                      onRenderMark={(mark: number, possibleNull?: boolean) => (
                        <ParamValue percentage precision={3} possibleNull={possibleNull}>
                          {memCalcRparam(mark)}
                        </ParamValue>
                      )}
                    />
                    <FinMultiplicator
                      rating="optimal"
                      field={OPTIMAL_SLOPE}
                      from={state[OPTIMAL_SLOPE].min}
                      to={state[OPTIMAL_SLOPE].max}
                      marks={[state[OPTIMAL_SLOPE].min, state[OPTIMAL_SLOPE].max]}
                      current={state[OPTIMAL_SLOPE].current}
                      userValue={state[OPTIMAL_SLOPE].userValue}
                      newValue={state[OPTIMAL_SLOPE].newValue}
                      onChange={handleChangeSingleParam}
                      onRenderMark={(mark: number, possibleNull?: boolean) => (
                        <ParamValue percentage precision={3} possibleNull={possibleNull}>
                          {memCalcRparam(mark) + memCalcRparam(state[BASE_BORROW_RATE].userValue)}
                        </ParamValue>
                      )}
                    />
                    <FinMultiplicator
                      top={true}
                      rating="100"
                      field={EXCESS_SLOPE}
                      from={state[EXCESS_SLOPE].min}
                      to={state[EXCESS_SLOPE].max}
                      marks={[state[EXCESS_SLOPE].min, state[EXCESS_SLOPE].max]}
                      current={state[EXCESS_SLOPE].current}
                      userValue={state[EXCESS_SLOPE].userValue}
                      newValue={state[EXCESS_SLOPE].newValue}
                      onChange={handleChangeSingleParam}
                      onRenderMark={(mark: number, possibleNull?: boolean) => (
                        <ParamValue percentage precision={3} possibleNull={possibleNull}>
                          {memCalcRparam(mark) +
                            memCalcRparam(state[BASE_BORROW_RATE].userValue) +
                            memCalcRparam(state[OPTIMAL_SLOPE].userValue)}
                        </ParamValue>
                      )}
                    />
                  </ModuleWrapperWhite>
                </FinancialModelItem>

                <FinancialModelItem>
                  <ModuleWrapperWhite title="Optimal lending pool utilization">
                    <Values
                      field={OPTIMAL_UTILIZATION}
                      from={state[OPTIMAL_UTILIZATION].min}
                      to={state[OPTIMAL_UTILIZATION].max}
                      marks={[state[OPTIMAL_UTILIZATION].min, state[OPTIMAL_UTILIZATION].max]}
                      current={state[OPTIMAL_UTILIZATION].current}
                      userValue={state[OPTIMAL_UTILIZATION].userValue}
                      newValue={state[OPTIMAL_UTILIZATION].newValue}
                      onChange={handleChangeSingleParam}
                      onRenderMark={(mark: number, possibleNull?: boolean) => (
                        <ParamValue precision={0} percentage possibleNull={possibleNull}>
                          {mark}
                        </ParamValue>
                      )}
                    />
                  </ModuleWrapperWhite>
                </FinancialModelItem>

                <FinancialModelItem>
                  <ModuleWrapperWhite title="Maximum share of the liquidity pool used to buy one token">
                    <Values
                      field={POOL_UTILIZATION_ALLOWANCE}
                      from={state[POOL_UTILIZATION_ALLOWANCE].min}
                      to={state[POOL_UTILIZATION_ALLOWANCE].max}
                      marks={[state[POOL_UTILIZATION_ALLOWANCE].min, state[POOL_UTILIZATION_ALLOWANCE].max]}
                      current={state[POOL_UTILIZATION_ALLOWANCE].current}
                      userValue={state[POOL_UTILIZATION_ALLOWANCE].userValue}
                      newValue={state[POOL_UTILIZATION_ALLOWANCE].newValue}
                      onChange={handleChangeSingleParam}
                      onRenderMark={(mark: number, possibleNull?: boolean) => (
                        <ParamValue precision={0} percentage possibleNull={possibleNull}>
                          {mark}
                        </ParamValue>
                      )}
                    />
                  </ModuleWrapperWhite>
                </FinancialModelItem>
              </FinancialModelCol>

              <FinancialModelCol>
                <FinancialModelItem>
                  <ModuleWrapperWhite title="Base interest Rate Multiplier for x5 leverage">
                    <Values
                      field={MAX_RATE_MULTIPLIER}
                      from={state[MAX_RATE_MULTIPLIER].min}
                      to={state[MAX_RATE_MULTIPLIER].max}
                      marks={[state[MAX_RATE_MULTIPLIER].min, state[MAX_RATE_MULTIPLIER].max]}
                      current={state[MAX_RATE_MULTIPLIER].current}
                      userValue={state[MAX_RATE_MULTIPLIER].userValue}
                      newValue={state[MAX_RATE_MULTIPLIER].newValue}
                      onChange={handleChangeSingleParam}
                      onRenderMark={(mark: number, possibleNull?: boolean) => (
                        <ParamValue precision={1} possibleNull={possibleNull}>
                          {mark}
                        </ParamValue>
                      )}
                    />
                  </ModuleWrapperWhite>
                </FinancialModelItem>

                <FinancialModelItem>
                  <ModuleWrapperWhite title="Liquidation Margin">
                    <Values
                      field={LIQUIDATION_MARGIN}
                      from={state[LIQUIDATION_MARGIN].min}
                      to={state[LIQUIDATION_MARGIN].max}
                      marks={[state[LIQUIDATION_MARGIN].min, state[LIQUIDATION_MARGIN].max]}
                      current={state[LIQUIDATION_MARGIN].current}
                      userValue={state[LIQUIDATION_MARGIN].userValue}
                      newValue={state[LIQUIDATION_MARGIN].newValue}
                      onChange={handleChangeSingleParam}
                      onRenderMark={(mark: number, possibleNull?: boolean) => (
                        <ParamValue precision={1} percentage possibleNull={possibleNull}>
                          {mark}
                        </ParamValue>
                      )}
                    />
                  </ModuleWrapperWhite>
                </FinancialModelItem>

                <FinancialModelItem>
                  <ModuleWrapperWhite title="Liquidation Bonus">
                    <Values
                      field={LIQUIDATION_REWARD}
                      from={state[LIQUIDATION_REWARD].min}
                      to={state[LIQUIDATION_REWARD].max}
                      marks={[state[LIQUIDATION_REWARD].min, state[LIQUIDATION_REWARD].max]}
                      current={state[LIQUIDATION_REWARD].current}
                      userValue={state[LIQUIDATION_REWARD].userValue}
                      newValue={state[LIQUIDATION_REWARD].newValue}
                      onChange={handleChangeSingleParam}
                      onRenderMark={(mark: number, possibleNull?: boolean) => (
                        <ParamValue precision={1} percentage possibleNull={possibleNull}>
                          {mark}
                        </ParamValue>
                      )}
                    />
                  </ModuleWrapperWhite>
                </FinancialModelItem>

                <FinancialModelItem>
                  <ModuleWrapperWhite title="Maximum price deviation from the oracle">
                    <Values
                      field={MAX_PRICE_THRESHOLD}
                      from={state[MAX_PRICE_THRESHOLD].min}
                      to={state[MAX_PRICE_THRESHOLD].max}
                      marks={[state[MAX_PRICE_THRESHOLD].min, state[MAX_PRICE_THRESHOLD].max]}
                      current={state[MAX_PRICE_THRESHOLD].current}
                      userValue={state[MAX_PRICE_THRESHOLD].userValue}
                      newValue={state[MAX_PRICE_THRESHOLD].newValue}
                      onChange={handleChangeSingleParam}
                      onRenderMark={(mark: number, possibleNull?: boolean) => (
                        <ParamValue precision={1} percentage possibleNull={possibleNull}>
                          {mark}
                        </ParamValue>
                      )}
                    />
                  </ModuleWrapperWhite>
                </FinancialModelItem>

                <FinancialModelItem>
                  <ModuleWrapperWhite title="Maximum liquidation reward USD">
                    <Values
                      field={MAX_LIQUIDATION_REWARD}
                      from={state[MAX_LIQUIDATION_REWARD].min}
                      to={state[MAX_LIQUIDATION_REWARD].max}
                      marks={[state[MAX_LIQUIDATION_REWARD].min, state[MAX_LIQUIDATION_REWARD].max]}
                      current={state[MAX_LIQUIDATION_REWARD].current}
                      userValue={state[MAX_LIQUIDATION_REWARD].userValue}
                      newValue={state[MAX_LIQUIDATION_REWARD].newValue}
                      onChange={handleChangeSingleParam}
                      onRenderMark={(mark: number, possibleNull?: boolean) => (
                        <ParamValue precision={0} possibleNull={possibleNull}>
                          {mark}
                        </ParamValue>
                      )}
                    />
                  </ModuleWrapperWhite>
                </FinancialModelItem>

                <FinancialModelItem>
                  <ModuleWrapperWhite title="Minimum WOW + xWOW balance requirement for x4 and x5 leverage">
                    <FinMultiplicator
                      multiplier="4"
                      field={MIN_WOW_BALANCE_X4}
                      from={state[MIN_WOW_BALANCE_X4].min}
                      to={state[MIN_WOW_BALANCE_X4].max}
                      marks={[state[MIN_WOW_BALANCE_X4].min, state[MIN_WOW_BALANCE_X4].max]}
                      current={state[MIN_WOW_BALANCE_X4].current}
                      userValue={state[MIN_WOW_BALANCE_X4].userValue}
                      newValue={state[MIN_WOW_BALANCE_X4].newValue}
                      onChange={handleChangeSingleParam}
                      onRenderMark={(mark: number, possibleNull?: boolean) => (
                        <ParamValue precision={0} possibleNull={possibleNull}>
                          {mark}
                        </ParamValue>
                      )}
                    />

                    <FinMultiplicator
                      multiplier="5"
                      field={MIN_WOW_BALANCE_X5}
                      from={state[MIN_WOW_BALANCE_X5].min}
                      to={state[MIN_WOW_BALANCE_X5].max}
                      marks={[state[MIN_WOW_BALANCE_X5].min, state[MIN_WOW_BALANCE_X5].max]}
                      current={state[MIN_WOW_BALANCE_X5].current}
                      userValue={state[MIN_WOW_BALANCE_X5].userValue}
                      newValue={state[MIN_WOW_BALANCE_X5].newValue}
                      onChange={handleChangeSingleParam}
                      onRenderMark={(mark: number, possibleNull?: boolean) => (
                        <ParamValue precision={0} possibleNull={possibleNull}>
                          {mark}
                        </ParamValue>
                      )}
                    />
                  </ModuleWrapperWhite>
                </FinancialModelItem>

                <FinancialModelBtn>
                  {showApproveFlow && hasUpdate ? (
                    <RowBetween>
                      <ButtonConfirmed
                        onClick={approveCallback}
                        disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted || !hasUpdate}
                        width="47%"
                        altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                        confirmed={approval === ApprovalState.APPROVED}
                        pending={approval === ApprovalState.PENDING}
                        style={{ padding: '0' }}
                      >
                        {approval === ApprovalState.PENDING ? (
                          <AutoRow gap="6px" justify="center">
                            Approving <Loader margin="0 -8px 0 0" />
                          </AutoRow>
                        ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                          'Approved'
                        ) : (
                          'Approve ' + tokenAmount.token.symbol
                        )}
                      </ButtonConfirmed>

                      <ButtonPrimary
                        width="47%"
                        disabled={status !== VoteStatus.VALID || approval !== ApprovalState.APPROVED}
                        onClick={handleVote}
                      >
                        Vote
                      </ButtonPrimary>
                    </RowBetween>
                  ) : (
                    <ButtonPrimary disabled={status !== VoteStatus.VALID || !hasUpdate} onClick={handleVote}>
                      Vote
                    </ButtonPrimary>
                  )}

                  {showApproveFlow && hasUpdate && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />}
                </FinancialModelBtn>
              </FinancialModelCol>
            </FinancialModelRow>
          </ContentWrapper>
        </GridSidebarBody>
      </GridSidebar>
    </GridSidebarWraper>
  )
}
