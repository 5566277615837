import { Text } from 'rebass'
import styled, { css } from 'styled-components'

import { convertHexToRGBA } from 'utils/converters'

export const HeaderFrame = styled.div`
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 0 20px;
  border-bottom: 4px solid ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => theme.colors.bg0};
  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  z-index: 6;

  ${({ theme }) => theme.mediaWidth.upToMedium`
position: fixed;
z-index: 5;

width: 100%;
height: 50px;
padding: 0 10px;
border-bottom: 2px solid ${({ theme }) => theme.colors.black};
`};
`

export const MobileHeaderElementWrap = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  margin-left: auto;
  ${({ theme }) => theme.mediaWidth.upToLarge`
display: flex;
`};
`

export const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

export const AccountElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  color: black;
  border-radius: 10px;
  white-space: nowrap;

  ${({ theme }) =>
    theme.mode === 'dark' &&
    css`
      background-color: black;
      color: white;
    `}
`

export const Icon = styled.div`
  display: block;

  & > img {
    display: block;
    width: 105px;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
display: none;
`};
`

export const IconMobile = styled.div`
  display: none;

  ${({ theme }) => theme.mediaWidth.upToLarge`
display: block;

                        & > img {
                          width: 35px;
                          height: 35px;
                          display: block;
                        }
`};
`

export const HeaderControls = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;

  ${({ theme }) => theme.mediaWidth.upToLarge`
position: fixed;
left: 0;
right: 0;
bottom: 0;

justify-content: space-between;
width: 100%;
height: 64px;
padding: 0 10px;

background: ${({ theme }) => theme.colors.bg0};
border-top: 2px solid ${({ theme }) => theme.colors.black}

                            & button {
                              height: 38px;
                            }
`};
`

export const BalanceText = styled(Text)`
  color: '#000000';
  background-color: '#ffffff';
  padding: 0 54px 0 15px;
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  font-weight: 500;
  font-size: ${({ theme }) => (theme.mode === 'dark' ? '14px' : '16px')};
  line-height: 18px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
padding: 0 15px;
font-size: 15px;
`};
`

export const BuyWOWWrapper = styled.div`
  margin-right: 15px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
    display: none;
  `};
`

export const AuditLink = styled.a`
  height: 48px;
  margin-right: 15px;
  padding: 0 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;

  &:hover {
    background: ${({ theme }) => convertHexToRGBA(theme.colors.text2, 0.3)};
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    display: none;
  `};
`
