import { createAction } from '@reduxjs/toolkit'

export enum DepositStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  APPROVE_REQUIRED = 'APPROVE_REQUIRED'
}

export enum StakeDirection {
  STAKE = 'STAKE',
  UNSTAKE = 'UNSTAKE'
}

export interface StakeState {
  tokenAddress?: string
  unstakeTokenAddress?: string
  period: number
  typedValue: string
  direction: StakeDirection
}

export const setToken = createAction<{ tokenAddress: string }>('stake/setToken')
export const setUnstakeToken = createAction<{ tokenAddress: string }>('stake/setUnstakeToken')
export const setLockTime = createAction<{ locktime: number }>('stake/setLockTime')
export const setTypeInput = createAction<{ amount: string }>('stake/typeInput')
export const setDirection = createAction<{ direction: StakeDirection }>('stake/setDirection')
export const resetStakeState = createAction('stake/resetStakeState')
