import React, { useCallback, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import { Currency, TokenAmount } from '@wowswap-io/wowswap-sdk'
import TagManager from 'react-gtm-module'

import { ButtonConfirmed, ButtonPrimary } from '../../components/Button'
import { ContentWrapper } from '../../components/ContentWrapper'
import EarnCurrencyInputPanel from '../../components/earn/EarnCurrencyInputPanel'
import { EarnTable } from '../../components/earn/EarnTable'
import Loader from '../../components/Loader'
import ProgressSteps from '../../components/ProgressSteps'
import { AutoRow, RowBetween } from '../../components/Row'
import { ArrowWrapper } from '../../components/swap/styleds'
import { TextBase } from '../../components/Text'
import ConfirmConvertModal from '../../components/earn/ConfirmConvertModal'
import ApprovalPendingModal from '../../components/ApprovalPendingModal'

import { useActiveWeb3React } from '../../hooks'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { DepositStatus, EarnDirection } from '../../state/earn/actions'
import { useDeposit, useDerivedEarnInfo, useEarnActionHandlers, useEarnTableData } from '../../state/earn/hooks'
import { getDefaultToken } from '../../state/wallet/hooks'
import { TradeToken } from '../../hooks/Tokens.types'

import { ROUTER_ADDRESS } from '../../constants'
import { calcAPY, calcUtilization } from '../../utils/calculations'
import { TranslateString } from '../../utils/translateTextHelpers'

import {
  ActionBlock,
  ActionBlockBody,
  ActionBlockBottom,
  ActionBlockTitle,
  EarnDesc,
  EarnHeader,
  EarnHeaderText,
  EarnTitle,
  Wrapper
} from './styleds'
import { defined } from '../../utils'
import { bn, ray } from '../../utils/math'
import '../../utils/math'

export default function Earn() {
  const { chainId } = useActiveWeb3React()
  const [{ showConfirm, attemptingTxn }, setConvertState] = useState<{
    showConfirm: boolean
    attemptingTxn: boolean
  }>({
    showConfirm: false,
    attemptingTxn: false
  })

  const [{ attemptingApprove }, setApproveState] = useState<{
    attemptingApprove: boolean
  }>({
    attemptingApprove: false
  })

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  const data = useDerivedEarnInfo()
  const tokenData = useEarnTableData()

  const { onValueInput, onTokenChange, onDirectionChange } = useEarnActionHandlers()

  const handleInputValue = useCallback(
    (value: string) => {
      onValueInput(value)
    },
    [onValueInput]
  )

  const searchPair = useCallback(
    (token: TradeToken | Currency, input: boolean) => {
      let tokenSymbol = getDefaultToken().tokenInfo.symbol
      const { symbol } = token

      if (typeof symbol === 'string') {
        if (token instanceof Currency) {
          tokenSymbol = symbol
        }

        if (token instanceof TradeToken) {
          tokenSymbol = symbol
        }
      }

      setApprovalSubmitted(false)

      const interest = tokenSymbol.slice(0, 2) === 'ib'
      onTokenChange(interest ? tokenSymbol.substr(2) : tokenSymbol)
      if (input) {
        onDirectionChange(!interest)
      } else {
        onDirectionChange(interest)
      }
    },
    [onDirectionChange, onTokenChange, setApprovalSubmitted]
  )

  const handleTokens = () => {
    onDirectionChange(data?.direction !== EarnDirection.Deposit)
  }

  const handleTokenChangeInput = useCallback(
    (token: TradeToken | Currency) => {
      searchPair(token, true)
    },
    [searchPair]
  )

  const handleTokenChangeOutput = useCallback(
    (token: TradeToken | Currency) => {
      searchPair(token, false)
    },
    [searchPair]
  )

  const amount = (token: TradeToken, bigInt?: string): TokenAmount => new TokenAmount(token, bigInt || '0')

  const tableData = useMemo(() => {
    return tokenData
      ? tokenData
          .filter(data => data !== null)
          .map(data => {
            if (!data) return undefined

            const deposited = bn(data.reserveDebt?.liquidity || 0).add(bn(data.reserveDebt?.currentDebt || 0))
            const borrowed = bn(data.reserveDebt?.currentDebt || 0)
            const utilization = ray(deposited.gt(0) ? borrowed.div(deposited) : bn(0))

            return {
              key: data.token.address + data.token.symbol,
              token: {
                icon: `https://raw.githubusercontent.com/wowswap-io/assets/master/${data.token.symbol}.png`,
                title: data?.token?.symbol || 'N/A',
                name: data?.token?.name || 'N/A',
                address: data.token.address,
                tradableAddress: data?.token?.tradableAddress
              },
              apy: calcAPY(data.liquidityRate) + `%`,
              deposited: amount(data.token, deposited.str()).toSignificant(6) + ` ${data.token?.symbol}`,
              borrowed: amount(data.token, borrowed.str()).toSignificant(6) + ` ${data.token?.symbol}`,
              utilization: calcUtilization(utilization.str()) + '%',
              balance: amount(data.token, data.balanceOf).toSignificant(6) + ` ib${data.token?.symbol}`,
              totalValue: amount(data.token, data.liquidityOf).toSignificant(6) + ` ${data.token?.symbol}`
            }
          })
          .filter(defined)
      : null
  }, [tokenData])

  const handleMaxInput = useCallback(() => {
    if (data.maxSpend) {
      onValueInput(data.maxSpend.toExact())
    }
  }, [data.maxSpend, onValueInput])

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallback(data.amount, chainId && ROUTER_ADDRESS[chainId])

  const { callback: onDeposit, status: deposit, error: depositError } = useDeposit()

  const convert = () => {
    setConvertState(prev => ({ ...prev, showConfirm: true }))
  }

  const handleConvert = () => {
    setConvertState(prev => ({ ...prev, attemptingTxn: true }))
    if (onDeposit && deposit === DepositStatus.VALID) {
      onDeposit()
        .then(() => {
          setConvertState({ showConfirm: false, attemptingTxn: false })
          onValueInput('')

          TagManager.dataLayer({
            dataLayer: {
              event: 'ClickConvert'
            }
          })
        })
        .catch(e => {
          console.error(e)
          setConvertState({ showConfirm: false, attemptingTxn: false })
          onValueInput('')
        })
    }
  }

  const handleDismiss = () => {
    setConvertState({ showConfirm: false, attemptingTxn: false })
  }

  const handleApprove = useCallback(() => {
    setApprovalSubmitted(true)
    setApproveState({ attemptingApprove: true })
    approveCallback()
      .then(() => {
        setApproveState({ attemptingApprove: false })
      })
      .catch(() => {
        setApproveState({ attemptingApprove: false })
      })
  }, [approveCallback, setApproveState, setApprovalSubmitted])

  const isApprovalPending = approval === ApprovalState.PENDING
  const isApprovalApproved = approval === ApprovalState.APPROVED
  const isApprovalNotApproved = approval === ApprovalState.NOT_APPROVED

  const showApproveFlow = isApprovalNotApproved || isApprovalPending || (approvalSubmitted && isApprovalApproved)
  const needDisabledApprove = approval !== ApprovalState.NOT_APPROVED || approvalSubmitted

  return (
    <ContentWrapper>
      <EarnHeader>
        <EarnHeaderText>
          <EarnTitle>Earn</EarnTitle>
          <EarnDesc>By supplying margin traders with tokens for liquidity</EarnDesc>
        </EarnHeaderText>
      </EarnHeader>
      <Wrapper id="earn-page">
        <ConfirmConvertModal
          isOpen={showConfirm}
          typedValue={data.typedValue}
          inputToken={data.input}
          outputToken={data.output}
          amountOut={data.amountOut}
          onConfirm={handleConvert}
          onDismiss={handleDismiss}
          attemptingTxn={attemptingTxn}
          swapErrorMessage={undefined}
          txHash={undefined}
        />

        <ApprovalPendingModal
          isOpen={attemptingApprove}
          onDismiss={() => setApproveState({ attemptingApprove: false })}
          pendingText=""
        />

        <ActionBlock>
          <ActionBlockTitle>
            <h3>Supply / Remove liquidity</h3>

            <TextBase>Deposit or redeem Interest Bearing Tokens</TextBase>
          </ActionBlockTitle>

          <ActionBlockBody>
            <EarnCurrencyInputPanel
              label={TranslateString(76, 'Convert')}
              value={data?.typedValue}
              showMaxButton={true}
              selected={data?.input}
              other={data?.output}
              variants={data?.possibleInputs}
              onUserInput={handleInputValue}
              onMax={handleMaxInput}
              onCurrencySelect={handleTokenChangeInput}
              id="earn-currency-input"
            />

            <AutoRow justify={'center'} id="ActionBlockArrow">
              <ArrowWrapper clickable>
                <ArrowDown size="20" onClick={handleTokens} />
              </ArrowWrapper>
            </AutoRow>

            <EarnCurrencyInputPanel
              id="earn-currency-output"
              label={TranslateString(76, 'Receive')}
              value={data?.amountOut?.toExact() || ''}
              showMaxButton={false}
              selected={data?.output}
              other={data?.input}
              variants={data?.possibleOutputs}
              disabled={true}
              onUserInput={handleInputValue}
              onMax={() => {}}
              onCurrencySelect={handleTokenChangeOutput}
            />
          </ActionBlockBody>

          <ActionBlockBottom>
            {showApproveFlow ? (
              <RowBetween>
                <ButtonConfirmed
                  onClick={handleApprove}
                  disabled={needDisabledApprove}
                  width="47%"
                  altDisabledStyle={isApprovalPending} // show solid button while waiting
                  confirmed={isApprovalApproved}
                  pending={isApprovalPending}
                  style={{ padding: '0' }}
                >
                  {isApprovalPending ? (
                    <AutoRow gap="6px" justify="center">
                      Approving <Loader margin="0 -8px 0 0" />
                    </AutoRow>
                  ) : isApprovalApproved ? (
                    'Approved'
                  ) : (
                    'Approve ' + data.input?.symbol
                  )}
                </ButtonConfirmed>

                <ButtonPrimary
                  width="47%"
                  disabled={deposit !== DepositStatus.VALID || approval !== ApprovalState.APPROVED}
                  onClick={convert}
                >
                  Convert
                </ButtonPrimary>
              </RowBetween>
            ) : (
              <ButtonPrimary disabled={deposit !== DepositStatus.VALID} onClick={convert}>
                {depositError || 'Convert'}
              </ButtonPrimary>
            )}

            {showApproveFlow && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />}
          </ActionBlockBottom>
        </ActionBlock>
        <EarnTable data={tableData} />
      </Wrapper>
    </ContentWrapper>
  )
}
