import { BigNumber } from '@ethersproject/bignumber'
import { useMemo } from 'react'
import { CurrencyAmount, Token, TokenAmount } from '@wowswap-io/wowswap-sdk'
import { useTradeOpenPositionHandler } from '../state/trade/hooks'
import { getDefaultToken } from '../state/wallet/hooks'
import { getRouterContract } from '../utils'
import { useActiveWeb3React } from './index'

export enum CalculateOpenPositionStatus {
  INVALID,
  VALID
}

export function useCalculateOpenPosition({
  isOpenPosition,
  shorting,
  leverageFactor,
  inputAddress,
  outputAddress,
  proxyAddress,
  amount
}: {
  isOpenPosition: boolean
  leverageFactor: number
  shorting: boolean
  inputAddress?: string
  outputAddress?: string
  proxyAddress?: string
  amount?: string
}): { status: CalculateOpenPositionStatus; callback: null | (() => Promise<any>) } {
  const { account, contract, chainId } = useContract()
  const onChangeOpenPositionInfo = useTradeOpenPositionHandler()

  return useMemo(() => {
    if (!account || !chainId || !amount || !inputAddress || !outputAddress || !contract || !isOpenPosition) {
      return { callback: null, status: CalculateOpenPositionStatus.INVALID }
    }

    const leverage = `0x${Math.floor((leverageFactor || 1) * 10 ** 4).toString(16)}`
    const inputs = [inputAddress]
    if (proxyAddress) {
      inputs.push(proxyAddress)
    }
    inputs.push(outputAddress, amount, leverage)

    const token = getDefaultToken(chainId)
    const toTokenAmount = (token: Token, bn: BigNumber) => new TokenAmount(token, bn.toString())
    const toNumber = (tokenAmount: CurrencyAmount) => Number.parseFloat(tokenAmount.toExact())

    return {
      status: CalculateOpenPositionStatus.VALID,
      callback: async function onCalculateBalance() {
        const methodParts: string[] = []
        methodParts.push('calculate')
        methodParts.push('Open')
        if (proxyAddress) methodParts.push('Proxy')
        if (shorting) methodParts.push('Short')
        methodParts.push('Position')
        const method = methodParts.join('')

        return contract.callStatic[method](...inputs)
          .then((response: any) => {
            const { borrowAmount, borrowRate, liquidationCost, maxAmountIn } = response

            const liquidationCostAmount = toTokenAmount(token, liquidationCost)
            const maxAmountInAmount = toTokenAmount(token, maxAmountIn)

            onChangeOpenPositionInfo({
              borrowAmount: borrowAmount.toString(),
              borrowRate: borrowRate.toString(),
              liquidationCost: toNumber(liquidationCostAmount),
              maxBorrow: maxAmountIn.toString()
            })

            return toNumber(maxAmountInAmount)
          })
          .catch(e => {
            console.error('Failed calculate open position', e)
          })
      }
    }
  }, [
    inputAddress,
    outputAddress,
    proxyAddress,
    amount,
    account,
    chainId,
    contract,
    isOpenPosition,
    leverageFactor,
    shorting,
    onChangeOpenPositionInfo
  ])
}

function useContract() {
  const { account, chainId, library } = useActiveWeb3React()

  return useMemo(() => {
    if (!account || !chainId || !library) {
      return {}
    }

    return {
      account,
      chainId,
      contract: getRouterContract(chainId, library, account)
    }
  }, [account, chainId, library])
}
