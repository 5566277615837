import React /*, { useContext }*/, { useMemo } from 'react'
import styled /*, { ThemeContext }*/ from 'styled-components'
import { BlockTitleLg, PageTitleStyles } from '../components/BlockTitle'
import { ContentWrapper, ContentWrapperMobileFullWidth } from '../components/ContentWrapper'
import { PortfolioEmpty } from '../components/PortfolioEmpty'
import { LongPositionPortfolioItem, ShortPositionPortfolioItem } from '../components/PortfolioItem'
import { PortfolioTable } from '../components/PortfolioTable'
import { usePositions } from '../state/portfolio/hooks'
import { useActiveWeb3React } from '../hooks'
import { ChainId } from '@wowswap-io/wowswap-sdk'

const PortfolioHeader = styled.div`
  margin-bottom: 37px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		margin-bottom: 20px;
	`};
`

const ShortPosWrapper = styled(ContentWrapper)`
  margin-bottom: 94px;
`

const Title = styled(PageTitleStyles)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

const TitleWrapper = styled(ContentWrapper)`
  margin: 0 auto;
  padding: 0;
`

export default function Portfolio() {
  const { chainId } = useActiveWeb3React()

  const longPositions = usePositions(false)
  const shortPositions = usePositions(true)

  const shortPositionsCols = useMemo(() => {
    return [
      {
        field: 'Shorted Amount',
        title: 'Shorted Amount'
      },
      {
        field: 'Collateral',
        title: 'Collateral',
        subtitle: '-Shorted Value'
      },
      {
        field: 'Net Value',
        title: 'Net Value'
      },
      {
        field: 'Value at opening',
        title: (
          <React.Fragment>
            Value at <br />
            opening
          </React.Fragment>
        )
      },
      {
        field: 'Profit',
        title: 'Profit'
      },
      {
        field: 'Liquidation Price',
        title: (
          <React.Fragment>
            Liquidation <br />
            Price
          </React.Fragment>
        )
      },
      {
        field: 'Health',
        title: 'Health'
      }
    ]
  }, [])

  const longPositionsCols = useMemo(() => {
    return [
      {
        field: 'Total value',
        title: 'Total value'
      },
      {
        field: 'Debt',
        title: 'Debt'
      },
      {
        field: 'Net Value',
        title: 'Net Value'
      },
      {
        field: 'Profit',
        title: 'Profit'
      },
      {
        field: 'Liquidation Price',
        title: (
          <React.Fragment>
            Liquidation <br />
            Price
          </React.Fragment>
        )
      },
      {
        field: 'Health',
        title: 'Health'
      }
    ]
  }, [])

  const isPageTitleWithBackground = chainId === ChainId.ETHEREUM || chainId === ChainId.IOTEX

  return (
    <>
      <TitleWrapper transparent>
        <Title withBackground={isPageTitleWithBackground}>Portfolio</Title>
      </TitleWrapper>

      <ContentWrapper>
        <PortfolioHeader>
          <BlockTitleLg>Long positions</BlockTitleLg>
        </PortfolioHeader>

        <ContentWrapperMobileFullWidth>
          <PortfolioTable
            positions={longPositions}
            cols={longPositionsCols}
            renderEmpty={() => (
              <PortfolioEmpty title={'You do not have any long positions'} subtitle={'Go to Swap menu to trade'} />
            )}
            renderRow={({ lendable, tradeble, position, isOdd }) => (
              <LongPositionPortfolioItem
                key={`${lendable.info.address}${tradeble.info.address}`}
                tradeble={tradeble}
                lendable={lendable}
                isOdd={isOdd}
                position={position}
              />
            )}
          />
        </ContentWrapperMobileFullWidth>
      </ContentWrapper>
      <ShortPosWrapper>
        <PortfolioHeader>
          <BlockTitleLg>Short positions</BlockTitleLg>
        </PortfolioHeader>
        <ContentWrapperMobileFullWidth>
          <PortfolioTable
            positions={shortPositions}
            cols={shortPositionsCols}
            renderEmpty={() => (
              <PortfolioEmpty title={'You do not have any short positions'} subtitle={'Go to Swap menu to trade'} />
            )}
            renderRow={({ lendable, tradeble, position, isOdd }) => {
              return (
                <ShortPositionPortfolioItem
                  key={`${lendable.info.address}${tradeble.info.address}`}
                  tradeble={tradeble}
                  lendable={lendable}
                  isOdd={isOdd}
                  position={position}
                />
              )
            }}
          />
        </ContentWrapperMobileFullWidth>
      </ShortPosWrapper>
    </>
  )
}
