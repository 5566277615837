import React from 'react'
import styled from 'styled-components'

import Label from '../Label'
import { ButtonGreen, ButtonBorderBlack } from '../Button'

const ModuleCollateralBody = styled.div``
const ModuleCollateralTop = styled.div`
  margin-bottom: 20px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
		margin-bottom: 10px;
	`};
`
const ModuleCollateralTitle = styled.h2`
  margin: 0;
  margin-bottom: 10px;

  font-family: 'Retro';
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.text1};
`
const ModuleCollateralDesc = styled.div`
  padding: 19px 12px 28px 20px;
  margin-bottom: 28px;

  background-color: ${({ theme }) => theme.colors.bg1};
  border-radius: 10px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
		padding: 10px 15px 15px 15px;
		margin-bottom: 20px;
	`};
`
const ModuleCollateralDescTitle = styled.h3`
  margin: 0;
  margin-bottom: 10px;

  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.text1};
`
const ModuleCollateralDescText = styled.p`
  margin: 0;
  margin-bottom: 20px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.text1};

  &:last-child {
    margin-bottom: 0;
  }
`
const ModuleCollateralActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
const ModuleCollateralActionsItem = styled.div`
  width: 49%;
  margin-bottom: 10px;

  &:last-child {
    width: 100%;
    flex: 1 1 auto;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
		margin-bottom: 6px;
	`};
`

export default function ModuleCollateral() {
  return (
    <ModuleCollateralBody>
      <ModuleCollateralTop>
        <ModuleCollateralTitle>Increase PICKLE Collateral Factor to 45%</ModuleCollateralTitle>
        <Label text="Active" />
      </ModuleCollateralTop>

      <ModuleCollateralDesc>
        <ModuleCollateralDescTitle>Summary</ModuleCollateralDescTitle>

        <ModuleCollateralDescText>List KEBAB to Wowswap for up to 5x leverage trading.</ModuleCollateralDescText>

        <ModuleCollateralDescTitle>For</ModuleCollateralDescTitle>

        <ModuleCollateralDescText>- List KEBAB.</ModuleCollateralDescText>

        <ModuleCollateralDescTitle>Against</ModuleCollateralDescTitle>

        <ModuleCollateralDescText>- Do not list KEBAB.</ModuleCollateralDescText>

        <ModuleCollateralDescTitle>Motivation</ModuleCollateralDescTitle>

        <ModuleCollateralDescText>
          - KEBAB is delicious so I want to list it. Nice much trading volume, much WOW.
        </ModuleCollateralDescText>

        <ModuleCollateralDescTitle>Additional Info</ModuleCollateralDescTitle>

        <ModuleCollateralDescText>If you do not list, I cry and die.</ModuleCollateralDescText>
      </ModuleCollateralDesc>

      <ModuleCollateralDescTitle>Cast your vote</ModuleCollateralDescTitle>

      <ModuleCollateralActions>
        <ModuleCollateralActionsItem>
          <ButtonBorderBlack>for</ButtonBorderBlack>
        </ModuleCollateralActionsItem>

        <ModuleCollateralActionsItem>
          <ButtonBorderBlack>against</ButtonBorderBlack>
        </ModuleCollateralActionsItem>

        <ModuleCollateralActionsItem>
          <ButtonGreen>vote</ButtonGreen>
        </ModuleCollateralActionsItem>
      </ModuleCollateralActions>
    </ModuleCollateralBody>
  )
}
