import React from 'react'
import styled from 'styled-components'
import { format, addDays } from 'date-fns'
import useDebouncedChangeHandler from '../../utils/useDebouncedChangeHandler'
import SliderCommon from '../SliderCustom/SliderCommon'

const SelectLeverageBody = styled.div``
const SelectLeverageWraper = styled.div`
  padding: 10px 15px;
  margin-bottom: 5px;

  background: #ffffff;
  border-radius: 10px;
`
const SelectLeverageTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`
const SelectLeverageTitle = styled.h2`
  margin: 0;
  margin-right: 12px;
  padding-bottom: 2px;

  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #000000;

  ${({ theme }) => theme.mediaWidth.upToMedium`
		font-size: 16px;
		line-height: 16px;
	`};
`
const DateLabel = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.black};
  font-family: 'Abadi MT Std', sans-serif;
  font-weight: normal;
  font-size: 20px;
  padding: 5px;
  border-radius: 5px;
`
const SelectLeverageBottom = styled.div``

const MARKS = ['14', '183', '365', '458', '730']
const getValueFromMin = (min: number) => {
  const len = MARKS.length
  if (min < Number(MARKS[0]) || min > Number(MARKS[len - 1])) {
    return 1
  }

  let topIdx = MARKS.findIndex(item => Number(item) > min)
  if (topIdx === -1 && Number(MARKS[len - 1]) === min) {
    topIdx = MARKS.length - 1
  }
  const topValue = Number(MARKS[topIdx])
  const bottomValue = Number(MARKS[topIdx - 1])
  const percent = (min - bottomValue) / (topValue - bottomValue)

  return topIdx + percent
}

const getPeriodFromTick = (tick: string) => {
  const bottomLevel = Math.floor(Number(tick))
  const topLevel = Math.ceil(Number(tick))

  const diff = +MARKS[topLevel - 1] - +MARKS[bottomLevel - 1]
  const change = +tick - bottomLevel
  const days = Math.round(diff * change)

  return +MARKS[bottomLevel - 1] + days
}

export function StakeDuration({ minDate, onChange }: { minDate: number; onChange?: (period: number) => void }) {
  const diff = minDate

  const minValue = getValueFromMin(diff || Number(MARKS[0]))
  const minPercentage = ((minValue - 1) / 4) * 100

  const [nextLeverage, setNextLeverage] = useDebouncedChangeHandler(
    minValue.toString(),
    v => {
      onChange!(getPeriodFromTick(v))
    },
    500
  )

  const handleChange = (val: number) => {
    if (val >= minValue) {
      setNextLeverage(val.toFixed(2))
    }
  }

  const period = getPeriodFromTick(nextLeverage)
  const date = addDays(new Date(), period)

  return (
    <SelectLeverageBody>
      <SelectLeverageWraper>
        <SelectLeverageTop>
          <SelectLeverageTitle>Choose lock time:</SelectLeverageTitle>
          <DateLabel>{format(date, 'dd MMM yyyy')}</DateLabel>
        </SelectLeverageTop>

        <SelectLeverageBottom>
          <SliderCommon
            value={Number(nextLeverage)}
            from={1}
            to={5}
            min={minPercentage}
            marks={MARKS}
            onChange={handleChange}
          />
        </SelectLeverageBottom>
      </SelectLeverageWraper>
    </SelectLeverageBody>
  )
}
