import React, { useState, useRef, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import QuestionHelper from '../QuestionHelper'
import { TYPE } from '../../components/Shared'
import { AutoColumn } from '../Column'
import { AutoRow, RowBetween, RowFixed } from '../Row'

// #JUNK import { darken } from 'polished'
import TranslatedText from '../TranslatedText'
import { TextBaseLg } from '../Text'

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh'
}

const FancyButton = styled.button`
  color: ${({ theme }) => theme.colors.text1};
  align-items: center;
  height: 2rem;
  font-size: 12px;
  width: auto;
  min-width: 3rem;
  border: 2px solid ${({ theme }) => theme.colors.bg3};
  outline: none;
  background: ${({ theme }) => theme.colors.bg1};
  :hover {
    border: 2px solid ${({ theme }) => theme.colors.bg4};
  }
  :focus {
    border: 2px solid ${({ theme }) => theme.colors.primary1};
  }
`

const Option = styled(FancyButton)<{ active: boolean }>`
  margin-right: 10px;
  background-color: ${({ active, theme }) => (active ? theme.colors.primary1 : theme.colors.white)};
  color: ${({ theme }) => theme.colors.black};

  font-weight: normal;
  font-size: 20px;
  line-height: 34px;
  width: 60px;
  height: 34px;
  border: 2px solid ${({ active, theme }) => (active ? theme.colors.primary1 : theme.colors.white)};
  border-radius: 5px;
  padding: 0;

  &:hover {
    cursor: pointer;
    border: 2px solid ${({ theme }) => theme.colors.primary1};
    line-height: 34px;
  }

  &:active,
  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.primary1};
  }
`

const Input = styled.input`
  background: ${({ theme }) => theme.colors.bg1};
  font-size: 20px;
  line-height: 30px;
  font-weight: normal;
  width: 60px;
  flex: 0 0 60px;
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  border: 2px solid ${({ theme }) => theme.colors.black};
  border-radius: 5px;
  padding: 0;

  &:active,
  &:focus {
    border: 2px solid #f3ba2f;
  }

  &:hover {
    border: 2px solid #f3ba2f !important;
  }
`

const OptionCustom = styled(FancyButton)<{ active?: boolean; warning?: boolean }>`
  height: 34px;
  position: relative;
  padding: 0;
  flex: 1;
  border: none;
  display: flex;
  align-items: center;
  background: none;

  &:hover {
    border: none;
  }
`

const SlippageEmojiContainer = styled.span`
  color: #f3841e;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

export interface SlippageTabsProps {
  rawSlippage: number
  setRawSlippage: (rawSlippage: number) => void
}

export default function SlippageTabs({ rawSlippage, setRawSlippage }: SlippageTabsProps) {
  const theme = useContext(ThemeContext)

  const inputRef = useRef<HTMLInputElement>()

  const [slippageInput, setSlippageInput] = useState('')

  const slippageInputIsValid =
    slippageInput === '' || (rawSlippage / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2)

  let slippageError: SlippageError | undefined
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput
  } else if (slippageInputIsValid && rawSlippage < 50) {
    slippageError = SlippageError.RiskyLow
  } else if (slippageInputIsValid && rawSlippage > 500) {
    slippageError = SlippageError.RiskyHigh
  } else {
    slippageError = undefined
  }

  function parseCustomSlippage(value: string) {
    setSlippageInput(value)

    try {
      const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString())
      if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat < 5000) {
        setRawSlippage(valueAsIntFromRoundedFloat)
      }
    } catch {}
  }

  return (
    <AutoColumn gap="20px">
      <AutoColumn gap="10px">
        <RowFixed>
          <TextBaseLg>
            <TranslatedText translationId={88}>Slippage tolerance</TranslatedText>
          </TextBaseLg>
          <QuestionHelper text="Your transaction will revert if the price changes unfavorably by more than this percentage." />
        </RowFixed>
        <AutoRow justify="flex-start">
          <Option
            onClick={() => {
              setSlippageInput('')
              setRawSlippage(10)
            }}
            active={rawSlippage === 10}
          >
            0.1%
          </Option>
          <Option
            onClick={() => {
              setSlippageInput('')
              setRawSlippage(50)
            }}
            active={rawSlippage === 50}
          >
            0.5%
          </Option>
          <Option
            onClick={() => {
              setSlippageInput('')
              setRawSlippage(100)
            }}
            active={rawSlippage === 100}
          >
            1%
          </Option>
          <OptionCustom
            active={![10, 50, 100].includes(rawSlippage)}
            warning={!slippageInputIsValid}
            tabIndex={-1}
            style={{ width: '60px', flex: '0 0 60px' }}
          >
            {!!slippageInput &&
            (slippageError === SlippageError.RiskyLow || slippageError === SlippageError.RiskyHigh) ? (
              <SlippageEmojiContainer>
                <span role="img" aria-label="warning">
                  ⚠️
                </span>
              </SlippageEmojiContainer>
            ) : null}
            {/* https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451 */}
            <Input
              ref={inputRef as any}
              placeholder={(rawSlippage / 100).toFixed(2)}
              value={slippageInput}
              onBlur={() => {
                parseCustomSlippage((rawSlippage / 100).toFixed(2))
              }}
              onChange={e => parseCustomSlippage(e.target.value)}
              color={!slippageInputIsValid ? 'red' : ''}
              style={{ borderColor: !slippageInputIsValid ? theme.colors.red : theme.colors.black }}
            />

            <TYPE.body style={{ paddingLeft: '4px' }} fontSize={20}>
              %
            </TYPE.body>
          </OptionCustom>
        </AutoRow>
        {!!slippageError && (
          <RowBetween
            style={{
              fontSize: '16px',
              color: slippageError === SlippageError.InvalidInput ? '#E85A44' : '#E85A44'
            }}
          >
            {slippageError === SlippageError.InvalidInput
              ? 'Enter a valid slippage percentage'
              : slippageError === SlippageError.RiskyLow
              ? 'Your transaction may fail'
              : 'Your transaction may be frontrun'}
          </RowBetween>
        )}
      </AutoColumn>
    </AutoColumn>
  )
}
