import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import { RowBetween } from '../Row'
import { Text } from 'rebass'
import { AutoColumn } from '../Column'
import { IconWarning } from '../Icons'

export const InputPanel = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.bg0};
  z-index: 1;
`

export const TradeTokenSelect = styled.button<{ selected: boolean }>`
  align-items: center;
  height: 2.2rem;
  font-size: 18px;
  font-weight: normal;
  background-color: ${({ selected, theme }) => (selected ? theme.colors.white : theme.colors.white)};
  color: ${({ selected, theme }) => (selected ? theme.colors.text1 : theme.colors.text1)};
  border-radius: 8px;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0 0.5rem;
  margin-top: 4px;

  :focus,
  :hover {
    background-color: ${({ selected, theme }) => (selected ? theme.colors.white : theme.colors.white)};
  }
`

export const Container = styled.div`
border-radius: 10px;
// border: 2px solid ${({ theme }) => theme.colors.bg1};
background-color: ${({ theme }) => theme.colors.bg1};
`

export const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.colors.text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.5rem 1rem 0 1rem;
  margin-bottom: 7px;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.colors.text2)};
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-bottom: 3px;
`};
`

export const AdvancedDetailsFooter = styled.div<{ show: boolean }>`
  padding-top: calc(16px + 2rem);
  padding-bottom: 20px;
  margin-top: -2rem;
  width: 100%;
  max-width: 380px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: ${({ theme }) => theme.colors.text2};
  background-color: ${({ theme }) => theme.colors.bg5};
  z-index: -1;

  transform: ${({ show }) => (show ? 'translateY(0%)' : 'translateY(-100%)')};
  transition: transform 300ms ease-in-out;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		max-width: 420px;
	`};
`

export const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > img {
    width: 24px;
    height: 24px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 20px;
    height: 20px;
  `};
  }
`

export const ErrorText = styled(Text)<{ severity?: 0 | 1 | 2 | 3 | 4 }>`
  font-size: 16px;
  line-height: 16px;

  color: ${({ theme, severity }) =>
    severity === 3 || severity === 4
      ? theme.colors.primary4
      : severity === 2
      ? theme.colors.primary1
      : severity === 1
      ? theme.colors.text1
      : theme.colors.primary5};

  ${({ theme }) => theme.mediaWidth.upToSmall`
		font-size: 14px;
		line-height: 14px;
	`};
`

export const StyledBalanceMaxMini = styled.button`
  height: 16px;
  width: 16px;
  background-color: ${({ theme }) => theme.colors.primary1};
  border: none;
  border-radius: 2px;
  padding: 0;
  // font-size: 0.875rem;
  margin-left: 13px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;

  :hover {
    background-color: ${({ theme }) => theme.colors.red};
  }
  :focus {
    opacity: 0.7;
    outline: none;
  }
`

export const StyledTokenName = styled.span<{ active?: boolean }>`
${({ active }) => (active ? '  margin: 0 0.5rem 0 0.5rem;' : '  margin: 0 0.5rem 0 0.5rem;')}
font-size:  ${({ active }) => (active ? '18px' : '16px')};

${({ theme }) => theme.mediaWidth.upToSmall`
  margin: 0 0.3rem 0 0.3rem;

  font-size: 16px;
  line-height: 16px;
`};
`

export const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.colors.text1 : theme.colors.text1)};
    stroke-width: 2px;
  }
`

export const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.25rem 0.25rem 0.25rem 0.5rem')};
`

export const DisabledLabel = styled.div<{ empty?: boolean; placeholder?: string }>`
  color: #000000;
  width: 0;
  position: relative;
  font-weight: bold;
  outline: none;
  border: none;
  flex: 1 1 auto;
  background-color: transparent;
  font-family: 'Abadi MT Std', sans-serif;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 14px 8px;
  border-radius: 6px;
  margin-right: 6px;

  ${({ empty, placeholder }) =>
    empty &&
    `
  &:before {
  opacity: 0.5;
  content: "${placeholder}";
}`}
`

export const TradeTokenSearchTitle = styled(Text)`
  font-family: 'Retro';
  color: ${({ theme }) => theme.colors.text1};
`
export const TradeTokenSearchRow = styled(RowBetween)`
  position: relative;
`

export const StyledBalanceText = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  min-width: 65px;
  padding: 0 16px;
  overflow: hidden;

  font-family: 'Abadi MT Std', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: #999999;
  white-space: nowrap;
  text-overflow: ellipsis;

  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.bg0};
`

export const MenuItemWraper = styled.div`
  display: flex;
  padding: 5px 3px;
  height: 55px;

  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.white};
  border-radius: 10px;

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary1};
  }
`

const SwapCallbackErrorInner = styled.div`
  background: none;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 16px;
  width: 100%;
  padding: 0;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.text4};
  z-index: -1;
  p {
    padding: 0;
    margin: 0;
    font-weight: 500;
  }
`

const SwapCallbackErrorInnerAlertTriangle = styled.div`
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  border-radius: 0;
  min-width: 19px;
  height: 16px;
`

export function SwapCallbackError({ error }: { error: string }) {
  return (
    <SwapCallbackErrorInner>
      <SwapCallbackErrorInnerAlertTriangle>
        {/* #JUNK <AlertTriangle size={24} /> */}
        <IconWarning />
      </SwapCallbackErrorInnerAlertTriangle>
      <p>{error}</p>
    </SwapCallbackErrorInner>
  )
}

export const SwapShowAcceptChanges = styled(AutoColumn)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  padding: 10px;
  border-radius: 0 0 12px 12px;
  margin-top: 5px;
`

export const StyledBalanceMax = styled.button`
  height: 27px;
  background-color: ${({ theme }) => theme.colors.primary1};
  border: 2px solid ${({ theme }) => theme.colors.primary1};
  border-color: ${({ theme }) => theme.colors.primary1};
  border-radius: 0.5rem;
  font-size: 16px;

  font-weight: normal;
  cursor: pointer;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.colors.buttonText};
  :hover {
    border: 2px solid ${({ theme }) => theme.colors.primary1};
  }
  :focus {
    border: 2px solid ${({ theme }) => theme.colors.primary1};
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
  height: 20px;

  font-size: 14px;
`};
`

export const StyledBalanceMaxDivider = styled.div`
  width: 1px;
  height: 24px;
  margin-left: 9px;
  margin-right: 12px;

  background-color: ${({ theme }) => theme.colors.text4};

  ${({ theme }) => theme.mediaWidth.upToSmall`
  height: 20px;
  margin-left: 2px;
  margin-right: 4px; 
`};
`

export const TokensWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 518px;
`
