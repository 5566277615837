import React from 'react'
import styled from 'styled-components'
import { TokenAmount } from '@wowswap-io/wowswap-sdk'
import { AutoColumn } from '../Column'
import Loader from '../Loader'
import { RowBetween, RowFixed } from '../Row'
import { TYPE } from '../Shared'

const SelectLeverageBody = styled.div``

const SelectLeverageWraper = styled.div`
  padding: 19px 15px;
  margin-bottom: 5px;

  background: #ffffff;
  border-radius: 10px;
`

export default function TradeInfo({
  shorting,
  amount,
  debtPayable,
  protocolFee
}: {
  shorting?: boolean
  amount?: TokenAmount
  debtPayable?: TokenAmount
  protocolFee?: TokenAmount
}) {
  const lines = [
    { amount: amount, label: shorting ? 'Collateral' : 'Swap value' },
    { amount: debtPayable, label: 'Debt payable' },
    { amount: protocolFee, label: 'Protocol fee' }
  ]
  return (
    <SelectLeverageBody>
      <SelectLeverageWraper>
        <AutoColumn gap="8px">
          {lines.map(({ amount, label }) => (
            <RowBetween key={label}>
              <RowFixed>
                <TYPE.baseGray>{label}</TYPE.baseGray>
              </RowFixed>
              {amount && (
                <TYPE.base>
                  {(amount as TokenAmount).toSignificant(6)} {(amount as TokenAmount).currency.symbol}
                </TYPE.base>
              )}
              {!amount && <Loader size="16px" />}
            </RowBetween>
          ))}
        </AutoColumn>
      </SelectLeverageWraper>
    </SelectLeverageBody>
  )
}
