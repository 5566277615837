import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { sentryConfig } from './config'

const SentryMonitoringService = {
  init: () => {
    Sentry.init({
      dsn: sentryConfig.dsn,
      integrations: [new BrowserTracing()],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.05,
      enabled: process.env.NODE_ENV === 'production'
    })
  },

  setTag: (name: string, value: string) => {
    Sentry.setTag(name, value)
  }
}

export default SentryMonitoringService
