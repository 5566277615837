import { createAction } from '@reduxjs/toolkit'

export enum DepositStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  APPROVE_REQUIRED = 'APPROVE_REQUIRED'
}

export enum EarnDirection {
  Deposit = 'DEPOSIT',
  Withdraw = 'WITHDRAW'
}

export interface EarnProperties {
  token?: string
  direction?: EarnDirection
  input: string
}

export const setToken = createAction<{ token: string }>('earn/setToken')
export const setDirection = createAction<{ deposit: boolean }>('earn/setDirection')
export const setProperties = createAction<{ data: EarnProperties }>('earn/setProperties')
export const typeInput = createAction<{ amount: string }>('earn/typeInput')
export const clear = createAction('earn/clear')
