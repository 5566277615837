import React from 'react'
import styled from 'styled-components'
import { Param } from '../../state/financial/actions'
import { useFinancialModelInfo } from '../../state/financial/hooks'
import { FeeDistributionItem } from './FeeDistributionItem'

const FeeDistributionTableStyle = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
		padding: 5px 0;
		
		background-color: ${({ theme }) => theme.colors.white};
		border-radius: 10px;
	`};
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 16px;
  padding-right: 20px;
  padding-bottom: 3px;

  font-weight: 500;
  font-size: 16px;
  line-height: 16px;

  & > div:nth-child(1) {
    flex-grow: 1;
  }
  & > div:nth-child(2) {
    width: 15%;
    flex: 0 0 15%;
  }
  & > div:nth-child(3) {
    width: 15%;
    flex: 0 0 15%;
  }
  & > div:nth-child(4) {
    width: 36%;
    flex: 0 0 36%;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		display: none;
	`};
`

const HeaderItem = styled.div``

const Body = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
		& > div:last-child {
			border-bottom: none;
		}
	`};
`

export function FeeDistributionTable({ onParamChange }: { onParamChange: (field: Param, value: number) => void }) {
  const { params } = useFinancialModelInfo()

  return (
    <FeeDistributionTableStyle>
      <Header>
        <HeaderItem>Name</HeaderItem>
        <HeaderItem>Current Value</HeaderItem>
        <HeaderItem>New Value</HeaderItem>
        <HeaderItem>Your Value</HeaderItem>
      </Header>

      <Body>
        {Object.entries(params).map(([key, item]) => (
          <FeeDistributionItem
            key={key}
            field={key as Param}
            icon={item.icon}
            title={item.title}
            currentValue={item.currentValue}
            newValue={item.newValue}
            yourValue={item.userValue}
            onChange={onParamChange}
          />
        ))}
      </Body>
    </FeeDistributionTableStyle>
  )
}
