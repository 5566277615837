import { TypedDataUtils } from 'eth-sig-util'
import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { BigNumberish, BytesLike, ethers } from 'ethers'
import { useMemo } from 'react'
import { Currency, CurrencyAmount, TokenAmount, Trade } from '@wowswap-io/wowswap-sdk'
import { INITIAL_ALLOWED_SLIPPAGE, ROUTER_ADDRESS } from '../constants'
import { getTradeVersion } from '../data/V1'
import { useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin, getRouterContract, isAddress, shortenAddress } from '../utils'
import isZero from '../utils/isZero'
import { useActiveWeb3React } from './index'
import { ApprovalState } from './useApproveCallback'
import useENS from './useENS'
import { useGuardedPriceSignature } from './useGuardedPriceSignature'
import { Version } from './useToggledVersion'
import { SignatureLike } from '@ethersproject/bytes'
import { getDefaultToken } from '../state/wallet/hooks'

export enum SwapCallbackState {
  INVALID,
  LOADING,
  VALID
}
interface SwapArgs {
  /**
   * The method to call on the Uniswap V2 Router.
   */
  methodName: string
  /**
   * The arguments to pass to the method, all hex encoded.
   */
  args: any[]
  /**
   * The amount of wei to send in hex.
   */
  value: string
}
interface SwapCall {
  contract: Contract
  parameters: SwapArgs
}

interface SuccessfulCall {
  call: SwapCall
  gasEstimate: BigNumber
}

interface FailedCall {
  call: SwapCall
  error: Error
}

type OpenPositionRequest = {
  short: boolean
  amountIn: BigNumberish
  leverageFactor: BigNumberish
  amountOutMin: BigNumberish
  lendable: string
  proxy: string
  tradable: string
  trader: string
  deadline: BigNumberish
  referrer: string
  guardedPrice: {
    minPrice: BigNumberish
    maxPrice: BigNumberish
    deadline: BigNumberish
    signature: { v: BigNumberish; r: BytesLike; s: BytesLike }
  }
  convertFromNative: boolean
}

type ClosePositionRequest = {
  short: boolean
  amountIn: BigNumberish
  amountOutMin: BigNumberish
  lendable: string
  proxy: string
  tradable: string
  trader: string
  deadline: BigNumberish
  referrer: string
  permit: { v: BigNumberish; r: BytesLike; s: BytesLike }
  convertToNative: boolean
}

export const NO_SIGNATURE = {
  v: 0,
  r: ethers.utils.formatBytes32String(''),
  s: ethers.utils.formatBytes32String('')
} as const

export const NO_GUARDED_PRICE = {
  minPrice: 0,
  maxPrice: 0,
  deadline: ethers.constants.MaxUint256,
  signature: NO_SIGNATURE
} as const

export const EMPTY_CLOSE_POSITION_REQUEST: ClosePositionRequest = {
  short: false,
  amountIn: ethers.constants.MaxUint256,
  amountOutMin: 0,
  lendable: ethers.constants.AddressZero,
  proxy: ethers.constants.AddressZero,
  tradable: ethers.constants.AddressZero,
  trader: ethers.constants.AddressZero,
  deadline: ethers.constants.MaxUint256,
  referrer: ethers.constants.AddressZero,
  permit: { ...NO_SIGNATURE },
  convertToNative: false
} as const

export const EMPTY_OPEN_POSITION_REQUEST: OpenPositionRequest = {
  short: false,
  amountIn: 0,
  leverageFactor: 0,
  amountOutMin: 0,
  lendable: ethers.constants.AddressZero,
  proxy: ethers.constants.AddressZero,
  tradable: ethers.constants.AddressZero,
  trader: ethers.constants.AddressZero,
  deadline: ethers.constants.MaxUint256,
  referrer: ethers.constants.AddressZero,
  guardedPrice: { ...NO_GUARDED_PRICE },
  convertFromNative: false
} as const

export interface LeverageTradeInfo {
  lendable?: string
  tradeble?: string
  proxyble?: string
  pair?: string
  isShortTrade?: boolean
  isOpenPosition: boolean
  isMaxPosition: boolean
  leverageFactor: number
  typedAmount: CurrencyAmount
}

const NAME = 'WOWswap'
const VERSION = '1'

const EIP712Domain = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'chainId', type: 'uint256' },
  { name: 'verifyingContract', type: 'address' }
]

const Permit = [
  { name: 'owner', type: 'address' },
  { name: 'spender', type: 'address' },
  { name: 'value', type: 'uint256' },
  { name: 'nonce', type: 'uint256' },
  { name: 'deadline', type: 'uint256' }
]

export const domainSeparator = (chainId: number, verifyingContract: string) => {
  return (
    '0x' +
    TypedDataUtils.hashStruct(
      'EIP712Domain',
      { name: NAME, version: VERSION, chainId, verifyingContract },
      { EIP712Domain }
    ).toString('hex')
  )
}

type EstimatedSwapCall = SuccessfulCall | FailedCall

/**
 * Returns the swap calls that can be used to make the trade
 * @param trade trade to execute
 * @param allowedSlippage user allowed slippage
 * @param recipientAddressOrName
 * @param options additional params for open close methods
 */
function useSwapCallArguments(
  trade: Trade | undefined, // trade to execute, required
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE, // in bips
  recipientAddressOrName: string | null, // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
  leverageTrade: LeverageTradeInfo,
  guardedPrice?: any
): SwapCall[] {
  const { account, chainId, library } = useActiveWeb3React()
  const { isOpenPosition, isShortTrade, leverageFactor, tradeble } = leverageTrade

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress

  return useMemo(() => {
    if (!guardedPrice) {
      return []
    }

    const tradeVersion = getTradeVersion(trade)
    if (!trade || !recipient || !library || !account || !tradeVersion || !chainId || !tradeble) return []

    const contract: Contract | null = getRouterContract(chainId, library, account)
    if (!contract) {
      return []
    }

    const swapMethods: SwapArgs[] = []

    if (isOpenPosition) {
      const convertFromNative = trade.inputAmount.currency === Currency.getBaseCurrency()
      const lendable = convertFromNative ? ethers.constants.AddressZero : leverageTrade.lendable!
      const amountIn = convertFromNative ? '0' : leverageTrade.typedAmount.raw.toString(10)
      const value = convertFromNative ? leverageTrade.typedAmount.raw.toString(10) : '0'

      const request: OpenPositionRequest = {
        amountIn,
        lendable,
        amountOutMin: '0',
        proxy: leverageTrade.proxyble || ethers.constants.AddressZero,
        tradable: leverageTrade.tradeble!,
        trader: account!,
        deadline: ethers.constants.MaxUint256,
        referrer: ethers.constants.AddressZero,
        convertFromNative,
        short: Boolean(isShortTrade),
        leverageFactor: (leverageFactor * 10 ** 4).toFixed(0),
        guardedPrice: guardedPrice || NO_GUARDED_PRICE
      }

      swapMethods.push({
        methodName: 'openPosition',
        args: [request],
        value
      })
    } else {
      const defaultToken = getDefaultToken()
      const convertToNative = (trade.outputAmount as TokenAmount).token.address === defaultToken.address
      const lendable = convertToNative ? ethers.constants.AddressZero : leverageTrade.lendable!

      const request: ClosePositionRequest = {
        amountIn: leverageTrade.isMaxPosition ? ethers.constants.MaxUint256 : trade.inputAmount.raw.toString(10),
        amountOutMin: '0',
        lendable,
        tradable: leverageTrade.tradeble!,
        proxy: leverageTrade.proxyble || ethers.constants.AddressZero,
        trader: account!,
        deadline: ethers.constants.MaxUint256,
        referrer: ethers.constants.AddressZero,
        convertToNative,
        short: Boolean(isShortTrade),
        permit: NO_SIGNATURE
      }

      swapMethods.push({
        methodName: 'closePosition',
        args: [request],
        value: '0'
      })
    }

    return swapMethods.map(parameters => ({ parameters, contract }))
  }, [
    leverageTrade,
    account,
    // allowedSlippage, TODO: add slippage back
    // recipientAddressOrName,
    chainId,
    // deadline,
    library,
    recipient,
    trade,
    tradeble,
    isOpenPosition,
    leverageFactor,
    isShortTrade,
    guardedPrice
  ])
}

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapCallback(
  trade: Trade | undefined, // trade to execute, required
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE, // in bips
  recipientAddressOrName: string | null, // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
  leverageTrade: LeverageTradeInfo,
  approval: ApprovalState
): { state: SwapCallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const provider = useActiveWeb3React()
  const { account, chainId, library } = provider

  const { data: guardedPrice } = useGuardedPriceSignature(leverageTrade.pair)

  const swapCalls = useSwapCallArguments(trade, allowedSlippage, recipientAddressOrName, leverageTrade, guardedPrice)

  console.log({ swapCalls })

  const addTransaction = useTransactionAdder()

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress

  return useMemo(() => {
    if (!trade || !library || !account || !chainId) {
      return { state: SwapCallbackState.INVALID, callback: null, error: 'Loading...' }
    }
    if (!recipient) {
      if (recipientAddressOrName !== null) {
        return { state: SwapCallbackState.INVALID, callback: null, error: 'Invalid recipient' }
      } else {
        return { state: SwapCallbackState.LOADING, callback: null, error: null }
      }
    }

    const tradeVersion = getTradeVersion(trade)

    return {
      state: SwapCallbackState.VALID,
      callback: async function onSwap(): Promise<string> {
        const signPermit = async (
          pair: any,
          spender: string,
          value: BigNumberish,
          deadline = ethers.constants.MaxUint256
        ) => {
          const signature: SignatureLike = await library.send('eth_signTypedData_v4', [
            account,
            JSON.stringify({
              primaryType: 'Permit',
              types: { EIP712Domain, Permit },
              domain: {
                name: NAME,
                version: VERSION,
                chainId: chainId,
                verifyingContract: pair
              },
              message: {
                owner: account,
                spender,
                value: value.toString(),
                nonce: 0,
                deadline: deadline.toString()
              }
            })
          ])

          return ethers.utils.splitSignature(signature)
        }

        const closePermit =
          !leverageTrade.isOpenPosition && approval !== ApprovalState.APPROVED
            ? await signPermit(
                leverageTrade.pair,
                ROUTER_ADDRESS[chainId],
                ethers.constants.MaxUint256,
                ethers.constants.MaxUint256
              )
            : undefined

        const estimatedCalls: EstimatedSwapCall[] = await Promise.all(
          swapCalls.map(call => {
            const {
              parameters: { methodName, args, value },
              contract
            } = call
            const options = value && !isZero(value) ? { value, from: account } : { from: account }

            console.log({ options, swapCalls })

            if (closePermit) {
              args[0].permit = closePermit
            }

            return contract.estimateGas[methodName](...args, { ...options })
              .then(gasEstimate => {
                return {
                  call,
                  gasEstimate
                }
              })
              .catch(gasError => {
                console.debug('Gas estimate failed, trying eth_call to extract error', call, gasError)

                return contract.callStatic[methodName](...args, options)
                  .then(result => {
                    return { call, error: new Error('Unexpected issue with estimating the gas. Please try again.') }
                  })
                  .catch(callError => {
                    let errorMessage: string
                    switch (callError.reason) {
                      case 'UniswapV2Router: INSUFFICIENT_OUTPUT_AMOUNT':
                      case 'UniswapV2Router: EXCESSIVE_INPUT_AMOUNT':
                        errorMessage =
                          'This transaction will not succeed either due to price movement or fee on transfer. Try increasing your slippage tolerance.'
                        break
                      default:
                        errorMessage =
                          `The transaction cannot succeed due to error: ${callError.reason}. This is probably an issue with one of the tokens you are swapping.` +
                          callError
                    }
                    return { call, error: new Error(errorMessage) }
                  })
              })
          })
        )
        // a successful estimation is a bignumber gas estimate and the next call is also a bignumber gas estimate
        const successfulEstimation = estimatedCalls.find(
          (el, ix, list): el is SuccessfulCall =>
            'gasEstimate' in el && (ix === list.length - 1 || 'gasEstimate' in list[ix + 1])
        )

        if (!successfulEstimation) {
          const errorCalls = estimatedCalls.filter((call): call is FailedCall => 'error' in call)
          if (errorCalls.length > 0) throw errorCalls[errorCalls.length - 1].error
          throw new Error('Unexpected error. Please contact support: none of the calls threw an error')
        }

        const {
          call: {
            contract,
            parameters: { methodName, args, value }
          },
          gasEstimate
        } = successfulEstimation

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(gasEstimate),
          ...(value && !isZero(value) ? { value, from: account } : { from: account })
        })
          .then((response: any) => {
            const inputSymbol = trade.inputAmount.currency.symbol
            const outputSymbol = trade.outputAmount.currency.symbol
            const inputAmount = trade.inputAmount.toSignificant(3)
            const outputAmount = trade.outputAmount.toSignificant(3)

            const base = `Swapped ${inputAmount} ${inputSymbol} for ${outputAmount} ${outputSymbol}`
            const withRecipient =
              recipient === account
                ? base
                : `${base} to ${
                    recipientAddressOrName && isAddress(recipientAddressOrName)
                      ? shortenAddress(recipientAddressOrName)
                      : recipientAddressOrName
                  }`

            const withVersion =
              tradeVersion === Version.v2 ? withRecipient : `${withRecipient} on ${(tradeVersion as any).toUpperCase()}`

            addTransaction(response, {
              summary: withVersion
            })

            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === 4001) {
              throw new Error('Transaction rejected.')
            } else {
              // otherwise, the error was unexpected and we need to convey that
              console.error(`Swap failed`, error, methodName, args, value)
              throw new Error(`Swap failed: ${error.message}`)
            }
          })
      },
      error: null
    }
  }, [
    trade,
    library,
    account,
    chainId,
    recipient,
    recipientAddressOrName,
    swapCalls,
    addTransaction,
    approval,
    leverageTrade.isOpenPosition,
    leverageTrade.pair
  ])
}
