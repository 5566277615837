import React, { useState, useEffect, useCallback } from 'react'
import styled, { css } from 'styled-components'

// refs to: https://codepen.io/JiveDig/pen/jbdJXR

const SwitchWrapper = styled.div<{ isSingle: boolean }>`
  width: 100%;
  .switch-field {
    display: flex;
    overflow: hidden;
    justify-content: ${({ isSingle }) => (isSingle ? 'flex-start' : 'center')};
    line-height: 16px;
    border-radius: 20px 20px 0 0;
    position: relative;
  }

  .switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }

  .switch-field label {
    background-color: ${({ theme }) => theme.colors.bg1};
    border-radius: 20px 20px 0 0;
    border: 4px solid ${({ theme }) => theme.colors.bg1};
    border-bottom: none;
    color: rgba(0, 0, 0, 0.6);
    flex: 0 0 50%;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    padding: 9px 0 29px;
    transition: all 0.1s ease-in-out;
  }

  .switch-field label:hover {
    cursor: pointer;
    background: #f5da9f;
    border-color: #f5da9f;
  }

  .switch-field input:checked + label {
    background-color: ${({ theme }) => theme.colors.bg2};
    border-color: ${({ theme }) => theme.colors.black};
    box-shadow: none;

    &:first-of-type {
      background-color: ${({ theme }) => theme.colors.primary5};
    }

    ${({ theme, isSingle }) =>
      isSingle
        ? ''
        : css`
            &:last-of-type {
              background-color: ${theme.colors.primary4};
            }
          `}
  }
`

const SwitchLabel = styled.label`
  font-family: 'Retro', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
`

interface ISwitchProps {
  options: Array<{ value: string; label: string }>
  onChange: (value: string) => void
  initialValue?: string
  value: string
}

export const Switch: React.FC<ISwitchProps> = ({ initialValue, options, onChange, value }) => {
  const [selectedOption, setSelectedOption] = useState(initialValue || options[0].value)

  const handleChange = useCallback(
    (value: string) => {
      setSelectedOption(value)
      onChange(value)
    },
    [setSelectedOption, onChange]
  )

  useEffect(() => {
    if (value !== selectedOption) {
      handleChange(value)
    }
  }, [value, selectedOption, handleChange])

  return (
    <SwitchWrapper isSingle={options.length === 1}>
      <div className="switch-field">
        {options.map(option => {
          const id = `radio-${option.label}`
          return (
            <React.Fragment key={id}>
              <input
                id={id}
                type="radio"
                name={`switch-${option.label}`}
                value={option.value}
                checked={selectedOption === option.value}
                onChange={e => handleChange(e.target.value)}
              />
              <SwitchLabel htmlFor={id}>{option.label}</SwitchLabel>
            </React.Fragment>
          )
        })}
      </div>
    </SwitchWrapper>
  )
}
