import React, { useCallback, useContext, useState } from 'react'
import { ChainId, TokenAmount, Trade } from '@wowswap-io/wowswap-sdk'
import { Text } from 'rebass'
import { ArrowDown } from 'react-feather'
import styled, { ThemeContext } from 'styled-components'
import TagManager from 'react-gtm-module'
import { Switch } from '../components/Switch'
import { TradeDetails } from '../components/trade/tradeDetails/TradeDetails'
import { Field } from '../state/trade/actions'

import Card from '../components/Card'
import { BlockHeader } from '../components/BlockHeader'
import { BlockTitle } from '../components/BlockTitle'
import { ButtonConfirmed, ButtonLight, ButtonError } from '../components/Button'
import { AutoColumn } from '../components/Column'
import { PageTitel } from '../components/PageTitel'
import { AutoRow, RowBetween } from '../components/Row'
import SelectLeverage from '../components/SelectLeverage'
import Settings from '../components/Settings'
import TradeInfo from '../components/TradeInfo'
import TradePrice from '../components/swap/TradePrice'
import { TradeBody } from './AppBody'
import { TextBase } from '../components/Text'
import { ClickableText } from './Pool/styleds'
import { TYPE } from '../components/Shared'
import { BottomGrouping, SwapCallbackError } from '../components/swap/styleds'
import Loader from '../components/Loader'
import ProgressSteps from '../components/ProgressSteps'
import confirmPriceImpactWithoutFee from '../components/swap/confirmPriceImpactWithoutFee'
import ConfirmSwapModal from '../components/trade/ConfirmSwapModal'
import TradeTokenInputPanel from '../components/trade/TradeTokenInputPanel'
import ApprovalPendingModal from '../components/ApprovalPendingModal'

import {
  useApproval,
  useDerivedTradeInfo,
  useIsMoreThenMaximumAmount,
  useMinAmountInForTrade,
  useTradeActionHandlers,
  useTradeState
} from '../state/trade/hooks'
import { useActiveWeb3React, useChainTradeWith, useChainLabel } from '../hooks'
import { useUserSlippageTolerance } from '../state/user/hooks'
import { useToggleSettingsMenu, useWalletModalToggle, useAddPopup } from '../state/application/hooks'
import { useInitParams as useGovernanceInitParams } from '../state/financial/hooks'

import { INITIAL_ALLOWED_SLIPPAGE } from '../constants'
import { ApprovalState } from '../hooks/useApproveCallback'
import { computeTradePriceBreakdown, warningSeverity } from '../utils/prices'
import { useSwapCallback } from '../hooks/useSwapCallback'

import { TradeToken } from '../hooks/Tokens.types'
import { ethers } from 'ethers'

const Title = styled(PageTitel)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

const PaddedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;
  position: relative;
  z-index: 2;

  padding: 20px;
  padding-top: 20px;
  padding-bottom: 120px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
padding: 12px;
padding-top: 68px;
padding-bottom: 78px;
`};
`

export const ArrowWrapper = styled.div`
  padding: 2px;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export const Wrapper = styled.div`
  position: relative;
`

const SwitchWrapper = styled.div`
  max-width: 420px;
  width: 100%;
  transform: translateY(20px);
`

const LEVEREGE_DISABLED_TRACK_WIDTH: {
  [tradeType: string]: {
    [maxLeverage: string]: string
  }
} = {
  short: {
    3: '41%',
    4: '20.5%',
    5: '0%'
  },
  long: {
    3: '50%',
    4: '25%',
    5: '0%'
  }
}

enum TRADE_MODE {
  SHORT = 'short',
  LONG = 'long'
}

const TRADE_MODE_SWITCH_OPTIONS = [{ value: TRADE_MODE.LONG, label: 'Long' }]

const TRADE_MODE_LEVERAGE: Record<TRADE_MODE, number> = {
  [TRADE_MODE.LONG]: 1,
  [TRADE_MODE.SHORT]: 0.1
}

export function TradePage() {
  useGovernanceInitParams() // hack to calculate leverage

  return <TradePageComponent />
}

function TradePageComponent() {
  const { account, chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const addPopup = useAddPopup()
  const {
    isOpenPosition,
    inputToken,
    outputToken,
    position,
    possibleInputs,
    possibleOutputs,
    inputError,
    maxLeverage
  } = useDerivedTradeInfo()
  const state = useTradeState()

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeLeverage,
    onShortTradeModeChange,
    onMaxPosition
  } = useTradeActionHandlers()

  const [showInverted, setShowInverted] = useState<boolean>(false)

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()
  const toggleSettings = useToggleSettingsMenu()

  const [allowedSlippage] = useUserSlippageTolerance()

  const recipient = null

  const { approval, approvalSubmitted, showApproveFlow, approveCallback, setApprovalSubmitted } = useApproval()

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const [{ attemptingApprove }, setApproveState] = useState<{
    attemptingApprove: boolean
  }>({
    attemptingApprove: false
  })

  const isValid = !inputError

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(position.trade)
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    position.trade,
    allowedSlippage,
    recipient,
    {
      isOpenPosition,
      isShortTrade: state.isShortTrade,
      isMaxPosition: state.isMaxPosition,
      lendable: isOpenPosition ? inputToken?.lendableAddress : outputToken?.lendableAddress,
      tradeble: isOpenPosition ? outputToken?.tradableAddress : inputToken?.tradableAddress,
      proxyble: isOpenPosition ? outputToken?.proxybleAddress : inputToken?.proxybleAddress,
      pair: isOpenPosition ? outputToken?.pairAddress : inputToken?.pairAddress,
      leverageFactor: position.leverage,
      typedAmount:
        state.isShortTrade && !isOpenPosition && inputToken && state.isMaxPosition
          ? new TokenAmount(inputToken, ethers.constants.MaxUint256.toString())
          : position.typedAmount!
    },
    approval
  )

  // handlers
  const handleCurrencySelect = useCallback(
    (field: Field, token: TradeToken) => {
      onCurrencySelection(field, token)
      setSwapState({
        showConfirm,
        tradeToConfirm,
        swapErrorMessage: undefined,
        attemptingTxn,
        txHash
      })
    },
    [onCurrencySelection, showConfirm, tradeToConfirm, attemptingTxn, txHash]
  )

  const handleTypeInput = useCallback(
    (value: string) => {
      if (state.typedValue !== value) {
        onUserInput(value)
        onMaxPosition(false)
        setSwapState({
          showConfirm,
          tradeToConfirm,
          swapErrorMessage: undefined,
          attemptingTxn,
          txHash
        })
      }
    },
    [onUserInput, showConfirm, tradeToConfirm, attemptingTxn, txHash, state.typedValue, onMaxPosition]
  )

  const handleChangeLeverage = useCallback(
    (value: string) => {
      onChangeLeverage(parseFloat(value))
    },
    [onChangeLeverage]
  )

  const handleOnMax = () => {
    if (inputToken && inputToken.balance && state.typedValue !== inputToken.balance.toExact()) {
      const toAmount = (token: TradeToken): string => {
        const balanceToExact = token?.balance?.toExact() ?? '0'
        const isNativeToken = token?.lendableInfo?.isNative ?? false

        if (isNativeToken) {
          const decreasedBalanceToExact = parseFloat(balanceToExact) - 0.01
          return (decreasedBalanceToExact < 0 ? 0 : decreasedBalanceToExact).toString()
        }

        return balanceToExact
      }

      onUserInput(toAmount(inputToken))
      onMaxPosition(true)
    }
  }

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: position.trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, position.trade, txHash])

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput('')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleSwap = useCallback(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'ClickSwap'
      }
    })

    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then(hash => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })
        TagManager.dataLayer({
          dataLayer: {
            event: 'ClickConfSwap'
          }
        })
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [tradeToConfirm, priceImpactWithoutFee, showConfirm, swapCallback])

  const isMoreThenMaximumAmount = useIsMoreThenMaximumAmount()
  const minAmountInForTrade = useMinAmountInForTrade()

  const handleChangeLeverageExceedsMax = (exceedsValue: number) => {
    const needs = exceedsValue <= 4 ? maxLeverage.needToX4 : maxLeverage.needToX5
    const leverage = exceedsValue <= 4 ? 'x4' : 'x5'

    if (needs > 0) {
      addPopup({
        message: {
          type: 'warning',
          message: `You need to hold ${needs} more of WOW or xWOW tokens to trade with up to ${leverage} leverage`
        }
      })
    }
  }

  const handleApprove = useCallback(() => {
    setApproveState({ attemptingApprove: true })
    approveCallback().then(() => {
      setApproveState({ attemptingApprove: false })
    })
  }, [approveCallback])

  const handleChangeTradeMode = useCallback(
    (value: string) => {
      onShortTradeModeChange(value === TRADE_MODE.SHORT)
      onChangeLeverage(TRADE_MODE_LEVERAGE[value as TRADE_MODE])
    },
    [onShortTradeModeChange, onChangeLeverage]
  )

  const TradeWith = useChainTradeWith()
  const MotherSwap = useChainLabel()

  // Couple of seconds after approve in wallet 'approval' change from PENDING to NOT_APPROVED
  const isApprovalPending =
    approval === ApprovalState.PENDING || (approval === ApprovalState.NOT_APPROVED && approvalSubmitted)

  const isPageTitleWithBackground = chainId === ChainId.ETHEREUM || chainId === ChainId.IOTEX

  return (
    <PaddedWrapper>
      <Title withBackground={isPageTitleWithBackground}>
        Trade {TradeWith} {MotherSwap} with up to <b style={{ fontWeight: 'normal' }}>5X leverage</b>
      </Title>

      <SwitchWrapper>
        <Switch
          initialValue={getTradeMode(state.isShortTrade)}
          value={getTradeMode(state.isShortTrade)}
          options={TRADE_MODE_SWITCH_OPTIONS}
          onChange={handleChangeTradeMode}
        />
      </SwitchWrapper>
      <TradeBody isShortTrade={state.isShortTrade}>
        <BlockHeader marginBottom="10px">
          <BlockTitle>Swap</BlockTitle>

          <Settings />
        </BlockHeader>

        <Wrapper id="trade-page">
          <ConfirmSwapModal
            isOpen={showConfirm}
            position={position}
            typedValue={state.typedValue}
            isOpenPosition={isOpenPosition}
            inputToken={inputToken}
            outputToken={outputToken}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          />

          <ApprovalPendingModal
            isOpen={attemptingApprove}
            onDismiss={() => setApproveState({ attemptingApprove: false })}
            pendingText=""
          />

          <TradeTokenInputPanel
            label="From"
            id="trade-input"
            value={state.typedValue}
            token={inputToken}
            variants={possibleInputs}
            other={outputToken}
            showMaxButton
            onMax={handleOnMax}
            onUserInput={handleTypeInput}
            onTradeTokenSelect={token => handleCurrencySelect(Field.INPUT, token)}
          />

          <SwitchTokensRow
            onClick={() => {
              setApprovalSubmitted(false) // reset 2 step UI for approvals
              onSwitchTokens()
            }}
          />

          {isOpenPosition ? (
            <SelectLeverage
              onChange={handleChangeLeverage}
              value={position.leverage.toFixed(2)}
              marks={state.isShortTrade ? [0.1, 1, 2, 3, 4, 5] : [1, 2, 3, 4, 5]}
              max={maxLeverage.level}
              onMax={handleChangeLeverageExceedsMax}
              from={state.isShortTrade ? 0.1 : 1}
              disabledTrackWidth={
                LEVEREGE_DISABLED_TRACK_WIDTH[`${state.isShortTrade ? 'short' : 'long'}`][maxLeverage.level]
              }
            />
          ) : (
            <TradeInfo
              shorting={state.isShortTrade}
              amount={position.amount}
              debtPayable={position.debtPayable}
              protocolFee={position.protocolFee}
            />
          )}

          <SwitchTokensRow
            onClick={() => {
              setApprovalSubmitted(false) // reset 2 step UI for approvals
              onSwitchTokens()
            }}
          />

          <TradeTokenInputPanel
            label="To"
            id="trade-input"
            value={isOpenPosition ? position.trade?.outputAmount.toSignificant(6)! : position.profit?.toSignificant(6)!}
            token={outputToken}
            variants={possibleOutputs}
            other={inputToken}
            onUserInput={handleTypeInput}
            onTradeTokenSelect={token => handleCurrencySelect(Field.OUTPUT, token)}
            disabled={true}
          />

          <Card padding={'6px 0 0 0'} borderRadius={'20px'}>
            <AutoColumn gap="8px">
              {Boolean(position.trade) && (
                <RowBetween align="center">
                  <TextBase color={theme.colors.text4}>Price</TextBase>

                  <TradePrice
                    price={position.trade?.executionPrice}
                    showInverted={showInverted}
                    setShowInverted={setShowInverted}
                  />
                </RowBetween>
              )}
              {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                <RowBetween align="center" marginBottom="10px">
                  <TextBase color={theme.colors.text4} onClick={toggleSettings} style={{ cursor: 'pointer' }}>
                    Slippage Tolerance
                  </TextBase>

                  <ClickableText
                    fontWeight={'normal'}
                    fontSize={16}
                    color={theme.colors.black}
                    onClick={toggleSettings}
                  >
                    {allowedSlippage / 100}%
                  </ClickableText>
                </RowBetween>
              )}
            </AutoColumn>
          </Card>
          <BottomGrouping style={{ marginTop: '8px' }}>
            {!account ? (
              <ButtonLight onClick={toggleWalletModal}>Connect to a Wallet</ButtonLight>
            ) : isMoreThenMaximumAmount ? (
              <ButtonError disabled>
                <TYPE.white mb="4px">
                  {state.isShortTrade ? "Amount exceeds the pool's size" : 'Amount exceeds pool limit'}
                </TYPE.white>
              </ButtonError>
            ) : minAmountInForTrade ? (
              <ButtonError disabled>
                <TYPE.white mb="4px">
                  Min swap size is {minAmountInForTrade} {inputToken?.symbol}
                </TYPE.white>
              </ButtonError>
            ) : showApproveFlow ? (
              <RowBetween>
                <ButtonConfirmed
                  onClick={handleApprove}
                  disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                  width="47%"
                  altDisabledStyle={isApprovalPending} // show solid button while waiting
                  confirmed={approval === ApprovalState.APPROVED}
                  pending={isApprovalPending}
                  style={{ padding: '0' }}
                >
                  {isApprovalPending ? (
                    <AutoRow gap="6px" justify="center">
                      Approving <Loader margin="0 -8px 0 0" />
                    </AutoRow>
                  ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                    'Approved'
                  ) : (
                    'Approve ' + inputToken?.symbol
                  )}
                </ButtonConfirmed>
                <ButtonError
                  onClick={() => {
                    setSwapState({
                      tradeToConfirm: position.trade,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      showConfirm: true,
                      txHash: undefined
                    })
                  }}
                  width="47%"
                  id="swap-button"
                  disabled={approval !== ApprovalState.APPROVED}
                  error={isValid && priceImpactSeverity > 2}
                >
                  <Text fontSize={16} fontWeight={'normal'}>
                    {getButtonLabel(state.isShortTrade, isOpenPosition)} {`${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                  </Text>
                </ButtonError>
              </RowBetween>
            ) : (
              <ButtonError
                onClick={() => {
                  setSwapState({
                    tradeToConfirm: position.trade,
                    attemptingTxn: false,
                    swapErrorMessage: undefined,
                    showConfirm: true,
                    txHash: undefined
                  })
                }}
                id="swap-button"
                disabled={!isValid || !!swapCallbackError}
                error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
              >
                <Text fontSize={16} fontWeight={'normal'}>
                  {inputError || swapCallbackError || getButtonLabel(state.isShortTrade, isOpenPosition)}
                </Text>
              </ButtonError>
            )}

            {showApproveFlow && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />}
            {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
          </BottomGrouping>
        </Wrapper>
      </TradeBody>
      <TradeDetails trade={position.trade} isOpenPosition={isOpenPosition} position={position} />
    </PaddedWrapper>
  )
}

function SwitchTokensRow({ onClick }: { onClick: () => void }) {
  const theme = useContext(ThemeContext)
  return (
    <AutoColumn justify="space-between">
      <AutoRow justify="center" style={{ padding: '0 1rem' }}>
        <ArrowWrapper>
          <ArrowDown size="20" onClick={onClick} color={theme.colors.black} />
        </ArrowWrapper>
      </AutoRow>
    </AutoColumn>
  )
}

function getButtonLabel(isShortTrade: boolean, isOpenPosition: boolean): string {
  if (isShortTrade) {
    if (isOpenPosition) {
      return 'SHORT SELL'
    } else {
      return 'SWAP'
    }
  } else {
    if (isOpenPosition) {
      return 'LONG BUY'
    } else {
      return 'SELL'
    }
  }
}

function getTradeMode(isShortTrade: boolean): string {
  return isShortTrade ? TRADE_MODE.SHORT : TRADE_MODE.LONG
}
