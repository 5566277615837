import styled from 'styled-components'

import { ReactComponent as CloseIcon } from 'assets/images/close.svg'
import { ReactComponent as MenuIcon } from 'assets/svg/menu.svg'

export const StyledMenuIcon = styled(MenuIcon)`
  path {
    stroke: ${({ theme }) => theme.colors.text1};
  }
`

export const NavWrapper = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 30px;
`

export const CloseBtn = styled(CloseIcon)`
  position: absolute;
  right: 10px;
  top: 18px;
  width: 17px;
  height: 17px;
  fill: black;
`

export const StyledSideMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;

  :hover,
  :focus {
    cursor: pointer;
    outline: none;
  }
`

export const StyledSideMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

export const SideMenuLayout = styled.section<{ open: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: ${({ open }) => (open ? '0' : '-244px')};
  z-index: 100;

  display: flex;
  flex-direction: column;
  width: 244px;

  background-color: ${({ theme }) => theme.colors.bg0};
  border-left: 2px solid ${({ theme }) => theme.colors.black};
  transition: right 200ms;
`
