import React from 'react'
import styled from 'styled-components'

const PageTitelStyle = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom || '20px'};

  text-align: center;
  font-family: Abadi MT Std;
  font-weight: 200;
  font-size: 26px;
  line-height: 34px;
  color: #000000;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		margin-bottom: 10px;
		font-size: 18px;
		line-height: 23px;
	`};
`

const PageTitelWithBackgroundStyle = styled(PageTitelStyle)`
  background: ${({ theme }) => theme.colors.pageTitelBG};
  padding: 10px 22px 5px 22px;
  border-radius: 10px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px 7px 5px 7px;
  `};
`

export function PageTitel(props: { children: React.ReactNode; marginBottom?: string; withBackground?: boolean }) {
  const { children, withBackground, ...rest } = props
  return withBackground ? (
    <PageTitelWithBackgroundStyle {...rest}>{children}</PageTitelWithBackgroundStyle>
  ) : (
    <PageTitelStyle {...rest}>{children}</PageTitelStyle>
  )
}
