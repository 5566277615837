import React from 'react'
import ReactSlider from 'react-slider'

import {
  SliderCustomBtn,
  SliderCustomValue,
  SliderCustomValueItem,
  SliderCustomValueItemDisabled,
  SliderCustomWraper,
  StyledReactSlider
} from './styleds'

interface SliderCustomProps {
  onChange: (value: number) => void
  value: number
  from: number
  to: number
  max: number
  onMax?: (exceedingValue: number) => void
  marks?: number[]
  disabledTrackWidth?: string
}

export default function SliderCustom({
  onChange,
  value,
  from,
  to,
  max,
  onMax,
  marks,
  disabledTrackWidth
}: SliderCustomProps) {
  const handleChange = (value: number | number[] | undefined | null) => {
    if (value && value <= max) {
      onChange(value as number)
    }
  }

  const handleAfterChange = (value: number | number[] | undefined | null) => {
    if (value && onMax && value > max) {
      onChange(max as number)
      onMax(value as number)
    }
  }

  return (
    <SliderCustomWraper>
      <StyledReactSlider>
        <ReactSlider
          className="slider"
          min={from}
          step={0.01}
          max={to}
          thumbClassName="thumb"
          trackClassName="track"
          onChange={handleChange}
          onAfterChange={handleAfterChange}
          value={value}
          renderThumb={props => (
            <div {...props}>
              <SliderCustomBtn />
            </div>
          )}
        />

        <div className={'disabled-track'} style={{ width: disabledTrackWidth }} />
      </StyledReactSlider>

      {marks && (
        <SliderCustomValue>
          {marks.map((mark: number) => {
            if (mark > max!) {
              return <SliderCustomValueItemDisabled key={mark}>{mark}X</SliderCustomValueItemDisabled>
            }

            return <SliderCustomValueItem key={mark}>{mark}X</SliderCustomValueItem>
          })}
        </SliderCustomValue>
      )}
    </SliderCustomWraper>
  )
}
