import styled from 'styled-components'

export const SliderCustomWraper = styled.div``

export const SliderCustomBtn = styled.div<any>`
  /* z-index: 5; */

  width: 17px;
  height: 17px;

  border-radius: 50%;
  background: #f3ba2f;
  border: 2px solid #000000;

  &:hover {
    cursor: pointer;
    background: #e85a44;
  }
`

export const SliderCustomValue = styled.ul`
  display: flex;
  justify-content: space-between;

  padding: 0;
  margin: 0;
  margin-top: 4px;

  list-style: none;
`
export const SliderCustomValueItem = styled.li`
  font-family: 'Retro', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 135%;
  color: #000000;
`
export const SliderCustomValueItemDisabled = styled(SliderCustomValueItem)`
  color: #d1d1cf;
`

export const StyledReactSlider = styled.div`
  position: relative;
  .disabled-track {
    width: 50%;
    position: absolute;
    height: 5px;
    background: gray;
    opacity: 0.4;
    right: 0;
    top: -5px;
    z-index: 2;
  }

  .slider {
    position: absolute;
    top: -10px;
    height: 20px;
  }

  .track {
    top: 5px;
    height: 5px;
    background: ${({ theme }) => theme.colors.primary1};
  }

  .track-0 {
    background: ${({ theme }) => theme.colors.primary1};
  }

  .track-1 {
    background: ${({ theme }) => theme.colors.bg0};
  }

  .track-2 {
    background: ${({ theme }) => theme.colors.secondary1};
  }

  .mark {
    cursor: pointer;
  }

  .thumb-0 {
    z-index: 3 !important;
  }

  .thumb {
    outline: none;
  }

  .thumb-1 {
    display: none;
  }
`

export const StyledReactSliderCommon = styled.div<{ min: number }>`
  position: relative;

  .disabled-track {
    width: ${({ min }) => min}%;
    position: absolute;
    height: 5px;
    background: gray;
    opacity: 0.4;
    left: 0;
    top: -5px;
    z-index: 2;
  }

  .slider {
    position: absolute;
    top: -10px;
    height: 20px;
  }

  .track {
    top: 5px;
    height: 5px;
    background: ${({ theme }) => theme.colors.primary1};
  }

  .track-0 {
    background: ${({ theme }) => theme.colors.primary1};
  }

  .track-1 {
    background: ${({ theme }) => theme.colors.bg0};
  }

  .track-2 {
    background: ${({ theme }) => theme.colors.secondary1};
  }

  .mark {
    cursor: pointer;
  }

  .thumb-0 {
    z-index: 3 !important;
  }

  .thumb {
    outline: none;
  }

  .thumb-1 {
    display: none;
  }
`

export const SliderGovValue = styled.ul`
  display: flex;
  justify-content: space-between;

  padding: 0;
  margin: 0;
  margin-top: -13px;

  list-style: none;
`

export const SliderGovValueItem = styled.li`
  font-family: 'Abadi', sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  color: #000000;
`

export const StyledReactSliderGov = styled.div<{ value: number }>`
  position: relative;

  .disabled-track {
    width: ${({ value }) => value}%;
    position: absolute;
    height: 5px;
    background: #f3ba2f;
    opacity: 1;
    right: 0;
    top: -5px;
    z-index: 2;
  }

  .slider {
    position: absolute;
    top: -10px;
    height: 20px;
  }

  .track {
    top: 5px;
    height: 5px;
    background: ${({ theme }) => theme.colors.primary1};
  }

  .track-0 {
    background: #42ce61;
  }

  .track-1 {
    background: #42ce61;
  }

  .track-2 {
    background: ${({ theme }) => theme.colors.secondary1};
  }

  .mark {
    cursor: pointer;
  }

  .thumb-0 {
    z-index: 3 !important;
  }

  .thumb {
    outline: none;
  }

  .thumb-1 {
    display: none;
  }
`
