import React from 'react'
import styled from 'styled-components'

const ContentWrapperStyle = styled.div<{
  padding?: string
  maxWidth?: string
  NoNegativeMargin?: boolean
  transparent?: boolean
}>`
  padding: ${({ padding }) => padding || '23px 26px 35px 26px'};
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 24px;
  max-width: ${({ maxWidth }) => maxWidth || '1200px'};

  ${({ transparent, theme }) =>
    transparent
      ? `
			border: none;
			border-radius: 0;
		`
      : `
			background-color: ${theme.colors.bg0};
			border: 4px solid ${theme.colors.black};
			border-radius: 20px;
		`}

  ${({ theme }) => theme.mediaWidth.upToLarge`
		max-width: 976px;
		margin: 0 auto;
		margin-top: 24px;		
	`};

  ${({ theme, NoNegativeMargin }) => theme.mediaWidth.upToMedium`
		padding: 17px 17px 28px 17px;
		margin-top: 84px;
		margin-left: -7px;
		margin-right: -7px;
		margin-bottom: 84px;

		border-radius: 42px;

		${NoNegativeMargin &&
      `
			margin-top: 0;
      margin-left: 12px;
			margin-right: 12px;

			border-radius: 20px;
    `}		
	`};
`

const ContentWrapperInBlockStyle = styled(ContentWrapperStyle)<{
  padding?: string
  maxWidth?: string
  NoNegativeMargin?: boolean
}>`
  margin-top: 0;

  ${({ theme }) => theme.mediaWidth.upToMedium`
		margin-top: 0;	
	`};
`

const ContentWrapperMobileFullWidthStyle = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
		margin-left: -14px;
		margin-right: -14px;
	`};
`
const ContentWrapperNoBgStyle = styled(ContentWrapperStyle)`
  border: none;
  background-color: transparent;
`
export function ContentWrapperMobileFullWidth(props: { children: React.ReactNode }) {
  const { children, ...rest } = props
  return <ContentWrapperMobileFullWidthStyle {...rest}>{children}</ContentWrapperMobileFullWidthStyle>
}

export function ContentWrapper(props: {
  children: React.ReactNode
  padding?: string
  maxWidth?: string
  NoNegativeMargin?: boolean
  transparent?: boolean
}) {
  const { children, ...rest } = props
  return <ContentWrapperStyle {...rest}>{children}</ContentWrapperStyle>
}

export function ContentWrapperInBlock(props: {
  children: React.ReactNode
  padding?: string
  maxWidth?: string
  NoNegativeMargin?: boolean
}) {
  const { children, ...rest } = props
  return <ContentWrapperInBlockStyle {...rest}>{children}</ContentWrapperInBlockStyle>
}

export function ContentWrapperNoBg(props: {
  children: React.ReactNode
  padding?: string
  maxWidth?: string
  NoNegativeMargin?: boolean
}) {
  const { children, ...rest } = props
  return <ContentWrapperNoBgStyle {...rest}>{children}</ContentWrapperNoBgStyle>
}
