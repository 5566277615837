import React from 'react'
import { Trade } from '@wowswap-io/wowswap-sdk'

import { useTradeState } from '../../../state/trade/hooks'
import LongTradeSwapModalFooter from './LongTradeSwapModalFooter'
import ShortTradeSwapModalFooter from './ShortTradeSwapModalFooter'
import { Position } from '../../../state/trade/types'

interface IFooterProps {
  trade: Trade
  allowedSlippage: number
  onConfirm: () => void
  swapErrorMessage: string | undefined
  disabledConfirm: boolean
  position: Position
  isOpenPosition: boolean
}

export const SwapModalFooter: React.FC<IFooterProps> = props => {
  const tradeState = useTradeState()

  return tradeState.isShortTrade ? <ShortTradeSwapModalFooter {...props} /> : <LongTradeSwapModalFooter {...props} />
}
