import styled from 'styled-components'
import { AutoColumn } from '../Column'
import { /*RowBetween,*/ RowFixed } from '../Row'

export const ModalInfo = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: 1rem 1rem;
  margin: 0.25rem 0.5rem;
  justify-content: center;
  flex: 1;
  user-select: none;
`

export const FadedSpan = styled(RowFixed)`
  color: ${({ theme }) => theme.colors.primary1};
  font-size: 14px;
`

export const PaddedColumn = styled(AutoColumn)`
  padding: 0 0 13px 0;
`

export const MenuItem = styled.div<{ disabled?: boolean; selected?: boolean }>`
  padding-bottom: 5px;

  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};

  opacity: ${({ disabled, selected }) => (disabled || selected ? 0.5 : 1)};
`

export const SearchInput = styled.input`
  position: relative;
  display: flex;
  padding: 15px 20px 14px 20px;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.black};
  border-style: solid;
  border: 3px solid ${({ theme }) => theme.colors.black};
  -webkit-appearance: none;

  font-family: 'Abadi MT Std', sans-serif;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.text4};

    font-weight: 200;
  }
  transition: border 100ms;

  &:hover,
  &:active,
  &:focus {
    border: 3px solid #f3ba2f;
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		padding: 13px 20px 12px 20px;
	`};
`
export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.bg2};
`

export const SeparatorDark = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.bg3};
`
