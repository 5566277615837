import React from 'react'
import ReactSlider from 'react-slider'

import {
  SliderCustomBtn,
  SliderCustomValue,
  SliderCustomValueItem,
  SliderCustomWraper,
  StyledReactSliderCommon
} from './styleds'

interface SliderCustomProps {
  onChange: (value: number) => void
  value: number
  from: number
  to: number
  marks?: string[]
  min: number
}

export default function SliderCommon({ onChange, value, from, to, marks, min }: SliderCustomProps) {
  const handleChange = (value: number | number[] | undefined | null) => {
    onChange(value as number)
  }

  return (
    <SliderCustomWraper>
      <StyledReactSliderCommon min={min}>
        <ReactSlider
          className="slider"
          min={from}
          step={0.01}
          max={to}
          thumbClassName="thumb"
          trackClassName="track"
          onChange={handleChange}
          value={value}
          renderThumb={props => (
            <div {...props}>
              <SliderCustomBtn />
            </div>
          )}
        />
        <div className={'disabled-track'} />
      </StyledReactSliderCommon>

      {marks && (
        <SliderCustomValue>
          {marks.map(mark => (
            <SliderCustomValueItem key={mark}>{mark}</SliderCustomValueItem>
          ))}
        </SliderCustomValue>
      )}
    </SliderCustomWraper>
  )
}
