import React from 'react'
import styled from 'styled-components'
import MenuSidebar from '../../components/MenuSidebar'

export const GovernanceMenuWapper = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding-top: 50px;
  `};
`
GovernanceMenuWapper.displayName = 'GovernanceMenuWapper'

export default function GovernanceMenu() {
  return (
    <GovernanceMenuWapper>
      <MenuSidebar />
    </GovernanceMenuWapper>
  )
}
