import { createReducer } from '@reduxjs/toolkit'
import { BASE_TOKEN_SYMBOL } from '../../constants'
import { EarnDirection, EarnProperties, setDirection, setProperties, setToken, typeInput, clear } from './actions'

export interface EarnState {
  readonly data: EarnProperties
}

const initialState: EarnState = {
  data: {
    token: BASE_TOKEN_SYMBOL,
    direction: EarnDirection.Deposit,
    input: ''
  }
}

export default createReducer<EarnState>(initialState, builder =>
  builder
    .addCase(setProperties, (state, { payload }) => {
      return {
        ...state,
        data: {
          ...payload.data
        }
      }
    })
    .addCase(setToken, (state, { payload }) => {
      return {
        ...state,
        data: { ...state.data, token: payload.token, input: '' }
      }
    })
    .addCase(setDirection, (state, { payload }) => {
      return {
        ...state,
        data: { ...state.data, direction: payload.deposit ? EarnDirection.Deposit : EarnDirection.Withdraw, input: '' }
      }
    })
    .addCase(typeInput, (state, { payload }) => {
      return {
        ...state,
        data: { ...state.data, input: payload.amount }
      }
    })
    .addCase(clear, (state, { payload }) => {
      return initialState
    })
)
