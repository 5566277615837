import React, { useLayoutEffect, useState } from 'react'
import { TokenAmount } from '@wowswap-io/wowswap-sdk'
import { ReactComponent as Minus } from '../../assets/images/icon--minus.svg'
import { ReactComponent as Plus } from '../../assets/images/icon--plus.svg'
import { ReactComponent as MetamaskIcon } from '../../assets/svg/metamask-logo.svg'
import { TradeToken } from '../../hooks/Tokens.types'
import useAddTokenToMetamask from '../../hooks/useAddTokenToMetamask'
import { PortfolioPosition } from '../../state/portfolio/hooks'
import { calcHealth, calcHIR } from '../../utils/calculations'
import '../../utils/math'
import { bn, amount } from '../../utils/math'
import CurrencyLogo from '../CurrencyLogo'
import {
  AddToMetamask,
  AddToMetamaskMsg,
  CurrencyOne,
  CurrencyTwo,
  Debt,
  Health,
  LiquidationPriceValue,
  MetamaskIconWrapper,
  MobileValuesWrapper,
  NetValue,
  OpenIcon,
  PortfolioItemStyle,
  PortfolioItemWraper,
  PortfolioItemWraperMobile,
  ProfitValue,
  TokenBodyStyled,
  TokenTexts,
  ValueAtOpening
} from './styleds'

interface PortfolioItemProps {
  tradeble: TradeToken
  lendable: TradeToken
  isOdd: boolean
  position: PortfolioPosition
}

function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

const NUM_DIGITS = 4

const TokenAmountLabel = ({ amount, digits }: { amount: TokenAmount; digits?: number }) => {
  return (
    <>
      {amount.toSignificant(digits || NUM_DIGITS)} {amount.token.symbol || ''}
    </>
  )
}

export const ShortPositionPortfolioItem = ({
  position,
  isOdd,
  tradeble: shortable,
  lendable: stable
}: PortfolioItemProps) => {
  const [openItem, setOpenItem] = useState(false)
  const { addToken } = useAddTokenToMetamask(shortable as any, 'short')
  const [width] = useWindowSize()

  const amountValue = bn(position.amount.toString())
  const currentCost = bn(position.currentCost.toString())
  const currentDebt = bn(position.currentDebt.toString())
  const selfValue = bn(position.selfValue.toString())
  const rate = bn(position.rate.toString())
  const liquidationCost = bn(position.liquidationCost.toString())

  const difference = currentCost.gt(currentDebt) ? currentCost.sub(currentDebt) : bn(0)
  const portion = difference.wadDiv(currentCost)
  const debtPayable = amountValue.wadMul(amount(1).sub(portion))

  const net = amountValue.sub(debtPayable)
  const profitValue = net.sub(selfValue)
  const profitPercentage = profitValue.div(selfValue).mul(100)
  const liqPrice = amount(1).wadDiv(liquidationCost.wadDiv(amountValue))

  const shortableAmount = new TokenAmount(shortable, currentDebt.str())
  const HIR = calcHIR(rate.str(), 60 * 60)
  const collateral = new TokenAmount(stable, amountValue.str())
  const shortedValue = new TokenAmount(stable, debtPayable.str())
  const netValue = new TokenAmount(stable, net.gt(0) ? net.str() : '0')
  const valueAtOpening = new TokenAmount(stable, selfValue.str())
  const profit = new TokenAmount(stable, profitValue.abs().str())
  const liquidationPrice = new TokenAmount(stable, liqPrice.str())
  const health = calcHealth(currentCost, liquidationCost)

  const health_color = Number(health) < 3 ? 'down' : Number(health) < 30 ? 'middle' : 'up'

  const handleAddToMetamask = () => {
    addToken()
  }

  return (
    <PortfolioItemStyle bgColor={isOdd ? '#ffffff' : ''}>
      <PortfolioItemWraper>
        <TokenBodyStyled>
          <CurrencyLogo currency={shortable} />

          <TokenTexts>
            <CurrencyOne>
              <span>-</span>
              <TokenAmountLabel amount={shortableAmount} />
            </CurrencyOne>
            <CurrencyTwo>{HIR} % HIR</CurrencyTwo>
          </TokenTexts>

          <AddToMetamask>
            <AddToMetamaskMsg>Add&nbsp;to&nbsp;Metamask</AddToMetamaskMsg>
            <MetamaskIconWrapper>
              <MetamaskIcon onClick={handleAddToMetamask} />
            </MetamaskIconWrapper>
          </AddToMetamask>
        </TokenBodyStyled>

        {width > 1280 && (
          <React.Fragment>
            <Debt>
              <CurrencyOne>
                <TokenAmountLabel amount={collateral} />
              </CurrencyOne>
              <CurrencyTwo>
                <span>-</span>
                <TokenAmountLabel amount={shortedValue} />
              </CurrencyTwo>
            </Debt>
            <NetValue>
              <CurrencyOne>
                <TokenAmountLabel amount={netValue} />
              </CurrencyOne>
            </NetValue>
            <ValueAtOpening>
              <TokenAmountLabel amount={valueAtOpening} />
            </ValueAtOpening>
            <ProfitValue>
              <CurrencyOne>
                {profitValue.lt(0) && '-'}
                <TokenAmountLabel amount={profit} />
              </CurrencyOne>
              <CurrencyTwo>{profitPercentage.toFixed(2)}%</CurrencyTwo>
            </ProfitValue>
            <LiquidationPriceValue>
              <TokenAmountLabel amount={liquidationPrice} />
            </LiquidationPriceValue>
          </React.Fragment>
        )}

        <Health color={health_color}>{health.toFixed(2)}%</Health>

        {width <= 1280 && (
          <OpenIcon onClick={() => setOpenItem(!openItem)}>
            {openItem ? <Minus id="iconMinus" /> : <Plus id="iconPlus" />}
          </OpenIcon>
        )}
      </PortfolioItemWraper>

      {width <= 1280 && openItem && (
        <PortfolioItemWraperMobile>
          <Debt>
            <CurrencyOne>Collateral</CurrencyOne>

            <MobileValuesWrapper>
              <CurrencyOne>
                <TokenAmountLabel amount={collateral} />
              </CurrencyOne>
              <CurrencyTwo>
                <span>-</span>
                <TokenAmountLabel amount={shortedValue} />
              </CurrencyTwo>
            </MobileValuesWrapper>
          </Debt>
          <NetValue>
            <CurrencyOne>Net Value</CurrencyOne>
            <MobileValuesWrapper>
              <CurrencyOne>
                <TokenAmountLabel amount={netValue} />
              </CurrencyOne>
            </MobileValuesWrapper>
          </NetValue>
          <ProfitValue>
            <CurrencyOne>Value at Opening</CurrencyOne>
            <MobileValuesWrapper>
              <CurrencyOne>
                <TokenAmountLabel amount={valueAtOpening} />
              </CurrencyOne>
            </MobileValuesWrapper>
          </ProfitValue>
          <ProfitValue>
            <CurrencyOne>Profit</CurrencyOne>
            <MobileValuesWrapper>
              <CurrencyOne>
                {profitValue.lt(0) && '-'}
                <TokenAmountLabel amount={profit} />
              </CurrencyOne>
              <CurrencyTwo>{profitPercentage.toFixed(2)}%</CurrencyTwo>
            </MobileValuesWrapper>
          </ProfitValue>
          <LiquidationPriceValue>
            <CurrencyOne>Liquidation Price</CurrencyOne>
            <MobileValuesWrapper>
              <CurrencyOne>
                <TokenAmountLabel amount={liquidationPrice} />
              </CurrencyOne>
            </MobileValuesWrapper>
          </LiquidationPriceValue>
        </PortfolioItemWraperMobile>
      )}
    </PortfolioItemStyle>
  )
}
