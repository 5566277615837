import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { TransactionResponse } from '@ethersproject/providers'
import { Interface } from '@ethersproject/abi'
import { useCallback, useMemo } from 'react'
import { amount as bnAmount } from '../../utils/math'
import { useDispatch, useSelector } from 'react-redux'
import { CurrencyAmount, TokenAmount } from '@wowswap-io/wowswap-sdk'

import RESERVE_ABI from '../../constants/abis/reserve.json'
import { ZERO_ADDRESS } from '../../constants'

import { AppDispatch, AppState } from '../index'
import { TradeToken } from '../../hooks/Tokens.types'
import { calculateGasMargin } from '../../utils'
import { WrappedTokenInfo } from '../lists/hooks'
import { DepositStatus, EarnDirection, setDirection, setToken, typeInput, clear } from './actions'

import { useActiveWeb3React } from '../../hooks'
import { useEarnTokens, useEarnTokensWithBalances } from '../../hooks/tokens/Tokens'
import { useRouterContract, useReserveContractForToken } from '../../hooks/useContract'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { useMultipleContractSingleData, useSingleCallResult } from '../multicall/hooks'
import { useTransactionAdder } from '../transactions/hooks'
import { getDefaultToken } from '../wallet/hooks'

const RESERVE_INTERFACE = new Interface(RESERVE_ABI)

function useEarnState(): AppState['earn'] {
  return useSelector<AppState, AppState['earn']>(state => state.earn)
}

export function useDerivedEarnInfo(): {
  input?: TradeToken
  output?: TradeToken
  maxSpend?: CurrencyAmount
  typedValue: string
  amount?: CurrencyAmount
  amountOut?: TokenAmount
  direction: EarnDirection
  isEther: boolean
  isDeposit: boolean
  possibleInputs: TradeToken[]
  possibleOutputs: TradeToken[]
} {
  const { data } = useEarnState()
  const tokens = useEarnTokensWithBalances()

  const nativeToken = tokens.find(token => token.symbol === data?.token)
  const interestToken = tokens.find(token => token.symbol === 'ib' + data?.token)
  const isDeposit = data?.direction === EarnDirection.Deposit
  const input = isDeposit ? nativeToken : interestToken
  const output = isDeposit ? interestToken : nativeToken
  const isEther = Boolean(isDeposit ? input?.lendableInfo?.isNative : output?.lendableInfo?.isNative)

  const possibleInputs = tokens
  const possibleOutputs = tokens

  const parsedAmount = data.input && input && bnAmount(data.input, input.decimals).str()

  const amount = useMemo(() => {
    if (!parsedAmount || isNaN(Number(parsedAmount))) {
      return undefined
    }

    return parsedAmount
      ? isEther && isDeposit
        ? CurrencyAmount.ether(parsedAmount)
        : new TokenAmount(input!, parsedAmount)
      : undefined
  }, [isEther, isDeposit, parsedAmount, input])

  const maxSpend = maxAmountSpend(input?.balance)

  const amountDeposit = useDepositAmount(amount)
  const token: TradeToken | WrappedTokenInfo = nativeToken ? nativeToken : getDefaultToken()
  const amountWithdraw = useWithdrawAmount(token.address, amount)

  const amountOut: TokenAmount | undefined = output
    ? new TokenAmount(output, isDeposit ? amountDeposit : amountWithdraw)
    : undefined

  return {
    input,
    output,
    maxSpend,
    typedValue: data.input,
    amount,
    amountOut,
    isEther,
    isDeposit,
    possibleInputs,
    possibleOutputs,
    direction: data?.direction || EarnDirection.Deposit
  }
}

export function useEarnTableData() {
  const { account } = useActiveWeb3React()
  const allEarnTokens = useEarnTokens()

  const tokenMap = useMemo(() => {
    return allEarnTokens.reduce<Record<string, TradeToken>>((acc, token) => {
      const reserveAddress = token.lendableInfo?.reserveAddress || token.shortableInfo?.reserveAddress
      if (reserveAddress) {
        acc[reserveAddress] = token
      }
      return acc
    }, {})
  }, [allEarnTokens])
  const addresses = Object.keys(tokenMap)

  const liquidityRate = useMultipleContractSingleData(addresses, RESERVE_INTERFACE, 'getLiquidityRate', undefined)
  const reserveDebt = useMultipleContractSingleData(addresses, RESERVE_INTERFACE, 'getReserveDebt', undefined)
  const liquidityOf = useMultipleContractSingleData(addresses, RESERVE_INTERFACE, 'liquidityOf', [account!])
  const balanceOf = useMultipleContractSingleData(addresses, RESERVE_INTERFACE, 'balanceOf', [account!])
  const utilizationRate = useMultipleContractSingleData(addresses, RESERVE_INTERFACE, 'getUtilizationRate', undefined)
  const totalValueLocked = useMultipleContractSingleData(addresses, RESERVE_INTERFACE, 'getTotalLiquidity', undefined)

  const dataStates = { liquidityRate, reserveDebt, liquidityOf, balanceOf, utilizationRate, totalValueLocked }
  const loading = useMemo(
    () =>
      Object.values(dataStates).some(set => {
        return set.some(state => state.loading)
      }),
    [dataStates]
  )

  return useMemo(() => {
    if (loading) return null
    return addresses.map((address, idx) => ({
      liquidityRate: dataStates.liquidityRate[idx].result?.toString() || '',
      reserveDebt: {
        liquidity: dataStates.reserveDebt[idx].result?.liquidity?.toString() || '',
        principalDebt: dataStates.reserveDebt[idx].result?.principalDebt?.toString() || '',
        averageRate: dataStates.reserveDebt[idx].result?.averageRate?.toString() || '',
        currentDebt: dataStates.reserveDebt[idx].result?.currentDebt?.toString() || ''
      },

      liquidityOf: dataStates.liquidityOf[idx]?.result?.toString() || '',
      balanceOf: dataStates.balanceOf[idx]?.result?.toString() || '',
      utilizationRate: dataStates.utilizationRate[idx]?.result?.toString() || '',
      totalValueLocked: dataStates.totalValueLocked[idx]?.result?.toString() || '',
      token: tokenMap[address]
    }))
  }, [addresses, dataStates, tokenMap, loading])
}

export function useEarnActionHandlers(): {
  onTokenChange: (token: string) => void
  onDirectionChange: (deposit: boolean) => void
  onValueInput: (amount: string) => void
  onClear: () => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onTokenChange = useCallback(
    (token: string) => {
      dispatch(setToken({ token }))
    },
    [dispatch]
  )

  const onValueInput = useCallback(
    (amount: string) => {
      dispatch(typeInput({ amount }))
    },
    [dispatch]
  )

  const onDirectionChange = useCallback(
    (deposit: boolean) => {
      dispatch(setDirection({ deposit }))
    },
    [dispatch]
  )

  const onClear = useCallback(() => dispatch(clear()), [dispatch])

  return {
    onTokenChange,
    onDirectionChange,
    onValueInput,
    onClear
  }
}

function useDepositAmount(amount?: TokenAmount | CurrencyAmount) {
  const { chainId } = useActiveWeb3React()
  const reserveContract = useReserveContractForToken(
    amount instanceof TokenAmount ? amount.token.address : getDefaultToken(chainId).address
  )
  const calculateDepositResult = useSingleCallResult(reserveContract, 'calculateDeposit', [
    amount ? amount.raw.toString() : '0'
  ])

  return useMemo(() => {
    if (calculateDepositResult.loading || !calculateDepositResult.result) return '0'
    return (calculateDepositResult.result[0] as BigNumber).toString()
  }, [calculateDepositResult])
}

function useWithdrawAmount(lendable: string, amount?: CurrencyAmount | TokenAmount) {
  const { account } = useActiveWeb3React()
  const reserveContract = useReserveContractForToken(lendable)

  const calculateWithdraw = useSingleCallResult(reserveContract, 'calculateWithdraw', [
    amount ? amount.raw.toString() : '0',
    account || ZERO_ADDRESS
  ])

  return useMemo(() => {
    if (calculateWithdraw.loading || !calculateWithdraw.result) return '0'
    return (calculateWithdraw.result[0] as BigNumber).toString()
  }, [calculateWithdraw])
}

export function useDeposit(): { status: DepositStatus; callback: null | (() => Promise<any>); error: null | string } {
  const data = useDerivedEarnInfo()
  const contract: Contract | null = useRouterContract()
  const { account } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const { onClear } = useEarnActionHandlers()

  return useMemo(() => {
    let error = null
    if (!account || !contract || !data.input || !data.output || !data.amount) {
      if (!account) error = error ?? 'Connect to a wallet'
      if (!data.input || !data.output) error = error ?? 'Enter an amount'
      if (!data.amount) error = error ?? 'Enter an amount'
      return {
        callback: null,
        status: DepositStatus.INVALID,
        error
      }
    }

    const isDeposit = data.direction === EarnDirection.Deposit

    const token = isDeposit ? (data.input as TradeToken) : (data.output as TradeToken)

    const inputs: any[] = []

    if (isDeposit) {
      if (data.isEther) {
        inputs.push(account)
      } else {
        inputs.push(token.address || getDefaultToken().address, data.amount.raw.toString(), account)
      }
    } else {
      if (data.isEther) {
        inputs.push(data.amount.raw.toString(), account)
      } else {
        inputs.push(token.address || getDefaultToken().address, data.amount.raw.toString(), account)
      }
    }

    const methodBaseName = isDeposit ? 'deposit' : 'withdraw'
    const methodNamePostfix = data.isEther ? 'ETH' : token.info.shortable ? 'Shortable' : ''
    const methodName = `${methodBaseName}${methodNamePostfix}`

    const options: any = {
      from: account
    }
    if (data.isEther && isDeposit) options.value = data.amount.raw.toString()

    return {
      status: DepositStatus.VALID,
      callback: async () => {
        const gasEstimate = await contract.estimateGas[methodName](...inputs, options)
        return contract[methodName](...inputs, {
          ...options,
          gasLimit: calculateGasMargin(gasEstimate)
        })
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              summary: `${isDeposit ? 'Deposited' : 'Withdrawn'} ${data.amount!.toSignificant(4)} ${
                data.amount!.currency.symbol
              }`
            })
          })
          .then(() => onClear())
      },
      error
    }
  }, [data, account, contract, addTransaction, onClear])
}
