import { ChainId } from '@wowswap-io/wowswap-sdk'
import React, { useEffect, useRef, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { useTranslation } from 'react-i18next'

import { ReactComponent as Avalanche } from 'assets/svg/networks/avalanche.svg'
import { ReactComponent as Binance } from 'assets/svg/networks/bnb.svg'
import { ReactComponent as Ethereum } from 'assets/svg/networks/ethereum.svg'
import { ReactComponent as Heco } from 'assets/svg/networks/heco.svg'
import { ReactComponent as Iotex } from 'assets/svg/networks/iotex.svg'
import { ReactComponent as Polygon } from 'assets/svg/networks/polygon.svg'
import { ReactComponent as Solana } from 'assets/svg/networks/solana.svg'
import { useActiveWeb3React } from 'hooks'
import useSwitchChainOnMetamask from 'hooks/useChangeChain'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { redirectByNetworkIfNeeded } from 'utils/networkRedirect'

import * as S from './styles'

type NetworkName = keyof typeof ChainId | 'SOLANA'

type Network = {
  name: NetworkName
  label: string
  component: React.ReactNode
  chainId: ChainId | null
  color: string
}

const NETWORKS: Network[] = [
  {
    name: 'MAINNET',
    label: 'BNB',
    component: <Binance />,
    chainId: ChainId.MAINNET,
    color: '#F3BA2F'
  },
  {
    name: 'MATIC',
    label: 'Polygon',
    component: <Polygon />,
    chainId: ChainId.MATIC,
    color: '#8F5AE8'
  },
  {
    name: 'ETHEREUM',
    label: 'Ethereum',
    component: <Ethereum />,
    chainId: ChainId.ETHEREUM,
    color: '#697FE3'
  },
  {
    name: 'HECO',
    label: 'HECO',
    component: <Heco />,
    chainId: ChainId.HECO,
    color: '#2483FF'
  },
  {
    name: 'AVALANCE',
    label: 'Avalanche',
    component: <Avalanche />,
    chainId: ChainId.AVALANCE,
    color: '#E84142'
  },
  {
    name: 'IOTEX',
    label: 'IoTeX',
    component: <Iotex />,
    chainId: ChainId.IOTEX,
    color: '#15E3B0'
  },
  {
    name: 'SOLANA',
    label: 'Solana',
    component: <Solana />,
    chainId: null,
    color:
      'linear-gradient(48.01deg, #9945FF 0.57%, #8A53F4 14.41%, #6377D6 42.09%, #24B0A7 78.67%, #00D18C 98.45%, #00D18C 99.43%), #000000'
  }
]

export const Network = () => {
  const node = useRef<HTMLDivElement>(null)
  const { chainId, account } = useActiveWeb3React()
  const { changeNetwork } = useSwitchChainOnMetamask()
  const { t } = useTranslation()

  const [network, setNetwork] = useState<Network>(NETWORKS[0])
  const [openTrigger, setOpenTrigger] = useState<boolean>(false)

  useEffect(() => {
    const network = NETWORKS.find(net => net.name === ChainId[chainId as ChainId])
    if (network) setNetwork(network)
  }, [chainId])

  const handleNetworkSelect = (network: Network) => {
    const { chainId, name } = network
    setOpenTrigger(!openTrigger)
    if (chainId && account) {
      changeNetwork(chainId)
    } else {
      redirectByNetworkIfNeeded(name)
    }
  }

  useOnClickOutside(node, () => setOpenTrigger(false))

  return (
    <S.NetworkWrapper ref={node}>
      <S.HiddenNetworkCurrent bgColor={network.color} />
      <S.NetworkContainer active={openTrigger}>
        <S.NetworkCurrent bgColor={network.color} onClick={() => setOpenTrigger(!openTrigger)}>
          <S.NetworkCurrentLabel>
            <S.NetworkCurrentLabelIcon>{network.component}</S.NetworkCurrentLabelIcon>
            {window.innerWidth > 1280 && (
              <>
                <S.NetworkCardName
                  title={!openTrigger ? t('Select Network') : undefined}
                  style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}
                >
                  {network.label}
                </S.NetworkCardName>
                {openTrigger ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
              </>
            )}
          </S.NetworkCurrentLabel>
          <S.ExpandBtn active={openTrigger} />
        </S.NetworkCurrent>
        <S.NetworkSelect active={openTrigger}>
          <S.NetworkSelectHeader>
            <S.NetworkSelectTitle>{t('Select Network')}</S.NetworkSelectTitle>
            <S.CloseBtn onClick={() => setOpenTrigger(false)} />
          </S.NetworkSelectHeader>
          {NETWORKS.filter(net => net.name !== network.name).map(net => (
            <S.NetworkCard key={net.name} onClick={() => handleNetworkSelect(net)}>
              <S.NetworkCardIcon color={net.color}>{net.component}</S.NetworkCardIcon>
              <S.NetworkCardName>{net.label}</S.NetworkCardName>
            </S.NetworkCard>
          ))}
        </S.NetworkSelect>
      </S.NetworkContainer>
    </S.NetworkWrapper>
  )
}
