import React, { useState, useLayoutEffect } from 'react'
import styled from 'styled-components'

const EarnItemStyle = styled.div<{ bgColor?: string }>`
  padding: 10px 14px 10px 10px;

  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black};
  text-align: right;

  background: ${({ bgColor }) => bgColor || 'none'};
  overflow: hidden;
  border-radius: 10px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
		padding: 10px 15px 15px 15px;

		background-color: ${({ theme }) => theme.colors.white};
	`};
`

const EarnItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div:nth-child(1) {
    flex-grow: 2;
  }
  & > div:nth-child(2) {
    width: 8.5%;
    flex: 0 0 8.5%;
  }
  & > div:nth-child(3) {
    width: 18.5%;
    flex: 0 0 18.5%;
  }
  & > div:nth-child(4) {
    width: 18.5%;
    flex: 0 0 18.5%;
  }
  & > div:nth-child(5) {
    width: 13.8%;
    flex: 0 0 13.8%;
  }
  & > div:nth-child(6) {
    width: 14.8%;
    flex: 0 0 14.8%;
  }
  & > div:nth-child(7) {
    width: 14.5%;
    flex: 0 0 14.5%;
  }
`

const Token = styled.div`
  display: flex;
  align-items: center;

  & > img {
    display: block;
    width: 28px;
    height: 28px;
    margin-right: 11px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
		margin-bottom: 10px;
		
		font-size: 18px;
		line-height: 22px;
		font-weight: bold;

		& > img {
			width: 24px;
			height: 24px;
			margin-right: 6px;
		}
	`};
`

const CurrencyIcon = styled.img``

const Apy = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary5};
`

const Deposited = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
		display: flex;
		justify-content: space-between;
		margin-top: 10px;

		font-size: 14px;
		line-height: 14px;

		& > div:first-child {
			color: #999999;	
		}
	`};
`

const Borrowed = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
		display: flex;
		justify-content: space-between;
		margin-top: 10px;

		font-size: 14px;
		line-height: 14px;

		& > div:first-child {
			color: #999999;	
		}
	`};
`

const Utilization = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary4};
`

const Balance = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
		display: flex;
		justify-content: space-between;
		padding-top: 10px;

		font-size: 14px;
		line-height: 14px;

		& > div:first-child {
			color: #999999;	
		}
	`};
`

const TotalValue = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
		display: flex;
		justify-content: space-between;
		margin-top: 10px;

		font-size: 14px;
		line-height: 14px;

		& > div:first-child {
			color: #999999;	
		}
	`};
`

const EarnItemRowMobile = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -3px;
  margin-right: -3px;
`

const EarnItemColMobile = styled.div`
  padding: 6px 0 5px 0;
  margin-left: 3px;
  margin-right: 3px;
  flex-grow: 2;

  font-size: 14px;
  line-height: 14px;
  text-align: center;

  background-color: ${({ theme }) => theme.colors.bg0};
  border-radius: 5px;

  & > div {
    margin-top: 3px;
    font-size: 16px;
    line-height: 16px;
  }
`

const EarnItemBottomMob = styled.div`
  margin-top: 8px;

  border-top: 2px solid ${({ theme }) => theme.colors.bg0};
`

function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

interface TokenProps {
  icon: string
  title: string
}

interface PortfolioItemProps {
  bgColor?: string
  token: TokenProps
  apy: string
  deposited: string
  borrowed: string
  utilization: string
  balance: string
  totalValue: string
}

export default function EarnItem({
  bgColor,
  token,
  apy,
  deposited,
  borrowed,
  utilization,
  balance,
  totalValue
}: PortfolioItemProps) {
  const [width] = useWindowSize()

  return (
    <EarnItemStyle bgColor={bgColor ? bgColor : ''}>
      {width <= 960 ? (
        <>
          <Token>
            <CurrencyIcon src={token.icon} alt={token.title} />
            {token.title}
          </Token>

          <EarnItemRowMobile>
            <EarnItemColMobile>
              APY
              <Apy>{apy}</Apy>
            </EarnItemColMobile>

            <EarnItemColMobile>
              Utilization
              <Utilization>{utilization}</Utilization>
            </EarnItemColMobile>
          </EarnItemRowMobile>

          <Deposited>
            <div>Deposited</div>

            {deposited}
          </Deposited>

          <Borrowed>
            <div>Borrowed</div>

            {borrowed}
          </Borrowed>

          <EarnItemBottomMob>
            <Balance>
              <div>Balance</div>

              {balance}
            </Balance>

            <TotalValue>
              <div>Total Value</div>

              {totalValue}
            </TotalValue>
          </EarnItemBottomMob>
        </>
      ) : (
        <EarnItemRow>
          <Token>
            <CurrencyIcon src={token.icon} alt={token.title} />
            {token.title}
          </Token>

          <Apy>{apy}</Apy>

          <Deposited>{deposited}</Deposited>

          <Borrowed>{borrowed}</Borrowed>

          <Utilization>{utilization}</Utilization>

          <Balance>{balance}</Balance>

          <TotalValue>{totalValue}</TotalValue>
        </EarnItemRow>
      )}
    </EarnItemStyle>
  )
}
