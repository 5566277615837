import { ChainId } from '@wowswap-io/wowswap-sdk'

type NetworkName = keyof typeof ChainId | 'SOLANA'

export const networkRedirectConfig: Record<NetworkName, string | undefined> = {
  MAINNET: process.env.REACT_APP_MAIN_URL, // Binance Smart Chain
  MATIC: process.env.REACT_APP_MAIN_URL,
  ETHEREUM: process.env.REACT_APP_ETHEREUM_URL,
  HECO: process.env.REACT_APP_MAIN_URL,
  AVALANCE: process.env.REACT_APP_MAIN_URL,
  IOTEX: process.env.REACT_APP_MAIN_URL,
  SOLANA: process.env.REACT_APP_SOLANA_URL,
  ROPSTEN: undefined,
  BSCTESTNET: undefined,
  LOCALNET: undefined,
  FUJI: undefined,
  ANDROMEDA: undefined,
  STARDUST: undefined
}

export const redirectByNetworkIfNeeded = (networkName: NetworkName) => {
  const { origin: currentUrl, pathname } = window.location
  // Don't redirect in pr preview stagings
  if (!Object.values(networkRedirectConfig).includes(currentUrl)) return

  const redirectUrl = networkRedirectConfig[networkName]
  if (!redirectUrl || currentUrl === redirectUrl) return

  const url = new URL(redirectUrl + pathname)
  const chainId = ChainId[networkName as keyof typeof ChainId]
  if (chainId) {
    url.searchParams.set('chain', String(chainId))
  }
  window.location.replace(url.toString())
}
