import React from 'react'
import { useDerivedStakeInfo } from '../../state/stake/hooks'
import { bn } from '../../utils/math'

import { YourWalletItemStyle, YourWalletItemText, YourWalletItemValue, YourWalletListStyle } from './styleds'

export function YourWalletList() {
  const { xWOW, possibleInputs, stableAPY, amountXwowTotalSupply } = useDerivedStakeInfo()
  const votingPower = xWOW?.balance?.greaterThan('0')
    ? bn(100)
        .mul(xWOW?.balance?.raw.toString() || 0)
        .div(amountXwowTotalSupply?.raw.toString() || 1)
    : bn(0)

  return (
    <YourWalletListStyle>
      {possibleInputs?.map(token => (
        <YourWalletItem
          text={`${token.symbol} staked:`}
          value={token.stakableInfo?.staked?.amount.toSignificant(6) || '0'}
          key={token.address}
        />
      ))}
      <YourWalletItem text="Your xWOW:" value={xWOW?.balance?.toSignificant(6) || '0'} />
      <YourWalletItem text="Your staking APY:" value={`${stableAPY}%`} />
      <YourWalletItem text="Your voting power:" value={`${new Intl.NumberFormat().format(votingPower.toNumber())}%`} />
    </YourWalletListStyle>
  )
}

function YourWalletItem({ text, value }: { text: string; value: string }) {
  return (
    <YourWalletItemStyle>
      <YourWalletItemText>{text}</YourWalletItemText>

      <YourWalletItemValue>{value}</YourWalletItemValue>
    </YourWalletItemStyle>
  )
}
