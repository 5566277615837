import styled from 'styled-components'

export const PortfolioTableStyle = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-bottom: 10px;
  
  font-size: 18px;
  line-height: 23px;
`};
`

export const PortfolioTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  a {
    flex: 0 0 14.8%;
    text-align: right;

    &:first-child {
      flex: 0 0 16%;
    }

    &:last-child {
      flex: 0 0 10%;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
  margin-bottom: 8px;
  padding: 0 48px 0;
  
  font-size: 18px;
  line-height: 23px;

  a {
    display:none;

    &:first-child {
      display: flex;
      text-align: left;
      flex: 0 0 40%;
      justify-content: flex-start;
    }

    &:last-child {
      display: flex;
      text-align: right;
      flex: 0 0 40%;
      justify-content: flex-end;
    }
  }
`};
`

export const PortfolioTableList = styled.div``

export const PortfolioTableSortWraper = styled.div<{ justifyContent?: string }>`
  ${({ justifyContent }) => {
    if (justifyContent) {
      return `
        display: flex;
        justify-content: ${justifyContent};
    `
    } else {
      return `
      `
    }
  }}
`
