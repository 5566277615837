import React, { useContext, useMemo } from 'react'
import { TokenAmount, TradeType } from '@wowswap-io/wowswap-sdk'
// import { AlertTriangle } from 'react-feather'
// import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { Field } from '../../../state/swap/actions'
import { TYPE } from '../../Shared'
import { ButtonPrimary } from '../../Button'
import { isAddress, shortenAddress } from '../../../utils'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown, warningSeverity } from '../../../utils/prices'
import { AutoColumn } from '../../Column'
import CurrencyLogo from '../../CurrencyLogo'
import { RowBetween, RowFixed } from '../../Row'
import { /*TruncatedText,*/ SwapShowAcceptChanges } from '../styleds'
import styled from 'styled-components'
import { ReactComponent as ArrowDownIcon } from '../../../assets/images/arrow-down.svg'
import { ReactComponent as WarningnIcon } from '../../../assets/images/warning.svg'
import { TextBaseLg, TextBaseXl } from '../../Text'
import { bn } from '../../../utils/math'

import { Position } from '../../../state/trade/types'
import { TradeToken } from '../../../hooks/Tokens.types'

const ConfirmSwapCurrencies = styled.div`
  padding: 15px 23px 15px 15px;

  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
`

const ArrowDownIconStyle = styled(ArrowDownIcon)`
  width: 24px;
  height: 24px;
`

const WarningnIconStyle = styled(WarningnIcon)`
  flex: 0 0 25px;
  width: 25px;
  height: 21px;
  margin-left: 2px;
  margin-right: 10px;
`

const ButtonSwapAccept = styled(ButtonPrimary)`
  width: auto;
  padding-left: 28px;
  padding-right: 28px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		width: auto;
		padding-left: 14px;
		padding-right: 14px;
	`};
`

export default function ShortTradeSwapModalHeader({
  position,
  typedValue,
  inputToken,
  outputToken,
  isOpenPosition,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges
}: {
  position: Position
  typedValue: string
  inputToken?: TradeToken
  outputToken?: TradeToken
  isOpenPosition: boolean
  allowedSlippage: number
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}) {
  const trade = position.trade!
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    trade,
    allowedSlippage
  ])
  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const theme = useContext(ThemeContext)

  const amountIn = typedValue
  const amountOut = isOpenPosition ? trade.outputAmount : position.profit
  const amountOutSlippage: string = bn(amountOut?.raw.toString()!)
    .div(10000)
    .mul(10000 - allowedSlippage)
    .str()

  const tokenAmountSlippage = new TokenAmount(outputToken!, amountOutSlippage || '0')

  return (
    <div>
      <ConfirmSwapCurrencies style={{ borderRadius: showAcceptChanges ? '10px 10px 0 0' : '' }}>
        <RowBetween align="flex-end">
          <RowFixed gap={'0px'}>
            <CurrencyLogo currency={inputToken} size={'24px'} style={{ marginRight: '15px' }} />

            <TextBaseXl
              fontWeight="bold"
              wrapText={true}
              color={showAcceptChanges && trade.tradeType === TradeType.EXACT_OUTPUT ? theme.colors.primary1 : ''}
            >
              {amountIn}
            </TextBaseXl>
          </RowFixed>
          <RowFixed gap={'0px'}>
            <TextBaseXl fontWeight="bold">{trade.inputAmount.currency.symbol}</TextBaseXl>
          </RowFixed>
        </RowBetween>

        <RowFixed marginTop={9} marginBottom={9}>
          <ArrowDownIconStyle />
        </RowFixed>

        <RowBetween align="flex-end">
          <RowFixed gap={'0px'}>
            <CurrencyLogo currency={outputToken} size={'24px'} style={{ marginRight: '15px' }} />
            <TextBaseXl
              fontWeight="bold"
              wrapText={true}
              color={
                priceImpactSeverity > 2
                  ? theme.colors.primary4
                  : showAcceptChanges && trade.tradeType === TradeType.EXACT_INPUT
                  ? theme.colors.primary1
                  : ''
              }
            >
              {amountOut?.toSignificant(6)}
            </TextBaseXl>
          </RowFixed>
          <RowFixed gap={'0px'}>
            <TextBaseXl fontWeight="bold">{trade.outputAmount.currency.symbol}</TextBaseXl>
          </RowFixed>
        </RowBetween>
      </ConfirmSwapCurrencies>

      {showAcceptChanges ? (
        <SwapShowAcceptChanges justify="flex-start" gap={'0px'}>
          <RowBetween>
            <RowFixed>
              {/* <AlertTriangle size={20} style={{ marginRight: '8px', minWidth: 24 }} /> */}
              <WarningnIconStyle />
              <TextBaseLg> Price Updated</TextBaseLg>
            </RowFixed>
            <ButtonSwapAccept onClick={onAcceptChanges}>Accept</ButtonSwapAccept>
          </RowBetween>
        </SwapShowAcceptChanges>
      ) : null}
      <AutoColumn justify="flex-start" style={{ padding: '20px 0 22px 0px' }}>
        {trade.tradeType === TradeType.EXACT_INPUT ? (
          <TYPE.base textAlign="left" style={{ width: '100%', lineHeight: '22px' }}>
            {`Output is estimated. You will short sell at least `}
            <b style={{ color: theme.colors.primary1 }}>
              {tokenAmountSlippage.toSignificant(6)} {trade.outputAmount.currency.symbol}
            </b>
            {' or the transaction will revert.'}
          </TYPE.base>
        ) : (
          <TYPE.base textAlign="left" style={{ width: '100%', lineHeight: '22px' }}>
            {`Input is estimated. You will sell at most `}
            <b>
              {slippageAdjustedAmounts[Field.INPUT]?.toSignificant(6)} {trade.inputAmount.currency.symbol}
            </b>
            {' or the transaction will revert.'}
          </TYPE.base>
        )}
      </AutoColumn>
      {recipient !== null ? (
        <AutoColumn justify="flex-start" gap="sm" style={{ padding: '12px 0 0 0px' }}>
          <TYPE.main>
            Output will be sent to{' '}
            <b title={recipient}>{isAddress(recipient) ? shortenAddress(recipient) : recipient}</b>
          </TYPE.main>
        </AutoColumn>
      ) : null}
    </div>
  )
}
