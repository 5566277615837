import { BigNumber as BigNumberish } from '@ethersproject/bignumber'
import BigNumber from 'bignumber.js'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAllStakableTokens } from '../../hooks/tokens/tokenLists'
import { AppDispatch, AppState } from '../index'
import { TransactionResponse } from '@ethersproject/providers'

import {
  paramList,
  Param,
  setInited,
  setInitSingleParam,
  setSingleParam,
  EconomicState,
  SingleParamType,
  SingleParamState,
  FeeParams,
  GOVERNANCE_REWARD,
  BUY_AND_BURN,
  CHARITY,
  DEVELOPMENT,
  INSURANCE,
  TRADER_PROFIT_FEE,
  BASE_BORROW_RATE,
  EXCESS_SLOPE,
  LIQUIDATION_MARGIN,
  LIQUIDATION_REWARD,
  MAX_LEVERAGE_FACTOR,
  MAX_LIQUIDATION_REWARD,
  MAX_PRICE_THRESHOLD,
  MAX_RATE_MULTIPLIER,
  MIN_WOW_BALANCE_X4,
  MIN_WOW_BALANCE_X5,
  OPTIMAL_SLOPE,
  OPTIMAL_UTILIZATION,
  POOL_UTILIZATION_ALLOWANCE,
  TREASURE_FACTOR
} from './actions'

import { useActiveWeb3React } from '../../hooks'
import { GOVERNANCE_ADDRESS, ZERO_ADDRESS } from '../../constants'
import { useGovernanceContract } from '../../hooks/useContract'
import { useSingleCallResult } from '../multicall/hooks'

import IconFee01 from '../../assets/images/icon--fee01.svg'
import IconFee02 from '../../assets/images/icon--fee02.svg'
import IconFee03 from '../../assets/images/icon--fee03.svg'
import IconFee04 from '../../assets/images/icon--fee04.svg'
import IconFee05 from '../../assets/images/icon--fee05.svg'

import { BytesLike, formatBytes32String, parseBytes32String } from 'ethers/lib/utils'
import { useStakeTokensWithBalances } from '../../hooks/tokens/Tokens'
import { TokenAmount } from '@wowswap-io/wowswap-sdk'
import { bn, toBN, WAD } from '../../utils/math'
import { ApprovalState, useApproveCallback } from '../../hooks/useApproveCallback'
import { useTransactionAdder } from '../transactions/hooks'
import { calculateGasMargin } from '../../utils'
import { TradeToken } from '../../hooks/Tokens.types'

const MULTIPLICATOR: Record<string, BigNumber> = {
  [TRADER_PROFIT_FEE]: bn(10).pow(20),
  [BASE_BORROW_RATE]: bn(10).pow(20),
  [EXCESS_SLOPE]: bn(10).pow(20),
  [OPTIMAL_SLOPE]: bn(10).pow(20),
  [OPTIMAL_UTILIZATION]: bn(10).pow(25),
  [LIQUIDATION_MARGIN]: bn(10).pow(20),
  [LIQUIDATION_REWARD]: bn(10).pow(20),
  [MAX_LEVERAGE_FACTOR]: bn(10).pow(22),
  [MAX_LIQUIDATION_REWARD]: bn(10).pow(18),
  [MAX_PRICE_THRESHOLD]: bn(10).pow(20),
  [MAX_RATE_MULTIPLIER]: bn(10).pow(22),
  [MIN_WOW_BALANCE_X4]: bn(10).pow(18),
  [MIN_WOW_BALANCE_X5]: bn(10).pow(18),
  [POOL_UTILIZATION_ALLOWANCE]: bn(10).pow(20),
  [TREASURE_FACTOR]: bn(10).pow(20),
  [GOVERNANCE_REWARD]: bn(10).pow(2),
  [BUY_AND_BURN]: bn(10).pow(2),
  [CHARITY]: bn(10).pow(2),
  [DEVELOPMENT]: bn(10).pow(2),
  [INSURANCE]: bn(10).pow(2)
}

export enum VoteStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  APPROVE_REQUIRED = 'APPROVE_REQUIRED'
}

interface ParamResponse {
  account: string
  data: LoadedParam[]
}
interface LoadedParam {
  amount: string
  value: number
  maxValue?: number
  minValue?: number
  name: SingleParamType
  encodedName?: string
}

interface EconomicModelInfo {
  params: Record<Param, EconimicParam>
  state: EconomicState
  hasUpdate: boolean
  hasFeeUpdate: boolean
  updates: Record<SingleParamType, number>
  feeUpdates: Record<Param, number>
  xWOW: TradeToken
}

interface EconimicParam {
  icon: string
  title: string
  currentValue: number
  newValue: number
  userValue: number
}

function useFinancialState(): AppState['financial'] {
  return useSelector<AppState, AppState['financial']>(state => state.financial)
}

export function useFinancialModelInfo(): EconomicModelInfo {
  const state = useFinancialState()
  const updates = useGetUpdates(state)
  const feeUpdates = useGetFeeUpdates(state)

  const stakeTokens = useStakeTokensWithBalances()
  const xWOW = stakeTokens.find(token => token.stakableInfo?.isBase)!

  const params = paramList.reduce((acc, param) => {
    acc[param] = {
      icon: getIconByParamName(param),
      title: getTitleByParamName(param),
      currentValue: state[param].current,
      userValue: state[param].userValue,
      newValue: state[param].newValue
    }
    return acc
  }, {} as Record<Param, EconimicParam>)

  const hasUpdate = Object.keys(updates).length > 0
  const hasFeeUpdate = Object.keys(feeUpdates).length > 0

  return { params, state, hasUpdate, hasFeeUpdate, updates, feeUpdates, xWOW }
}

function getIconByParamName(param: Param) {
  switch (param) {
    case GOVERNANCE_REWARD:
      return IconFee01
    case DEVELOPMENT:
      return IconFee02
    case INSURANCE:
      return IconFee03
    case CHARITY:
      return IconFee04
    case BUY_AND_BURN:
      return IconFee05
    default:
      return IconFee01
  }
}

function getTitleByParamName(param: Param) {
  switch (param) {
    case DEVELOPMENT:
      return 'Development fund'
    case INSURANCE:
      return 'Insurance fund'
    case CHARITY:
      return 'Charity'
    case BUY_AND_BURN:
      return 'Buy back and burn'
    case GOVERNANCE_REWARD:
      return 'Governance reward'
    default:
      return param
  }
}

export function useFinancialActionHandlers(): {
  onChangeSingleParam: (field: SingleParamType, data: Partial<SingleParamState>) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onChangeSingleParam = useCallback(
    (field: SingleParamType, data: Partial<SingleParamState>) => {
      dispatch(setSingleParam({ field, data }))
    },
    [dispatch]
  )

  return {
    onChangeSingleParam
  }
}

export function useInitParams() {
  const paramResponse = useLoadInitailParam()
  const initedParams = useSetCurrentParams(paramResponse)

  const votesResponse = useGetVotes()
  const setVoted = useSetVotedParams(votesResponse)

  useSetInited(initedParams && setVoted)
}

function useSetCurrentParams(paramResponse: ParamResponse | undefined) {
  const dispatch = useDispatch<AppDispatch>()
  if (!paramResponse) {
    return false
  }

  paramResponse.data.forEach((param: LoadedParam) => {
    dispatch(
      setInitSingleParam({
        field: param.name,
        data: {
          amount: param.amount,
          current: param.value,
          userValue: param.value,
          min: param.minValue,
          max: param.maxValue
        },
        account: paramResponse.account
      })
    )
  })

  return true
}

function useSetVotedParams(params: ParamResponse | undefined) {
  const dispatch = useDispatch<AppDispatch>()
  if (!params) {
    return false
  }

  params.data.forEach((param: LoadedParam) => {
    const payload = {
      field: param.name,
      data: {
        votedValue: param.value,
        userValue: param.value,
        voted: true
      },
      account: params.account
    }

    dispatch(setInitSingleParam(payload))
  })

  return true
}

function useSetInited(loaded: boolean) {
  const dispatch = useDispatch<AppDispatch>()

  if (loaded) {
    dispatch(setInited())
  }

  return true
}

const PRECICION = 2

export function changeStateEqual(state: FeeParams, field: Param, value: number) {
  const weightWithoutField = 100 - state[field]

  const diff = value - state[field]
  const newState = Object.entries(state).reduce((acc, [key, val]) => {
    if (key === field) {
      acc[key] = value
    } else {
      acc[key as Param] = Number((val - (diff * val) / weightWithoutField).toFixed(PRECICION)) || 0
    }
    return acc
  }, {} as Record<Param, number>)

  return newState
}

export function fixErrorOnFee(state: FeeParams): FeeParams {
  const newState = { ...state }
  const random = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min

  const total = Object.entries(state).reduce((acc, [_, val]) => {
    return acc + val
  }, 0)
  const error = Number((100 - total).toFixed(PRECICION))
  const fieldToApplyError = paramList[random(0, paramList.length - 1)]
  newState[fieldToApplyError] = Number((newState[fieldToApplyError] + error).toFixed(PRECICION))

  return newState
}

export function calcNewValue({
  amount,
  value,
  balance,
  votedValue,
  userValue,
  voted
}: {
  amount: string
  value: number
  votedValue: number
  userValue: number
  balance?: string
  voted: boolean
}) {
  const userAmount = bn(balance || '0')

  let oldValue
  let newAmount
  let oldAmount

  if (voted) {
    oldAmount = bn(amount).sub(userAmount)
    oldValue = oldAmount.isEqualTo(0)
      ? bn(0)
      : bn(amount)
          .mul(value)
          .sub(bn(votedValue).mul(userAmount))
          .div(oldAmount)
    newAmount = oldAmount.add(userAmount)
  } else {
    oldValue = bn(value)
    oldAmount = bn(amount)
    newAmount = bn(amount).add(userAmount)
  }

  const newValue = newAmount.isEqualTo(0)
    ? 0
    : oldValue
        .mul(oldAmount)
        .add(bn(userValue).mul(userAmount))
        .div(newAmount)
        .toNumber()

  return newValue
}

export function calcRparam(value: number) {
  return bn(value)
    .div(bn(10).pow(7))
    .plus(1)
    .pow(3600)
    .minus(1)
    .mul(100)
    .toNumber()
}

export function memoizer(fun: Function) {
  const cache: Record<number, number> = {}
  return function(n: any) {
    if (cache[n] !== undefined) {
      return cache[n]
    } else {
      const result = fun(n)
      cache[n] = result
      return result
    }
  }
}

export function toParam(value: number) {
  return Math.round(value * 100)
}

export function fromParam(param: number) {
  return param / 100
}

function useGetUpdates(state: EconomicState) {
  const params: SingleParamType[] = [
    TREASURE_FACTOR,
    TRADER_PROFIT_FEE,
    BASE_BORROW_RATE,
    EXCESS_SLOPE,
    LIQUIDATION_MARGIN,
    LIQUIDATION_REWARD,
    MAX_LEVERAGE_FACTOR,
    MAX_LIQUIDATION_REWARD,
    MAX_PRICE_THRESHOLD,
    MAX_RATE_MULTIPLIER,
    MIN_WOW_BALANCE_X4,
    MIN_WOW_BALANCE_X5,
    OPTIMAL_SLOPE,
    OPTIMAL_UTILIZATION,
    POOL_UTILIZATION_ALLOWANCE,
    TREASURE_FACTOR
  ]

  return params.reduce((acc, key) => {
    if (state[key].voted) {
      if (state[key].userValue !== state[key].votedValue) {
        acc[key] = state[key].userValue
      }
    } else if (state[key].current !== state[key].userValue) {
      acc[key] = state[key].userValue
    }
    return acc
  }, {} as Record<SingleParamType, number>)
}

function useGetFeeUpdates(state: EconomicState) {
  return paramList.reduce((acc, key) => {
    if (state[key].voted) {
      if (state[key].userValue !== state[key].votedValue) {
        acc[key] = state[key].userValue
      }
    } else if (state[key].current !== state[key].userValue) {
      acc[key] = state[key].userValue
    }
    return acc
  }, {} as Record<Param, number>)
}

export function useFinancialApprove() {
  const { chainId } = useActiveWeb3React()

  const stakeTokens = useAllStakableTokens()
  const stakableTokensArray: TradeToken[] = Object.values(stakeTokens)
  const xWOW = stakableTokensArray.find(token => token.stakableInfo?.isBase)!
  const tokenAmount = new TokenAmount(xWOW, WAD)

  const [approval, approveCallback] = useApproveCallback(tokenAmount, GOVERNANCE_ADDRESS[chainId!])

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const showApproveFlow =
    approval === ApprovalState.NOT_APPROVED ||
    approval === ApprovalState.PENDING ||
    (approvalSubmitted && approval === ApprovalState.APPROVED)

  return {
    approval,
    approveCallback,
    approvalSubmitted,
    setApprovalSubmitted,
    showApproveFlow,
    tokenAmount
  }
}

export function parseParam(params: {
  amount: BigNumberish
  value: BigNumberish
  maxValue: BigNumberish
  minValue: BigNumberish
  name: BytesLike
}) {
  const min = toBN(params.minValue)
  const max = toBN(params.maxValue)
  const amount = toBN(params.amount)
  const name = parseBytes32String(params.name)

  const multiplicator = MULTIPLICATOR[name]
  const value = BigNumber.min(BigNumber.max(toBN(params.value), min), max)

  return {
    amount: amount.toString(10),
    value: value.div(multiplicator).toNumber(),
    min: min.div(multiplicator).toNumber(),
    max: max.div(multiplicator).toNumber(),
    name: name.toString(),
    multiplicator: multiplicator
  }
}

function useLoadInitailParam(): ParamResponse | undefined {
  const { account, chainId } = useActiveWeb3React()
  const contract = useGovernanceContract(GOVERNANCE_ADDRESS[chainId!])
  const response = useSingleCallResult(contract, 'getParams', [])

  return useMemo(() => {
    if (response.loading || !response.result) return undefined

    return {
      account: account!,
      data: (response.result as any).parameters.map((item: any) => {
        const parsedParams = parseParam(item)
        return {
          amount: parsedParams.amount,
          value: parsedParams.value,
          maxValue: parsedParams.max,
          minValue: parsedParams.min,
          name: parsedParams.name ? parsedParams.name : item.name,
          encodedName: item.name
        }
      })
    }
  }, [account, response])
}

function useGetVotes(): ParamResponse | undefined {
  const { chainId, account } = useActiveWeb3React()
  const contract = useGovernanceContract(GOVERNANCE_ADDRESS[chainId!])
  const response = useSingleCallResult(contract, 'getVotes', [account || ZERO_ADDRESS])

  return useMemo(() => {
    if (response.loading || !response.result) return undefined

    return {
      account: account!,
      data: (response.result as any)[0]?.map((item: any, idx: number) => {
        const name = parseBytes32String(item)
        const multiplicator = MULTIPLICATOR[name]

        return {
          value: toBN(response.result![1][idx] as BigNumberish)
            .div(multiplicator)
            .toNumber(),
          name
        }
      })
    }
  }, [account, response])
}

export function useVoteForParams(): {
  status: VoteStatus
  callback: null | ((updates: Record<SingleParamType, number>) => Promise<any>)
} {
  const { account, chainId } = useActiveWeb3React()
  const contract = useGovernanceContract(GOVERNANCE_ADDRESS[chainId!])
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!account || !contract) {
      return { callback: null, status: VoteStatus.INVALID }
    }

    return {
      status: VoteStatus.VALID,
      callback: async (updates: Record<SingleParamType, number>) => {
        const data = Object.entries(updates).reduce(
          (acc, [key, value]) => {
            acc.keys.push(formatBytes32String(key))
            acc.value.push(
              bn(value)
                .times(MULTIPLICATOR[key])
                .toString(10)
            )
            return acc
          },
          { keys: [], value: [] } as { keys: string[]; value: string[] }
        )

        const inputs: any[] = [account, data.keys, data.value]

        const methodName = 'voteForParams'
        const options: any = {
          from: account
        }

        const gasEstimate = await contract.estimateGas[methodName](...inputs, options)

        return contract[methodName](...inputs, {
          ...options,
          gasLimit: calculateGasMargin(gasEstimate)
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Voted`
          })
        })
      }
    }
  }, [addTransaction, account, contract])
}

const LABEL_FOR_FUNDS: Record<Param, string> = {
  GOVERNANCE_REWARD: 'governanceReward',
  DEVELOPMENT: 'development',
  INSURANCE: 'insurance',
  CHARITY: 'charity',
  BUY_AND_BURN: 'buyAndBurn'
}

export function useVoteForFees(): {
  status: VoteStatus
  callback: null | ((updates: Record<Param, number>) => Promise<any>)
} {
  const { account, chainId } = useActiveWeb3React()
  const contract = useGovernanceContract(GOVERNANCE_ADDRESS[chainId!])
  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!account || !contract) {
      return { callback: null, status: VoteStatus.INVALID }
    }

    return {
      status: VoteStatus.VALID,
      callback: async (updates: Record<Param, number>) => {
        const fixedUpdates = fixErrorOnFee(updates)
        const data = Object.entries(fixedUpdates).reduce((acc, [key, value]) => {
          acc[LABEL_FOR_FUNDS[key as Param]] = toParam(value)
          return acc
        }, {} as Record<string, number>)

        // TODO: remove when update method
        // data['manualReward'] = 0
        // data['feeDistributionReward'] = 0

        const inputs: any[] = [account, data]

        const methodName = 'voteForFeeDistribution'
        const options: any = {
          from: account
        }

        const gasEstimate = await contract.estimateGas[methodName](...inputs, options)

        return contract[methodName](...inputs, {
          ...options,
          gasLimit: calculateGasMargin(gasEstimate)
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Voted`
          })
        })
      }
    }
  }, [addTransaction, account, contract])
}
