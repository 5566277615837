import React from 'react'
import styled from 'styled-components'
import { SingleParamType } from '../../state/financial/actions'

import { ValuesColumn } from './Values'

const FinMultiplicatorStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 23px;
  margin-bottom: 15px;

  border-bottom: 2px solid ${({ theme }) => theme.colors.bg0};

  &:last-child {
    margin-bottom: 10px;
    padding-bottom: 0;

    border-bottom: none;
  }
  ${({ theme }) => theme.mediaWidth.upToLarge`
			flex-direction: column;
			margin-bottom: 10px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	`};
`
const FinMultiplicatorLeft = styled.div`
  width: 70px;
  flex: 0 0 70px
    ${({ theme }) => theme.mediaWidth.upToLarge`
			width: auto;
			flex: 1 1 auto;
		}
	`};
`
const FinMultiplicatorCurrent = styled.div`
  font-weight: bold;
  font-size: 40px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.text1};

  ${({ theme }) => theme.mediaWidth.upToLarge`
			margin-bottom: 15px;
		}
	`};
`
const FinMultiplicatorCurrentNumber = styled.span`
  font-weight: bold;
  font-size: 40px;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.text1};
`
const FinMultiplicatorRating = styled.div<{ top?: boolean }>`
  position: relative;

  display: inline-flex;
  align-items: flex-end;
  padding-top: 1px;

  font-weight: bold;
  font-size: 27px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.text1};

  ${({ top, theme }) =>
    top &&
    `
    border-top: 1px solid ${theme.colors.black};
		}
	`};

  ${({ theme }) => theme.mediaWidth.upToLarge`
			margin-bottom: 10px;
		}
	`};
`
const FinMultiplicatorRatingNumber = styled.div`
  position: relative;
  bottom: -3px;
  left: 4px;

  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.text1};
`
const FinMultiplicatorRight = styled.div``

interface FinMultiplicatorProps {
  multiplier?: string
  top?: boolean
  rating?: string
  field: SingleParamType
  current: number
  userValue: number
  newValue: number
  from: number
  to: number
  marks: number[]
  onChange: (field: SingleParamType, value: number) => void
  onRenderMark: (mark: number, possibleNull?: boolean) => string | React.ReactNode
}

export function FinMultiplicator({
  multiplier,
  top,
  rating,
  field,
  current,
  userValue,
  newValue,
  from,
  to,
  marks,
  onChange,
  onRenderMark
}: FinMultiplicatorProps) {
  return (
    <FinMultiplicatorStyle>
      <FinMultiplicatorLeft>
        {multiplier && (
          <FinMultiplicatorCurrent>
            x<FinMultiplicatorCurrentNumber>{multiplier}</FinMultiplicatorCurrentNumber>
          </FinMultiplicatorCurrent>
        )}
        {rating && (
          <FinMultiplicatorRating top={top}>
            R<FinMultiplicatorRatingNumber>{rating}</FinMultiplicatorRatingNumber>
          </FinMultiplicatorRating>
        )}
      </FinMultiplicatorLeft>

      <FinMultiplicatorRight>
        <ValuesColumn
          field={field}
          from={from}
          to={to}
          marks={marks}
          current={current}
          userValue={userValue}
          newValue={newValue}
          onChange={onChange}
          onRenderMark={onRenderMark}
        />
      </FinMultiplicatorRight>
    </FinMultiplicatorStyle>
  )
}
