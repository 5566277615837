import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Info } from '../../assets/svg/msg-info.svg'
import { ReactComponent as Success } from '../../assets/svg/msg-success.svg'
import { ReactComponent as Warning } from '../../assets/svg/msg-warning.svg'
import { ReactComponent as Error } from '../../assets/svg/msg-error.svg'

import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'

const MESSAGE_ICONS = {
  info: Info,
  success: Success,
  warning: Warning,
  error: Error
}

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
`

export default function MessagePopup({
  type,
  message
}: {
  type: 'info' | 'success' | 'warning' | 'error'
  message: string
}) {
  const Icon = MESSAGE_ICONS[type]

  return (
    <RowNoFlex>
      <div style={{ marginRight: 16 }}>
        <Icon />
      </div>
      <AutoColumn gap="8px">{message}</AutoColumn>
    </RowNoFlex>
  )
}
