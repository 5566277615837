import { Trade, TradeType } from '@wowswap-io/wowswap-sdk'
import React, { useContext, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { Field } from '../../../state/swap/actions'
import { useDerivedSwapInfo } from '../../../state/swap/hooks'
import { TYPE } from '../../Shared'
import {
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  formatExecutionPrice,
  warningSeverity
} from '../../../utils/prices'
import { ButtonError } from '../../Button'
import QuestionHelper from '../../QuestionHelper'
import { AutoRow, RowBetween, RowFixed, RowFixedNowrap } from '../../Row'
import FormattedPriceImpact from '../FormattedPriceImpact'
import { StyledBalanceMaxMini, SwapCallbackError } from '../styleds'
import { IconExchange } from '../../Icons'
import { TextBase } from '../../Text'
import { useChainLabel } from '../../../hooks'
import { Position } from '../../../state/trade/types'

export default function LongTradeSwapModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  swapErrorMessage,
  disabledConfirm,
  position
}: {
  trade: Trade
  allowedSlippage: number
  onConfirm: () => void
  swapErrorMessage: string | undefined
  disabledConfirm: boolean
  position: Position
}) {
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const theme = useContext(ThemeContext)
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    allowedSlippage,
    trade
  ])
  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const severity = warningSeverity(priceImpactWithoutFee)
  const { leverageTrade } = useDerivedSwapInfo()

  const chainLabel = useChainLabel()
  const label =
    chainLabel === 'QuickSwap'
      ? `A portion of each trade (0.30%) goes to QuickSwap liquidity providers as a protocol incentive.`
      : `For each trade a 0.2% fee is paid. 0.17% goes to liquidity providers to PancakeSwap treasury.`

  return (
    <>
      <div>
        <RowBetween align="center" marginBottom={'8px'}>
          <TextBase color={theme.colors.text4} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            Price
          </TextBase>

          <TextBase style={{ textAlign: 'right', whiteSpace: 'nowrap', marginLeft: '7px', flexShrink: 0 }}>
            {formatExecutionPrice(trade, showInverted)}

            <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
              <IconExchange />
            </StyledBalanceMaxMini>
          </TextBase>
        </RowBetween>

        <RowBetween marginBottom={'8px'}>
          <RowFixedNowrap>
            <TextBase color={theme.colors.text4} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {trade.tradeType === TradeType.EXACT_INPUT ? 'Minimum received' : 'Maximum sold'}
            </TextBase>
            <QuestionHelper text="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed." />
          </RowFixedNowrap>
          <RowFixed style={{ textAlign: 'right', whiteSpace: 'nowrap', marginLeft: '7px', flexShrink: 0 }}>
            <TextBase>
              {trade.tradeType === TradeType.EXACT_INPUT
                ? slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-'
                : slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4) ?? '-'}
            </TextBase>
            <TextBase marginLeft={'4px'}>
              {trade.tradeType === TradeType.EXACT_INPUT
                ? trade.outputAmount.currency.symbol
                : trade.inputAmount.currency.symbol}
            </TextBase>
          </RowFixed>
        </RowBetween>
        <RowBetween marginBottom={'8px'}>
          <RowFixedNowrap>
            <TextBase color={theme.colors.text4} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              Price Impact
            </TextBase>
            <QuestionHelper text="The difference between the market price and your price due to trade size." />
          </RowFixedNowrap>
          <div style={{ textAlign: 'right', whiteSpace: 'nowrap', marginLeft: '7px', flexShrink: 0 }}>
            <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
          </div>
        </RowBetween>
        <RowBetween marginBottom={'8px'}>
          <RowFixedNowrap>
            <TextBase color={theme.colors.text4} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              Liquidity Provider Fee
            </TextBase>
            <QuestionHelper text={label} />
          </RowFixedNowrap>
          <TextBase style={{ textAlign: 'right', whiteSpace: 'nowrap', marginLeft: '7px', flexShrink: 0 }}>
            {realizedLPFee ? realizedLPFee?.toSignificant(6) + ' ' + trade.inputAmount.currency.symbol : '-'}
          </TextBase>
        </RowBetween>
        {leverageTrade.isOpenPosition && (
          <RowBetween marginBottom={'8px'}>
            <RowFixedNowrap>
              <TextBase color={theme.colors.text4} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                You borrow for this swap
              </TextBase>
            </RowFixedNowrap>

            <TYPE.base
              style={{ lineHeight: '18px', textAlign: 'right', whiteSpace: 'nowrap', marginLeft: '7px', flexShrink: 0 }}
            >
              {leverageTrade.borrow} {trade.inputAmount.currency.symbol}
            </TYPE.base>
          </RowBetween>
        )}
        {leverageTrade.isOpenPosition && (
          <RowBetween marginBottom={'8px'}>
            <RowFixedNowrap>
              <TextBase color={theme.colors.text4} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                HIR (Hourly Interest Rate)
              </TextBase>
            </RowFixedNowrap>
            <TYPE.base
              style={{ lineHeight: '18px', textAlign: 'right', whiteSpace: 'nowrap', marginLeft: '7px', flexShrink: 0 }}
            >
              {position.HIR} %
            </TYPE.base>
          </RowBetween>
        )}
      </div>

      <AutoRow marginTop={'20px'}>
        <ButtonError onClick={onConfirm} disabled={disabledConfirm} error={severity > 2} id="confirm-swap-or-send">
          <Text fontSize={16} fontWeight={'normal'}>
            {severity > 2 ? 'Swap Anyway' : 'Confirm Swap'}
          </Text>
        </ButtonError>

        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
