import React from 'react'
import styled from 'styled-components'
import { ItemStat } from '../ItemStat'

const ListStatStyle = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
		
	`};
`

const ListStatTitle = styled.div`
  margin-bottom: 10px;

  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.text3};

  ${({ theme }) => theme.mediaWidth.upToSmall`
		margin-bottom: 12px;

		font-size: 18px;
		line-height: 18px;
	`};
`

const ListStatList = styled.div``

interface ListStatItemsProps {
  itemName: string
  value: React.ReactNode
}

interface ListStatProps {
  title?: string
  items: ListStatItemsProps[]
}

export function ListStat({ title, items }: ListStatProps) {
  return (
    <ListStatStyle>
      {title && <ListStatTitle>{title}</ListStatTitle>}

      <ListStatList>
        {items.map((item, index) => (
          <ItemStat title={item.itemName} value={item.value} key={index} />
        ))}
      </ListStatList>
    </ListStatStyle>
  )
}
