import React from 'react'

import MenuSidebar from '../../components/MenuSidebar'
import { YourWalletList } from '../../components/stake/WalletList'
import { YourWalletGovernance } from '../../components/stake/WalletGovernance'
import { YourWalletStake } from '../../components/stake/WalletStake'
import {
  GridSidebarWraper,
  GridSidebar,
  SidebarLeft,
  GridSidebarBody,
  GridSidebarTitle,
  GridSidebarBack,
  GridSidebarHeader
} from '../../components/GridSidebar'
import { StakeTable } from '../../components/stake/StakeTable'
import {
  CoinLeft,
  CoinRight,
  Desc,
  YourWalletCol6,
  YourWalletContainer,
  YourWalletItem,
  YourWalletRow
} from './styleds'
import { useActiveWeb3React } from '../../hooks'
import { ChainId } from '@wowswap-io/wowswap-sdk'

export function GovernanceYourWallet() {
  const { chainId } = useActiveWeb3React()

  const lps: Record<ChainId, string> = {
    [ChainId.ETHEREUM]: 'WOW/ETH LP',
    [ChainId.ROPSTEN]: 'WOW/ETH LP',
    [ChainId.MAINNET]: 'WOW/BNB LP',
    [ChainId.MATIC]: 'WOW/USDC LP',
    [ChainId.LOCALNET]: 'WOW/BNB LP',
    [ChainId.BSCTESTNET]: 'WOW/BNB LP',
    [ChainId.HECO]: 'WOW/BNB LP',
    [ChainId.AVALANCE]: 'WOW/AVAX LP',
    [ChainId.FUJI]: '',
    [ChainId.IOTEX]: 'WOW/IOTX LP',
    [ChainId.ANDROMEDA]: '',
    [ChainId.STARDUST]: ''
  }

  const selectedLp = (chainId ? lps[chainId] : 'WOW LP') || 'WOW LP'

  const isPageTitleWithBackground = chainId === ChainId.ETHEREUM || chainId === ChainId.IOTEX

  return (
    <GridSidebarWraper>
      <GridSidebar>
        <SidebarLeft>
          <MenuSidebar />
        </SidebarLeft>

        <GridSidebarBody>
          <GridSidebarHeader withBackground={isPageTitleWithBackground}>
            <GridSidebarBack to={'/governance-menu'} />

            <GridSidebarTitle>Your Wallet</GridSidebarTitle>

            <Desc>
              <div>
                <b>You can stake WOW and {selectedLp}</b> tokens and vote on major protocol parameters to receive your
                governance rewards. Staking by itself is sufficient to be rewarded.
                <CoinLeft />
                <CoinRight />
              </div>
            </Desc>
          </GridSidebarHeader>

          <YourWalletContainer>
            <StakeTable />
          </YourWalletContainer>

          <YourWalletContainer>
            <YourWalletRow>
              <YourWalletCol6>
                <YourWalletItem>
                  <YourWalletList />
                </YourWalletItem>

                <YourWalletItem>
                  <YourWalletGovernance />
                </YourWalletItem>
              </YourWalletCol6>

              <YourWalletCol6>
                <YourWalletItem>
                  <YourWalletStake />
                </YourWalletItem>
              </YourWalletCol6>
            </YourWalletRow>
          </YourWalletContainer>
        </GridSidebarBody>
      </GridSidebar>
    </GridSidebarWraper>
  )
}
