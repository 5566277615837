import { useEffect, useState, useMemo } from 'react'
import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { isMobile } from 'react-device-detect'
import { ChainId } from '@wowswap-io/wowswap-sdk'

import { injected, NETWORK_CHAIN_ID } from '../connectors'
import { NetworkContextName } from '../constants'
import useAuth from './useAuth'

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & { chainId?: ChainId } {
  const context = useWeb3ReactCore<Web3Provider>()
  const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName)
  const result = context.active ? context : contextNetwork

  return result
}

export function useEagerConnect() {
  const { active } = useWeb3ReactCore() // specifically using useWeb3ReactCore because of what this hook does
  const [tried, setTried] = useState(false)
  const { login } = useAuth()

  useEffect(() => {
    injected.isAuthorized().then(async isAuthorized => {
      if (isAuthorized) {
        await login(injected, NETWORK_CHAIN_ID)
        setTried(true)
        return
      }

      if (isMobile && window.ethereum) {
        await login(injected, NETWORK_CHAIN_ID)
        setTried(true)
        return
      }

      setTried(true)
    })
  }, [login]) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true)
    }
  }, [active])

  return tried
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
  const { active, error, activate } = useWeb3ReactCore() // specifically using useWeb3React because of what this hook does

  useEffect(() => {
    const { ethereum } = window

    // WORKAROUND: switch to Avalanche
    if (ethereum && ethereum.removeAllListeners && active) {
      ethereum.removeAllListeners(['networkChanged'])
    }

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        activate(injected, undefined, true).catch(error => {
          console.error('Failed to activate after chain changed', error)
        })
      }

      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          // eat errors
          activate(injected, undefined, true).catch(error => {
            console.error('Failed to activate after accounts changed', error)
          })
        }
      }

      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
        }
      }
    }
    return undefined
  }, [active, error, suppress, activate])
}

export function useChainLabel() {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    switch (chainId) {
      case ChainId.ETHEREUM:
        return ''
      case ChainId.MATIC:
        return 'QuickSwap'
      case ChainId.HECO:
        return 'Mdex'
      case ChainId.AVALANCE:
        return 'Pangolin'
      case ChainId.IOTEX:
        return 'Mimo'
      default:
        return 'Pancakeswap'
    }
  }, [chainId])
}

export function useChainTradeWith() {
  const { chainId } = useActiveWeb3React()

  return useMemo(() => {
    switch (chainId) {
      case ChainId.ETHEREUM:
        return 'fractionalized NFTs'
      default:
        return 'tokens on'
    }
  }, [chainId])
}
