import React, { useCallback, useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { Currency, Pair } from '@wowswap-io/wowswap-sdk'
import TranslatedText from '../TranslatedText'
import { useActiveWeb3React } from '../../hooks'
import { TradeToken } from '../../hooks/Tokens.types'
import { TranslateString } from '../../utils/translateTextHelpers'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import EarnCurrencySearchModal from './EarnCurrencySearchModal'
import { Input as NumericalInput } from '../NumericalInput'
import { RowBetween } from '../Row'
import { TextBase, TextBaseLg } from '../Text'

import {
  Aligner,
  Container,
  CurrencySelect,
  DisabledLabel,
  InputPanel,
  InputRow,
  LabelRow,
  StyledBalanceMax,
  StyledBalanceMaxDivider,
  StyledDropDown,
  StyledTokenName
} from './styleds'

interface EarnCurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  selected?: TradeToken
  other?: TradeToken
  variants?: TradeToken[]
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  id: string
  disabled?: true
}

export default function EarnCurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label = TranslateString(132, 'Input'),
  onCurrencySelect,
  selected,
  other,
  variants,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  id,
  disabled
}: EarnCurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useActiveWeb3React()

  const theme = useContext(ThemeContext)

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  return (
    <InputPanel id={id}>
      <Container hideInput={hideInput}>
        {!hideInput && (
          <LabelRow>
            <RowBetween>
              <TextBaseLg>{label}</TextBaseLg>

              {account && (
                <TextBase onClick={onMax} color={theme.colors.text4} style={{ cursor: 'pointer' }}>
                  {!hideBalance && !!selected && selected.balance
                    ? 'Balance: ' + selected.balance?.toSignificant(6)
                    : ' -'}
                </TextBase>
              )}
            </RowBetween>
          </LabelRow>
        )}
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
          {!hideInput && (
            <>
              {disabled ? (
                <DisabledLabel placeholder="0.0" empty={!value}>
                  {value}
                </DisabledLabel>
              ) : (
                <NumericalInput
                  className="token-amount-input"
                  value={value}
                  onUserInput={val => {
                    onUserInput(val)
                  }}
                />
              )}
              {account && selected && showMaxButton && label !== 'To' && (
                <>
                  <StyledBalanceMax onClick={onMax}>MAX</StyledBalanceMax>

                  <StyledBalanceMaxDivider></StyledBalanceMaxDivider>
                </>
              )}
            </>
          )}
          <CurrencySelect
            selected={!!selected}
            className="open-currency-select-button"
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Aligner>
              {pair ? (
                <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={24} margin={true} />
              ) : selected ? (
                <CurrencyLogo currency={selected} size={'24px'} />
              ) : null}
              {pair ? (
                <StyledTokenName className="pair-name-container">
                  {pair?.token0.symbol}:{pair?.token1.symbol}
                </StyledTokenName>
              ) : (
                <StyledTokenName className="token-symbol-container" active={Boolean(selected && selected.symbol)}>
                  {(selected && selected.symbol && selected.symbol.length > 20
                    ? selected.symbol.slice(0, 4) +
                      '...' +
                      selected.symbol.slice(selected.symbol.length - 5, selected.symbol.length)
                    : selected?.symbol) || <TranslatedText translationId={82}>Select a token</TranslatedText>}
                </StyledTokenName>
              )}
              {!disableCurrencySelect && <StyledDropDown selected={!!selected} />}
            </Aligner>
          </CurrencySelect>
        </InputRow>
      </Container>
      {!disableCurrencySelect && onCurrencySelect && (
        <EarnCurrencySearchModal
          isOpen={modalOpen}
          selected={selected}
          other={other}
          variants={variants}
          onSelect={onCurrencySelect}
          onDismiss={handleDismissSearch}
        />
      )}
    </InputPanel>
  )
}
