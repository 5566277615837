import { useMemo } from 'react'
import { ZERO_ADDRESS } from '../../constants'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import { getDefaultToken } from '../../state/wallet/hooks'
import { TradeToken } from '../Tokens.types'
import { useAllTokens } from './Tokens'

function getShortableTokens(allTokens: Record<string, WrappedTokenInfo>, nativeToken: WrappedTokenInfo) {
  const lendables: TradeToken[] = []
  const shortingPairs = Object.values(allTokens).reduce((map, token) => {
    if (token.tokenInfo.lendable) {
      const reserveAddress =
        token.tokenInfo.reserve && token.tokenInfo.reserve !== ZERO_ADDRESS ? token.tokenInfo.reserve : undefined
      const proxyAddresses = Object.values(token.tokenInfo.proxies)

      // Lendable token. Токен с ценностью в сети и имеет цену на бирже.
      // Используется для пополнения резервов (в обмен на Interest bearing токен)
      // или для открытия позициий (long/short)
      lendables.push(
        new TradeToken({
          id: token.tokenInfo.symbol,
          chainId: token.tokenInfo.chainId,
          address: token.address,
          decimals: token.tokenInfo.decimals,
          symbol: token.tokenInfo.symbol,
          name: token.tokenInfo.name,
          isNative: token.address === nativeToken.address,
          isLendable: true,
          reserveAddress,
          isBase: Boolean(proxyAddresses.length > 0),
          proxyAddresses
        })
      )
    }

    if (token.tokenInfo.shorting) {
      Object.entries(token.tokenInfo.shorting).forEach(([stableToken, pairInfo]) => {
        const { pair, proxy } = pairInfo
        const stable = allTokens[stableToken]
        if (stable) {
          const id = [token.tokenInfo.symbol, stable.tokenInfo.symbol].join('_')

          // Proxy Short token. Владение токеном доказывает наличие открытой short позиции.
          // Когда пользователь открывает шорт BUSD/CAKE он получает именно ProxyLongToken -Cake,
          // который определен в wowswap.json как: CAKE -> shorting -> BUSD -> pair
          map[pair] = new TradeToken({
            id: token.tokenInfo.symbol,
            chainId: token.tokenInfo.chainId,
            address: pair,
            decimals: token.tokenInfo.decimals,
            symbol: token.tokenInfo.symbol,
            name: token.tokenInfo.name,
            isShort: true,
            stableAddress: stableToken,
            shortAddress: token.address,
            proxyAddress: proxy,
            pairName: id
          })
        }
      })
    }

    return map
  }, {} as Record<string, TradeToken>)

  lendables
    .filter(lendable =>
      Object.values(shortingPairs).some(pair => pair.shortingInfo?.stableAddress === lendable.address)
    )
    .forEach(lendable => (shortingPairs[lendable.address] = lendable))

  return shortingPairs
}

function getTradableTokens(allTokens: Record<string, WrappedTokenInfo>, nativeToken: WrappedTokenInfo) {
  return Object.values(allTokens).reduce((map, token) => {
    if (token.tokenInfo.lendable) {
      const reserveAddress =
        token.tokenInfo.reserve && token.tokenInfo.reserve !== ZERO_ADDRESS ? token.tokenInfo.reserve : undefined
      const proxyAddresses = Object.values(token.tokenInfo.proxies)

      // Lendable token. Токен с ценностью в сети и имеет цену на бирже.
      // Используется для пополнения резервов (в обмен на Interest bearing токен)
      // или для открытия позициий (long/short)
      map[token.address] = new TradeToken({
        id: token.tokenInfo.symbol,
        chainId: token.tokenInfo.chainId,
        address: token.address,
        decimals: token.tokenInfo.decimals,
        symbol: token.tokenInfo.symbol,
        name: token.tokenInfo.name,
        isNative: token.address === nativeToken.address,
        isLendable: true,
        reserveAddress,
        isBase: Boolean(proxyAddresses.length > 0),
        proxyAddresses
      })
    }

    if (token.tokenInfo.pairs) {
      Object.entries(token.tokenInfo.pairs).forEach(([proxyHost, pairInfo]) => {
        const { pair, proxy } = pairInfo
        const lendable = allTokens[proxyHost]
        if (lendable) {
          const id = [token.tokenInfo.symbol, lendable.tokenInfo.symbol].join('_')
          // Proxy Long token. Владение токеном доказывает наличие открытой позиции.
          // Когда пользователь открывает лонг BUSD/CAKE он получает именно ProxyLongToken prxCake,
          // который определен в wowswap.json как: CAKE -> pairs -> BUSD -> pair
          map[pair] = new TradeToken({
            id: token.tokenInfo.symbol,
            chainId: token.tokenInfo.chainId,
            address: pair,
            decimals: token.tokenInfo.decimals,
            symbol: token.tokenInfo.symbol,
            name: token.tokenInfo.name,
            isProxy: true,
            lendableAddress: proxyHost,
            tradableAddress: token.address,
            proxyAddress: proxy,
            pairName: id
          })
        }
      })
    }

    return map
  }, {} as Record<string, TradeToken>)
}

function getStakableTokens(allTokens: Record<string, WrappedTokenInfo>) {
  return Object.values(allTokens).reduce((map, token) => {
    if (token.tokenInfo.staking) {
      if (token.tokenInfo.staking.base) {
        // Governance token. Он такой единственный и является токеном голосования. Это xWOW
        map[token.address] = new TradeToken({
          id: token.tokenInfo.symbol,
          chainId: token.tokenInfo.chainId,
          address: token.address,
          decimals: token.tokenInfo.decimals,
          symbol: token.tokenInfo.symbol,
          name: token.tokenInfo.name,
          isStaking: true,
          isBase: true
        })
      }

      if (token.tokenInfo.staking.straight) {
        // Staking Governance token. Используются для стейкинга и получения токенов голосования.
        // Другими словами это WOW за который мы можем получить xWOW
        map[token.address] = new TradeToken({
          id: token.tokenInfo.symbol,
          chainId: token.tokenInfo.chainId,
          address: token.address,
          decimals: token.tokenInfo.decimals,
          symbol: token.tokenInfo.symbol,
          name: token.tokenInfo.name,
          isStaking: true
        })
      }

      if (token.tokenInfo.staking.lp) {
        Object.entries(token.tokenInfo.staking.lp).forEach(([hostSymbol, hostAddress]) => {
          const id = [token.tokenInfo.symbol, hostSymbol].join('_')
          // LP Token for stake. Токены провайдера ликвидности на бирже.
          // Выдаются пользователю когда он пополняет резервы Pancake swap
          // и используются у нас в протоколе для получения xWOW
          map[hostAddress] = new TradeToken({
            id: `${token.tokenInfo.symbol}/${hostSymbol}`,
            address: hostAddress,
            chainId: token.tokenInfo.chainId,
            decimals: token.tokenInfo.decimals,
            symbol: `${token.tokenInfo.symbol}/${hostSymbol}`,
            name: `LP ${token.tokenInfo.symbol} ${hostSymbol}`,
            isStaking: true,
            pairName: id
          })
        })
      }
    }

    return map
  }, {} as Record<string, TradeToken>)
}

function getRewardableTokens(allTokens: Record<string, WrappedTokenInfo>, nativeToken: WrappedTokenInfo) {
  return Object.values(allTokens).reduce((map, token) => {
    if (token.tokenInfo.lendable) {
      const reserveAddress =
        token.tokenInfo.reserve && token.tokenInfo.reserve !== ZERO_ADDRESS ? token.tokenInfo.reserve : undefined
      const proxyAddresses = Object.values(token.tokenInfo.proxies)

      // Lendable token. Токен с ценностью в сети и имеет цену на бирже.
      // Используется для пополнения резервов (в обмен на Interest bearing токен)
      // или для открытия позициий (long/short)
      map[token.address] = new TradeToken({
        id: token.tokenInfo.symbol,
        chainId: token.tokenInfo.chainId,
        address: token.address,
        decimals: token.tokenInfo.decimals,
        symbol: token.tokenInfo.symbol,
        name: token.tokenInfo.name,
        isNative: token.address === nativeToken.address,
        isLendable: true,
        reserveAddress,
        isBase: Boolean(proxyAddresses.length > 0),
        proxyAddresses
      })
    }

    if (token.tokenInfo.staking) {
      if (token.tokenInfo.staking.straight) {
        // Staking Governance token. Используются для стейкинга и получения токенов голосования.
        // Другими словами это WOW за который мы можем получить xWOW
        map[token.address] = new TradeToken({
          id: token.tokenInfo.symbol,
          chainId: token.tokenInfo.chainId,
          address: token.address,
          decimals: token.tokenInfo.decimals,
          symbol: token.tokenInfo.symbol,
          name: token.tokenInfo.name,
          isStaking: true
        })
      }
    }

    return map
  }, {} as Record<string, TradeToken>)
}
function getEarnableTokens(allTokens: Record<string, WrappedTokenInfo>, nativeToken: WrappedTokenInfo) {
  return Object.values(allTokens).reduce((map, token) => {
    if (token.tokenInfo.reserve && token.tokenInfo.reserve !== ZERO_ADDRESS) {
      const reserveAddress = token.tokenInfo.reserve!
      const proxyAddresses = Object.values(token.tokenInfo.proxies)
      const shortable = token.tokenInfo.shortable ? true : undefined
      const isLendable = token.tokenInfo.lendable ? true : undefined

      // Lendable token. Токен с ценностью в сети и имеет цену на бирже.
      // Используется для пополнения резервов (в обмен на Interest bearing токен)
      // или для открытия позициий (long/short)
      map[token.address] = new TradeToken({
        id: token.tokenInfo.symbol,
        chainId: token.tokenInfo.chainId,
        address: token.address,
        decimals: token.tokenInfo.decimals,
        symbol: token.tokenInfo.symbol,
        name: token.tokenInfo.name,
        isNative: Boolean(token.tokenInfo.native),
        isLendable: isLendable as any,
        shortable,
        reserveAddress,
        isBase: Boolean(proxyAddresses.length > 0),
        proxyAddresses
      })

      if (reserveAddress) {
        // Interest Bearing token. Говорит, что владелец может вывести средства из резерва с накопленным доходом
        map[reserveAddress] = new TradeToken({
          id: token.tokenInfo.symbol,
          chainId: token.tokenInfo.chainId,
          address: reserveAddress,
          decimals: token.tokenInfo.decimals,
          symbol: `ib${token.symbol}`,
          name: `Interest-bearing ${token.name}`,
          isReserve: true,
          lendableAddress: token.address
        })
      }
    }

    return map
  }, {} as Record<string, TradeToken>)
}

function getAllTradeTokens(allTokens: Record<string, WrappedTokenInfo>, nativeToken: WrappedTokenInfo) {
  return Object.values(allTokens).reduce((map, token) => {
    if (token.tokenInfo.lendable) {
      const reserveAddress =
        token.tokenInfo.reserve && token.tokenInfo.reserve !== ZERO_ADDRESS ? token.tokenInfo.reserve : undefined
      const proxyAddresses = Object.values(token.tokenInfo.proxies)

      // Lendable token. Токен с ценностью в сети и имеет цену на бирже.
      // Используется для пополнения резервов (в обмен на Interest bearing токен)
      // или для открытия позициий (long/short)
      map[token.address] = new TradeToken({
        id: token.tokenInfo.symbol,
        chainId: token.tokenInfo.chainId,
        address: token.address,
        decimals: token.tokenInfo.decimals,
        symbol: token.tokenInfo.symbol,
        name: token.tokenInfo.name,
        isNative: token.address === nativeToken.address,
        isLendable: true,
        reserveAddress,
        isBase: Boolean(proxyAddresses.length > 0),
        proxyAddresses
      })

      if (reserveAddress) {
        // Interest Bearing token. Говорит, что владелец может вывести средства из резерва с накопленным доходом
        map[reserveAddress] = new TradeToken({
          id: token.tokenInfo.symbol,
          chainId: token.tokenInfo.chainId,
          address: reserveAddress,
          decimals: token.tokenInfo.decimals,
          symbol: `ib${token.symbol}`,
          name: `Interest-bearing ${token.name}`,
          isReserve: true,
          lendableAddress: token.address
        })
      }
    }

    if (token.tokenInfo.pairs) {
      Object.entries(token.tokenInfo.pairs).forEach(([proxyHost, pairInfo]) => {
        const { pair, proxy } = pairInfo
        const lendable = allTokens[proxyHost]
        if (lendable) {
          const id = [token.tokenInfo.symbol, lendable.tokenInfo.symbol].join('_')
          // Proxy Long token. Владение токеном доказывает наличие открытой позиции.
          // Когда пользователь открывает лонг BUSD/CAKE он получает именно ProxyLongToken prxCake,
          // который определен в wowswap.json как: CAKE -> pairs -> BUSD -> pair
          map[pair] = new TradeToken({
            id: token.tokenInfo.symbol,
            chainId: token.tokenInfo.chainId,
            address: pair,
            decimals: token.tokenInfo.decimals,
            symbol: token.tokenInfo.symbol,
            name: token.tokenInfo.name,
            isProxy: true,
            lendableAddress: proxyHost,
            tradableAddress: token.address,
            proxyAddress: proxy,
            pairName: id
          })
        }
      })
    }

    if (token.tokenInfo.shorting) {
      const reserveAddress =
        token.tokenInfo.reserve && token.tokenInfo.reserve !== ZERO_ADDRESS ? token.tokenInfo.reserve : undefined

      if (reserveAddress) {
        // Shortable token. Используется для пополнения резервов на странице Earn
        // и предоставления кредитов при открытии Short позиций
        // TODO: если токен определен как lendable и shorting, например, BNB, то остается только запись про шортабл
        map[token.address] = new TradeToken({
          id: token.tokenInfo.symbol,
          chainId: token.tokenInfo.chainId,
          address: token.address,
          decimals: token.tokenInfo.decimals,
          symbol: token.tokenInfo.symbol,
          name: token.tokenInfo.name,
          shortable: true,
          reserveAddress
        })

        // Interest Bearing token.
        // Говорит, что владелец может вывести средства из резерва с накопленным доходом
        map[reserveAddress] = new TradeToken({
          id: token.tokenInfo.symbol,
          chainId: token.tokenInfo.chainId,
          address: reserveAddress,
          decimals: token.tokenInfo.decimals,
          symbol: `ib${token.symbol}`,
          name: `Interest-bearing ${token.name}`,
          isReserve: true,
          lendableAddress: token.address
        })
      }

      Object.entries(token.tokenInfo.shorting).forEach(([stableToken, pairInfo]) => {
        const { pair, proxy } = pairInfo
        const stable = allTokens[stableToken]
        if (stable) {
          const id = [token.tokenInfo.symbol, stable.tokenInfo.symbol].join('_')

          // Proxy Short token. Владение токеном доказывает наличие открытой short позиции.
          // Когда пользователь открывает шорт BUSD/CAKE он получает именно ProxyLongToken -Cake,
          // который определен в wowswap.json как: CAKE -> shorting -> BUSD -> pair
          map[pair] = new TradeToken({
            id: token.tokenInfo.symbol,
            chainId: token.tokenInfo.chainId,
            address: pair,
            decimals: token.tokenInfo.decimals,
            symbol: token.tokenInfo.symbol,
            name: token.tokenInfo.name,
            isShort: true,
            stableAddress: stableToken,
            shortAddress: token.address,
            proxyAddress: proxy,
            pairName: id
          })
        }
      })
    }

    return map
  }, {} as Record<string, TradeToken>)
}

export function getAllValueTokens(allTokens: Record<string, WrappedTokenInfo>, nativeToken: WrappedTokenInfo) {
  return Object.values(allTokens).reduce((map, token) => {
    map[token.address] = new TradeToken({
      id: token.tokenInfo.symbol,
      chainId: token.tokenInfo.chainId,
      address: token.address,
      decimals: token.tokenInfo.decimals,
      symbol: token.tokenInfo.symbol,
      name: token.tokenInfo.name
    })

    return map
  }, {} as Record<string, TradeToken>)
}

export function useAllValueTokens() {
  const allTokens = useAllTokens() as Record<string, WrappedTokenInfo>
  const nativeToken = getDefaultToken()

  return useMemo(() => {
    return getAllValueTokens(allTokens, nativeToken)
  }, [allTokens, nativeToken])
}
//TODO:(Dimitreee): remove rudiment
export function useAllTradeTokens() {
  const allTokens = useAllTokens() as Record<string, WrappedTokenInfo>
  const nativeToken = getDefaultToken()

  return useMemo(() => {
    return getAllTradeTokens(allTokens, nativeToken)
  }, [allTokens, nativeToken])
}

export function useAllRewardTokens() {
  const allTokens = useAllTokens() as Record<string, WrappedTokenInfo>
  const nativeToken = getDefaultToken()

  return useMemo(() => {
    return getRewardableTokens(allTokens, nativeToken)
  }, [allTokens, nativeToken])
}

export function useAllEarnbaleTokens() {
  const allTokens = useAllTokens() as Record<string, WrappedTokenInfo>
  const nativeToken = getDefaultToken()

  return useMemo(() => {
    return getEarnableTokens(allTokens, nativeToken)
  }, [allTokens, nativeToken])
}

export function useAllStakableTokens() {
  const allTokens = useAllTokens() as Record<string, WrappedTokenInfo>

  return useMemo(() => {
    return getStakableTokens(allTokens)
  }, [allTokens])
}

export function useAllTradableTokens() {
  const allTokens = useAllTokens() as Record<string, WrappedTokenInfo>
  const nativeToken = getDefaultToken()

  return useMemo(() => {
    return getTradableTokens(allTokens, nativeToken)
  }, [allTokens, nativeToken])
}

export function useAllShortableTokens() {
  const allTokens = useAllTokens() as Record<string, WrappedTokenInfo>
  const nativeToken = getDefaultToken()

  return useMemo(() => {
    return getShortableTokens(allTokens, nativeToken)
  }, [allTokens, nativeToken])
}
