export const WOWSWAP_ROUTE = {
  LANDING: '/',
  TRADE: '/swap',
  EARN: '/earn',
  ABOUT: '/about',
  ALERTS: '/alerts',
  PORTFOLIO: '/portfolio',
  LIQUIDATION: '/liquidation',
  GOVERNANCE_MENU: '/governance-menu',
  GOVERNANCE_YOUR_WALLET: '/governance-your-wallet',
  GOVERNANCE_PROPOSALS: '/governance-proposals',
  GOVERNANCE_PROPOSALS_ITEM: '/governance-proposals-item',
  GOVERNANCE_FINANCIAL_MODEL: '/governance-financial-model',
  GOVERNANCE_ECONOMIC_MODEL: '/governance-economic-model',
  GOVERNANCE_DONATIONS: '/governance-donations',
  GOVERNANCE_STATS: '/governance-stats',
  XDEGEN: '/ambassador'
}
