import React from 'react'
import styled from 'styled-components'

const ModuleWrapperStyle = styled.div`
  padding: 30px;

  background-color: rgba(253, 244, 225, 0.8);
  border-radius: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		padding: 20px;
	`};
`
const ModuleWrapperBigStyle = styled.div`
  padding: 36px;

  background: ${({ theme }) => theme.colors.bg0};
  border: 4px solid ${({ theme }) => theme.colors.black};
  border-radius: 20px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		padding: 16px 16px 24px 16px;
	`};
`
const ModuleWrapperSmStyle = styled.div`
  padding: 26px;

  background: ${({ theme }) => theme.colors.bg0};
  border: 4px solid ${({ theme }) => theme.colors.black};
  border-radius: 20px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		padding: 16px 16px 24px 16px;
	`};
`
const ModuleWrapperWhiteWStyle = styled.div`
  padding: 20px;

  background: ${({ theme }) => theme.colors.bg1};
  border-radius: 10px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		padding: 15px;
	`};
`
const ModuleWrapperWhiteWTitle = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom || '15px'};

  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.text1};

  ${({ theme }) => theme.mediaWidth.upToLarge`
		margin-bottom: 10px;
	`};
`
const ModuleWrapperOrangeStyle = styled.div`
  padding: 20px 0 20px 20px;

  background: ${({ theme }) => theme.colors.bg0};
  border-radius: 10px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		padding: 15px 0 15px 15px;
	`};
`

export function ModuleWrapper(props: { children: React.ReactNode }) {
  const { children, ...rest } = props
  return <ModuleWrapperStyle {...rest}>{children}</ModuleWrapperStyle>
}

export function ModuleWrapperBig(props: { children: React.ReactNode }) {
  const { children, ...rest } = props
  return <ModuleWrapperBigStyle {...rest}>{children}</ModuleWrapperBigStyle>
}
export function ModuleWrapperSm(props: { children: React.ReactNode }) {
  const { children, ...rest } = props
  return <ModuleWrapperSmStyle {...rest}>{children}</ModuleWrapperSmStyle>
}

export function ModuleWrapperWhite(props: { children: React.ReactNode; title?: string; marginBottom?: string }) {
  const { children, title, marginBottom, ...rest } = props
  return (
    <ModuleWrapperWhiteWStyle {...rest}>
      <ModuleWrapperWhiteWTitle marginBottom={marginBottom}>{title}</ModuleWrapperWhiteWTitle>
      {children}
    </ModuleWrapperWhiteWStyle>
  )
}

export function ModuleWrapperOrange(props: { children: React.ReactNode }) {
  const { children, ...rest } = props
  return <ModuleWrapperOrangeStyle {...rest}>{children}</ModuleWrapperOrangeStyle>
}
