import { ChainId, Currency, CurrencyAmount, id, JSBI, Token, TokenAmount, WETH } from '@wowswap-io/wowswap-sdk'
import { useMemo } from 'react'
import ERC20_INTERFACE from '../../constants/abis/erc20'
import { useAllTokens, toFlatArray } from '../../hooks/tokens/Tokens'
import { useActiveWeb3React } from '../../hooks'
import { useMulticallContract } from '../../hooks/useContract'
import { isAddress } from '../../utils'
import { useSingleContractMultipleData, useMultipleContractSingleData } from '../multicall/hooks'
import { WrappedTokenInfo } from '../lists/hooks'

/**
 * Returns a map of the given addresses to their eventually consistent ETH balances.
 */
export function useETHBalances(
  uncheckedAddresses?: (string | undefined)[]
): { [address: string]: CurrencyAmount | undefined } {
  const multicallContract = useMulticallContract()

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
            .map(isAddress)
            .filter((a): a is string => a !== false)
            .sort()
        : [],
    [uncheckedAddresses]
  )

  const results = useSingleContractMultipleData(
    multicallContract,
    'getEthBalance',
    addresses.map(address => [address])
  )

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: CurrencyAmount }>((memo, address, i) => {
        const value = results?.[i]?.result?.[0]
        if (value) memo[address] = CurrencyAmount.ether(JSBI.BigInt(value.toString()))
        return memo
      }, {}),
    [addresses, results]
  )
}

export function getDefaultToken(chainId?: ChainId): WrappedTokenInfo {
  const weth = WETH[chainId || id.getId()]
  return new WrappedTokenInfo(
    {
      name: weth.name!,
      symbol: weth.symbol!,
      address: weth.address,
      chainId: weth.chainId,
      decimals: weth.decimals,
      lendable: true,
      native: true,
      proxies: {}
    },
    []
  )
}

export function useCurrencyToken(currency?: Currency): WrappedTokenInfo | undefined {
  const defaultToken = getDefaultToken()
  const allTokens = useAllTokens()
  if (currency?.symbol === Currency.getBaseCurrency().symbol) {
    return defaultToken
  } else if (currency) {
    return Object.values(allTokens)
      .filter((t: Token): t is WrappedTokenInfo => t && 'tokenInfo' in t)
      .find(tkn => tkn.symbol === currency?.symbol)
  } else {
    return undefined
  }
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[],
  lendable?: Currency
): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  return useTokenBalancesProxiesWithLoadingIndicator(address, tokens, lendable)
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesProxiesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[],
  lendToken?: Currency
): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const defaultToken = getDefaultToken()
  let lendableToken = useCurrencyToken(lendToken)
  if (!lendableToken) {
    lendableToken = defaultToken
  }

  const validatedTokens = useMemo(() => {
    const tokensWithInfo = tokens?.filter((t?: Token): t is WrappedTokenInfo => !!t && 'tokenInfo' in t)
    const lendableTokens = tokensWithInfo?.filter(t => t.tokenInfo.lendable) || []
    const tradableProxies =
      tokensWithInfo?.filter(t => !t.tokenInfo.lendable && Object.keys(t.tokenInfo.proxies).length > 0) || []
    return [...lendableTokens, ...tradableProxies]
  }, [tokens])

  const validatedTokenAddresses = useMemo(() => validatedTokens.map(token => token.getTokenAddress), [validatedTokens])

  const balances = useMultipleContractSingleData(validatedTokenAddresses, ERC20_INTERFACE, 'balanceOf', [address])

  const anyLoading: boolean = useMemo(() => balances.some(callState => callState.loading), [balances])

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
              const tokenAddress = token.getTokenAddress
              const value = balances?.[i]?.result?.[0]
              const amount = value ? JSBI.BigInt(value.toString()) : undefined
              if (amount) {
                memo[tokenAddress] = new TokenAmount(token, amount)
              }
              return memo
            }, {})
          : {},
      [address, validatedTokens, balances]
    ),
    anyLoading
  ]
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[],
  lendable?: Currency
): { [tokenAddress: string]: TokenAmount | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens, lendable)[0]
}

export function useTokenBalancesProxies(
  address?: string,
  tokens?: (Token | undefined)[],
  lendToken?: Currency
): { [tokenAddress: string]: TokenAmount | undefined } {
  return useTokenBalancesProxiesWithLoadingIndicator(address, tokens, lendToken)[0]
}

// get the balance for a single token/account combo
export function useTokenBalance(account?: string, token?: Token, lendable?: Currency): TokenAmount | undefined {
  const tokenBalances = useTokenBalances(account, [token], lendable)
  if (!token) return undefined
  return tokenBalances[token.address]
}

export function useCurrencyBalances(
  account?: string,
  currencies?: (Currency | undefined)[],
  lendable?: Currency
): (CurrencyAmount | undefined)[] {
  return useCurrencyBalancesProxies(account, currencies, lendable)
}

export function useCurrencyBalancesProxies(
  account?: string,
  currencies?: (Currency | undefined)[],
  lendable?: Currency
): (CurrencyAmount | undefined)[] {
  const tokens = useMemo(() => currencies?.filter((currency): currency is Token => currency instanceof Token) ?? [], [
    currencies
  ])

  const tokenBalances = useTokenBalancesProxies(account, tokens, lendable)
  const containsETH: boolean = useMemo(
    () => currencies?.some(currency => currency === Currency.getBaseCurrency()) ?? false,
    [currencies]
  )
  const ethBalance = useETHBalances(containsETH ? [account] : [])

  return useMemo(
    () =>
      currencies?.map(currency => {
        if (!account || !currency) return undefined
        if (currency instanceof WrappedTokenInfo) return tokenBalances[currency.getTokenAddress]
        if (currency instanceof Token) return tokenBalances[currency.address]
        if (currency === Currency.getBaseCurrency()) return ethBalance[account]
        return undefined
      }) ?? [],
    [account, currencies, ethBalance, tokenBalances]
  )
}

export function useCurrencyBalance(
  account?: string,
  currency?: Currency,
  lendable?: Currency
): CurrencyAmount | undefined {
  return useCurrencyBalanceProxies(account, currency, lendable)
}

export function useCurrencyBalanceProxies(
  account?: string,
  currency?: Currency,
  lendable?: Currency
): CurrencyAmount | undefined {
  return useCurrencyBalancesProxies(account, [currency], lendable)[0]
}

// mimics useAllBalances
export function useAllTokenBalances(lendable?: Currency): { [tokenAddress: string]: TokenAmount | undefined } {
  return useAllTokenBalancesProxies(lendable)
}

export function useAllTokenBalancesProxies(lendable?: Currency): { [tokenAddress: string]: TokenAmount | undefined } {
  const { account } = useActiveWeb3React()
  const allTokens = useAllTokens()
  const allTokensArray = useMemo(() => toFlatArray(allTokens), [allTokens])
  const balances = useTokenBalancesProxies(account ?? undefined, allTokensArray, lendable)
  return balances ?? {}
}
