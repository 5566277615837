import loaderTikTokPixel from './loaderTikTokPixel'

function initialize(pixelId: string) {
  if (!pixelId) {
    console.error('[TikTok Pixel]: Can not initialize TikTok Pixel without <TIK_TOK_PIXEL_ID>')
    return false
  }

  const ttq = loaderTikTokPixel()
  ttq.load(pixelId)
  ttq.page()
  return true
}

export default {
  initialize
}
