import styled from 'styled-components'

export const ApyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
`
export const ApyLabel = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
`
export const ApyValue = styled.div`
  background: #ffffff;
  border: 2px solid ${({ theme }) => theme.colors.bg2};
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 22px;
  line-height: 100%;

  padding: 5px 20px;
  color: #42ce61;
`

export const YourWalletListStyle = styled.div`
  padding: 36px 36px;

  background: #fdf4e1;
  border: 4px solid #000000;
  border-radius: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		padding: 26px 16px;
	`};
`

export const YourWalletItemStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 10px;

  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text1};

  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.bg1};

  &:nth-child(2n) {
    background-color: transparent;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		padding: 10px 15px;

		font-size: 14px;
		line-height: 14px;
	`};
`
export const YourWalletItemText = styled.span`
  display: inline-block;
  padding-right: 5px;
`
export const YourWalletItemValue = styled.span`
  display: inline-block;
  padding-left: 5px;
`

export const YourWalletGovernanceStyle = styled.div`
  padding: 37px 36px 35px 36px;

  background-color: ${({ theme }) => theme.colors.bg0};
  border: 4px solid ${({ theme }) => theme.colors.black};
  border-radius: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		padding: 16px 16px 26px 16px;
	`};
`
export const YourWalletGovernanceTitle = styled.h3`
  margin: 0;
  margin-bottom: 10px;

  font-family: 'Retro';
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.text1};
`
export const YourWalletGovernanceItemStyle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px 15px;
  margin-bottom: 10px;

  background-color: ${({ theme }) => theme.colors.bg1};
  border-radius: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`
export const YourWalletGovernanceItemLeft = styled.div`
  flex: 1 1 auto;
  padding-right: 5px;
`
export const YourWalletGovernanceItemIcons = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`
export const YourWalletGovernanceItemValue = styled.div`
  align-self: center;

  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text1};
  margin-left: 15px;
`

export const YourWalletStakeStyle = styled.div`
  padding: 36px 36px;

  background-color: ${({ theme }) => theme.colors.bg0};
  border: 4px solid ${({ theme }) => theme.colors.black};
  border-radius: 20px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		padding: 16px 16px 26px 16px;
	`};
`
export const YourWalletStakeTabs = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  padding-left: 10px;
`
export const YourWalletStakeTabsLink = styled.div<{ active: boolean }>`
  font-family: 'Retro';
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  text-decoration: none;

  cursor: pointer;

  transition: all 0.4s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.text2};
  }
  ${({ active }) => {
    if (active) {
      return `
				color: #E85A44;
			`
    } else {
      return `
				color: #000000;
			`
    }
  }}
`
export const YourWalletStakeBody = styled.div``
export const YourWalletStakeItemTab = styled.div<{ active: boolean }>`
  ${({ active }) => {
    if (active) {
      return `
				display: block;
			`
    } else {
      return `
				display: none;
			`
    }
  }}
`
export const YourWalletStakeList = styled.div``
export const YourWalletUnstakeItemStyle = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px 15px;
  margin-bottom: 10px;

  background-color: ${({ theme }) => theme.colors.bg1};
  border-radius: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`
export const YourWalletUnstakeItemLeft = styled.div`
  flex: 1 1 auto;
  padding-right: 5px;
`
export const YourWalletUnstakeItemIcons = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
`
export const YourWalletUnstakeItemValue = styled.div`
  align-self: center;
  margin-left: 15px;

  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text1};
`
export const YourWalletUnstakeItemDate = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.text4};
`
export const YourWalletUnstakeItemRight = styled.div``
export const NotAvailable = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.text3};
`
