import React from 'react'
import styled from 'styled-components'
import { ContentWrapperNoBg } from '../components/ContentWrapper'
import { ModuleWrapperOrange } from '../components/ModuleWrapper'
import {
  GridSidebarWraper,
  GridSidebar,
  SidebarLeft,
  GridSidebarBody,
  GridSidebarTitle,
  GridSidebarBack,
  GridSidebarHeader
} from '../components/GridSidebar'
import MenuSidebar from '../components/MenuSidebar'
import { StatsTotal } from '../components/StatsTotal'
import ImgGraf01 from '../assets/images/img-stats-graf-01.jpg'
import ImgGraf02 from '../assets/images/img-stats-graf-02.jpg'
import ImgGraf03 from '../assets/images/img-stats-graf-03.jpg'
import ImgGraf04 from '../assets/images/img-stats-graf-04.jpg'
import { useActiveWeb3React } from '../hooks'
import { ChainId } from '@wowswap-io/wowswap-sdk'

const StatsRow = styled.div`
  display: flex;
  margin: 0 -5px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		flex-direction: column;
	`};
`
const StatsCol = styled.div`
  width: 50%;
  flex: 0 0 50%;
  padding: 0 5px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		width: 100%;
		flex: 0 0 100%;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	`};
`
const StatsItem = styled.div`
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
		margin-bottom: 20px;
	`};
`
const StatsImg = styled.img`
  display: block;
  width: 100%;
`
export default function GovernanceStats() {
  const { chainId } = useActiveWeb3React()

  const isPageTitleWithBackground = chainId === ChainId.ETHEREUM || chainId === ChainId.IOTEX

  return (
    <GridSidebarWraper>
      <GridSidebar>
        <SidebarLeft>
          <MenuSidebar />
        </SidebarLeft>

        <GridSidebarBody>
          <GridSidebarHeader withBackground={isPageTitleWithBackground}>
            <GridSidebarBack to={'/governance-menu'} />
            <GridSidebarTitle>Stats</GridSidebarTitle>
          </GridSidebarHeader>

          <ContentWrapperNoBg NoNegativeMargin maxWidth="894px" padding="0">
            <StatsItem>
              <StatsTotal />
            </StatsItem>
            <StatsRow>
              <StatsCol>
                <StatsItem>
                  <ModuleWrapperOrange>
                    <StatsImg src={ImgGraf01} alt="graf" />
                  </ModuleWrapperOrange>
                </StatsItem>

                <StatsItem>
                  <ModuleWrapperOrange>
                    <StatsImg src={ImgGraf02} alt="graf" />
                  </ModuleWrapperOrange>
                </StatsItem>
              </StatsCol>

              <StatsCol>
                <StatsItem>
                  <ModuleWrapperOrange>
                    <StatsImg src={ImgGraf03} alt="graf" />
                  </ModuleWrapperOrange>
                </StatsItem>

                <StatsItem>
                  <ModuleWrapperOrange>
                    <StatsImg src={ImgGraf04} alt="graf" />
                  </ModuleWrapperOrange>
                </StatsItem>
              </StatsCol>
            </StatsRow>
          </ContentWrapperNoBg>
        </GridSidebarBody>
      </GridSidebar>
    </GridSidebarWraper>
  )
}
