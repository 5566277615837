import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Close } from '../../assets/images/close.svg'
import { ReactComponent as Exchange } from '../../assets/images/exchange.svg'
import { ReactComponent as approvingArrow } from '../../assets/images/approvingArrow.svg'
import { ReactComponent as Check } from '../../assets/images/check.svg'
import { ReactComponent as Warning } from '../../assets/images/warning.svg'

const BaseIconStyle = styled.div`
  width: 24px;
  height: 24px;

  > svg {
    width: 24px;
    height: 24px;

    cursor: pointer;
    fill: ${({ theme }) => theme.colors.black};

    transition: all 0.4s ease;

    &:hover {
      fill: ${({ theme }) => theme.colors.primary1};
    }
  }
`

const ExchangeStyle = styled(Exchange)`
  width: 9px;
  height: 12px;
`

const ApprovingArrowStyle = styled(approvingArrow)`
  width: 142px;
  height: 142px;
`

const CheckStyle = styled(Check)`
  width: 18px;
  height: 18px;
`

const WarningStyle = styled(Warning)`
  width: 19px;
  height: 16px;
`

export function IconClose() {
  return (
    <BaseIconStyle>
      <Close />
    </BaseIconStyle>
  )
}

export function IconExchange() {
  return <ExchangeStyle />
}

export function IconApprovingArrow() {
  return <ApprovingArrowStyle />
}

export function IconCheck() {
  return <CheckStyle />
}

export function IconWarning() {
  return <WarningStyle />
}
