import styled from 'styled-components'

export const PortfolioEmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const PortfolioEmptyImg = styled.img`
  width: 270px;
  height: 160px;
  margin-bottom: 20px;
`

export const PortfolioBoldText = styled.div`
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  font-style: normal;
  line-height: 24px;
  color: #000000;
  margin-bottom: 10px;
`

export const PortfolioSmallText = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #999999;
  width: 340px;
  margin-bottom: 20px;
`
