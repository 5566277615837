import { createAction } from '@reduxjs/toolkit'

export const GOVERNANCE_REWARD = 'GOVERNANCE_REWARD'
export const DEVELOPMENT = 'DEVELOPMENT'
export const INSURANCE = 'INSURANCE'
export const CHARITY = 'CHARITY'
export const BUY_AND_BURN = 'BUY_AND_BURN'

export type Param =
  | typeof GOVERNANCE_REWARD
  | typeof BUY_AND_BURN
  | typeof CHARITY
  | typeof DEVELOPMENT
  | typeof INSURANCE
export const paramList: Param[] = [GOVERNANCE_REWARD, DEVELOPMENT, INSURANCE, CHARITY, BUY_AND_BURN]

export interface FeeParamState {
  amount: string
  current: number
  userValue: number
  newValue: number
  voted: boolean
  votedValue: number
}

export type FeeParams = Record<Param, number>

export const TRADER_PROFIT_FEE = 'TRADER_PROFIT_FEE'
export const POOL_UTILIZATION_ALLOWANCE = 'POOL_UTILIZATION_ALLOWANCE'
export const BASE_BORROW_RATE = 'BASE_BORROW_RATE'
export const OPTIMAL_SLOPE = 'OPTIMAL_SLOPE'
export const EXCESS_SLOPE = 'EXCESS_SLOPE'
export const OPTIMAL_UTILIZATION = 'OPTIMAL_UTILIZATION'
export const LIQUIDATION_MARGIN = 'LIQUIDATION_MARGIN'
export const LIQUIDATION_REWARD = 'LIQUIDATION_REWARD'
export const MAX_LEVERAGE_FACTOR = 'MAX_LEVERAGE_FACTOR'
export const MAX_RATE_MULTIPLIER = 'MAX_RATE_MULTIPLIER'
export const MIN_WOW_BALANCE_X4 = 'MIN_WOW_BALANCE_X4'
export const MIN_WOW_BALANCE_X5 = 'MIN_WOW_BALANCE_X5'
export const TREASURE_FACTOR = 'TREASURE_FACTOR'
export const MAX_LIQUIDATION_REWARD = 'MAX_LIQUIDATION_REWARD'
export const MAX_PRICE_THRESHOLD = 'MAX_PRICE_THRESHOLD'

export type SingleParamType =
  | typeof TRADER_PROFIT_FEE
  | typeof TREASURE_FACTOR
  | typeof POOL_UTILIZATION_ALLOWANCE
  | typeof BASE_BORROW_RATE
  | typeof OPTIMAL_SLOPE
  | typeof EXCESS_SLOPE
  | typeof OPTIMAL_UTILIZATION
  | typeof LIQUIDATION_MARGIN
  | typeof LIQUIDATION_REWARD
  | typeof MAX_LEVERAGE_FACTOR
  | typeof MAX_RATE_MULTIPLIER
  | typeof MIN_WOW_BALANCE_X4
  | typeof MIN_WOW_BALANCE_X5
  | typeof TREASURE_FACTOR
  | typeof MAX_LIQUIDATION_REWARD
  | typeof MAX_PRICE_THRESHOLD

export interface SingleParamState {
  amount: string
  current: number
  userValue: number
  newValue: number
  min: number
  max: number
  voted: boolean
  votedValue: number
}

export type EconomicState = {
  account?: string
  loaded: boolean
  [GOVERNANCE_REWARD]: FeeParamState
  [BUY_AND_BURN]: FeeParamState
  [CHARITY]: FeeParamState
  [DEVELOPMENT]: FeeParamState
  [INSURANCE]: FeeParamState
  [TRADER_PROFIT_FEE]: SingleParamState
  [POOL_UTILIZATION_ALLOWANCE]: SingleParamState
  [BASE_BORROW_RATE]: SingleParamState
  [OPTIMAL_SLOPE]: SingleParamState
  [EXCESS_SLOPE]: SingleParamState
  [OPTIMAL_UTILIZATION]: SingleParamState
  [LIQUIDATION_MARGIN]: SingleParamState
  [LIQUIDATION_REWARD]: SingleParamState
  [MAX_LEVERAGE_FACTOR]: SingleParamState
  [MAX_RATE_MULTIPLIER]: SingleParamState
  [MIN_WOW_BALANCE_X4]: SingleParamState
  [MIN_WOW_BALANCE_X5]: SingleParamState
  [TREASURE_FACTOR]: SingleParamState
  [MAX_LIQUIDATION_REWARD]: SingleParamState
  [MAX_PRICE_THRESHOLD]: SingleParamState
}

export const setInited = createAction('economic/setInited')
export const setSingleParam = createAction<{ field: SingleParamType; data: Partial<SingleParamState> }>(
  'economic/setSingleParam'
)
export const setInitSingleParam = createAction<{
  field: SingleParamType
  data: Partial<SingleParamState>
  account: string
}>('economic/setInitSingleParam')
