import { ChainId } from '@wowswap-io/wowswap-sdk'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as WOWLogo } from 'assets/svg/logo--mobile.svg'
import { useActiveWeb3React } from 'hooks'

import * as S from './styleds'

const LINKS = {
  [ChainId.MAINNET]: 'https://pancakeswap.finance/swap?outputCurrency=0x4da996c5fe84755c80e108cf96fe705174c5e36a',
  [ChainId.MATIC]: 'https://quickswap.exchange/#/swap',
  [ChainId.ETHEREUM]: 'https://app.sushi.com/swap?outputCurrency=0x3405a1bd46b85c5c029483fbecf2f3e611026e45',
  [ChainId.HECO]: 'https://ht.mdex.com/#/swap?outputCurrency=0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73',
  [ChainId.AVALANCE]: 'https://app.pangolin.exchange/#/swap?outputCurrency=0xa384bc7cdc0a93e686da9e7b8c0807cd040f4e0b',
  [ChainId.IOTEX]: 'https://swap.mimo.exchange/#/swap?outputCurrency=0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454'
}

type Props = { WOWPrice: number | null }

const BuyWOWButton = ({ WOWPrice }: Props) => {
  const { chainId } = useActiveWeb3React()
  const { t } = useTranslation()

  const link = LINKS[chainId as keyof typeof LINKS] || LINKS[ChainId.MAINNET]

  return (
    <S.Button href={link} target="_blank" rel="noopener noreferrer">
      <WOWLogo /> {WOWPrice ? `$${WOWPrice.toFixed(2)}` : t('Buy WOW')}
    </S.Button>
  )
}

export default BuyWOWButton
