import React from 'react'
import styled from 'styled-components'
import { ReactComponent as IconSort } from '../../assets/images/icon--sort.svg'
import QuestionHelper from '../QuestionHelper'
import { OrderBy } from '../PortfolioTable'
const SortTableStyle = styled.div``

const SortIconWrapper = styled.div`
  cursor: pointer;
  margin-left: 10px;
  width: 20px;
  height: 20px;
`

const SortTableLinkStyle = styled.a`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;

  .icon-sort__rotate {
    transform: rotate(180deg);
  }

  .icon-sort__disabled {
    opacity: 0.5;
  }

  & > svg {
    display: block;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    cursor: pointer;
  }
`

export const SortTableText = styled.div`
  font-size: 18px;
  line-height: 20px;
  word-break: normal;
  text-align: left;

  ${({ theme }) => theme.mediaWidth.upToSmall`
font-size: 14px;
line-height: 16px;
`};
`

const SortableSubtitle = styled.span`
  display: block;
  font-size: 14px;
  color: #999999;
`

export interface IOrderApi {
  method: () => void
  orderBy: OrderBy
}

interface SortTableProps {
  title: React.ReactElement | string
  subtitle?: string
  field: string
  sort?: boolean
  help?: string
  order?: IOrderApi
}

export enum Order {
  ASC = 'ASC',
  DESC = 'DESC'
}

export default function SortTable({ title, subtitle, sort, help, order, field }: SortTableProps) {
  return sort ? (
    <SortTableLinkStyle>
      <SortTableText onClick={() => order?.method()}>
        {title}
        <SortableSubtitle>{subtitle}</SortableSubtitle>
      </SortTableText>

      {help && <QuestionHelper text={help} />}

      <SortIconWrapper onClick={() => order?.method()}>
        <IconSort
          className={
            order?.orderBy.order === Order.DESC && field === order?.orderBy.field
              ? 'icon-sort__rotate'
              : field !== order?.orderBy.field
              ? 'icon-sort__disabled'
              : ''
          }
        />
      </SortIconWrapper>
    </SortTableLinkStyle>
  ) : (
    <SortTableStyle>
      <SortTableText>{title}</SortTableText>
    </SortTableStyle>
  )
}
