export const vapidKey = 'BGqV7NSmW2sfwkR1iBFTchuaZ2iXKyQA0OapkNeqsTa6XE9CEEqXt3Hk4P87dKHSZjkFbprLiv2-AfixsZWszDg'

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: 'AIzaSyC8ea_PkjmO6jwMRu8a5df-OvokqWjkkn0',
  authDomain: 'utility-chimera-336108.firebaseapp.com',
  projectId: 'utility-chimera-336108',
  storageBucket: 'utility-chimera-336108.appspot.com',
  messagingSenderId: '401631592694',
  appId: '1:401631592694:web:6d9442f5595095aad92cd7'
}
