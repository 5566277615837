import BigNumber from 'bignumber.js'
import React, { useLayoutEffect, useState } from 'react'
import { TokenAmount } from '@wowswap-io/wowswap-sdk'
import { ReactComponent as Minus } from '../../assets/images/icon--minus.svg'
import { ReactComponent as Plus } from '../../assets/images/icon--plus.svg'
import { ReactComponent as MetamaskIcon } from '../../assets/svg/metamask-logo.svg'
import { TradeToken } from '../../hooks/Tokens.types'
import useAddTokenToMetamask from '../../hooks/useAddTokenToMetamask'
import { PortfolioPosition } from '../../state/portfolio/hooks'
import { calcHealth, calcHIR } from '../../utils/calculations'
import { amount, fromDecimals } from '../../utils/math'
import CurrencyLogo from '../CurrencyLogo'
import {
  AddToMetamask,
  AddToMetamaskMsg,
  CurrencyOne,
  CurrencyTwo,
  Debt,
  Health,
  LiquidationPriceValue,
  MobileValuesWrapper,
  NetValue,
  OpenIcon,
  PortfolioItemStyle,
  PortfolioItemWraper,
  PortfolioItemWraperMobile,
  ProfitValue,
  TokenBody,
  TokenBodyStyled,
  TokenTexts
} from './styleds'

interface PortfolioItemProps {
  tradeble: TradeToken
  lendable: TradeToken
  isOdd: boolean
  position: PortfolioPosition
}

function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

const NUM_DIGITS = 4

export const LongPositionPortfolioItem = ({ position, isOdd, tradeble, lendable }: PortfolioItemProps) => {
  const [openItem, setOpenItem] = useState(false)
  const { addToken } = useAddTokenToMetamask(tradeble as any, 'long')
  const [width] = useWindowSize()

  const tradebleAmount = new TokenAmount(tradeble, position.amount?.toString())
  const currentCost = new TokenAmount(lendable, position.currentCost?.toString())
  const currentDebt = new TokenAmount(lendable, position.currentDebt?.toString())
  const selfValue = new TokenAmount(lendable, position.selfValue?.toString())
  // const liquidationCost = new TokenAmount(lendable, position.liquidationCost?.toString())
  const profitValue = new BigNumber(currentCost.toSignificant(NUM_DIGITS))
    .minus(currentDebt.toSignificant(NUM_DIGITS))
    .minus(selfValue.toSignificant(NUM_DIGITS))
    .valueOf()

  const lp = fromDecimals(position.liquidationCost.toString(), lendable.decimals).div(
    fromDecimals(position.amount.toString(), tradeble.decimals)
  )
  const liquidationPrice = new TokenAmount(lendable, amount(lp, lendable.decimals).str()).toSignificant(NUM_DIGITS)

  const HIR = calcHIR(position.rate?.toString(), 60 * 60)
  const health = calcHealth(position.currentCost?.toString(), position.liquidationCost?.toString()).toFixed(2)
  const grossValue = new TokenAmount(
    lendable,
    position.currentCost.gte(position.currentDebt) ? position.currentCost.sub(position.currentDebt).toString() : '0'
  )

  const health_color = Number(health) < 3 ? 'down' : Number(health) < 30 ? 'middle' : 'up'

  const handleAddToMetamask = () => {
    addToken()
  }

  return (
    <PortfolioItemStyle bgColor={isOdd ? '#ffffff' : ''}>
      <PortfolioItemWraper>
        <TokenBodyStyled>
          <TokenBody>
            <CurrencyLogo currency={tradeble} />

            <TokenTexts>
              <CurrencyOne>
                {tradebleAmount.toSignificant(NUM_DIGITS)} {tradeble.info.symbol}
              </CurrencyOne>
              <CurrencyTwo>
                {currentCost.toSignificant(NUM_DIGITS)} {lendable.info.symbol}
              </CurrencyTwo>
            </TokenTexts>
          </TokenBody>

          <AddToMetamask>
            <AddToMetamaskMsg>Add&nbsp;to&nbsp;Metamask</AddToMetamaskMsg>
            <MetamaskIcon onClick={handleAddToMetamask} />
          </AddToMetamask>
        </TokenBodyStyled>

        {width > 1280 && (
          <React.Fragment>
            <Debt>
              <CurrencyOne>
                {currentDebt.toSignificant(NUM_DIGITS)} {lendable.info.symbol}
              </CurrencyOne>
              <CurrencyTwo>{HIR} % HIR</CurrencyTwo>
            </Debt>
            <NetValue>
              <CurrencyOne>
                {grossValue.toSignificant(NUM_DIGITS)} {lendable.info.symbol}
              </CurrencyOne>
              <CurrencyTwo>
                {selfValue.toSignificant(NUM_DIGITS)} {lendable.info.symbol}
              </CurrencyTwo>
            </NetValue>
            <ProfitValue>
              {profitValue} {lendable.info.symbol}
            </ProfitValue>
            <LiquidationPriceValue>
              {liquidationPrice} {lendable.info.symbol}
            </LiquidationPriceValue>
          </React.Fragment>
        )}

        <Health color={health_color}>{health} %</Health>
        {width <= 1280 && (
          <OpenIcon onClick={() => setOpenItem(!openItem)}>
            {openItem ? <Minus id="iconMinus" /> : <Plus id="iconPlus" />}
          </OpenIcon>
        )}
      </PortfolioItemWraper>

      {width <= 1280 && openItem && (
        <PortfolioItemWraperMobile>
          <Debt>
            <CurrencyOne>Debt</CurrencyOne>

            <MobileValuesWrapper>
              <CurrencyOne>
                {currentDebt.toSignificant(NUM_DIGITS)} {lendable.info.symbol}
              </CurrencyOne>
              <CurrencyTwo>{HIR} % HIR</CurrencyTwo>
            </MobileValuesWrapper>
          </Debt>

          <NetValue>
            <CurrencyOne>Net Value</CurrencyOne>

            <MobileValuesWrapper>
              <CurrencyOne>
                {grossValue.toSignificant(NUM_DIGITS)} {lendable.info.symbol}
              </CurrencyOne>
              <CurrencyTwo>
                {selfValue.toSignificant(NUM_DIGITS)} {lendable.info.symbol}
              </CurrencyTwo>
            </MobileValuesWrapper>
          </NetValue>

          <ProfitValue>
            <CurrencyOne>Profit</CurrencyOne>
            <MobileValuesWrapper>
              <CurrencyOne>
                {profitValue} {lendable.info.symbol}
              </CurrencyOne>
            </MobileValuesWrapper>
          </ProfitValue>

          <LiquidationPriceValue>
            <CurrencyOne>Liquidation Price</CurrencyOne>
            <MobileValuesWrapper>
              <CurrencyOne>
                {liquidationPrice} {lendable.info.symbol}
              </CurrencyOne>
            </MobileValuesWrapper>
          </LiquidationPriceValue>
        </PortfolioItemWraperMobile>
      )}
    </PortfolioItemStyle>
  )
}
