import { ChainId, Currency, CurrencyAmount, Token, TokenAmount, WETH } from '@wowswap-io/wowswap-sdk'
import { WrappedTokenInfo } from '../state/lists/hooks'
import { getDefaultToken } from '../state/wallet/hooks'

export function wrappedCurrency(
  currency: Currency | undefined,
  chainId: ChainId | undefined
): Token | WrappedTokenInfo | undefined {
  return chainId && currency === Currency.getBaseCurrency()
    ? getDefaultToken(chainId)
    : currency instanceof WrappedTokenInfo
    ? currency
    : currency instanceof Token
    ? currency
    : undefined
}

export function wrappedCurrencyAmount(
  currencyAmount: CurrencyAmount | undefined,
  chainId: ChainId | undefined
): TokenAmount | undefined {
  const token = currencyAmount && chainId ? wrappedCurrency(currencyAmount.currency, chainId) : undefined
  return token && currencyAmount ? new TokenAmount(token, currencyAmount.raw) : undefined
}

export function unwrappedToken(token: Token): Currency {
  if (token.equals(WETH[token.chainId])) return Currency.getBaseCurrency()
  return token
}
