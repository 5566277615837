import { currencyEquals, Trade } from '@wowswap-io/wowswap-sdk'
import React, { useCallback, useMemo } from 'react'
import { useTradeState } from '../../state/trade/hooks'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent
} from '../TransactionConfirmationModal'
import { SwapModalFooter } from './SwapModalFooter'
import { SwapModalHeader } from './SwapModalHeader/index'

import { Position } from '../../state/trade/types'
import { TradeToken } from '../../hooks/Tokens.types'

/**
 * Returns true if the trade requires a confirmation of details before we can submit it
 * @param tradeA trade A
 * @param tradeB trade B
 */
function tradeMeaningfullyDiffers(tradeA: Trade, tradeB: Trade): boolean {
  return (
    tradeA.tradeType !== tradeB.tradeType ||
    !currencyEquals(tradeA.inputAmount.currency, tradeB.inputAmount.currency) ||
    !tradeA.inputAmount.equalTo(tradeB.inputAmount) ||
    !currencyEquals(tradeA.outputAmount.currency, tradeB.outputAmount.currency) ||
    !tradeA.outputAmount.equalTo(tradeB.outputAmount)
  )
}

export default function ConfirmSwapModal({
  position,
  typedValue,
  originalTrade,
  isOpenPosition,
  inputToken,
  outputToken,
  onAcceptChanges,
  allowedSlippage,
  onConfirm,
  onDismiss,
  recipient,
  swapErrorMessage,
  isOpen,
  attemptingTxn,
  txHash
}: {
  isOpen: boolean
  position: Position
  typedValue: string
  isOpenPosition: boolean
  inputToken: TradeToken | undefined
  outputToken: TradeToken | undefined
  originalTrade: Trade | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  recipient: string | null
  allowedSlippage: number
  onAcceptChanges: () => void
  onConfirm: () => void
  swapErrorMessage: string | undefined
  onDismiss: () => void
}) {
  const { trade } = position
  const showAcceptChanges = useMemo(
    () => Boolean(trade && originalTrade && tradeMeaningfullyDiffers(trade, originalTrade)),
    [originalTrade, trade]
  )

  const modalHeader = useCallback(() => {
    return trade ? (
      <SwapModalHeader
        position={position}
        typedValue={typedValue}
        inputToken={inputToken}
        outputToken={outputToken}
        isOpenPosition={isOpenPosition}
        allowedSlippage={allowedSlippage}
        recipient={recipient}
        showAcceptChanges={showAcceptChanges}
        onAcceptChanges={onAcceptChanges}
      />
    ) : null
  }, [
    allowedSlippage,
    onAcceptChanges,
    recipient,
    showAcceptChanges,
    position,
    trade,
    typedValue,
    inputToken,
    outputToken,
    isOpenPosition
  ])

  const modalBottom = useCallback(() => {
    return trade ? (
      <SwapModalFooter
        trade={trade}
        onConfirm={onConfirm}
        position={position}
        disabledConfirm={showAcceptChanges}
        swapErrorMessage={swapErrorMessage}
        allowedSlippage={allowedSlippage}
        isOpenPosition={isOpenPosition}
      />
    ) : null
  }, [allowedSlippage, onConfirm, showAcceptChanges, swapErrorMessage, position, trade, isOpenPosition])

  const state = useTradeState()

  // text to show while loading
  const amountOut = isOpenPosition ? position.trade?.outputAmount : position.profit
  let pendingText = ''

  if (state.isShortTrade) {
    if (isOpenPosition) {
      pendingText = `Shorting ${position.trade?.outputAmount.toSignificant(6)!} ${trade?.outputAmount?.currency.symbol}`
    } else {
      pendingText = `Swapping ${position.profit?.toSignificant(6)!} ${trade?.outputAmount?.currency.symbol}`
    }
  } else {
    pendingText = `Swapping ${typedValue} ${trade?.inputAmount?.currency?.symbol} for ${
      amountOut ? amountOut.toSignificant(6) : 0
    } ${trade?.outputAmount?.currency?.symbol}`
  }

  const confirmationContent = useCallback(
    () =>
      swapErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={swapErrorMessage} />
      ) : (
        <ConfirmationModalContent
          title="Confirm Swap"
          onDismiss={onDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [onDismiss, modalBottom, modalHeader, swapErrorMessage]
  )

  const currencyToAdd = (isOpenPosition && outputToken) || undefined

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
      currencyToAdd={currencyToAdd}
    />
  )
}
