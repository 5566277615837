import React from 'react'
import { TradeToken } from '../../../hooks/Tokens.types'
import { useTradeState } from '../../../state/trade/hooks'
import { Position } from '../../../state/trade/types'
import LongTradeSwapModalHeader from './LongTradeSwapModalHeader'
import ShortTradeSwapModalHeader from './ShortTradeSwapModalHeader'

interface SwapModalHeader {
  position: Position
  typedValue: string
  inputToken?: TradeToken
  outputToken?: TradeToken
  isOpenPosition: boolean
  allowedSlippage: number
  recipient: string | null
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}

export const SwapModalHeader: React.FC<SwapModalHeader> = props => {
  const tradeState = useTradeState()

  return tradeState.isShortTrade ? <ShortTradeSwapModalHeader {...props} /> : <LongTradeSwapModalHeader {...props} />
}
