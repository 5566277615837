import { createGlobalStyle } from 'styled-components'

export const FixedGlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Retro;
    src: url(/fonts/RetroComputer.ttf);
  }

  // @font-face {
  //   font-family: Abadi;
  //   src: url(./fonts/Abadi-ExtraLight.otf);
  //   font-weight: normal;
  // }

  // @font-face {
  //   font-family: Abadi;
  //   src: url(./fonts/Abadi-Bold.otf);
  //   font-weight: bold;
  // }

  @font-face {
    font-family: 'Abadi MT Std';
    src: url('/fonts/AbadiMTStd.woff2') format('woff2'),
        url('/fonts/AbadiMTStd.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Abadi MT Std';
    src: url('/fonts/AbadiMTStd-ExtraLight.woff2') format('woff2'),
        url('/fonts/AbadiMTStd-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Abadi MT Std';
    src: url('/fonts/AbadiMTStd-Bold.woff2') format('woff2'),
        url('/fonts/AbadiMTStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


  html, input, textarea, button {
    font-family: 'Abadi MT Std', sans-serif;
    letter-spacing: -0.018em;
    font-display: fallback;
  }
  @supports (font-variation-settings: normal) {
    html, input, textarea, button {
      font-family: 'Abadi MT Std', sans-serif;
    }
  }
  


  html,
  body {
    margin: 0;
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }

  button {
    user-select: none;
  }

  html {
    font-family: 'Abadi MT Std', sans-serif;
    font-size: 14px;
    font-variant: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
`

export const ThemedGlobalStyle = createGlobalStyle`
  html {
    color: ${({ theme }) => theme.colors.text1};
    background-image: ${({ theme }) => theme.colors.gradient};
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;

		${({ theme }) => theme.mediaWidth.upToMedium`
      background-position: 0 50px;
    `};

		${({ theme }) => theme.mediaWidth.upToSmall`
      background-position: 0 50px;
    `};
  }

  body {
    min-height: 100vh;
    background-position: 0 -30vh;
    background-repeat: no-repeat;
  }
`
