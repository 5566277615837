import styled from 'styled-components'

import { ReactComponent as Help } from '../../assets/images/help.svg'
import { ReactComponent as HelpMetis } from '../../assets/images/help-metis.svg'

export const QuestionWrapper = styled.div<{ backgroundColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  padding: 0;

  border: none;
  outline: none;
  cursor: pointer;

  ${({ theme, backgroundColor }) => {
      return `
      border-radius: 2px;
      background-color: ${backgroundColor || theme.colors.white};	
    `
    }}
    :hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.primary1};
  }
`
export const QuestionBaseIcon = styled(Help)`
  width: 7px;
  height: 10px;
`

export const QuestionMetisIcon = styled(HelpMetis)`
  width: 15px;
  height: 15px;
`
