import { createAction } from '@reduxjs/toolkit'

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT'
}

export const selectCurrency = createAction<{ field: Field; currency: string }>('trade/selectCurrency')
export const switchCurrencies = createAction<void>('trade/switchCurrencies')
export const typeInput = createAction<{ typedValue: string }>('trade/typeInput')
export const replaceTradeState = createAction<{
  field: Field
  typedValue: string
  inputCurrencyId?: string
  outputCurrencyId?: string
  recipient: string | null
}>('trade/replacetradeState')

export const setClosePositionInfo = createAction<{
  amountOut: string
  debtPayable: string
  protocolFee: string
  profit: string
}>('trade/setClosePositionInfo')

export const setOpenPositionInfo = createAction<{
  borrowAmount: string
  borrowRate: number
  liquidationCost: number
  maxBorrow: string
}>('trade/setOpenPositionInfo')
export const setLeverage = createAction<{ leverage: number }>('trade/setLeverage')
export const setIsShortTrade = createAction<{ isShortTrade: boolean }>('trade/setIsShortTrade')
export const setLeverageAllow = createAction<{ leverageAllow: number }>('trade/setLeverageAllow')
export const setShowError = createAction<{ showError: boolean }>('trade/setShowError')
export const resetSwapState = createAction('trade/resetSwapState')
export const setMaxPositionAmount = createAction<{ isMaxPosition: boolean }>('trade/setMaxPositionAmount')
