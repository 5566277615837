import { Trade } from '@wowswap-io/wowswap-sdk'
import React, { useContext, useMemo, useState } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { Position } from '../../../state/trade/types'
import { computeTradePriceBreakdown, formatExecutionPrice, warningSeverity } from '../../../utils/prices'
import { ButtonError } from '../../Button'
import { AutoColumn } from '../../Column'
import { AutoRow, RowBetween } from '../../Row'
import { ShortTradeDetails } from '../../trade/tradeDetails/ShortTradeDetails'
import { StyledBalanceMaxMini, SwapCallbackError } from '../styleds'
import { IconExchange } from '../../Icons'
import { TextBase } from '../../Text'

export default function ShortTradeSwapModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  swapErrorMessage,
  disabledConfirm,
  isOpenPosition,
  position
}: {
  trade: Trade
  allowedSlippage: number
  onConfirm: () => void
  swapErrorMessage: string | undefined
  disabledConfirm: boolean
  position: Position
  isOpenPosition: boolean
}) {
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const theme = useContext(ThemeContext)
  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const severity = warningSeverity(priceImpactWithoutFee)

  return (
    <>
      <div>
        <RowBetween align="center" marginBottom={'8px'}>
          <TextBase color={theme.colors.text4} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            Price
          </TextBase>

          <TextBase style={{ textAlign: 'right', whiteSpace: 'nowrap', marginLeft: '7px', flexShrink: 0 }}>
            {formatExecutionPrice(trade, showInverted)}

            <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
              <IconExchange />
            </StyledBalanceMaxMini>
          </TextBase>
        </RowBetween>
        <AutoColumn gap="8px">
          <ShortTradeDetails
            trade={trade}
            allowedSlippage={allowedSlippage}
            isOpenPosition={isOpenPosition}
            position={position}
          />
        </AutoColumn>
      </div>

      <AutoRow marginTop={'20px'}>
        <ButtonError onClick={onConfirm} disabled={disabledConfirm} error={severity > 2} id="confirm-swap-or-send">
          <Text fontSize={16} fontWeight={'normal'}>
            {severity > 2 ? 'Swap Anyway' : 'Confirm Swap'}
          </Text>
        </ButtonError>

        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
