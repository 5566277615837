import styled from 'styled-components'

export const EarnHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  flex-direction: column;		
  // align-items: flex-start;
`};
`

export const EarnTitle = styled.div`
  font-family: Retro;
  font-weight: normal;
  font-size: 30px;
  line-height: 42px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  font-size: 20px;
  line-height: 28px;
`};
`

export const EarnHeaderText = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
  margin-bottom: 10px;
`};
`

export const LockedValueContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const LockedValueAmountTitle = styled.span`
  font-size: 20px;
  transform: translateY(15%);
`

export const LockedValueAmount = styled.span`
  margin-left: 48px;
  font-family: Retro;
  font-weight: normal;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.red};
`

export const EarnDesc = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`

export const Wrapper = styled.div``

export const ActionBlock = styled.div`
  padding-bottom: 20px;
`

export const ActionBlockTitle = styled.div`
  margin-bottom: 25px;

  text-align: center;

  & > h3 {
    margin: 0;
    margin-bottom: 7px;

    font-family: Retro;
    font-size: 20px;
    line-height: 28px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
  margin-bottom: 15px;

  text-align: left;
`};
`

export const ActionBlockBody = styled.div`
  display: flex;
  justify-content: spase-between;
  align-items: center;
  max-width: 952px;
  margin: 0 auto;

  & > div {
    flex-grow: 2;
    width: 100%;
  }

  & > div#ActionBlockArrow {
    height: 52px;
    width: 52px;
    flex: 0 0 52px;

    transform: rotate(-90deg);
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
  flex-direction: column;

  & > div#ActionBlockArrow {	
    width: 42px;
    flex: 0 0 42px;

    transform: none;
  }
`};
`

export const ActionBlockBottom = styled.div`
  margin: 0 auto;
  padding-top: 20px;
  max-width: 340px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  padding-top: 17px;
`};
`
