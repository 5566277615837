import React from 'react'
import styled from 'styled-components'
import { ShareSocialDonations } from '../ShareSocial'
import SliderCustom from '../SliderCustom'

const DonationModuleStyle = styled.div`
  width: 100%;
  padding: 20px 20px 24px 20px;

  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		margin-bottom: 10px;
		padding: 10px 15px 15px 15px;

		:last-child {
			margin-bottom: 0;
		}
	`};
`
const DonationModuleHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 9px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		margin-bottom: 4px;
	`};
`

const DonationModuleLogo = styled.div`
  min-width: 180px;
  margin-bottom: 15px;
`
const DonationModuleImg = styled.img`
  display: block;
  height: 35px;
`
const DonationModuleSocial = styled.div`
  margin-bottom: 15px;
`
const DonationModuleData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 28px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
			flex-direction: column;
			margin-bottom: 16px;
		}
	`};
`
const DonationModuleDataValue = styled.div`
  flex: 1 1 auto;
  margin-right: 20px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
			margin-bottom: 7px;
			margin-right: 0;
		}
	`};

  &:first-child {
    width: 100%;
    max-width: 49px;

    ${({ theme }) => theme.mediaWidth.upToLarge`
			max-width: 100%;
		`};
  }
  &:last-child {
    width: 100%;
    max-width: 175px;
    margin-right: 0;
    margin-bottom: 0;

    ${({ theme }) => theme.mediaWidth.upToLarge`
			max-width: 100%;
		`};
  }
  &:last-child > div {
    flex-direction: row;
    align-items: center;
    margin-bottom: 13px;

    ${({ theme }) => theme.mediaWidth.upToLarge`
			margin-bottom: 19px;
		`};
  }
  &:last-child > div > span {
    padding-top: 0;
    margin-left: 5px;
  }
`
const DonationModuleValue = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.text4};

  ${({ theme }) => theme.mediaWidth.upToLarge`
		flex-direction: row;
		justify-content: space-between;
	`};
`
const DonationModuleCurent = styled.span`
  display: block;
  padding-top: 5px;

  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.text1};

  ${({ theme }) => theme.mediaWidth.upToLarge`
		padding-top: 0;
	`};
`
interface DonationModuleProps {
  children: React.ReactNode
  logo: string
  current: string
  currentNew: string
  currentSlider: string
}

export default function DonationModule({ children, logo, current, currentNew, currentSlider }: DonationModuleProps) {
  return (
    <DonationModuleStyle>
      <DonationModuleHeader>
        <DonationModuleLogo>
          <DonationModuleImg src={logo} alt="logo" />
        </DonationModuleLogo>

        <DonationModuleSocial>
          <ShareSocialDonations />
        </DonationModuleSocial>
      </DonationModuleHeader>

      <DonationModuleData>
        <DonationModuleDataValue>
          <DonationModuleValue>
            Current Value
            <DonationModuleCurent>{current}%</DonationModuleCurent>
          </DonationModuleValue>
        </DonationModuleDataValue>

        <DonationModuleDataValue>
          <DonationModuleValue>
            New Value (applied in 24h)
            <DonationModuleCurent>{currentNew}%</DonationModuleCurent>
          </DonationModuleValue>
        </DonationModuleDataValue>

        <DonationModuleDataValue>
          <DonationModuleValue>
            Your Value
            <DonationModuleCurent>{currentSlider}%</DonationModuleCurent>
          </DonationModuleValue>

          <SliderCustom from={0} to={100} max={100} value={10} onChange={() => {}} />
        </DonationModuleDataValue>
      </DonationModuleData>

      {children}
    </DonationModuleStyle>
  )
}
