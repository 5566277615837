import { getTokenLogoURL } from './../components/CurrencyLogo/index'
import { useCallback, useState } from 'react'
import { useActiveWeb3React } from '../hooks'

import { TradeToken } from './Tokens.types'

export default function useAddTokenToMetamask(
  token: TradeToken | undefined,
  positionType: 'short' | 'long'
): { addToken: () => void; success: boolean | undefined } {
  const { library } = useActiveWeb3React()

  const [success, setSuccess] = useState<boolean | undefined>()

  const addToken = useCallback(() => {
    if (library && library.provider.isMetaMask && library.provider.request && token) {
      const proxyAddress = token.address
      const tokenSymbol = token.uniqSymbol
      const symbolPrefix = positionType === 'short' ? '-' : '*'

      library.provider
        .request({
          method: 'wallet_watchAsset',
          params: {
            //@ts-ignore // need this for incorrect ethers provider type
            type: 'ERC20',
            options: {
              address: proxyAddress,
              symbol: symbolPrefix + token.info.symbol.substr(0, 5),
              decimals: token.info.decimals,
              image: getTokenLogoURL(tokenSymbol!)
            }
          }
        })
        .then(success => {
          setSuccess(success)
        })
        .catch(() => setSuccess(false))
    } else {
      setSuccess(false)
    }
  }, [library, token, positionType])

  return { addToken, success }
}
