import React from 'react'
import styled from 'styled-components'
import IconChecked from '../../assets/images/checkbox--checked.svg'
import IconHover from '../../assets/images/checkbox--hover.svg'

const CheckboxStyle = styled.div`
  display: flex;
  align-items: center;

  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
`

const CheckboxLabel = styled.label`
  position: relative;

  display: flex;
  width: 29px;
  height: 29px;
  margin-right: 11px;

  text-align: left;

  cursor: pointer;
`

const CheckboxInput = styled.input`
	display: none;

	+ span {
		position: absolute;
		top: 0;
		right: 0;

		box-sizing: border-box;
		display: block;
		width: 29px;
		height: 29px;

		background: none;		
		border-radius: 5px;
		border: 3px solid #000000;

		::before {
			position: absolute;
			top: 50%;
			left: 50%;
		
			display: block;
			width: 16px;
			height: 11px;
		
			content: none;
			background-size: cover;
			background-repeat: no-repeat;
			transform: translate(-50%, -50%);
		
			transition: all 0.3s;
		}

		:hover {
			:before {
				content: "";

				background-image: url('${IconHover}');
			}
		}
	}

	:checked {
		+ span {
			background-color: #F3BA2F;

			:before {
				content: "";

				background-image: url('${IconChecked}');
			}
		}
	}
`

const CheckboxSpan = styled.span``

const CheckboxText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		font-size: 14px;
		line-height: 14px;
	`};
`

interface LiquidationItemProps {
  checked?: boolean
  text?: string
}

export function Checkbox({ checked, text }: LiquidationItemProps) {
  return (
    <CheckboxStyle>
      <CheckboxLabel>
        <CheckboxInput checked={checked} type="checkbox" />

        <CheckboxSpan></CheckboxSpan>
      </CheckboxLabel>

      {text && <CheckboxText>{text}</CheckboxText>}
    </CheckboxStyle>
  )
}
