import styled from 'styled-components'
import { ReactComponent as CoinImg } from '../../assets/images/coin.svg'

export const Desc = styled.div`
  position: relative;

  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  padding: 0 12px 16px 12px;

  font-weight: 200;
  font-size: 26px;
  line-height: 34px;
  text-align: center;
  color: #000000;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  padding: 9px 12px 16px 12px;
  
  font-size: 18px;
  line-height: 23px;
`};
`

export const CoinRight = styled(CoinImg)`
  position: absolute;
  top: -52px;
  right: -114px;

  display: block;
  width: 98px;
  height: 97px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  display: none;
`};
`

export const CoinLeft = styled(CoinRight)`
  top: 0;
  left: -70px;
  right: auto;

  width: 76px;
  height: 74px;

  transform: scale(-1, 1);

  ${({ theme }) => theme.mediaWidth.upToLarge`
  display: none;
`};
`
export const YourWalletContainer = styled.div`
  width: 100%;
  max-width: 948px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 27px;
  padding-right: 27px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  padding-left: 12px;
  padding-right: 12px;
`};
`
export const YourWalletRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -27px;
  margin-right: -27px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  margin-left: -12px;
  margin-right: -12px;
`};
`

export const YourWalletCol6 = styled.div`
  width: 50%;
  max-width: 100%;
  flex: 0 0 auto;
  padding-left: 27px;
  padding-right: 27px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
`};
`
export const YourWalletItem = styled.div`
  margin-bottom: 20px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  margin-bottom: 10px;
`};
`
