import { useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'

import { ChainId } from '@wowswap-io/wowswap-sdk'

import AppMonitoringService from 'services/monitoring/monitoring.service'

const AppMonitoringMetadataProvider = () => {
  const { chainId = ChainId.ETHEREUM } = useWeb3React()

  useEffect(() => {
    AppMonitoringService.setTag('chainId', `${chainId}`)
  }, [chainId])

  return null
}

export default AppMonitoringMetadataProvider
