import { createReducer } from '@reduxjs/toolkit'
import {
  EconomicState,
  setInited,
  setInitSingleParam,
  setSingleParam,
  TRADER_PROFIT_FEE,
  BASE_BORROW_RATE,
  EXCESS_SLOPE,
  LIQUIDATION_MARGIN,
  LIQUIDATION_REWARD,
  MAX_LEVERAGE_FACTOR,
  MAX_LIQUIDATION_REWARD,
  MAX_PRICE_THRESHOLD,
  MAX_RATE_MULTIPLIER,
  MIN_WOW_BALANCE_X4,
  MIN_WOW_BALANCE_X5,
  OPTIMAL_SLOPE,
  OPTIMAL_UTILIZATION,
  POOL_UTILIZATION_ALLOWANCE,
  TREASURE_FACTOR,
  GOVERNANCE_REWARD,
  BUY_AND_BURN,
  CHARITY,
  DEVELOPMENT,
  INSURANCE
} from './actions'

const initialState: EconomicState = {
  account: undefined,
  loaded: false,
  [GOVERNANCE_REWARD]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    voted: false,
    votedValue: 0
  },
  [BUY_AND_BURN]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    voted: false,
    votedValue: 0
  },
  [CHARITY]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    voted: false,
    votedValue: 0
  },
  [DEVELOPMENT]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    voted: false,
    votedValue: 0
  },
  [INSURANCE]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    voted: false,
    votedValue: 0
  },
  [TRADER_PROFIT_FEE]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    min: 0,
    max: 0,
    voted: false,
    votedValue: 0
  },
  [TREASURE_FACTOR]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    min: 0,
    max: 0,
    voted: false,
    votedValue: 0
  },
  [BASE_BORROW_RATE]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    min: 0,
    max: 0,
    voted: false,
    votedValue: 0
  },
  [OPTIMAL_SLOPE]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    min: 0,
    max: 0,
    voted: false,
    votedValue: 0
  },
  [EXCESS_SLOPE]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    min: 0,
    max: 0,
    voted: false,
    votedValue: 0
  },
  [LIQUIDATION_MARGIN]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    min: 0,
    max: 0,
    voted: false,
    votedValue: 0
  },
  [LIQUIDATION_REWARD]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    min: 0,
    max: 0,
    voted: false,
    votedValue: 0
  },
  [MAX_LEVERAGE_FACTOR]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    min: 0,
    max: 0,
    voted: false,
    votedValue: 0
  },
  [MAX_LIQUIDATION_REWARD]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    min: 0,
    max: 0,
    voted: false,
    votedValue: 0
  },
  [MAX_PRICE_THRESHOLD]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    min: 0,
    max: 0,
    voted: false,
    votedValue: 0
  },
  [MAX_RATE_MULTIPLIER]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    min: 0,
    max: 0,
    voted: false,
    votedValue: 0
  },
  [MIN_WOW_BALANCE_X4]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    min: 0,
    max: 0,
    voted: false,
    votedValue: 0
  },
  [MIN_WOW_BALANCE_X5]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    min: 0,
    max: 0,
    voted: false,
    votedValue: 0
  },
  [OPTIMAL_UTILIZATION]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    min: 0,
    max: 0,
    voted: false,
    votedValue: 0
  },
  [POOL_UTILIZATION_ALLOWANCE]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    min: 0,
    max: 0,
    voted: false,
    votedValue: 0
  },
  [TREASURE_FACTOR]: {
    amount: '0',
    current: 0,
    newValue: 0,
    userValue: 0,
    min: 0,
    max: 0,
    voted: false,
    votedValue: 0
  }
}

export default createReducer<EconomicState>(initialState, builder =>
  builder
    .addCase(setInited, state => {
      return {
        ...state,
        loaded: true
      }
    })
    .addCase(setSingleParam, (state, { payload: { data, field } }) => {
      return {
        ...state,
        [field]: {
          ...state[field],
          ...data
        }
      }
    })
    .addCase(setInitSingleParam, (state, { payload: { data, field, account } }) => {
      const isLoadingNewAccount = account === state.account ? !state.loaded : true
      if (!isLoadingNewAccount) {
        delete data.userValue
      }

      return {
        ...(account === state.account ? state : initialState),
        loaded: !isLoadingNewAccount,
        account: account,
        [field]: {
          ...state[field],
          ...data
        }
      }
    })
)
