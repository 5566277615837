import { Currency } from '@wowswap-io/wowswap-sdk'
import React from 'react'

import { TradeToken } from 'hooks/Tokens.types'

import * as S from './styleds'

export const getTokenLogoURL = (symbol: string) =>
  `https://raw.githubusercontent.com/wowswap-io/assets/master/${symbol}.png`

export default function CurrencyLogo({
  currency,
  size = '24px',
  style
}: {
  currency?: Currency | TradeToken
  size?: string
  style?: React.CSSProperties
}) {
  let tokenSymbol: string | null = null
  let subTokenSymbol: string | null = null

  if (currency instanceof Currency) {
    if (currency.symbol) tokenSymbol = currency.symbol
  }
  if (currency instanceof TradeToken) {
    if (currency.symbol) tokenSymbol = currency.symbol
    if ('pairName' in currency.info && currency.info.pairName) {
      tokenSymbol = currency.info.pairName.split('_')[0]
      subTokenSymbol = currency.info.pairName.split('_')[1]
    }
  }

  if (!tokenSymbol) return null

  return (
    <S.Root
      style={style}
      size={size}
      isSingle={!subTokenSymbol}
      data-tokens-symbols={[tokenSymbol, subTokenSymbol].join(' - ')}
    >
      <S.MainLogo srcs={[getTokenLogoURL(tokenSymbol)]} alt={`${tokenSymbol} logo`} />
      {subTokenSymbol && <S.SubLogo srcs={[getTokenLogoURL(subTokenSymbol)]} alt={`${subTokenSymbol} logo`} />}
    </S.Root>
  )
}
