import React, { useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { ButtonLight } from '../Button'

const LiquidationItemStyle = styled.div<{ bgColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 6px 6px 6px 21px;

  font-size: 16px;
  line-height: 16px;

  background: ${({ bgColor }) => bgColor || 'none'};
  border-radius: 10px;

  & > div:nth-child(1) {
    width: 165px;
    flex: 0 0 165px;
    margin-right: 25px;
  }
  & > div:nth-child(2) {
    width: 175px;
    flex: 0 0 175px;
    margin-right: 25px;
  }
  & > div:nth-child(3) {
    flex-grow: 2;
    margin-right: 50px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
		padding: 5px 12px;

		font-size: 14px;
		line-height: 14px;

		border-radius: 0;

		& > div:nth-child(1) {
			width: 128px;
			flex: 0 0 128px;			
			margin-right: 0;
		}
		& > div:nth-child(2) {
			width: 65px;
			flex: 0 0 65px;
			margin-right: 20px;
		}
		& > div:nth-child(3) {
			margin-right: 20px;
		}
	`};

  ${({ theme }) => theme.mediaWidth.upToSmall`
		& > div:nth-child(1) {
			width: 70px;
			flex: 0 0 70px;
			margin-right: 20px;
		}
		& > div:nth-child(2) {
			margin-right: 10px;
		}
	`};
`

const LiquidationItemOwner = styled.div``

const LiquidationItemHealth = styled.div<{ color: 'up' | 'down' | 'middle' }>`
  color: ${({ color, theme }) =>
    (color === 'up' && theme.colors.primary5) ||
    (color === 'down' && theme.colors.primary4) ||
    (color === 'middle' && theme.colors.black)};
`

const LiquidationItemMaxReward = styled.div`
  font-weight: 700;
  text-align: right;
`

const LiquidationItemAction = styled.div`
  width: 164px;
  flex: 0 0 164px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		width: 30px;
		flex: 0 0 30px;
	`};
`

const LiquidationItemButton = styled(ButtonLight)`
  width: 100%;
  height: 36px;
  padding: 0 8px;

  font-size: 13px;
  line-height: 18px;

  border-width: 2px;
  border-radius: 5px;

  :disabled {
    border-width: 2px;

    :hover {
      border-width: 2px;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
		height: 30px;
	`};
`

function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

interface healthProps {
  value: string
  action: 'up' | 'down' | 'middle'
}

interface LiquidationItemProps {
  bgColor?: string
  owner: string
  health: healthProps
  reward: string
  disabled?: boolean
}

export function LiquidationItem({ bgColor, owner, health, reward, disabled }: LiquidationItemProps) {
  const [width] = useWindowSize()

  return (
    <LiquidationItemStyle bgColor={bgColor ? bgColor : ''}>
      <LiquidationItemOwner>{owner}</LiquidationItemOwner>

      <LiquidationItemHealth color={health.action}>{health.value}</LiquidationItemHealth>

      <LiquidationItemMaxReward>{reward}</LiquidationItemMaxReward>

      <LiquidationItemAction>
        <LiquidationItemButton disabled={disabled}>{width > 600 ? <>Liquidate</> : <>L</>}</LiquidationItemButton>
      </LiquidationItemAction>
    </LiquidationItemStyle>
  )
}
