import BigNumber from 'bignumber.js'
import React from 'react'
import styled from 'styled-components'
import { TradeToken } from '../../hooks/Tokens.types'
import { useCalcReferenceAPY, ReferenceAPYType } from '../../state/stake/hooks'
import CurrencyLogo from '../CurrencyLogo'

const Wrapper = styled.div<{ bgColor?: string }>`
  padding: 10px;
  display: flex;

  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black};

  border-radius: 10px;
  background: ${({ bgColor }) => bgColor || 'none'};

  & > div:nth-child(1) {
    flex-grow: 2;
  }
  & > div:nth-child(n + 2) {
    margin-right: 10px;
    flex: 0 1 15%;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 12px;
    line-height: 12px;

    & > div {
      flex: 0 0 15%;
    }
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall` 
    display: flex;
    flex-direction: column;

    align-items: flex-start;

    font-size: 14px;
    line-height: 14px;

    margin: 20px;

    background: white;

    & > div {
      margin-bottom: 17px;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }
  `};
`

const Token = styled.div`
  display: flex;
  align-items: center;

  & > img {
    display: block;
    width: 28px;
    height: 28px;
    margin-right: 11px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;

    & > img {
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
  `};
`

const Apy = styled.div`
  text-align: right;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary5};
`

export const ApyRowDataCellStyle = styled.div`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`

export const ApyLabel = styled.div`
  display: none;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: flex;
  `};
`

function ApyRowDataCell({ text, value }: { text: string; value: string }) {
  return (
    <ApyRowDataCellStyle>
      <ApyLabel>{text}</ApyLabel>
      <Apy>{value}</Apy>
    </ApyRowDataCellStyle>
  )
}

export default function StakeItem({
  bgColor,
  token,
  apyRef,
  effectiveWOWFarmingSpeed,
  totalXWOW
}: {
  bgColor?: string
  token: TradeToken
  apyRef?: { amountXwow?: string; value?: string }
  effectiveWOWFarmingSpeed?: string
  totalXWOW: BigNumber
}) {
  const APY = useCalcReferenceAPY({ totalXWOW, effectiveWOWFarmingSpeed, apyRef })

  const SHOW_APY = [
    ReferenceAPYType.apy14,
    ReferenceAPYType.apy183,
    ReferenceAPYType.apy365,
    ReferenceAPYType.apy458,
    ReferenceAPYType.apy730
  ]

  const toFormat = (value: BigNumber) => {
    return new Intl.NumberFormat().format(Number(value.toFixed(0)))
  }

  return (
    <Wrapper className="wrapper" bgColor={bgColor}>
      <Token>
        <CurrencyLogo currency={token} />
        {token.symbol === 'WOW/BNB' ? 'LP' : token.symbol}
      </Token>
      {SHOW_APY &&
        SHOW_APY.map((apy: ReferenceAPYType) => (
          <ApyRowDataCell
            key={apy}
            text={`${APY[apy]?.period} ${APY[apy]?.label}`}
            value={`${toFormat(APY[apy]?.value)} %`}
          />
        ))}
    </Wrapper>
  )
}
