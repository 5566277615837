import styled from 'styled-components'
import { ButtonSecondary } from '../Button'
import { TextBase } from '../Text'

export const PortfolioItemStyle = styled.div<{ bgColor?: string }>`
  background: ${({ bgColor }) => bgColor || 'none'};
  border-radius: 10px;
  overflow: hidden;

  & > div:nth-child(2) {
    ${({ bgColor }) => {
      if (bgColor) {
        return `
          background: #FDF4E1;
        `
      } else {
        return `
        background: #ffffff;
      `
      }
    }}
  }
`

export const PortfolioItemWraper = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 6px 4px 10px;
  min-height: 60px;
  border-radius: 10px;

  div {
    flex: 0 0 14.8%;
    text-align: right;

    &:first-of-type {
      flex: 0 0 16%;
    }

    &:last-of-type {
      flex: 0 0 10%;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
    min-height: 45px;
    padding: 8px 13px;
`};

  ${({ theme }) => theme.mediaWidth.upToMedium`
  border-radius: 0;
`};
`

export const PortfolioItemWraperMobile = styled.div`
  padding: 10px 15px 15px 15px;
  margin: 0 12px 12px 12px;

  border-radius: 10px;
`

export const TokenBodyStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  width: 58%;
  flex: 0 0 57%;
`};
`
export const TokenBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 16px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  margin-right: 13px;
`};
`

export const ValueAtOpening = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
`

export const TokenIcon = styled.div`
  width: 28px;
  height: 28px;

  & > img {
    display: block;
    width: 100%;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
  width: 20px;
  height: 20px;
`};
`

export const TokenTexts = styled.div`
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const CurrencyOne = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  font-size: 14px;
  line-height: 14px;
`};
`

export const CurrencyTwo = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.text4};
  white-space: nowrap;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  font-size: 13px;
  line-height: 13px;
`};
`

export const MetamaskIconWrapper = styled.div`
  width: 30px;
  height: 30px;
  padding: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary1};
    border-radius: 50%;
  }
`

export const AddToMetamask = styled.div`
  position: relative;
  margin-left: auto;
}

& > svg {
  position: relative;
  z-index: 2;

  display: block;
  width: 100%;
  height: auto;

  cursor: pointer;
}

${({ theme }) => theme.mediaWidth.upToLarge`
  width: 15px;
  height: 15px;
`};
`

export const AddToMetamaskMsg = styled(TextBase)`
  position: absolute;
  top: -40px;
  left: 50%;
  z-index: 3;

  display: none;

  padding: 6px 14px;

  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.primary1};
  border-radius: 10px;

  transform: translateX(-50%);
`

export const Debt = styled.div`
  ${({ theme }) => theme.mediaWidth.upToLarge`
  width: 100%;
  flex: 0 0  100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;

  // background-color: ${({ theme }) => theme.colors.white};
`};
`

export const MobileValuesWrapper = styled.div`
  & > div:first-child {
    margin-bottom: 3px;
    order: 1;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  & > div:first-child {
    margin-left: 10px;
    margin-bottom: 0;
    order: 2;
  }
`};
`

export const NetValue = styled.div`
  text-align: right;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  width: 100%;
  flex: 0 0  100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;

  // background-color: ${({ theme }) => theme.colors.white};
`};
`

export const ProfitValue = styled.div`
  text-align: right;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  width: 100%;
  flex: 0 0  100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 9px;

  // background-color: ${({ theme }) => theme.colors.white};
`};
`

export const LiquidationPriceValue = styled.div`
  ${({ theme }) => theme.mediaWidth.upToLarge`
    width: 100%;
    flex: 0 0  100%;
    display: flex;
    justify-content: space-between;
`};
`

export const Health = styled(CurrencyOne)<{ color: 'up' | 'down' | 'middle' }>`
  color: ${({ color, theme }) =>
    (color === 'up' && theme.colors.primary5) ||
    (color === 'down' && theme.colors.primary4) ||
    (color === 'middle' && theme.colors.primary1)};
  text-align: right;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  width: auto;
  flex: auto;
  flex-grow: 2;
  margin-right: 41px;
`};
`

export const HealthCalculator = styled.div`
  width: 27%;
  flex: 0 0 27%;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  width: 100%;
  flex: 0 0  100%;
  margin-top: 26px;
`};
`

export const HealthCalculatorWraper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToLarge`
  margin-top: 5px;
`};
`

export const HealthCalculatorSlider = styled.div`
  padding-right: 40px;
`

export const HealthCalculatorBody = styled.div`
  padding-top: 10px;
  padding-right: 7px;
`

export const HealthCalculatorButton = styled(ButtonSecondary)`
  height: 48px;
  padding: 0 9px;

  font-size: 13px;
  line-height: 13px;

  border: 2px solid ${({ theme }) => theme.colors.black};
  border-radius: 5px;
`

export const OpenIcon = styled.div`
  width: 18px;
  height: 18px;

  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.mediaWidth.upToLarge`
  position: absolute;
  top: 12px;
  right: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
`};
`
