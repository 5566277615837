import React from 'react'
import styled from 'styled-components'
import { useChainTradeWith, useChainLabel } from '../../hooks'

import { ReactComponent as Envelope } from '../../assets/svg/icon--20-envelope.svg'
import { ReactComponent as Telegram } from '../../assets/svg/icon--20-tm.svg'
import { ReactComponent as Twitter } from '../../assets/svg/icon--20-tw.svg'
import { ReactComponent as Discord } from '../../assets/svg/discord.svg'
import { ReactComponent as Socicon } from '../../assets/svg/icon--20-socicon.svg'
import { ReactComponent as CoinGecko } from '../../assets/svg/coingecko.svg'

const TitleBarStyle = styled.div`
  width: 100%;
  position: relative;
  padding: 0 10rem;

  background-color: ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.mediaWidth.upToLarge`
padding: 0 1rem;
`};

  ${({ theme }) => theme.mediaWidth.upToMedium`
display: none;
`};
`

const TitleBarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToLarge`
justify-content: space-between;
`};
`

const TitleBarText = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.buttonText};
  text-transform: uppercase;
  font-size: 14px;
  padding: 8px 0;
  margin: 0 auto;

  text-align: center;

  & > span {
    color: ${({ theme }) => theme.colors.primary1};
  }
`

const TitleBarShare = styled.div`
  position: absolute;
  right: -5px;
  top: 0;

  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToLarge`
right: 0;
`};
`

const TitleBarShareTitle = styled.div`
  padding: 7px 0;
  margin-right: 10px;

  font-weight: bold;
  color: #626262;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
`

const TitleBarShareList = styled.div`
  display: flex;
  align-items: center;
`

const TitleBarShareItem = styled.a`
  display: block;

  transition: all 0.5s ease;

  & > svg {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 10px;

    fill: #626262;

    transition: all 0.5s ease;

    &:hover {
      fill: #f3ba2f;
    }
  }
`

export function TitleBar() {
  const TradeWith = useChainTradeWith()
  const MotherSwap = useChainLabel()

  return (
    <TitleBarStyle>
      <TitleBarContainer>
        <TitleBarText>
          Trade {TradeWith} {MotherSwap} with up to <span>5X leverage!</span>
        </TitleBarText>

        <TitleBarShare>
          <TitleBarShareTitle>Share:</TitleBarShareTitle>

          <TitleBarShareList>
            <TitleBarShareItem href="mailto:hello@wowswap.io">
              <Envelope />
            </TitleBarShareItem>

            <TitleBarShareItem href="https://t.me/wowswap" target="_blank">
              <Telegram />
            </TitleBarShareItem>

            <TitleBarShareItem href="https://twitter.com/Wowswap_io" target="_blank">
              <Twitter />
            </TitleBarShareItem>

            <TitleBarShareItem href="https://discord.gg/H4Z4fVNnFF" target="_blank">
              <Discord />
            </TitleBarShareItem>

            <TitleBarShareItem href="https://wowswap-io.medium.com/" target="_blank">
              <Socicon />
            </TitleBarShareItem>

            <TitleBarShareItem href="https://www.coingecko.com/en/coins/wowswap" target="_blank">
              <CoinGecko />
            </TitleBarShareItem>
          </TitleBarShareList>
        </TitleBarShare>
      </TitleBarContainer>
    </TitleBarStyle>
  )
}
