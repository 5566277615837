import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 420px;
  width: 100%;
  background: ${({ theme }) => theme.colors.bg0};
  border: 4px solid ${({ theme }) => theme.colors.black};
  // #JUNK box-shadow: 0px 6px 10px rgba(0,0,0,0.05);
  border-radius: 20px;
  padding: 39px 36px 36px 36px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
		padding: 16px 16px 26px 16px;
	`};
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}

export const TradeBodyWrapper = styled.div<{ isShortTrade: boolean }>`
  position: relative;
  max-width: 420px;
  width: 100%;
  background: ${({ theme }) => theme.colors.bg0};
  border: 4px solid ${({ theme }) => theme.colors.black};
  // #JUNK box-shadow: 0px 6px 10px rgba(0,0,0,0.05);
  border-radius: 20px;
  padding: 20px 40px 40px;
	
	&:before {
		content: '';
		position: absolute;
    top: -4px;
		${({ isShortTrade }) => (isShortTrade ? `right: 0;` : `left: 0;`)}
		border-top: 4px solid ${({ isShortTrade, theme }) =>
      isShortTrade ? `${theme.colors.primary4}` : `${theme.colors.primary5}`};
		width: 49%;
		height: 20px;
		background: ${({ theme }) => theme.colors.bg0};
	}

  ${({ isShortTrade }) => (isShortTrade ? `border-radius: 20px 0 20px 20px;` : `border-radius: 0 20px 20px 20px;`)}

  ${({ theme }) => theme.mediaWidth.upToSmall`
		padding: 16px 16px 26px 16px;
	`};
`

export const TradeBody = ({ children, isShortTrade }: { children: React.ReactNode; isShortTrade: boolean }) => {
  return <TradeBodyWrapper isShortTrade={isShortTrade}>{children}</TradeBodyWrapper>
}
