import React, { useState, useLayoutEffect } from 'react'
import styled from 'styled-components'

const EarnItemStyle = styled.div<{ bgColor?: string }>`
  padding: 10px 14px 10px 10px;

  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black};
  text-align: right;

  background: ${({ bgColor }) => bgColor || 'none'};
  overflow: hidden;
  border-radius: 10px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
		padding: 10px 15px 15px 15px;

		background-color: ${({ theme }) => theme.colors.white};
	`};
`

const EarnItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div:nth-child(1) {
    flex-grow: 2;

    & > div:nth-child(2) {
      width: 50%;
    }
  }
  & > div:nth-child(2) {
    width: 8.5%;
    flex: 0 0 8.5%;
    display: flex;
    justify-content: flex-end;
  }
  & > div:nth-child(3) {
    width: 18.5%;
    flex: 0 0 18.5%;
    display: flex;
    justify-content: flex-end;
  }
  & > div:nth-child(4) {
    width: 18.5%;
    flex: 0 0 18.5%;
    display: flex;
    justify-content: flex-end;
  }
  & > div:nth-child(5) {
    width: 13.8%;
    flex: 0 0 13.8%;
    display: flex;
    justify-content: flex-end;
  }
  & > div:nth-child(6) {
    width: 14.8%;
    flex: 0 0 14.8%;
    display: flex;
    justify-content: flex-end;
  }
  & > div:nth-child(7) {
    width: 14.5%;
    flex: 0 0 14.5%;
    display: flex;
    justify-content: flex-end;
  }
`

const Token = styled.div`
  display: flex;
  align-items: center;

  & > img {
    display: block;
    width: 28px;
    height: 28px;
    margin-right: 11px;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
		margin-bottom: 10px;
		
		font-size: 18px;
		line-height: 22px;
		font-weight: bold;

		& > img {
			width: 24px;
			height: 24px;
			margin-right: 6px;
		}
	`};
`

const Apy = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary5};
`

const Deposited = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
		display: flex;
		justify-content: space-between;
		margin-top: 10px;

		font-size: 14px;
		line-height: 14px;

		& > div:first-child {
			color: #999999;	
      margin-right: 10px;
		}
	`};
`

const Borrowed = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
		display: flex;
		justify-content: space-between;
		margin-top: 10px;

		font-size: 14px;
		line-height: 14px;

		& > div:first-child {
			color: #999999;	
      margin-right: 10px;
		}
	`};
`

const Utilization = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary4};
`

const Balance = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
		display: flex;
		justify-content: space-between;
		padding-top: 10px;

		font-size: 14px;
		line-height: 14px;

		& > div:first-child {
			color: #999999;	
      margin-right: 10px;
		}
	`};
`

const TotalValue = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
		display: flex;
		justify-content: space-between;
		margin-top: 10px;

		font-size: 14px;
		line-height: 14px;

		& > div:first-child {
			color: #999999;	
      margin-right: 10px;
      white-space: nowrap;
		}
	`};
`

const EarnItemRowMobile = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -3px;
  margin-right: -3px;
`

const CurrencyIcon = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 10px;
  border-radius: 50%;
  background: #f2f2f2;
`

const EarnItemColMobile = styled.div`
  padding: 30px 0 5px 0;
  margin-left: 3px;
  margin-right: 3px;
  flex-grow: 2;

  background-color: #f2f2f2;
  border-radius: 5px;

  & > div {
    margin-top: 3px;
    font-size: 16px;
    line-height: 16px;
  }
`

const EarnItemBottomMob = styled.div`
  margin-top: 8px;

  border-top: 2px solid ${({ theme }) => theme.colors.bg0};
`

const LoaderBlock = styled.div`
  background: #f2f2f2;
  border-radius: 5px;
  width: 90%
  height: 18px;
`

function useWindowSize() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

interface PortfolioItemProps {
  bgColor?: string
}

export default function EarnItemLoader({ bgColor }: PortfolioItemProps) {
  const [width] = useWindowSize()

  return (
    <EarnItemStyle bgColor={bgColor ? bgColor : ''}>
      {width <= 960 ? (
        <>
          <Token>
            <CurrencyIcon />
            <LoaderBlock />
          </Token>

          <EarnItemRowMobile>
            <EarnItemColMobile />

            <EarnItemColMobile />
          </EarnItemRowMobile>

          <Deposited>
            <div>Deposited</div>
            <LoaderBlock />
          </Deposited>

          <Borrowed>
            <div>Borrowed</div>
            <LoaderBlock />
          </Borrowed>

          <EarnItemBottomMob>
            <Balance>
              <div>Balance</div>
              <LoaderBlock />
            </Balance>

            <TotalValue>
              <div>Total Value</div>
              <LoaderBlock />
            </TotalValue>
          </EarnItemBottomMob>
        </>
      ) : (
        <EarnItemRow>
          <Token>
            <CurrencyIcon />
            <LoaderBlock />
          </Token>

          <Apy>
            <LoaderBlock />
          </Apy>

          <Deposited>
            <LoaderBlock />
          </Deposited>

          <Borrowed>
            <LoaderBlock />
          </Borrowed>

          <Utilization>
            <LoaderBlock />
          </Utilization>

          <Balance>
            <LoaderBlock />
          </Balance>

          <TotalValue>
            <LoaderBlock />
          </TotalValue>
        </EarnItemRow>
      )}
    </EarnItemStyle>
  )
}
