import React from 'react'
import styled from 'styled-components'
import {
  GridSidebarWraper,
  GridSidebar,
  SidebarLeft,
  GridSidebarBody,
  GridSidebarTitle,
  GridSidebarBack
} from '../components/GridSidebar'
import MenuSidebar from '../components/MenuSidebar'
import { ModuleWrapperBig, ModuleWrapperSm } from '../components/ModuleWrapper'
import { Information } from '../components/Information'
import { CurrentResults } from '../components/CurrentResults'
import ModuleCollateral from '../components/ModuleCollateral'

const GovernanceProposalsItemWrapper = styled.div`
  width: 100%;
  max-width: 894px;
  padding: 24px 0 0 0;
  margin: 0 auto;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		padding: 12px 12px 0 12px;
	`};
`
const GovernanceProposalsItemRow = styled.div`
  display: flex;
  margin: 0 -10px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		flex-direction: column;
	`};
`
const GovernanceProposalsItemCol = styled.div`
  padding: 0 10px;

  ${({ theme }) => theme.mediaWidth.upToLarge`
		width: 100%;
		flex: 0 0 100%;
		max-width: 100%;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	`};
`
const GovernanceProposalsItemCol1 = styled(GovernanceProposalsItemCol)`
  max-width: 546px;
  flex: 1 1 auto;
`
const GovernanceProposalsItemCol2 = styled(GovernanceProposalsItemCol)`
  max-width: 368px;
  flex: 1 1 auto;
`
const GovernanceProposalsItemItem = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`

export default function GovernanceProposalsItem() {
  return (
    <GridSidebarWraper>
      <GridSidebar>
        <SidebarLeft>
          <MenuSidebar />
        </SidebarLeft>

        <GridSidebarBody>
          <GridSidebarBack desktopShow to={'/governance-menu'} />

          <GridSidebarTitle>Governance Proposals Item</GridSidebarTitle>

          <GovernanceProposalsItemWrapper>
            <GovernanceProposalsItemRow>
              <GovernanceProposalsItemCol1>
                <ModuleWrapperBig>
                  <ModuleCollateral />
                </ModuleWrapperBig>
              </GovernanceProposalsItemCol1>

              <GovernanceProposalsItemCol2>
                <GovernanceProposalsItemItem>
                  <ModuleWrapperSm>
                    <Information title="Information" />
                  </ModuleWrapperSm>
                </GovernanceProposalsItemItem>

                <GovernanceProposalsItemItem>
                  <ModuleWrapperSm>
                    <CurrentResults title="Current results" />
                  </ModuleWrapperSm>
                </GovernanceProposalsItemItem>
              </GovernanceProposalsItemCol2>
            </GovernanceProposalsItemRow>
          </GovernanceProposalsItemWrapper>
        </GridSidebarBody>
      </GridSidebar>
    </GridSidebarWraper>
  )
}
