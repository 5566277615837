import { Currency, CurrencyAmount, Pair, Route, Token, Trade, TradeType } from '@wowswap-io/wowswap-sdk'
import flatMap from 'lodash.flatmap'
import { useMemo } from 'react'

import { BASES_TO_CHECK_TRADES_AGAINST } from '../constants'
import { PairState, usePairs } from '../data/Reserves'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { useBlockNumber } from '../state/application/hooks'

import { useActiveWeb3React } from './index'

function useAllCommonPairs(currencyA?: Currency, currencyB?: Currency): Pair[] {
  const { chainId } = useActiveWeb3React()

  const bases: Token[] = chainId ? BASES_TO_CHECK_TRADES_AGAINST[chainId] : []

  const [tokenA, tokenB] = chainId
    ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
    : [undefined, undefined]

  const basePairs: [Token, Token][] = useMemo(
    () =>
      flatMap(bases, (base): [Token, Token][] => bases.map(otherBase => [base, otherBase])).filter(
        ([t0, t1]) => t0?.address !== t1?.address
      ),
    [bases]
  )

  const allPairCombinations: [Token, Token][] = useMemo(
    () =>
      tokenA && tokenB
        ? [
            // the direct pair
            [tokenA, tokenB],
            // token A against all bases
            ...bases.map((base): [Token, Token] => [tokenA, base]),
            // token B against all bases
            ...bases.map((base): [Token, Token] => [tokenB, base]),
            // each base against all bases
            ...basePairs
          ]
            .filter((tokens): tokens is [Token, Token] => Boolean(tokens[0] && tokens[1]))
            .filter(([t0, t1]) => t0.address !== t1.address)
            .filter(([tokenA, tokenB]) => {
              if (!chainId) return true

              return tokenA.chainId === tokenB.chainId
            })
        : [],
    [tokenA, tokenB, bases, basePairs, chainId]
  )

  const allPairs = usePairs(allPairCombinations)

  // only pass along valid pairs, non-duplicated pairs
  return useMemo(
    () =>
      Object.values(
        allPairs
          // filter out invalid pairs
          .filter((result): result is [PairState.EXISTS, Pair] => Boolean(result[0] === PairState.EXISTS && result[1]))
          // filter out duplicated pairs
          .reduce<{ [pairAddress: string]: Pair }>((memo, [, curr]) => {
            memo[curr.liquidityToken.address] = memo[curr.liquidityToken.address] ?? curr
            return memo
          }, {})
      ),
    [allPairs]
  )
}

export function usePathTradeExactIn(currencyAmountIn: CurrencyAmount | undefined, path: Currency[]): Trade | null {
  const { chainId } = useActiveWeb3React()
  const latestBlockNumber = useBlockNumber()
  const tokens = useMemo(() => path.map(currency => wrappedCurrency(currency, chainId)), [path, chainId])
  const pairs = usePairs(
    tokens.reduce((arr, step, index) => {
      if (index === path.length - 1) return arr

      arr.push([step, tokens[index + 1]])
      return arr
    }, [] as [Token | undefined, Token | undefined][])
  )

  return useMemo(() => {
    if (!currencyAmountIn) {
      return null
    }
    try {
      const route = new Route(
        pairs.map(p => p[1]).filter((p): p is Pair => !!p),
        path[0],
        path[path.length - 1]
      )

      return new Trade(route, currencyAmountIn, TradeType.EXACT_INPUT)
    } catch (e) {
      console.warn({ e, currencyAmountIn, path, pairs, tokens, latestBlockNumber })
      return null
    }
  }, [currencyAmountIn, path, pairs, tokens, latestBlockNumber])
}

/**
 * Returns the best trade for the exact amount of tokens in to the given token out
 */
export function useTradeExactIn(
  currencyAmountIn?: CurrencyAmount,
  currencyOut?: Currency,
  isProxyTrade?: boolean
): Trade | null {
  const allowedPairs = useAllCommonPairs(currencyAmountIn?.currency, currencyOut)
  const maxLength = isProxyTrade ? 3 : 1

  return useMemo(() => {
    if (currencyAmountIn && currencyOut && allowedPairs.length > 0) {
      return (
        Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, {
          maxHops: maxLength,
          maxNumResults: maxLength
        })[0] ?? null
      )
    }
    return null
  }, [allowedPairs, currencyAmountIn, currencyOut, maxLength])
}

/**
 * Returns the best trade for the token in to the exact amount of token out
 */
export function useTradeExactOut(currencyIn?: Currency, currencyAmountOut?: CurrencyAmount): Trade | null {
  const allowedPairs = useAllCommonPairs(currencyIn, currencyAmountOut?.currency)

  return useMemo(() => {
    if (currencyIn && currencyAmountOut && allowedPairs.length > 0) {
      return (
        Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, { maxHops: 1, maxNumResults: 1 })[0] ??
        null
      )
    }
    return null
  }, [allowedPairs, currencyIn, currencyAmountOut])
}
