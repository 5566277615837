import React, { ChangeEvent, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TokenAmount } from '@wowswap-io/wowswap-sdk'
import { ThemeContext } from 'styled-components'

import { useActiveWeb3React } from '../../hooks'
import { TradeToken } from '../../hooks/Tokens.types'

import { Text } from 'rebass'
import Column from '../Column'
import Loader from '../Loader'
import Modal from '../Modal'
import { Input as NumericalInput } from '../NumericalInput'
import { RowBetween, RowFixed } from '../Row'
import { FadedSpan, MenuItem, PaddedColumn } from '../SearchModal/styleds'
import { SearchInput } from '../SearchModal/styleds'
import { CloseIcon } from '../Shared'
import { TextBase, TextBaseLg } from '../Text'
import TranslatedText from '../TranslatedText'
import CurrencyLogo from '../CurrencyLogo'
import { StyledBalanceMax, StyledBalanceMaxDivider } from './styleds'

import {
  Aligner,
  Container,
  DisabledLabel,
  InputPanel,
  InputRow,
  LabelRow,
  MenuItemWraper,
  StyledBalanceText,
  StyledDropDown,
  StyledTokenName,
  TradeTokenSearchRow,
  TradeTokenSearchTitle,
  TradeTokenSelect,
  TokensWrapper
} from './styleds'

interface TradeTokenInputPanelProps {
  value: string
  id: string
  label?: string
  token?: TradeToken
  other?: TradeToken
  variants?: TradeToken[]
  showMaxButton?: true
  disableTradeTokenSelect?: true
  disabled?: true
  onUserInput: (value: string) => void
  onTradeTokenSelect: (token: TradeToken) => void
  onMax?: () => void
}

export default function TradeTokenInputPanel({
  value,
  id,
  label,
  token,
  other,
  variants,
  disableTradeTokenSelect,
  disabled,
  showMaxButton,
  onUserInput,
  onTradeTokenSelect,
  onMax
}: TradeTokenInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useActiveWeb3React()

  const theme = useContext(ThemeContext)

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  let tokenInputLabel = ' -'

  if (token && token.balance) {
    if (token?.shortingInfo) {
      tokenInputLabel = 'Shorted amount: ' + token.balance.toSignificant(6)
    } else {
      tokenInputLabel = 'Balance: ' + token.balance.toSignificant(6)
    }
  }

  return (
    <InputPanel id={id}>
      <Container>
        <LabelRow>
          <RowBetween>
            <TextBaseLg>{label}</TextBaseLg>

            {account && (
              <TextBase onClick={onMax} color={theme.colors.text4} style={{ cursor: 'pointer' }}>
                {tokenInputLabel}
              </TextBase>
            )}
          </RowBetween>
        </LabelRow>
        <InputRow selected={!!disableTradeTokenSelect}>
          {disabled ? (
            <DisabledLabel placeholder="0.0" empty={!value}>
              {value}
            </DisabledLabel>
          ) : (
            <NumericalInput
              className="token-amount-input"
              value={value}
              onUserInput={val => {
                onUserInput(val)
              }}
            />
          )}

          {showMaxButton && (
            <>
              <StyledBalanceMax onClick={onMax}>MAX</StyledBalanceMax>

              <StyledBalanceMaxDivider></StyledBalanceMaxDivider>
            </>
          )}

          <TradeTokenSelect
            selected={!!token}
            className="open-currency-select-button"
            onClick={() => {
              if (!disableTradeTokenSelect) {
                setModalOpen(true)
              }
            }}
          >
            <Aligner>
              {token && <CurrencyLogo currency={token} size={'24px'} />}
              <StyledTokenName className="token-symbol-container" active={!!token}>
                {token?.symbol || 'Select a token'}
              </StyledTokenName>
              {!disableTradeTokenSelect && <StyledDropDown selected={!!token} />}
            </Aligner>
          </TradeTokenSelect>
        </InputRow>
      </Container>
      {!disableTradeTokenSelect && onTradeTokenSelect && (
        <TradeTokenSearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onSelect={onTradeTokenSelect}
          selected={token}
          other={other}
          variants={variants}
        />
      )}
    </InputPanel>
  )
}

interface TradeTokenSearchModalProps {
  isOpen: boolean
  selected?: TradeToken
  other?: TradeToken
  variants?: TradeToken[]
  onDismiss: () => void
  onSelect: (token: TradeToken) => void
}

function TradeTokenSearchModal({ isOpen, onDismiss, onSelect, selected, other, variants }: TradeTokenSearchModalProps) {
  const handleSelect = useCallback(
    (token: TradeToken) => {
      onSelect(token)
      onDismiss()
    },
    [onDismiss, onSelect]
  )

  return (
    <Modal height="660px" isOpen={isOpen} onDismiss={onDismiss} maxHeight={90} minHeight={50} paddingBottom="0">
      <TradeTokenSearch
        onDismiss={onDismiss}
        onSelect={handleSelect}
        selected={selected}
        other={other}
        variants={variants}
      />
    </Modal>
  )
}

interface TradeTokenSearchProps {
  onDismiss: () => void
  onSelect: (token: TradeToken) => void
  selected?: TradeToken
  other?: TradeToken
  variants?: TradeToken[]
}

function TradeTokenSearch({ onDismiss, onSelect, selected, other, variants }: TradeTokenSearchProps) {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState('')
  const searchRegExp = !searchValue ? null : new RegExp(searchValue, 'gi')
  const variantsFiltered =
    !searchValue || !searchRegExp
      ? variants
      : variants?.filter(
          token =>
            token?.symbol?.match(searchRegExp) ||
            token?.name?.match(searchRegExp) ||
            token.address.match(searchRegExp) ||
            token?.tradableAddress?.match(searchRegExp)
        )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(event.target.value)
    },
    [setSearchValue]
  )

  return (
    <Column style={{ width: '100%', flex: '1 1', height: '100%' }}>
      <PaddedColumn gap="18px">
        <TradeTokenSearchRow>
          <TradeTokenSearchTitle fontWeight={400} fontSize={20}>
            <TranslatedText translationId={82}>Select a token</TranslatedText>
          </TradeTokenSearchTitle>
          <CloseIcon onClick={onDismiss} />
        </TradeTokenSearchRow>
      </PaddedColumn>

      {variants && variants?.length > 10 && (
        <SearchInput
          type="text"
          id="token-search-input"
          placeholder={t('Search name')}
          value={searchValue}
          onChange={handleChange}
          style={{ marginBottom: '10px' }}
        />
      )}

      <TokensWrapper>
        {variantsFiltered?.map(token => (
          <CurrencyRow
            key={token.uniqSymbol}
            token={token}
            onSelect={() => onSelect(token)}
            other={other}
            selected={selected}
          />
        ))}
      </TokensWrapper>
    </Column>
  )
}

function CurrencyRow({
  token,
  selected,
  other,
  onSelect
}: {
  token: TradeToken
  selected?: TradeToken
  other?: TradeToken
  onSelect: () => void
}) {
  const { account } = useActiveWeb3React()
  const key = token?.uniqSymbol
  const isSelected = selected && selected.uniqSymbol === token.uniqSymbol
  const isOtherSelected = other && other.uniqSymbol === token.uniqSymbol

  return (
    <MenuItem
      className={`token-item-${key}`}
      onClick={() => (isSelected ? null : onSelect())}
      disabled={isSelected}
      selected={isOtherSelected}
    >
      <MenuItemWraper>
        <CurrencyLogo
          currency={token}
          size={'25px'}
          style={{
            display: 'block',
            marginTop: '5px',
            marginLeft: '6px',
            marginRight: '10px'
          }}
        />
        <Column style={{ flexGrow: 2, marginTop: '2px' }}>
          <Text
            title={token.info.name}
            fontWeight={700}
            style={{
              paddingTop: '0',
              color: '#000000',
              fontFamily: '"Abadi MT Std", sans-serif',
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '100%'
            }}
          >
            {token.info.id}
          </Text>
          <FadedSpan
            style={{
              color: '#999999',
              fontFamily: '"Abadi MT Std", sans-serif',
              fontWeight: 200,
              fontSize: '16px',
              lineHeight: '100%'
            }}
          >
            {token.info.name}
          </FadedSpan>
        </Column>
        <RowFixed
          style={{
            justifySelf: 'flex-end',
            minWidth: '65px'
          }}
        >
          {token.balance ? <Balance balance={token.balance} /> : account ? <Loader /> : null}
        </RowFixed>
      </MenuItemWraper>
    </MenuItem>
  )
}

function Balance({ balance }: { balance: TokenAmount }) {
  return <StyledBalanceText title={balance.toExact()}>{balance.toSignificant(4)}</StyledBalanceText>
}
