import React, { useState } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import IconActive from '../../assets/images/icon--arrow.svg'
import IconYourWallet from '../../assets/images/icon--your-wallet.svg'
// import iconProposals from '../../assets/images/icon--proposals.svg'
import IconFinancialModel from '../../assets/images/icon--financial-model.svg'
import IconEconomicModel from '../../assets/images/icon--economic-model.svg'
// import IconDonations from '../../assets/images/icon--donations.svg'
// import IconStatistics from '../../assets/images/icon--statistics.svg'
// import IconDocumentation from '../../assets/images/icon--documentation.svg'

const MenuSidebarStyle = styled.div``

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 9px;
  width: 248px;
  height: 47px;

  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.mediaWidth.upToMedium`    
    width: 100%;
  `};
`

const MenuSidebarItemStyle = styled.div<{ active?: boolean }>`
  border-left: 7px solid transparent;

  :hover {
    background-color: #f4e49c;
  }

  ${({ active }) => {
    if (active) {
      return `
				background-color: #F4E49C;
				border-left: 7px solid #F3BA2F;
      `
    } else {
      return ``
    }
  }}

  ${({ theme }) => theme.mediaWidth.upToMedium`    
    background-color: ${({ theme }) => theme.colors.bg0};
    border-radius: 10px;
    margin: 5px 12px;
  `};
`

const MenuSidebarItemBody = styled.div`
  display: flex;
  align-items: center;
`

const MenuSidebarItemActive = styled.img`
  display: block;
  width: 35px;
  height: 33px;
`

const MobileMenuItemImg = styled.img`
  display: none;
  width: 20px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: flex;
  `};
`

const ItemIcon = styled.img`
  display: block;
  width: 23px;
  height: 23px;
  margin-right: 10px;
`

interface MenuSidebarItemProps {
  icon: string
  title: string
  link: string
  isActive: boolean
  handleActive: (match: any) => boolean
}

function MenuSidebarItem({ icon, title, link, isActive, handleActive }: MenuSidebarItemProps) {
  return (
    <MenuSidebarItemStyle active={isActive}>
      <StyledNavLink id={title} to={link} isActive={handleActive}>
        <MenuSidebarItemBody>
          <ItemIcon src={icon} alt="" />

          {title}
        </MenuSidebarItemBody>

        {isActive && <MenuSidebarItemActive src={IconActive} alt="" />}
        <MobileMenuItemImg src={IconActive} alt="" />
      </StyledNavLink>
    </MenuSidebarItemStyle>
  )
}

export default function MenuSidebar() {
  const [link, setLink] = useState('')
  const handleActive = (match: any) => {
    if (!match) {
      return false
    }

    setLink(match.url)
    return true
  }

  return (
    <MenuSidebarStyle>
      <MenuSidebarItem
        icon={IconYourWallet}
        title="Your wallet"
        link="/governance-your-wallet"
        isActive={link === '/governance-your-wallet'}
        handleActive={handleActive}
      />
      {/* <MenuSidebarItem icon={iconProposals} title="Proposals" link="/governance-proposals" /> */}
      <MenuSidebarItem
        icon={IconFinancialModel}
        title="Financial model"
        link="/governance-financial-model"
        isActive={link === '/governance-financial-model'}
        handleActive={handleActive}
      />
      <MenuSidebarItem
        icon={IconEconomicModel}
        title="Economic model"
        link="/governance-economic-model"
        isActive={link === '/governance-economic-model'}
        handleActive={handleActive}
      />
      {/* <MenuSidebarItem icon={IconDonations} title="Donations" link="/governance-donations" />
      <MenuSidebarItem icon={IconStatistics} title="Statistics" link="/governance-stats" /> 
      <MenuSidebarItem icon={IconDocumentation} title="Documentation" link="/governance-financial-model"/>*/}
    </MenuSidebarStyle>
  )
}
