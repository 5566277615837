import React, { StrictMode } from 'react'
import { isMobile } from 'react-device-detect'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import { Provider } from 'react-redux'
import { createWeb3ReactRoot, useWeb3React, Web3ReactProvider } from '@web3-react/core'
import 'inter-ui'
import { DefaultTheme, ThemeProvider as StyledThemeProvider } from 'styled-components'

import { ChainId } from '@wowswap-io/wowswap-sdk'

import { NetworkContextName } from './constants'

import 'i18n'
import AppMonitoringService from 'services/monitoring/monitoring.service'

import App from 'pages/App'

import store from 'state'
import ApplicationUpdater from 'state/application/updater'
import ListsUpdater from 'state/lists/updater'
import MulticallUpdater from 'state/multicall/updater'
import TransactionUpdater from 'state/transactions/updater'
import UserUpdater from 'state/user/updater'

import getLibrary from './utils/getLibrary'

import { maticTheme, hecoTheme, ethereumTheme, avalancheTheme, iotexTheme } from 'theme'

import { FixedGlobalStyle, ThemedGlobalStyle } from 'components/Shared'
import TikTokPixel from 'components/analytics/TikTokPixel'
import AppMonitoringMetadataProvider from 'components/monitoring/AppMonitoringMetadataProvider'

AppMonitoringService.init()

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName)

if ('ethereum' in window) {
  ;(window.ethereum as any).autoRefreshOnNetworkChange = false
}

const GOOGLE_ANALYTICS_ID: string | undefined = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
if (typeof GOOGLE_ANALYTICS_ID === 'string') {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID)
  ReactGA.set({
    customBrowserType: !isMobile ? 'desktop' : 'web3' in window || 'ethereum' in window ? 'mobileWeb3' : 'mobileRegular'
  })
} else {
  ReactGA.initialize('test', { testMode: true, debug: true })
}

window.addEventListener('error', error => {
  localStorage && localStorage.removeItem('redux_localstorage_simple_lists')
  ReactGA.exception({
    description: `${error.message} @ ${error.filename}:${error.lineno}:${error.colno}`,
    fatal: true
  })
})

const TIK_TOK_PIXEL_ID: string | undefined = process.env.REACT_APP_TIK_TOK_PIXEL_ID
if (TIK_TOK_PIXEL_ID && typeof TIK_TOK_PIXEL_ID === 'string') {
  TikTokPixel.initialize(TIK_TOK_PIXEL_ID)
}

function Updaters() {
  return (
    <>
      <ListsUpdater />
      <UserUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}

const THEMES: {
  [key: number]: DefaultTheme // TODO: better use ChainId as key
} = {
  [ChainId.MATIC]: maticTheme,
  [ChainId.HECO]: hecoTheme,
  [ChainId.ETHEREUM]: ethereumTheme,
  [ChainId.ROPSTEN]: ethereumTheme,
  [ChainId.AVALANCE]: avalancheTheme,
  [ChainId.FUJI]: avalancheTheme,
  [ChainId.IOTEX]: iotexTheme
}

function ThemeProvider({ children }: { children?: React.ReactNode }) {
  const { chainId = 1 } = useWeb3React()

  const theme = THEMES[chainId] ?? ethereumTheme

  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
}

ReactDOM.render(
  <StrictMode>
    <FixedGlobalStyle />
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Provider store={store}>
          <Updaters />
          <ThemeProvider>
            <ThemedGlobalStyle />
            <AppMonitoringMetadataProvider />
            <App />
          </ThemeProvider>
        </Provider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </StrictMode>,
  document.getElementById('root')
)
