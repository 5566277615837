import { TokenAmount } from '@wowswap-io/wowswap-sdk'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import CurrencyLogo from '../CurrencyLogo'
import { RowBetween, RowFixed } from '../Row'
import styled from 'styled-components'
import { ReactComponent as ArrowDownIcon } from '../../assets/images/arrow-down.svg'
import { TextBaseXl } from '../Text'

import { TradeToken } from '../../hooks/Tokens.types'

const ConfirmSwapCurrencies = styled.div`
  padding: 15px 23px 15px 15px;

  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.white};
`

const ArrowDownIconStyle = styled(ArrowDownIcon)`
  width: 24px;
  height: 24px;
`

export default function SwapModalHeader({
  typedValue,
  inputToken,
  outputToken,
  amountOut
}: {
  typedValue: string
  inputToken?: TradeToken
  outputToken?: TradeToken
  amountOut?: TokenAmount
}) {
  const theme = useContext(ThemeContext)

  return (
    <div>
      <ConfirmSwapCurrencies>
        <RowBetween align="flex-end">
          <RowFixed gap={'0px'}>
            <CurrencyLogo currency={inputToken} size={'24px'} style={{ marginRight: '15px' }} />

            <TextBaseXl fontWeight="bold" wrapText={true} color={theme.colors.primary1}>
              {typedValue}
            </TextBaseXl>
          </RowFixed>
          <RowFixed gap={'0px'}>
            <TextBaseXl fontWeight="bold">{inputToken?.symbol}</TextBaseXl>
          </RowFixed>
        </RowBetween>

        <RowFixed marginTop={9} marginBottom={9}>
          <ArrowDownIconStyle />
        </RowFixed>

        <RowBetween align="flex-end">
          <RowFixed gap={'0px'}>
            <CurrencyLogo currency={outputToken} size={'24px'} style={{ marginRight: '15px' }} />
            <TextBaseXl fontWeight="bold" wrapText={true} color={theme.colors.primary1}>
              {amountOut?.toSignificant(6)}
            </TextBaseXl>
          </RowFixed>
          <RowFixed gap={'0px'}>
            <TextBaseXl fontWeight="bold">{outputToken?.symbol}</TextBaseXl>
          </RowFixed>
        </RowBetween>
      </ConfirmSwapCurrencies>
    </div>
  )
}
